
import { Box, DialogActions, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material'
import { sendPasswordMail } from 'Apis/Auth/auth';
import Caller from 'Apis/config/Caller';
import { POST } from 'Apis/config/RequestType';
import { update_user } from 'Apis/config/Url';
import { create_user } from 'Apis/config/Url';
import MDButton from 'components/MDButton';
import React, { useEffect, useState } from 'react'

const AddUpdateUser = (props) => {
    const [values, setAllValues] = useState({
        first_name: '',
        last_name: "",
        email: "",
        phone: "",
        extension: '',
        mobile: "",
        profile_pic: ""
    })

    const [errors, setAllErrors] = useState({
        first_name: '',
        last_name: "",
        email: "",
        phone: "",
        extension: '',
        mobile: "",
        profile_pic: ""
    })

    const [isInitialRender, setIsInitialRender] = useState(true);
    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);
            if (props.data.length != 0) {
                setAllValues((values) => ({
                    ...values,
                    first_name: props.data[0].first_name,
                    last_name: props.data[0].last_name,
                    email: props.data[0].email,
                    phone: props.data[0].phone,
                    extension: props.data[0].extension,
                    mobile: props.data[0].mobile,
                    profile_pic: props.data[0].profile_pic
                }))
            }
        }
    }, [])

    
    const handleFileUpload = (event) => {

    }
    const sendPasswordLink =   (event) => {
        
         sendPasswordMail(props.data[0].user_id).then(data => {
            if(data.success == true){
               
                
            }
        });
        
        
        
    }

    const textUpdate = (e) => {
        const re = /^[a-zA-Z0-9 ]+$/;
        if (re.test(e.target.value) || (e.target.value === "")) {
            setAllErrors((values) => ({
                ...values,
                [`${e.target.name}`]: ''
            }));

            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }))
        }
    }

    const emailUpdate = (e) => {
        setAllValues((state) => ({
            ...state,
            [`${e.target.name}`]: e.target.value
        }));
        setAllErrors((values) => ({
            ...values,
            [`${e.target.name}`]: ''
        }));
        const re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        if (re.test(e.target.value) || (e.target.value === "")) {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }))
        }
    }

    const onMobileUpdate = (e) => {
        const re = /^[1-9][0-9]*$/;
        if (re.test(e.target.value) || (e.target.value === "")) {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }));
            setAllErrors((values) => ({
                ...values,
                [`${e.target.name}`]: ''
            }));
        }
    }

    const addAdmin = async (event) => {
        event.target.classList.add("was-validated");
        event.preventDefault();
        event.stopPropagation();
        const formData = new FormData();
        formData.append('first_name', values.first_name);
        formData.append('last_name', values.last_name);
        formData.append('email', values.email);
        formData.append('phone', values.phone);
        formData.append('extension', values.extension);
        formData.append('mobile', values.mobile);
        formData.append('profile_pic', values.profile_pic);
        // formData.append('id',props.data[0].user_id);
        
        await Caller(props.data ? update_user+"?user_type=admin" : create_user+"?user_type=admin", formData, POST, false, true).then((res) => {
            if (res.success === true) {
                props.close();
                
            }else if(res.success === false){
                for (const [key, value] of Object.entries(res.message['errors'])) {
                    setAllErrors((values) => ({
                        ...values,
                        [`${key}`]: value,
                    }))
                }
            }
        })
            .catch(error => { alert(JSON.stringify(error.message)); })
       
    }
    const allTrue = () => {
        if(values.first_name && values.last_name && values.email && values.phone  && values.extension && values.mobile ){
            return false
        }else{
            return true
        }
    }

    return (
        
        <Box
            component="form"
            onSubmit={addAdmin}
            sx={{
                '#demo-row-radio-buttons-group-label': { fontSize: 'medium', textAlign: "left" },
                '.role-select-custom': { pt: 1.5, pb: 1.5 },
                '.add-label': { pb: 1 },
                '.radio-button-custom': { fontSize: 'small' }
            }}
            noValidate
        >
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={6} sm={6} md={6}>
                    <InputLabel className='add-label'>First Name <sup style={{ color: "red" }}>*</sup></InputLabel>
                    <TextField
                        required
                        error={errors.first_name}
                        helperText={errors.first_name === "" ? '' : errors.first_name}
                        id="outlined-required"
                        style={{ width: "90%" }}
                        name='first_name'
                        value={values.first_name}
                        onChange={textUpdate}
                        inputProps={{
                            maxLength: 50
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <InputLabel className='add-label '>Last Name <sup style={{ color: "red" }}>*</sup></InputLabel>
                    <TextField
                    className='border-red'
                        required
                        id="outlined-required"
                        error={errors.last_name}
                        helperText={errors.last_name === "" ? '' : errors.last_name}
                        style={{ width: "90%" }}
                        name="last_name"
                        value={values.last_name}
                        onChange={textUpdate}
                        inputProps={{
                            maxLength: 50
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    
                    <InputLabel className='add-label'>Email Address<sup style={{ color: "red" }}>*</sup></InputLabel>
                    <TextField
                        required
                        id="outlined-required"
                        style={{ width: "90%" }}
                        error={errors.email}
                        helperText={errors.email === "" ? '' : errors.email}
                        name="email"
                        value={values.email}
                        onChange={emailUpdate}
                        inputProps={{
                            maxLength: 50
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <InputLabel className='add-label'>Phone <sup style={{ color: "red" }}>*</sup></InputLabel>
                    <TextField
                        required
                        id="outlined-required"
                        style={{ width: "90%" }}
                        name="phone"
                        error={errors.phone}
                        helperText={errors.phone === "" ? '' : errors.phone}
                        value={values.phone}
                        onChange={onMobileUpdate}
                        inputProps={{
                            maxLength: 10,
                            minLength: 10
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <InputLabel className='add-label'>Extension <sup style={{ color: "red" }}>*</sup></InputLabel>
                    <TextField
                        required
                        id="outlined-required"
                        style={{ width: "90%" }}
                        name="extension"
                        error={errors.extension}
                        value={values.extension}
                        helperText={errors.extension === "" ? '' : errors.extension}
                        onChange={onMobileUpdate}
                        inputProps={{
                            maxLength: 5,
                            minLength: 5
                        }}
                    /></Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <InputLabel className='add-label'>Mobile <sup style={{ color: "red" }}>*</sup></InputLabel>
                    <TextField
                        required
                        id="outlined-required"
                        error={errors.mobile}
                        helperText={errors.mobile === "" ? '' : errors.mobile}
                        style={{ width: "90%" }}
                        name="mobile"
                        value={values.mobile}
                        onChange={onMobileUpdate}
                        inputProps={{
                            maxLength: 10,
                            minLength: 10,
                        }}
                        
                    /></Grid>

                <Grid item xs={6} sm={6} md={6}>
                    <InputLabel className="add-label">File Upload <sup style={{ color: "red" }}>*</sup></InputLabel>
                    <MDButton
                        component="label"
                        variant="outlined"
                        className="upload-file-custom"
                        style={{ width: "90%" }}
                        color="info"
                        id="outlined-required"
                        required>
                        Upload Profile Pic
                        <input type="file" accept="image/*" hidden onChange={handleFileUpload} value={values.profile_pic} name="profile_pic" />
                    </MDButton>
                </Grid>
                {props.data? 
                <Grid item xs={6} sm={6} md={6}>
                    <InputLabel className="add-label">Settings:</InputLabel>
                    <MDButton
                        component="label"
                        
                        style={{ fontWeight: 'bold',textDecorationLine: 'underline',color: "#02babc",padding:"0px",fontSize:"13px" }}
                        onClick={sendPasswordLink}
                        id="outlined-required"
                        >
                        Resend Password Link.
                        
                    </MDButton>
                </Grid>
                : ''}
                <DialogActions
                    sx={{
                        background: "linear-gradient(195deg, #dee1e1 10%, #f4f4f4 360%)",
                        marginTop: "3%",
                        marginBottom: "0%",
                        width: "104%",
                        borderRadius: '0.5rem',
                        transform: "translateY(35%)",
                        '.action-button': { width: 100 }
                    }}
                >
                    <MDButton color='success' className="action-button" disabled={allTrue()} type="submit">{props.data ? "Update": "Add"}</MDButton>
                    <MDButton color='warning' className="action-button" onClick={props.close}>Cancel</MDButton>
                </DialogActions>
            </Grid>
        </Box>
    )
}

export default AddUpdateUser