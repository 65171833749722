


/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Data
import { AppBar, Box, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Icon, IconButton, List, ListItem, ListItemText, Slide, Switch, Tab, Tabs, TextField, Typography, useMediaQuery } from "@mui/material";
import React, { Suspense, forwardRef, useEffect, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { makeStyles } from "@mui/styles";
import { Button, Column, ColumnChooser, DataGrid, Lookup, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar, RequiredRule, NumericRule, RangeRule, StringLengthRule, Popup, Form, AsyncRule, HeaderFilter, ColumnHeaderFilter, FilterRow, Search, Scrolling } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { Pager } from 'devextreme-react/tree-list';
import CloseIcon from '@mui/icons-material/Close';
import { GET, POST, PUT } from "Apis/config/RequestType";
import { delete_leads, leads, duplicate_leads } from "Apis/config/Url";
import Caller from "Apis/config/Caller";
import { useNavigate } from "react-router-dom";
import CustomStore from "devextreme/data/custom_store";
import MDBadge from "components/MDBadge";
// import { getAllLeads } from "Apis/Auth/auth";
import { getCommunicationContact } from "Apis/Auth/auth";

import { EmailRule, PatternRule } from "devextreme-react/form";
import { useSnackbar } from 'notistack';

import Cookies from "js-cookie";
import { RIGHTS } from "Apis/config/ResponseHandler";
import Avatar from "@mui/material/Avatar";
import ChatPage from "pages/organization/communication-assignment/communication-assignment/chat-ui/chatPage";
import { getWhatsAppChatHistory } from "Apis/Auth/auth";
import { CHATNOTIFICATION } from "Apis/config/ResponseHandler";

const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    bottom: "0px",
    left: "2.5%",
    borderTopLeftRadius: '0.7rem',
    borderTopRightRadius: '0.7rem',
    top: 35
  }
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



let updateRowData = {};

const chatHistoryDetails = new CustomStore({
  // key: 'lead_guid',
  load: (loadOptions) => {

    // clearLookUpCache();

    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      "filter",
      "totalSummary",
      "group",
      "groupSummary",
      "isLoadingAll"
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {

        if (i === "skip") {
          let page = (loadOptions[i] + 25) / 25;
          if (page !== 1) {
            params += `page=${page}&`;
          }
        } else if (i === "sort") {
          let sortType = loadOptions[i][0]["desc"] ? "desc" : "asc";
          let sortColoumn = loadOptions[i][0]["selector"];
          params += `sortType=${sortType}&sortColoumn=${sortColoumn}&`;
        } else if (i === "filter") {
          let filterO = loadOptions[i][0]["filterValue"];
          params += `s=${filterO}&`;
        } else {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      }
    }),
      params = params.slice(0, -1);
    let response = getWhatsAppChatHistory(params)
      .then((data) => ({
        data: data.data.data,
        totalCount: data.data.count,
        next: data.data.next,
        previous: data.data.previous
      }));

    return response;
  },


  insert: (values) => Caller(leads, values, POST, false, false).then((res) => {
    if (res.success === true) {
      cancelEvent = false;
    }
    else if (res.message == "Lead already exists.") {
      addTaskValue = values;
      cancelEvent = true;
    }
  }),

  update: (key, values) => Caller(leads + '?create=' + key, { ...updateRowData, ...values }, PUT, false, false).then((res) => {
    values: JSON.stringify(values)
  }),
  remove: (key) => Caller(delete_leads + '?lead_guid=' + key, "", POST, false, false).then((res) => {

    values: JSON.stringify(res)
  }),


});

const isNotEmpty = (value) => {
  return value !== undefined && value !== null && value !== "";
}

const ChatHistoryTab = (props) => {
  let rights = Cookies.get(RIGHTS) ?? "";
  let rightsArray = rights.split(',') ?? [];
  let dataGrid = "";
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [popuptitle, setPopupTitle] = useState("Employee Data 1");
  const [popupGuid, setpopupGuid] = useState("");
  const [updateData, setUpdate] = useState("");
  const userGrid = useRef();
  const newFilteredGrid = useRef();
  const [open, setOpen] = useState(false);
  const [openChatHistory, setOpenChatHistory] = useState(false);
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [currentChatUser, setCurrentChatUser] = useState({
    id: "",
    first_name: "",
    mobile: ""
  })


  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      cancelEvent = false;
      setUpdate("");
      setOpen(false);
      setOpenChatHistory(false);
      setDeleteOpen(false);
    }

  };

  const refreshGrid = () => {
    userGrid.current.instance.refresh();
  }


  // Render ID column
  const renderID = (data) => {
    return <div>{"FDCH-" + data.data.id}</div>
  }

  // On Row click.
  const onRowClick = (data) => {
    setOpenChatHistory(true);
    setpopupGuid(data.data.product_name + " (" + data.data.sub_product_name + ")");
    setUpdate(data.data);
    setCurrentChatUser({
      id: data.data.whatsapp_contact,
      first_name: data.data.first_name,
      mobile: data.data.mobile,
    })
  }

  const CustomTab = ({ label, user }) => {
    return (
      <Tab
        value={label}
        label={
          <Box sx={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
            <Avatar alt={user.first_name} src={`https://material-ui.com/static/images/avatar/alt.jpg`} />
            <Typography variant="h6" sx={{ ml: '0.5vw' }}>
              {user.first_name}
            </Typography>
          </Box>
        }
      />
    );
  };

  const setProfile = (prof) => {
    if (prof.is_group) {
      setCurrentChatUser(prof)
    } else {
      setCurrentChatUser({
        id: prof.whatsapp_contact,
        first_name: prof.first_name,
        mobile: prof.mobile,
      })
    }
  }

  const renderMessage = (data) => {
    if (data.data.whatsapp_message != null || data.data.whatsapp_message != '') {
      return <div>{data.data?.whatsapp_message?.replace(/<\/?[^>]+(>|$)|&[a-zA-Z0-9]+;/g, "")}</div>
    } else {
      return ''
    }
  }

  return (


    <MDBox pb={1}>
      <MDBox p={3} sx={{ '.dx-header-filter.dx-header-filter': { color: '#FFFFFF' }, }}>
        <DataGrid
          id="gridContainer"
          keyExpr="id"
          dataSource={chatHistoryDetails}
          ref={userGrid}
          showBorders={true}
          remoteOperations={true}
          wordWrapEnabled={true}
          showColumnLines={false}
          rowAlternationEnabled={true}
          allowColumnReordering={true}
          onRowClick={onRowClick}
        >


          <ColumnChooser
            enabled={true}
            mode="select"
          />

          <Pager
            visible={true}
            displayMode="full"
            showInfo={true}
            showNavigationButtons={true} />
          <Paging defaultPageSize={25} />


          <SearchPanel
            visible={true}
          />
          <Export
            enabled={(Cookies.get('id') == 1 || Cookies.get('id') == 2)}
            fileName="Chat History"
          />

          <Column
            caption="ID"
            dataField="id"
            alignment="center"
            // cellRender={renderID}
            width={100}
            allowEditing={false}
          >
          </Column>
          <Column
            caption="Name"
            dataField="first_name"
            alignment="center"
            minWidth={80}
          >
            <RequiredRule />
            <PatternRule
              pattern="^[a-zA-Z ]+$"
              message="The name should not contain digits"
            />
          </Column>

          <Column
            caption="Mobile"
            dataField="mobile"
            alignment="center"
            minWidth={50}
          >
            <NumericRule />
            <StringLengthRule message="Mobile must have at least 10 digits" min={10} max={13} />
            <RequiredRule />
          </Column>

          <Column
            caption="Date & Time"
            dataField="updated_at"
            alignment="center"
            minWidth={80}
            dataType="datetime"
            format="MM/dd/yyyy, hh:mm a"
          >

          </Column>

          <Column
            caption="Message"
            dataField="whatsapp_message"
            alignment="center"
            cellRender={renderMessage}
            minWidth={400}
          >

          </Column>
          <Column
            caption="Message Type"
            dataField="message_type"
            alignment="center"
            minWidth={150}
          >

          </Column>
          <Column
            caption="Nature"
            dataField="message_nature"
            alignment="center"
            minWidth={150}
          >

          </Column>

          <Toolbar>

            <Item name="searchPanel" />
            <Item name="exportButton" />

          </Toolbar>

        </DataGrid>


        <Dialog
          fullScreen
          open={openChatHistory}
          onClose={handleClose}
          TransitionComponent={Transition}
          sx={{
            width: "95%",

          }}
          classes={{
            paper: classes.dialog,

          }}
        >
          <DialogTitle
            sx={{
              padding: "0px",

            }}
          ><MDBox
            color="primary"
            variant="gradient"
            opacity={1}
            pl={2}
            pr={2}
            pt={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"

          // textAlign="center"
          >
              <MDTypography variant="h5" color="secondary"> <MDBadge variant="contained" container color="success" ></MDBadge> </MDTypography>
              <IconButton
                style={{ color: "black", cursor: "pointer" }}
                sx={{
                  "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
                }}
                onClick={() => setOpenChatHistory(false)}
              >
                <CloseIcon />
              </IconButton>
            </MDBox>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="icon label tabs example"
                sx={{
                  width: "25%",
                  paddingLeft: 2,
                  fontSize: "small",
                  fontWeight: "600",
                }}>

                <CustomTab mg user={currentChatUser} value={0} />

              </Tabs>
            </Box>

          </DialogTitle>
          <DialogContent
            p={3}
            sx={{
              overflowY: 'hidden',
              height: '100vh',
              backgroundColor: "#eee",
              paddingTop: "17px !important"
            }}>
            {(tabValue == 0) && <ChatPage currentChat={currentChatUser} updateData={updateData} type={"chat"} refresh={refreshGrid} close={handleClose} setProfile={setProfile} />}
          </DialogContent>
        </Dialog>

      </MDBox >
    </MDBox >
  );
}

export default ChatHistoryTab;


