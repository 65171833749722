/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Data
import { Dialog, DialogTitle, FormControlLabel, IconButton, Switch } from "@mui/material";
import React, { useRef, useState } from "react";
import { Column, ColumnChooser, DataGrid, Lookup, Editing, Export, Item, Paging, SearchPanel, Toolbar, RequiredRule } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { Pager } from 'devextreme-react/tree-list';
import CloseIcon from '@mui/icons-material/Close';
import { GET } from "Apis/config/RequestType";
import { PUT } from "Apis/config/RequestType";
import DeletePopup from "layouts/master-data/delete-popup";
import { delete_user } from "Apis/config/Url";
import Caller from "Apis/config/Caller";
import { useNavigate } from "react-router-dom";
import CustomStore from "devextreme/data/custom_store";
import { change_user_status } from "Apis/config/Url";
import { POST } from "Apis/config/RequestType";
import { leads } from "Apis/config/Url";
import { get_product } from "Apis/config/Url";
import { assigned_products } from "Apis/config/Url";
import { getAllAssignedProductUser } from "Apis/Auth/auth";
import { get_team_member_list } from "Apis/config/Url";
import { DELETE } from "Apis/config/RequestType";

import { getAllAssignedCommunication } from "Apis/Auth/auth";
import { get_related_type_designation } from "Apis/config/Url";

import { createAssignCommunication } from "Apis/config/Url";
import { updateAssignedCommunication } from "Apis/config/Url";
import { deleteAssignedComunication } from "Apis/config/Url";
import { getTeamLeader } from "Apis/config/Url";

import TagBox from 'devextreme-react/tag-box';
import DropDownBox from 'devextreme-react/drop-down-box';

let updateRowData = {}
const assignedCommunicationDetails = new CustomStore({
  key: 'id',
  load: (loadOptions) => {

    clearLookUpCache();
    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      "filter",
      "totalSummary",
      "group",
      "groupSummary",
      "isLoadingAll"
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {

        if (i === "skip") {
          let page = (loadOptions[i] + 25) / 25;
          if (page !== 1) {
            params += `page=${page}&`;
          }
        } else if (i === "sort") {
          let sortType = loadOptions[i][0]["desc"] ? "desc" : "asc";
          let sortColoumn = loadOptions[i][0]["selector"];
          params += `sortType=${sortType}&sortColoumn=${sortColoumn}&`;
        } else if (i === "filter") {
          let filterO = loadOptions[i][0]["filterValue"];
          params += `s=${filterO}&`;
        } else {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      }
    });
    params = params.slice(0, -1);
    return getAllAssignedCommunication(params)
      .then((data) => ({
        data: data.data.data,
        totalCount: data.data.count,
        next: data.data.next,
        previous: data.data.previous
      }))
  },

  insert: (values) => Caller(createAssignCommunication, values, POST, false, false).then((res) => {

    values: JSON.stringify(values)
  }),

  update: (key, values) => Caller(updateAssignedCommunication + '?id=' + key, { ...updateRowData, ...values }, POST, false, false).then((res) => {
    values: JSON.stringify(values)
  }),

  remove: (key) => Caller(deleteAssignedComunication + '?id=' + key, "", POST, false, false).then((res) => {
    values: JSON.stringify(res)
  }),


});

const productData = new CustomStore({
  key: 'Value',
  loadMode: 'raw',
  load: (options) => {
    return Caller(get_product + "?isLoadingAll=true&id", '', GET, false, false).then((res) => {
      return res.data.data
    })
  }
});

const teamMemberData = new CustomStore({
  key: 'Value',
  loadMode: 'raw',
  load: (options) => {
    return Caller(getTeamLeader, '', GET, false, false).then((res) => {
      return res.data
    })
  }
});

const departmentData = new CustomStore({
  key: 'Value',
  loadMode: 'raw',
  load: (options) => {
    return Caller(get_related_type_designation + "?take=10&requireTotalCount=true&id=&req_type=departments", '', GET, false, false).then((res) => {
      return res.data.data
    })
  }
});


const clearLookUpCache = () => {
  productData.clearRawDataCache();
  teamMemberData.clearRawDataCache();
}

const isNotEmpty = (value) => {
  return value !== undefined && value !== null && value !== "";
}

const onRowUpdate = (value) => {
  updateRowData = value.oldData
  delete updateRowData.id;
  delete updateRowData.product_id;
  delete updateRowData.employee_user_id;
  delete updateRowData.lead_status_name;
  delete updateRowData.product_name;
}

const AssignedCommunication = () => {
  const productLabel = { 'aria-label': 'Product' };

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [popuptitle, setPopupTitle] = useState("Employee Data 1");
  const [updateData, setUpdate] = useState("");
  const userGrid = useRef();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      if (reason !== 'backdropClick') {
        setUpdate("");
        setOpen(false);
        setDeleteOpen(false);
      }
    }
  };


  const refreshGrid = () => {
    userGrid.current.instance.refresh();
  }


  const handleChanged = async (event, id) => {

    let status = "";
    if (event.target.checked === false) {
      status = 0
    }
    else {
      status = 1
    }
    const formData = new FormData();
    formData.append("id", id);
    formData.append("active", status);
    await Caller(change_user_status + "?user_type=employee", formData, POST, false, true)
      .then((data) => {
        if (data.success === true) {
          refreshGrid();
        }
      });
  }


  const setInitialValues = (e) => {
    clearLookUpCache();
  }

  const [selectedValues, setSelectedValues] = useState([]);

  const handleValueChanged = (e) => {
    setSelectedValues(e.value);
  };

  const renderAssignedBy = (e) => {
    if (e.data.assigned_by == 1) {
      return "Admin"
    } else {
      return "Other"
    }

  }


  return (


    <MDBox pt={2} pb={1}>
      {deleteOpen && <DeletePopup deleteId={updateData} open={deleteOpen} deleteText={deleteText} close={handleClose} refresh={refreshGrid} path={delete_user + "?user_type=employee"} />}




      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='sm'
        sx={{
          '.MuiDialogContent-root': { pl: 5, pr: 5, pb: 5, pt: 2 }
        }}
      >
        <DialogTitle
        ><MDBox
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          shadow="lg"
          opacity={1}
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        // textAlign="center"
        >
            <MDTypography variant="h4" color="white">{popuptitle}</MDTypography>
            <IconButton
              style={{ color: "white", cursor: "pointer" }}
              sx={{
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
              }}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </MDBox></DialogTitle>

      </Dialog>

      <MDBox p={3}>
        <DataGrid
          id="gridContainer"
          keyExpr="id"
          dataSource={assignedCommunicationDetails}
          ref={userGrid}
          showBorders={true}
          remoteOperations={true}
          wordWrapEnabled={true}
          showColumnLines={false}
          rowAlternationEnabled={true}
          allowColumnReordering={true}
          onRowUpdating={onRowUpdate}
          onInitNewRow={setInitialValues}
        >
          <ColumnChooser
            enabled={true}
            mode="select"
          />
          <Pager
            visible={true}
            displayMode="full"
            showInfo={true}
            showNavigationButtons={true} />
          <Paging defaultPageSize={25} />
          <SearchPanel
            visible={true}
          />
          <Export
            enabled={true}
            fileName="Assigned Communications"
          />
          <Editing
            mode="row"
            allowAdding={true}
            allowUpdating={true}
            useIcons={true}
            allowDeleting={true}
          />
          <Column
            caption="Departments"
            dataField="department"
            alignment="center"
            minWidth={150}
          >
            <Lookup dataSource={departmentData} valueExpr="id" displayExpr="name" />
          </Column>

          <Column
            caption="Products"
            alignment="center"
            dataField="product_id"
            minWidth={150}
          >
            <Lookup dataSource={productData} valueExpr="product_id" displayExpr="product_name" />
            <RequiredRule />
          </Column>

          <Column
            caption="Team Leaders"
            dataField="team_leader"
            alignment="center"
            minWidth={150}
          >
            <Lookup dataSource={teamMemberData} valueExpr="employee_user_id" displayExpr="name" />
            <RequiredRule />
          </Column>

          <Column
            caption="Assigned By"
            dataField="assigned_by"
            alignment="center"
            minWidth={150}
            // cellRender={renderAssignedBy}
            allowEditing={false}
          >
          </Column>

          <Column type="buttons" dataField="Actions" minWidth={150}>

          </Column>
          <Toolbar>

            <Item name="searchPanel" />
            <Item name="exportButton" />
            <Item name="columnChooserButton"
              locateInMenu="auto" />
            <Item location="after" name='addRowButton' showText='always' title='a' >
            </Item>
          </Toolbar>

        </DataGrid>

      </MDBox>

    </MDBox>
  );
}

export default AssignedCommunication;
