/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import team2 from "assets/images/team-2.jpg";
import CabinIcon from '@mui/icons-material/Cabin';


// Data
import { AppBar, Autocomplete, Box, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Drawer, FormControlLabel, Grid, Icon, IconButton, List, ListItem, ListItemText, Slide, Switch, Tab, Tabs, TextField, Typography, useMediaQuery } from "@mui/material";
import React, { Fragment, Suspense, forwardRef, useEffect, useRef, useState, useMemo } from "react";
import { useTheme } from "@emotion/react";
import { makeStyles } from "@mui/styles";
import { Button, Column, ColumnChooser, DataGrid, Lookup, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar, RequiredRule, NumericRule, RangeRule, StringLengthRule, Popup, Form, AsyncRule, HeaderFilter, ColumnHeaderFilter, FilterRow, Search, Scrolling } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { Pager } from 'devextreme-react/tree-list';
import CloseIcon from '@mui/icons-material/Close';
import { GET, POST, PUT } from "Apis/config/RequestType";
import { update_user, get_sub_product, get_lead_lookup_source_data, delete_leads, leads, duplicate_leads, pick_lead } from "Apis/config/Url";
import Caller from "Apis/config/Caller";
import { useNavigate } from "react-router-dom";
import CustomStore from "devextreme/data/custom_store";
import MDBadge from "components/MDBadge";
import { getAllLeads, leadSubStatus, leadStatus, getEmployeeCity } from "Apis/Auth/auth";
import OverviewLead from "../single-lead/overview-lead";
// import ActivityLog from "../single-lead/activity-log";
import Payments from "../payments/payments";
import { Validator } from "devextreme-react";
import { EmailRule, PatternRule } from "devextreme-react/form";
import MDButton from "components/MDButton";
import { useSnackbar } from 'notistack';
import BulkUpload from "layouts/bulkupload";
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';

import Cookies from "js-cookie";
import { RIGHTS, UNIVERSAL_SEARCH_COOKIE } from "Apis/config/ResponseHandler";
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import ShowSelectedLeads from './showSelectedLeads';
import ToggleButton from '@mui/material/ToggleButton';
import DoneIcon from '@mui/icons-material/Done';
import { get_product } from "Apis/config/Url";
import { get_lead_employees_name } from "Apis/config/Url";
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { LOGIN_USERTYPE_COOKIE } from "Apis/config/ResponseHandler";

const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    bottom: "0px",
    left: "2.5%",
    borderTopLeftRadius: '0.7rem',
    borderTopRightRadius: '0.7rem',
    top: 35
  },
  filter: {
    position: 'absolute',
    top: "0px",
    left: "50%"
  },
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TransitionDown = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


// Define cachedData outside of the component
// let cachedData = null;


let updateRowData = {};
let addTaskValue = {};
let cancelEvent = false;
const employeeUserUserDetails = new CustomStore({
  key: 'lead_guid',
  load: (loadOptions) => {

    // clearLookUpCache();

    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      "filter",
      "totalSummary",
      "group",
      "groupSummary",
      "isLoadingAll"
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {

        if (i === "skip") {
          let page = (loadOptions[i] + 25) / 25;
          if (page !== 1) {
            params += `page=${page}&`;
          }
        } else if (i === "sort") {
          let sortType = loadOptions[i][0]["desc"] ? "desc" : "asc";
          let sortColoumn = loadOptions[i][0]["selector"];
          params += `sortType=${sortType}&sortColoumn=${sortColoumn}&`;
        } else if (i === "filter") {
          let filterO = loadOptions[i][0]["filterValue"];
          params += `s=${filterO}&`;
        } else {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      }
    }),
      params = params.slice(0, -1);
    let response = getAllLeads(params)
      .then((data) => ({
        data: data.data.data,
        totalCount: data.data.count,
        next: data.data.next,
        previous: data.data.previous
      }));

    return response;
  },


  insert: (values) => Caller(leads, values, POST, false, false).then((res) => {
    if (res.success === true) {
      cancelEvent = false;
    }
    else if (res.message == "Lead already exists.") {
      addTaskValue = values;
      cancelEvent = true;
    }
  }),

  update: (key, values) => Caller(leads + '?create=' + key, { ...updateRowData, ...values }, PUT, false, false).then((res) => {
    values: JSON.stringify(values)
  }),
  remove: (key) => Caller(delete_leads + '?lead_guid=' + key, "", POST, false, false).then((res) => {

    values: JSON.stringify(res)
  }),


});

const newLeadFilteredDataset = new CustomStore({
  key: 'lead_guid',
  load: (loadOptions) => {
    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      "filter",
      "totalSummary",
      "group",
      "groupSummary",
      "isLoadingAll"
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        if (i === "skip") {
          let page = (loadOptions[i] + 25) / 25;
          if (page !== 1) {
            params += `page=${page}&`;
          }
        } else if (i === "sort") {
          let sortType = loadOptions[i][0]["desc"] ? "desc" : "asc";
          let sortColoumn = loadOptions[i][0]["selector"];
          params += `sortType=${sortType}&sortColoumn=${sortColoumn}&`;
        } else if (i === "filter") {
          let filterO = "";
          let guidRemoveArray = [];
          let guidAddArray = [];
          if (loadOptions[i].includes('and')) {
            loadOptions[i].forEach((currentValue, index, arr) => {
              if (Array.isArray(currentValue)) {
                if (currentValue[0] == "!") {
                  guidRemoveArray.push(currentValue[1][2]);
                }
              }
            })
          } else if (loadOptions[i].includes('or')) {
            loadOptions[i].forEach((currentValue, index, arr) => {
              if (Array.isArray(currentValue)) {
                guidAddArray.push(currentValue[2]);
              }
            })
          }
          filterO += (loadOptions[i].includes('and') ? ('s=New Lead&lead_guid=' + guidRemoveArray) : ('s=New Lead&add_lead_guid=' + guidAddArray));
          params += `${filterO}&requireTotalCount=true&isLoadingAll=true `;
        } else {
          params += `${i}=${JSON.stringify(loadOptions[i])}&isLoadingAll=true&`;
        }
      }
    }),
      params = params.slice(0, -1);
    let response = getAllLeads(params ? params : "?&s=New Lead&requireTotalCount=true&isLoadingAll=true")
      .then((data) => ({
        data: data.data.data.filter(results => results.lead_status == "New Lead"),
        totalCount: data.data.count,

      }));

    return response;

  }
});

// const productData = new CustomStore({
//   key: 'Value',
//   loadMode: 'raw',
//   load: (options) => {
//     return Caller(get_product + "?isLoadingAll=true&sortColoumn=product_name&sortType=ASC&id=", '', GET, false, false).then((res) => {
//       return res.data.data
//     })
//   },
// })




const subProductDataStore = new CustomStore({
  key: 'sub_product_id',
  loadMode: 'raw',
  load: (options) => {
    return Caller(get_sub_product, '', GET, false, false).then((res) => {
      return res.data
    })
  }
})

const subProductData = (options) => {
  if (options) {
    return {
      store: subProductDataStore,
      filter: options.data ? ['product_id', '=', options.data.product_id] : null,
    };
  }
}


// old

// const get_lead_employees_name_for_lookup = new CustomStore({
//   key: 'Value',
//   loadMode: 'raw',
//   cacheRawData: false,
//   load: (options) => {
//     return Caller(get_lead_employees_name, '', GET, false, false).then((res) => {
//       return res.data.data;
//     })
//   }
// })


let cachedData = null;

export const clearCache = () => {
  cachedData = null;
};

const get_lead_employees_name_for_lookup = new CustomStore({
  key: 'Value',
  loadMode: 'raw',
  cacheRawData: false,
  load: (options) => {
    // Check if the data is already cached
    if (cachedData) {
      // Return a resolved promise with the cached data
      return Promise.resolve(cachedData);
    }

    // Make the API call if the data is not cached
    return Caller(get_lead_employees_name, '', GET, false, false).then((res) => {
      cachedData = res.data.data; // Cache the data
      return cachedData;
    });
  }
});

const categoryOptions = [
  { Value: 'L1' },
  { Value: 'L2' },
  { Value: 'L3' }
];

const dropdownValues = [
  // { value: 'L1', label: 'L1' },
  { value: 'L2', label: 'L2' },
  // { value: 'L3', label: 'L3' },
];

// Custom store to provide data for the dropdown
const categoryDataSource = new CustomStore({
  key: 'Value',
  loadMode: 'raw',
  load: () => {
    return categoryOptions;
  }
});


const get_assign_to_lead_employees_name = new CustomStore({
  key: 'Value',
  loadMode: 'raw',
  load: (options) => {
    return Caller(get_lead_employees_name, '', GET, false, false).then((res) => {
      let newData = res.data.data.map(item => {
        return {
          text: item.employee_name,
          value: item.employee_user_id
        };
      });
      return newData;
    })
  }
})

const isNotEmpty = (value) => {
  return value !== undefined && value !== null && value !== "";
}


const onRowUpdate = (value) => {
  updateRowData = value.oldData
  delete updateRowData.id;
  delete updateRowData.assignee_name;
  delete updateRowData.lead_source_name;
  delete updateRowData.lead_status_name;
  delete updateRowData.product_name;
  delete updateRowData.sub_product_name;
  delete updateRowData.option_value;
}

const newLeadSelectionFilter = ['lead_status', '=', 'New Lead'];

const AllLeads = (props) => {

  let universalSearchCookie = Cookies.get(UNIVERSAL_SEARCH_COOKIE);
  Cookies.set(UNIVERSAL_SEARCH_COOKIE, "")

  let rights = Cookies.get(RIGHTS) ?? "";
  let rightsArray = rights.split(',') ?? [];
  let dataGrid = "";
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [popuptitle, setPopupTitle] = useState("Employee Data 1");
  const [popupGuid, setpopupGuid] = useState("");
  const [updateData, setUpdate] = useState("");
  const userGrid = useRef();
  const newFilteredGrid = useRef();
  const [open, setOpen] = useState(false);
  const [openLead, setOpenLead] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [bulkOpen, setBulkOpen] = useState(0);
  const [selectedData, setSelectedData] = useState(null);
  const [showSelectedPopup, setShowSelectedPopup] = useState(false);
  const [leadAssignBtnSelected, setLeadAssignBtnSelected] = useState(false);
  const [columnsList, setColumnsList] = useState([]);
  const [columnsValue, setColumnsValue] = useState([]);
  const [columnListValue, setColumnListValue] = useState();
  const [columnValueSelected, setColumnValueSelected] = useState();
  const [showFilteredConditions, setShowFilteredConditions] = useState(false);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [pullLeadPopupOpen, setPullLeadPopupOpen] = useState(false)
  const [pickLeadValue, setPickLeadValue] = useState()
  const [errorPickLead, setErrorPickLead] = useState('');

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setBulkOpen(false);
      setConfirmPopup(false);
      cancelEvent = false;
      setUpdate("");
      setOpen(false);
      setOpenLead(false);
      setDeleteOpen(false);
    }
    setColumnListValue('');
    setColumnValueSelected('');
    setOpenFilter(false);
  };


  const [userType, setUserType] = useState()

  useEffect(() => {
    let userType = Cookies.get('auth_usertype');
    setUserType(parseInt(userType));
  }, []);

  const [isValidPickLead, setIsValidPickLead] = useState(false);

  const handleInputPickLeadChange = (e) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value >= 1 && value <= 20) {
      setPickLeadValue(value);
      setIsValidPickLead(true);
      setErrorPickLead('');
    } else if (e.target.value === '') {
      setPickLeadValue('');
      setIsValidPickLead(false);
      setErrorPickLead('');
    } else {
      setPickLeadValue(value);
      setIsValidPickLead(false);
      setErrorPickLead('Max 20 Leads Allowed');
    }
  };

  function cellRender(data) {
    return <img src={team2} style={{ borderRadius: "50%", width: "2.5rem", height: "2.5rem" }} />;
  }



  // old

  // const [isNotProduct, setIsNotProduct] = useState(false);


  // const productData = new CustomStore({
  //   key: 'Value',
  //   loadMode: 'raw',
  //   load: (options) => {
  //     return Caller(get_product + "?isLoadingAll=true&sortColoumn=product_name&sortType=ASC&id=", '', GET, false, false).then((res) => {
  //       if (res.data.data.length < 1) {
  //         setIsNotProduct(true)
  //       }
  //       return res.data.data
  //     })
  //   },
  // })

  // let cachedProductData = null;

  // const productData = new CustomStore({
  //   key: 'Value',
  //   loadMode: 'raw',
  //   load: (options) => {
  //     // Check if the data is already cached
  //     if (cachedProductData) {
  //       // Return a resolved promise with the cached data
  //       return Promise.resolve(cachedProductData);
  //     }

  //     // Make the API call if the data is not cached
  //     return Caller(get_product + "?isLoadingAll=true&sortColoumn=product_name&sortType=ASC&id=", '', GET, false, false).then((res) => {
  //       if (res.data.data.length < 1) {
  //         setIsNotProduct(true);
  //       }
  //       cachedProductData = res.data.data; // Cache the data
  //       return cachedProductData;
  //     });
  //   },
  // });

  const [isNotProduct, setIsNotProduct] = useState(false);
  const [leadSourceData, setLeadSourceData] = useState(null);
  const cachedProductData = useRef(null);

  const productData = useMemo(() => {
    return new CustomStore({
      key: 'Value',
      loadMode: 'raw',
      load: (options) => {
        if (cachedProductData.current) {
          // Return a resolved promise with the cached data
          return Promise.resolve(cachedProductData.current);
        }

        // Make the API call if the data is not cached
        return Caller(get_product + "?isLoadingAll=true&sortColoumn=product_name&sortType=ASC&id=", '', GET, false, false).then((res) => {
          if (res.data.data.length < 1) {
            setIsNotProduct(true);
          }
          cachedProductData.current = res.data.data; // Cache the data

          return cachedProductData.current;
        });
      },
    });
  }, []);

  const cachedLeadSourceData = useRef(null);

  const lookup_lead_source = new CustomStore({
    key: 'Value',
    loadMode: 'raw',
    cacheRawData: false,
    load: (options) => {
      // Check if the data is already cached
      if (cachedLeadSourceData.current) {
        // Return a resolved promise with the cached data
        return Promise.resolve(cachedLeadSourceData.current);
      }
      return Caller(get_lead_lookup_source_data + "?isLoadingAll=true&id", '', GET, false, false).then((res) => {

        cachedLeadSourceData.current = res.data; // Cache the data
        setLeadSourceData(cachedLeadSourceData.current);
        return cachedLeadSourceData.current;
      });
    }
  })



  const refreshGrid = () => {
    setLeadAssignBtnSelected(false);
    if (leadAssignBtnSelected) {
      newFilteredGrid.current.instance.refresh();
    } else {
      userGrid.current.instance.refresh();
    }
  }

  // Render Action column
  const renderRecordingColumn = (data) => {
    if (data.data.is_call_recording == "Yes") {
      return <IconButton color="success" ><PermPhoneMsgIcon /></IconButton >
    } else {
      return <IconButton><PhoneDisabledIcon style={{ color: "red", transform: 'rotate(90deg)' }} /></IconButton>
    }
  }

  // Render ID column
  const renderID = (data) => {
    return <div>{"FD-" + data.data.id}</div>
  }

  // On Row click.
  const onRowClick = (data) => {
    setOpenLead(true); setpopupGuid(data.data.product_name + " (" + data.data.sub_product_name + ")"); setUpdate(data.data); setPopupTitle((data.data.first_name ? data.data.first_name : ""));
  }

  const renderGridCellStage = (data) => {

    switch (data.value) {
      case "Closed":
        return <MDTypography style={{ color: 'white', background: 'linear-gradient(195deg, #0f9b0f 10%, #000000 360%)' }} variant="subtitle1">{data.value}</MDTypography>;
      case "New Lead":
        return <MDTypography style={{ color: 'white', background: 'linear-gradient(195deg, #DD2476 10%, #FF512F 360%)' }} variant="subtitle1">{data.value}</MDTypography>;
      case "Follow Up":
        return <MDTypography style={{ color: 'white', background: 'linear-gradient(195deg, #F37335 10%, #000000 360%)' }} variant="subtitle1">{data.value}</MDTypography>;
      case "Interested":
        return <MDTypography style={{ color: 'white', background: 'linear-gradient(195deg, #FFC837 10%, #F09819 360%)' }} variant="subtitle1">{data.value}</MDTypography>;
      case "Not Interested":
        return <MDTypography style={{ color: 'white', background: 'linear-gradient(195deg, #dd1818 10%, #1f1c18 360%)' }} variant="subtitle1">{data.value}</MDTypography>;
      default:
        break;
    }
  }


  const renderGridPickLead = (data) => {

    switch (data.value) {
      case "L1":
        return <MDTypography style={{ color: 'white', background: 'linear-gradient(195deg, #0f9b0f 10%, #799F0C 360%)' }} variant="subtitle1">{data.value}</MDTypography>;
      case "L2":
        return <MDTypography style={{ color: 'white', background: 'linear-gradient(195deg, #F37335 10%, #000000 360%)' }} variant="subtitle1">{data.value}</MDTypography>;
      case "L3":
        return <MDTypography style={{ color: 'white', background: 'linear-gradient(195deg, #DD2476 10%, #FF512F 360%)' }} variant="subtitle1">{data.value}</MDTypography>;
      default:
        break;
    }
  }


  const renderGridCellSubStage = (data) => {

    if (data.data.lead_status == "Not Interested") {
      return "";
    }

    switch (data.value) {
      case "Open":
        return <MDTypography style={{ color: 'white', background: 'linear-gradient(195deg, #0f9b0f 10%, #799F0C 360%)' }} variant="subtitle1">{data.value}</MDTypography>;
      case "Callback":
        return <MDTypography style={{ color: 'white', background: 'linear-gradient(195deg, #274046 10%, #a8c0ff 360%)' }} variant="subtitle1">{data.value}</MDTypography>;
      case "Not Reachable":
        return <MDTypography style={{ color: 'white', background: 'linear-gradient(195deg, #870000 10%, #000000 360%)' }} variant="subtitle1">{data.value}</MDTypography>;
      default:
        break;
    }
  }


  const renderPaymentStatus = (e) => {
    if (e.data.payment_status == "Paid") {
      return <IconButton color="success"><DoneIcon /></IconButton>;
    } else {
      return <IconButton color="error"><CloseIcon /></IconButton>
    }
  }

  const setInitialValues = (e) => {
    // clearLookUpCache();
    e.data.lead_status = "New Lead";
    e.data.lead_sub_status = "Open";
    e.data.lead_source = 35;
    e.data.category = 'L2';

    // let user_id = Cookies.get(EMP_USER_ID);
    // e.data.assigned_to = user_id ? parseInt(user_id) : "";
  }



  const onEditorPreparing = (e) => {
    if ((e.dataField === "lead_status" || e.dataField === "lead_sub_status") && e.parentType === "dataRow") {
      e.editorOptions.disabled = e.row.data && e.row.isNewRow === true;
    }
  }

  const getFilteredSubStages = (options) => {
    if (options) {
      return {
        store: leadSubStatus,
        filter: options.data ? ['status_type', '=', options.data.lead_status] : null,
      };
    }
  }

  const handlePopupClose = (e) => {
    e.cancel = cancelEvent;
    if (cancelEvent === false) {
      setConfirmPopup(false);
    } else {
      setConfirmPopup(true);
    }
  }
  const handleConfirmSubmit = (e) => {
    employeeUserUserDetails.insert(Caller(leads + "?task=yes", addTaskValue, POST, false, false).then(res => {
      if (res.success === true) {
        enqueueSnackbar(<div>Task added for the Lead: <br />Name: {res.data.first_name} {res.data.last_name} <br />Mobile: {res.data.mobile}</div>, { variant: 'success', autoHideDuration: 4000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
      }
    }));
    cancelEvent = false;
    setConfirmPopup(false);
    refreshGrid();
    userGrid.current.instance.saveEditData();
  }


  const pullLeadConfirmSubmit = async (e) => {

    const formData = new FormData();
    formData.append('leads', pickLeadValue);


    // await Caller(pick_lead, formData, POST, false, true).then((data) => {
    //   setPullLeadPopupOpen(false)
    //   console.log("data result===>",data);
    //   if (data.success == true) {
    //     enqueueSnackbar(`Lead Assigned Successfully.`, { variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
    //   } else {
    //     enqueueSnackbar(`Lead Not Assigned.`, { variant: 'warning', autoHideDuration: 4000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });

    //   }
    // })

    await Caller(pick_lead, formData, POST, false, true)
      .then((data) => {
        setPullLeadPopupOpen(false);
        if (data.success === true) {
          enqueueSnackbar(`Lead Assigned Successfully.`, {
            variant: 'success',
            autoHideDuration: 2000,
            TransitionProps: { direction: "left" },
            anchorOrigin: { horizontal: "right", vertical: "top" }
          });
          setPickLeadValue();
          userGrid.current.instance.refresh();
        } else {
          if (data.data == null) {
            enqueueSnackbar(`${data.message}`, {
              variant: 'error',
              autoHideDuration: 2000,
              TransitionProps: { direction: "left" },
              anchorOrigin: { horizontal: "right", vertical: "top" }
            });
            setPickLeadValue();
          } else {
            enqueueSnackbar(`Leads not able to Assigned.`, {
              variant: 'warning',
              autoHideDuration: 2000,
              TransitionProps: { direction: "left" },
              anchorOrigin: { horizontal: "right", vertical: "top" }
            });
            setPickLeadValue();
          }
        }
      })
      .catch((error) => {
        enqueueSnackbar(`Error Occured!`, {
          variant: 'error',
          autoHideDuration: 2000,
          TransitionProps: { direction: "left" },
          anchorOrigin: { horizontal: "right", vertical: "top" }
        });
        setPickLeadValue();
      });
  }


  const setStatusValue = (rowData, value) => {
    rowData.lead_sub_status = null;
    rowData.lead_status = value;
  }

  const setSubProductValue = (rowData, value) => {

    rowData.sub_product_id = null;
    rowData.product_id = value;
  }

  const handleOpenBulk = () => {
    setBulkOpen(true);
  };

  const closeBulk = () => {
    setBulkOpen(false);
    userGrid.current.instance.refresh();
  }

  const renderFbPageName = (e) => {
    let pageName;
    switch (e.row.data.fb_lead_page_source) {
      case '102463499036880':
        pageName = "FirstDemat";
        break;
      case '103272099125675':
        pageName = "Hero Cars";
        break;
      case '116361374755304':
        pageName = "Brilliant Traders Research Experts";
        break;
      case '101326706267041':
        pageName = "Nifty/BankNifty : 99% Sureshot Calls By Experts";
        break;
      case '119724604368127':
        pageName = "Banknifty SureShot Calls: 99% Accurate Calls";
        break;
      case '106088448503209':
        pageName = "Stock Option Super King";
        break;
      case '1478733429066289':
        pageName = "Free Stock Tips";
        break;
      case '101136056290008':
        pageName = "Call Option King";
        break;
      case '100796939449243':
        pageName = "Nifty/Bank Nifty Super King";
        break;
      case '100219223050402':
        pageName = "Banknifty King House";
        break;
      case '105868722450715':
        pageName = "BankNifty Expert Team";
        break;
      case '113721158254444':
        pageName = "Call Put Experts";
        break;
      case '116536851398467':
        pageName = "BankNifty Option Expert";
        break;
      case '101472729496917':
        pageName = "Nifty Option Tips-Nifty Calls";
        break;
      case '103037159404721':
        pageName = "BankNifty Nifty SureShot";
        break;
      case '110258412088860':
        pageName = "Banknifty Sure Shot Calls";
        break;
      case '108303198988423':
        pageName = "Banknifty Superking";
        break;
      case '101272396362308':
        pageName = "FinniftyTraders";
        break;
      case '117299141368907':
        pageName = "Banknifty Experts";
        break;
      case '117768104615135':
        pageName = "Experet Option Trader";
        break;
      case '115191484879591':
        pageName = "Banknifty Expert King";
        break;
      case '110497765347980':
        pageName = "Banknifty Master";
        break;
      case '111342071910864':
        pageName = "Stock Option Expert";
        break;
      case '114068711736286':
        pageName = "Option Traders";
        break;
      case '110212558688067':
        pageName = "Invest Researcher Team";
        break;
      case '110113592049614':
        pageName = "Nifty Calls Expert";
        break;
      case '115789671461620':
        pageName = "Index Option King";
        break;
      default:
        pageName = '';
        break;
    }
    return pageName;
  }


  const openPopup = async () => {
    await sendSelectedRows();
    setShowSelectedPopup(true);
  };

  const closePopup = () => {
    setShowSelectedPopup(false);
  };

  const leadAssignBtnToggle = () => {
    setShowFilteredConditions(false);
    setColumnListValue('');
    setColumnValueSelected('');
    setLeadAssignBtnSelected(!leadAssignBtnSelected);
  };

  const sendSelectedRows = () => {
    newFilteredGrid.current.instance.getSelectedRowsData().then((rowData) => {
      setSelectedData(rowData);
    })
  }

  const setColumnNameFilter = async (e, newValue, clickType) => {

    if (clickType == 'clear') {
      setColumnListValue('');
    } else {
      setColumnListValue(newValue);
      setColumnValueSelected('');
      switch (newValue) {
        case 'Product':
          setColumnsValue(productData.__rawData);
          break;
        case 'Assigned To':
          // Old
          // setColumnsValue(get_lead_employees_name_for_lookup.__rawData);
          // NEW
          const assigned_to = await get_lead_employees_name_for_lookup.load();
          setColumnsValue(assigned_to);
          break;
        case 'Lead Status':
          setColumnsValue(leadStatus);
          break;
        case 'Lead Sub Status':
          setColumnsValue(leadSubStatus);
          break;
        case 'Payment Status':
          setColumnsValue([{ payment_status: 'Paid' }, { payment_status: 'Not Paid' }]);
          break;
        case 'Lead Source':
          // setColumnsValue(lookup_lead_source.__rawData);
          setColumnsValue(leadSourceData);
          break;
        // Changes Start
        case 'City':
          let resultCity = await getEmployeeCity();
          setColumnsValue(resultCity)
          break;
        // Changes End
        case 'Category':
          setColumnsValue([{ category: 'L1' }, { category: 'L2' }, { category: 'L3' }]);
        default:
          break;
      }
    }
  }

  const setColumnValueFilter = (e, newValue, clickType) => {
    if (clickType == 'clear') {
      setColumnValueSelected('');
    } else {
      setColumnValueSelected(newValue);
    }
  }

  // const startFilter = () => {
  //   switch (columnListValue) {
  //     case 'Product':
  //       filterDataGridColumns('product_id', columnValueSelected, 4, "=")
  //       break;
  //     case 'Assigned To':
  //       filterDataGridColumns('assigned_to', columnValueSelected, 6, "contains")
  //       break;
  //     case 'Lead Status':
  //       filterDataGridColumns('lead_status', columnValueSelected, 7, "=")
  //       break;
  //     case 'Lead Sub Status':
  //       filterDataGridColumns('lead_sub_status', columnValueSelected, 8, "=")
  //       break;
  //     case 'Payment Status':
  //       filterDataGridColumns('payment_status', columnValueSelected)
  //       break;
  //     case 'Lead Source':
  //       filterDataGridColumns('lead_source', columnValueSelected, 13, "=")
  //       break;
  //     // Changes start
  //     case 'City':
  //       filterDataGridColumns('lead_source', columnValueSelected, 6, "=")
  //       break;
  //     // Changes End
  //     case 'Category':
  //       // filterDataGridColumns('category', columnValueSelected,)
  //       filterDataGridColumns('payment_status', columnValueSelected)
  //     default:
  //       break;
  //   }
  // }

  // const filterDataGridColumns = (filterColumn, filterValue, columnIndex, filterType) => {
  //   let ds = userGrid.current.instance.getDataSource();
  //   ds.filter([{ 0: filterColumn, 1: filterType, 2: filterValue, columnIndex: columnIndex, filterValue: filterValue }]);
  //   ds.load();
  //   setOpenFilter(false);
  //   setShowFilteredConditions(true);
  // }

  const startFilter = () => {
    switch (columnListValue) {
      case 'Product':
        filterDataGridColumns('product_id', columnValueSelected, 4, "=");
        break;
      case 'Assigned To':
        filterDataGridColumns('assigned_to', columnValueSelected, 6, "contains");
        break;
      case 'Lead Status':
        filterDataGridColumns('lead_status', columnValueSelected, 7, "=");
        break;
      case 'Lead Sub Status':
        filterDataGridColumns('lead_sub_status', columnValueSelected, 8, "=");
        break;
      case 'Payment Status':
        filterDataGridColumns('payment_status', columnValueSelected);
        break;
      case 'Lead Source':
        filterDataGridColumns('lead_source', columnValueSelected, 13, "=");
        break;
      // Changes start
      case 'City':
        filterDataGridColumns('city', columnValueSelected, 6, "=");
        break;
      // Changes End
      case 'Category':
        filterDataGridColumns('category', columnValueSelected);
        break;
      default:
        break;
    }
  }

  const filterDataGridColumns = (filterColumn, filterValue, columnIndex = 0, filterType = "=") => {
    let ds = userGrid.current.instance.getDataSource();
    ds.filter([{ columnIndex, filterType, filterValue }]);
    ds.load();
    setOpenFilter(false);
    setShowFilteredConditions(true);
  }


  const clearFilter = async () => {
    let ds = userGrid.current.instance.getDataSource();
    ds.filter(null);
    ds.load();
    setShowFilteredConditions(false);
    setColumnListValue('');
    setColumnValueSelected('');
  }

  const pullLeadPopupClose = () => {
    setPullLeadPopupOpen(false)
  }

  const toolbarItems = [
    {
      widget: 'dxButton',
      location: 'before',
      options: {
        icon: 'filter', //working
        // text: "Filter On",
        visible: !showFilteredConditions,
        onClick: () => {
          setOpenFilter(true);
          setColumnsList(userGrid.current.instance.getVisibleColumns());
        },
        stylingMode: 'text',
        elementAttr: {
          style: {

          },
          class: 'filter-icon',
        },
      },
    },

    {
      widget: 'dxButton',
      location: 'before',
      options: {
        // icon: 'clear',
        icon: 'remove',
        visible: showFilteredConditions,
        onClick: () => {
          clearFilter();
        },
        stylingMode: 'text',
        elementAttr: {
          style: {
            // color: 'white', // Change text color
            // backgroundColor: '#F44335', // Change background color
            alignItems: 'left'
          },
          class: 'clear-filter'
        },
      },
    },

    {
      widget: 'dxButton',
      location: 'before',
      options: {
        text: `Filtered Value (${columnListValue} : ${columnValueSelected})`,
        visible: showFilteredConditions,
        stylingMode: 'text',
        elementAttr: {
          style: {
            color: '#344767', // Change text color
            fontSize: '18px',
            fontWeight: 'bold',
            // backgroundColor: '#F44335', // Change background color
            // alignItems: 'left'
          },
        },
      },
    },
    {
      widget: 'dxButton',
      location: 'after',
      disabled: rightsArray.includes('111') ? false : true,
      options: {
        text: 'Lead Assignment Mode(Off)',
        stylingMode: 'text',
        elementAttr: {
          style: {
            color: 'white', // Change text color
            backgroundColor: '#F44335', // Change background color
          },
        },
        onClick: () => {
          // lead assignment mode
          leadAssignBtnToggle();
        },
      },
    },
    'searchPanel',
    'exportButton',
    'columnChooserButton',
    {
      widget: 'dxButton',
      location: 'after',
      options: {
        icon: 'add',
        text: 'Add a Lead',
        stylingMode: 'text',
        elementAttr: {
          style: {
            color: 'white',
            backgroundColor: '#4CAF50',
          },
          class: 'white-icon',
        },
        onClick: () => {
          userGrid.current.instance.addRow();
        },
      },
    },

    // {
    //   widget: 'dxButton',
    //   location: 'after',
    //   options: {
    //     text: 'Bulk Lead Upload',
    //     stylingMode: 'text',
    //     elementAttr: {
    //       style: {
    //         color: 'white',
    //         backgroundColor: '#E91E63',
    //       },
    //       class: 'white-icon',
    //     },
    //     onClick: () => {
    //       // bulk lead upload
    //       handleOpenBulk();
    //     },
    //   },
    // },
  ];


  if (userType == 1 || userType == 4) {
    toolbarItems.push(
      {
        widget: 'dxButton',
        location: 'after',
        options: {
          text: 'Bulk Lead Upload',
          stylingMode: 'text',
          elementAttr: {
            style: {
              color: 'white',
              backgroundColor: '#E91E63',
            },
            class: 'white-icon',
          },
          onClick: () => {
            // bulk lead upload
            handleOpenBulk();
          },
        },
      }
    )
  }




  if (userType == 4 || userType == 5) {
    toolbarItems.splice(3, 0, {
      widget: 'dxButton',
      location: 'after',
      options: {
        text: 'Pull Lead/Pick Lead',
        stylingMode: 'text',
        elementAttr: {
          style: {
            color: 'white', // Change text color
            backgroundColor: '#4CAF50', // Change background color
          },
        },
        onClick: () => {
          setPullLeadPopupOpen(true);
        },
      },
    });
  }

  const renderInvestment = (data) => {
    if (data.value == null || data.value == undefined || data.value == "" || data.value == "null" || data.value == "0" || data.value.startsWith("0")) {
      return <div>{""}</div>
    } else {
      return <div>{data.value}</div>
    }
  }

  return (


    <MDBox pb={1}>
      <Dialog
        open={confirmPopup}
        onClose={handleClose}
        maxWidth='sm'
        sx={{
          '.MuiDialogContent-root': { pl: 5, pr: 5, pb: 5, pt: 2 },
          zIndex: 2000
        }}
      >
        <DialogTitle
        ><MDBox
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          shadow="lg"
          opacity={1}
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        // textAlign="center"
        >
            <MDTypography variant="h4" color="white">Confirm</MDTypography>
            <IconButton
              style={{ color: "white", cursor: "pointer" }}
              sx={{
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
              }}
              onClick={() => { setConfirmPopup(false); cancelEvent = false; userGrid.current.instance.cancelEditData(); }}
            >
              <CloseIcon />
            </IconButton>
          </MDBox></DialogTitle>
        <DialogContent p={3}>
          Lead already exists. Do you want to add it as a task for the corresponding lead ?
        </DialogContent>
        <DialogActions
          sx={{
            background: "linear-gradient(195deg, #dee1e1 10%, #f4f4f4 360%)",
            marginTop: "5%",
            width: "100%",
            borderRadius: '0.5rem',
            '.action-button': { width: 120 }
          }}
        >
          <MDButton color='success' className="action-button" onClick={handleConfirmSubmit}>Yes</MDButton>
          <MDButton color='warning' className="action-button" onClick={() => { setConfirmPopup(false); cancelEvent = false; userGrid.current.instance.cancelEditData(); }}>No</MDButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={pullLeadPopupOpen}
        onClose={pullLeadPopupClose}
        maxWidth='sm'
        sx={{
          '.MuiDialogContent-root': { pl: 5, pr: 5, pb: 5, pt: 2 },
          zIndex: 2000
        }}
      >
        <DialogTitle
        ><MDBox
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          shadow="lg"
          opacity={1}
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        // textAlign="center"
        >
            <MDTypography variant="h4" color="white">PICK LEADS</MDTypography>
            <IconButton
              style={{ color: "white", cursor: "pointer" }}
              sx={{
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
              }}
              onClick={() => {
                setPullLeadPopupOpen(false);
                setPickLeadValue('')
                setErrorPickLead('');
              }}
            >
              <CloseIcon />
            </IconButton>
          </MDBox></DialogTitle>
        <DialogContent p={3}>
          {/* <TextField
            label="Pick Leads"
            type="number"
            fullWidth
            margin="normal"
            value={pickLeadValue}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              if (!isNaN(value) && value >= 1 && value <= 20) {
                setPickLeadValue(value);
              } else if (e.target.value === '') {
                // Allow clearing the input field
                setPickLeadValue('');
              }
            }}
            inputProps={{ min: "1", max: "20", step: "1" }}
          /> */}

          <TextField
            label="Pick Leads"
            type="number"
            fullWidth
            margin="normal"
            value={pickLeadValue}
            onChange={handleInputPickLeadChange}
            inputProps={{ min: "1", max: "20", step: "1" }}
            error={!!errorPickLead}
            helperText={errorPickLead}
          />
        </DialogContent>
        <DialogActions
          sx={{
            background: "linear-gradient(195deg, #dee1e1 10%, #f4f4f4 360%)",
            marginTop: "5%",
            width: "100%",
            borderRadius: '0.5rem',
            '.action-button': { width: 120 }
          }}
        >
          <MDButton color='success' className="action-button" onClick={pullLeadConfirmSubmit} disabled={!isValidPickLead}>PICK</MDButton>
          <MDButton color='warning' className="action-button" onClick={() => {
            setPullLeadPopupOpen(false);
            setPickLeadValue('')
            setErrorPickLead(''); // Resetting the error state
          }}>CANCEL</MDButton>
        </DialogActions>
      </Dialog>


      <MDBox p={3} display="flex" justifyContent="center">

        {isNotProduct && (
          <Box
            pb={1}
            height={100}
            width={1000}
            my={4}
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={2}
            sx={{ border: '2px solid #F44335', color: '#F44335' }}
          >
            You Don't Have Any Product, Please Contact Admin Or Team Leader.
          </Box>
        )}


        {!leadAssignBtnSelected && !isNotProduct && <DataGrid
          id="gridContainer"
          keyExpr="id"
          dataSource={employeeUserUserDetails}
          ref={userGrid}
          showBorders={true}
          remoteOperations={true}
          wordWrapEnabled={true}
          showColumnLines={false}
          rowAlternationEnabled={true}
          allowColumnReordering={true}
          onRowUpdating={onRowUpdate}
          onInitNewRow={setInitialValues}
          onEditorPreparing={onEditorPreparing}
          onRowClick={onRowClick}
          onRowUpdated={() => {
            cachedData1 = null; // Clear the cache
            lookup_lead_source.load(); // Load fresh data for lookup
          }}
          onOptionChanged={(e) => {
            if (e.name === "dataSource") {
              // When the dataSource changes, clear the cache and load fresh data
              cachedData1 = null; // Clear the cache
              lookup_lead_source.load(); // Load fresh data for lookup
            }
          }}
        >
          <ColumnChooser
            enabled={true}
            mode="select"
          />
          <Pager
            visible={true}
            displayMode="full"
            showInfo={true}
            showNavigationButtons={leadAssignBtnSelected ? false : true}
            allowedPageSizes={'all'}
          />
          <Paging defaultPageSize={25} />


          <SearchPanel
            visible={true}
            defaultText={universalSearchCookie}
          />
          <Export
            enabled={(Cookies.get('id') == 1 || Cookies.get('id') == 2)}
            fileName="Leads"
          />
          <Editing
            mode="popup"
            allowAdding={true}
            //remove update access from team leader and team member
            // allowUpdating={(rightsArray.includes('100') && rightsArray.includes('108') && !rightsArray.includes('201')) ? false : true}
            useIcons={true}
            //remove delete access from team leader and team member
            allowDeleting={true}
          >
            <Popup
              title="Lead Information"
              showTitle={true}
              width="52vw"
              height="70vh"
              dragEnabled={false}
              onHiding={handlePopupClose}
            />
            <Form>
              <Item itemType="group" colCount={2} colSpan={2}>
                <Item dataField="first_name" />
                <Item dataField="last_name" />
                <Item dataField="product_id" />
                <Item dataField="sub_product_id" />
                <Item dataField="lead_status" />
                <Item dataField="lead_sub_status" />
                <Item dataField="mobile" />
                <Item dataField="email" />
                <Item dataField="lead_source" />
                <Item dataField="investment" />
                {/* <Item dataField="Capital Amount" /> */}

                <Item dataField="expected_profit" />
                <Item dataField="assigned_to" />
                <Item dataField="city" />
                <Item
                  dataField="category"
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: dropdownValues,
                    displayExpr: 'label',
                    valueExpr: 'value',
                    acceptCustomValue: false,
                    searchEnabled: false,
                    disabled: true,
                  }}
                />

                <Item cssClass="addleadConfirmText" visible={confirmPopup}>
                  <MDTypography variant="h5">Lead already exists. Do you want to add it as a task for the corresponding lead ?</MDTypography>
                </Item>
              </Item>
            </Form>
          </Editing>
          {/* <Column
            caption="View"
            alignment="center"
            cellRender={renderProductColumn}
            width={80}
            allowEditing={false}
          >
          </Column> */}

          <Column
            caption="Recordings"
            alignment="center"
            cellRender={renderRecordingColumn}
            width={90}
            allowEditing={false}
          >
          </Column>
          <Column
            caption="ID"
            dataField="id"
            alignment="center"
            // cellRender={renderID}
            // width={80}
            allowEditing={false}
            allowHeaderFiltering={false}  //disable the header filter for this column
          >
          </Column>

          <Column
            caption="Category"
            alignment="center"
            dataField="category"
            cellRender={renderGridPickLead}
          // minWidth={200}
          // dataType='string'
          >
            <RequiredRule />
            <Lookup dataSource={categoryDataSource} valueExpr="Value" displayExpr="Value" />

          </Column>

          <Column
            caption="First Name"
            dataField="first_name"
            alignment="center"
            // minWidth={150}
            allowHeaderFiltering={false}
          >
            <RequiredRule />
            <PatternRule
              pattern="^[a-zA-Z ]+$"
              message="The name should not contain digits"
            />
          </Column>

          <Column
            caption="Last Name"
            dataField="last_name"
            alignment="center"
            // minWidth={150}
            visible={false}
            allowHeaderFiltering={false}
          >
            <PatternRule
              pattern="^[a-zA-Z ]+$"
              message="The name should not contain digits"
            />
          </Column>
          <Column
            caption="Product"
            alignment="center"
            dataField="product_id"
            // minWidth={150}
            setCellValue={setSubProductValue}
            allowHeaderFiltering={false}
          >
            <RequiredRule />
            <Lookup dataSource={productData} valueExpr="product_id" displayExpr="product_name" />
          </Column>
          <Column
            caption="Sub Product"
            alignment="center"
            dataField="sub_product_id"
            // minWidth={150}
            visible={false}
            allowHeaderFiltering={false}
          >
            <Lookup dataSource={subProductData} valueExpr="sub_product_id" displayExpr="product_name" />
          </Column>
          <Column
            caption="Assigned To"
            alignment="center"
            dataField="assigned_to"
            // minWidth={200}
            dataType='string'
          >
            <Lookup dataSource={get_lead_employees_name_for_lookup} valueExpr="employee_user_id" displayExpr="employee_name" />

          </Column>

          <Column
            caption="City"
            dataField="city"
            alignment="center"
            // minWidth={150}
            allowHeaderFiltering={false}
          >
            <PatternRule
              pattern="^[a-zA-Z ]+$"
              message="The city should not contain digits"
            />
          </Column>

          <Column
            caption="Lead Status"
            alignment="center"
            dataField="lead_status"
            cellRender={renderGridCellStage}
            setCellValue={setStatusValue}
          // minWidth={120}
          ><Lookup
            dataSource={leadStatus}
            valueExpr="lead_status"
            displayExpr="lead_status"
          >
            </Lookup>
          </Column>
          <Column
            caption="Lead Sub Status"
            alignment="center"
            dataField="lead_sub_status"
            cellRender={renderGridCellSubStage}
          // minWidth={120}
          ><Lookup
            dataSource={getFilteredSubStages}
            valueExpr="lead_sub_status"
            displayExpr="lead_sub_status"
          >
            </Lookup>
          </Column>
          {/* <Column
            caption="Mobile"
            dataField="mobile"
            alignment="center"
            // minWidth={150}
            visible={false}
          >
            <NumericRule />
            <StringLengthRule message="Mobile must have at least 10 digits" min={10} max={13} />
            <RequiredRule />
          </Column> */}
          <Column
            caption="Mobile"
            dataField="mobile"
            alignment="center"
            visible={false}
          >
            <NumericRule />
            <RequiredRule />
            <PatternRule
              pattern="^[6-9]\d{9}$"
              message="Invalid mobile number"
            />
          </Column>
          <Column
            caption="Payment Status"
            dataField="payment_status"
            alignment="center"
            // minWidth={120}
            cellRender={renderPaymentStatus}
          >
          </Column>

          <Column
            caption="FB Source Page"
            dataField="fb_lead_page_source"
            alignment="center"
            // minWidth={150}
            cellRender={renderFbPageName}
            visible={false}
          >
          </Column>

          <Column
            caption="Email"
            dataField="email"
            alignment="center"
            // minWidth={150}
            visible={false}
          >
            {/* <RequiredRule /> */}
            <EmailRule />
          </Column>

          <Column
            caption="Lead Source"
            dataField="lead_source"
            alignment="center"
          // minWidth={100}
          >
            <Lookup dataSource={lookup_lead_source} valueExpr="option_id" displayExpr="option_value" />
          </Column>
          <Column
            caption="Investment"
            alignment="center"
            dataField="investment"
            cellRender={renderInvestment}
            // minWidth={150}
            visible={false}
          >
            <NumericRule />
          </Column>
          <Column
            caption="Expected Profit "
            alignment="center"
            dataField="expected_profit"
            // minWidth={150}
            visible={false}
          />
          <Column
            caption="Street"
            alignment="center"
            dataField="street"
            // minWidth={150}
            visible={false}
          />
          {/* <Column
            caption="City"
            alignment="center"
            dataField="city"
            // minWidth={150}
            visible={true}
          /> */}
          <Column
            caption="State"
            alignment="center"
            dataField="state"
            // minWidth={150}
            visible={false}
          />
          <Column
            caption="Zip Code"
            alignment="center"
            dataField="zip_code"
            // minWidth={150}
            visible={false}
          />
          <Column
            caption="Created Date"
            alignment="center"
            dataField="created_at"
            // minWidth={180}
            dataType="datetime"
            format="MM/dd/yyyy, hh:mm a"
          />
          <Column
            caption="Modified Date"
            alignment="center"
            dataField="updated_at"
            // minWidth={180}
            dataType="datetime"
            format="MM/dd/yyyy, hh:mm a"
          />
          <Column type="buttons"
            dataField="Actions"
            visible={!!((Cookies.get('id') == 1 || Cookies.get('id') == 2))}
          >
          </Column>
          {/* <Toolbar>
            <Item location="before" name="column_filter_disabled">
              {(!showFilteredConditions) && <FilterListIcon onClick={() => {
                setOpenFilter(true);
                setColumnsList(userGrid.current.instance.getVisibleColumns());
              }} color="secondary" sx={{
                cursor: "pointer",
                fontSize: "2.2rem !important",
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
              }} />}
              {(showFilteredConditions) && <FilterListOffIcon onClick={clearFilter} color="error" sx={{
                cursor: "pointer",
                fontSize: "2.2rem !important",
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
              }} />}
            </Item>
            <Item location="before" name="column_filter_enabled">
              <Typography variant="h4" sx={{ my: 0, textDecoration: "none", }}>
                {(showFilteredConditions) && `Filtered Value (${columnListValue} : ${columnValueSelected})`}
              </Typography>
            </Item>


            {(rightsArray.includes('111')) &&
              <Item name="lead_assignment" location="after">
                <ToggleButton
                  value="check"
                  selected={leadAssignBtnSelected}
                  size="small"
                  sx={{
                    "&:hover": { backgroundColor: "#F44335", },
                    backgroundColor: "#F44335", color: "#FFFFFF", font: "sans-serif", borderColor: "none", padding: "8px 18px", textTransform: 'capitalize',
                  }}
                  onClick={leadAssignBtnToggle}
                >
                  {leadAssignBtnSelected ? "Lead Assignment Mode(on)" : "Lead Assignment Mode(off)"}
                </ToggleButton>
              </Item>}

            <Item name="searchPanel" />
            <Item name="exportButton" />
            <Item name="columnChooserButton" locateInMenu="auto" />



            <Item name='addRowButton' showText='always' options={{ text: "Add a Lead" }}>
            </Item>

            <Item visible={!!((Cookies.get(LOGIN_USERTYPE_COOKIE) == 1 || Cookies.get(LOGIN_USERTYPE_COOKIE) == 4))} location="after">
              <MDButton color='primary' size="small" onClick={handleOpenBulk}>Bulk Lead Upload</MDButton>
            </Item>

          </Toolbar> */}

          <Toolbar items={toolbarItems} />

        </DataGrid>}

        {leadAssignBtnSelected && <DataGrid
          id="gridContainer"
          keyExpr="id"
          dataSource={newLeadFilteredDataset}
          ref={newFilteredGrid}
          showBorders={true}
          remoteOperations={false}
          wordWrapEnabled={true}
          showColumnLines={false}
          rowAlternationEnabled={true}
          allowColumnReordering={true}
          onOptionChanged={(e) => {
            if (e.name === "dataSource") {
              // When the dataSource changes, clear the cache and load fresh data
              cachedData1 = null; // Clear the cache
              lookup_lead_source.load(); // Load fresh data for lookup
            }
          }}
          onRowUpdated={() => {
            cachedData1 = null; // Clear the cache
            lookup_lead_source.load(); // Load fresh data for lookup
          }}
        // defaultSelectionFilter={newLeadSelectionFilter}
        >
          <Pager
            visible={true}
            displayMode="full"
            showInfo={true}
            showNavigationButtons={leadAssignBtnSelected ? false : true}
            // showNavigationButtons={false}
            allowedPageSizes={'all'}
          />
          <SearchPanel
            visible={true}
          />
          <Paging defaultPageSize={25} />
          <Selection
            mode="multiple"
            showCheckBoxesMode="always"
            deferred={true}
          />
          <ColumnChooser
            enabled={true}
            mode="select"
          />

          <Column
            caption="Recordings"
            alignment="center"
            cellRender={renderRecordingColumn}
            width={90}
            allowEditing={false}
          >
          </Column>
          <Column
            caption="ID"
            dataField="id"
            alignment="center"
            // cellRender={renderID}
            // width={80}
            allowEditing={false}
            allowHeaderFiltering={false}  //disable the header filter for this column
          >
          </Column>

          <Column
            caption="Category"
            alignment="center"
            dataField="category"
            cellRender={renderGridPickLead}
          // minWidth={200}
          // dataType='string'
          >
            <RequiredRule />
            <Lookup dataSource={categoryDataSource} valueExpr="Value" displayExpr="Value" />

          </Column>

          <Column
            caption="First Name"
            dataField="first_name"
            alignment="center"
            // minWidth={150}
            allowHeaderFiltering={false}
          >
            <RequiredRule />
            <PatternRule
              pattern="^[a-zA-Z ]+$"
              message="The name should not contain digits"
            />
          </Column>

          <Column
            caption="Last Name"
            dataField="last_name"
            alignment="center"
            // minWidth={150}
            visible={false}
            allowHeaderFiltering={false}
          >
            <PatternRule
              pattern="^[a-zA-Z ]+$"
              message="The name should not contain digits"
            />
          </Column>
          <Column
            caption="Product"
            alignment="center"
            dataField="product_id"
            // minWidth={150}
            setCellValue={setSubProductValue}
            allowHeaderFiltering={false}
          >
            <RequiredRule />
            <Lookup dataSource={productData} valueExpr="product_id" displayExpr="product_name" />
          </Column>
          <Column
            caption="Sub Product"
            alignment="center"
            dataField="sub_product_id"
            // minWidth={150}
            visible={false}
            allowHeaderFiltering={false}
          >
            <Lookup dataSource={subProductData} valueExpr="sub_product_id" displayExpr="product_name" />
          </Column>
          <Column
            caption="Assigned To"
            alignment="center"
            dataField="assigned_to"
            // minWidth={200}
            dataType='string'
          >
            <Lookup dataSource={get_lead_employees_name_for_lookup} valueExpr="employee_user_id" displayExpr="employee_name" />

          </Column>
          <Column
            caption="Lead Status"
            alignment="center"
            dataField="lead_status"
            cellRender={renderGridCellStage}
            setCellValue={setStatusValue}
          // minWidth={120}
          ><Lookup
            dataSource={leadStatus}
            valueExpr="lead_status"
            displayExpr="lead_status"
          >
            </Lookup>
          </Column>
          <Column
            caption="Lead Sub Status"
            alignment="center"
            dataField="lead_sub_status"
            cellRender={renderGridCellSubStage}
          // minWidth={120}
          ><Lookup
            dataSource={getFilteredSubStages}
            valueExpr="lead_sub_status"
            displayExpr="lead_sub_status"
          >
            </Lookup>
          </Column>
          {/* <Column
            caption="Mobile"
            dataField="mobile"
            alignment="center"
            // minWidth={150}
            visible={false}
          >
            <NumericRule />
            <StringLengthRule message="Mobile must have at least 10 digits" min={10} max={13} />
            <RequiredRule />
          </Column> */}

          <Column
            caption="Mobile"
            dataField="mobile"
            alignment="center"
            visible={false}
          >
            <NumericRule />
            <RequiredRule />
            <PatternRule
              pattern="^[6-9]\d{9}$"
              message="Invalid mobile number"
            />
          </Column>

          <Column
            caption="Payment Status"
            dataField="payment_status"
            alignment="center"
            // minWidth={120}
            cellRender={renderPaymentStatus}
          >
          </Column>

          <Column
            caption="FB Source Page"
            dataField="fb_lead_page_source"
            alignment="center"
            // minWidth={150}
            cellRender={renderFbPageName}
            visible={false}
          >
          </Column>

          <Column
            caption="Email"
            dataField="email"
            alignment="center"
            // minWidth={150}
            visible={false}
          >
            {/* <RequiredRule /> */}
            <EmailRule />
          </Column>

          <Column
            caption="Lead Source"
            dataField="lead_source"
            alignment="center"
          // minWidth={100}
          >
            <Lookup dataSource={lookup_lead_source} valueExpr="option_id" displayExpr="option_value" />
          </Column>
          <Column
            caption="Investment"
            alignment="center"
            dataField="investment"
            cellRender={renderInvestment}
            // minWidth={150}
            visible={false}
          >
            <NumericRule />
          </Column>
          <Column
            caption="Expected Profit "
            alignment="center"
            dataField="expected_profit"
            // minWidth={150}
            visible={false}
          />
          <Column
            caption="Street"
            alignment="center"
            dataField="street"
            // minWidth={150}
            visible={false}
          />
          {/* <Column
            caption="City"
            alignment="center"
            dataField="city"
            // minWidth={150}
            visible={true}
          /> */}
          <Column
            caption="State"
            alignment="center"
            dataField="state"
            // minWidth={150}
            visible={false}
          />
          <Column
            caption="Zip Code"
            alignment="center"
            dataField="zip_code"
            // minWidth={150}
            visible={false}
          />
          <Column
            caption="Created Date"
            alignment="center"
            dataField="created_at"
            // minWidth={180}
            dataType="datetime"
            format="MM/dd/yyyy, hh:mm a"
          />
          <Column
            caption="Modified Date"
            alignment="center"
            dataField="updated_at"
            // minWidth={180}
            dataType="datetime"
            format="MM/dd/yyyy, hh:mm a"
          />
          <Toolbar>

            <Item location="before">
              <Typography variant="h5" sx={{ my: 0, textDecoration: "none", }}>
                Lead Assignment Mode
              </Typography>
            </Item>


            {(rightsArray.includes('111')) &&
              <Item>
                <ToggleButton
                  value="check"
                  size="small"
                  sx={{
                    "&:hover": { backgroundColor: "#4da851", },
                    backgroundColor: "#4da851", color: "#FFFFFF", font: "sans-serif", borderColor: "none", padding: "8px 18px", textTransform: 'capitalize',
                  }}
                  onClick={leadAssignBtnToggle}
                >
                  Lead Assignment Mode(On)
                </ToggleButton>
              </Item>}
            <Item name="searchPanel" />
            <Item
              location="after"
            >
              <MDButton color='success' size="small" onClick={openPopup}>Assign</MDButton>
            </Item>

          </Toolbar>

        </DataGrid>}

        <Dialog
          open={bulkOpen}
          fullWidth={true}
          onClose={handleClose}
          TransitionComponent={Transition}
          maxWidth="md"

        >
          <DialogTitle
            opacity={1}
            p={0}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <MDTypography variant="h4" color="black" style={{ paddingTop: "0px", paddingBottom: "0px" }}></MDTypography>
            <IconButton
              style={{ color: "black", cursor: "pointer" }}
              sx={{
                "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.3)" }
              }}
              onClick={() => setBulkOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <BulkUpload onCloseBulk={closeBulk}></BulkUpload>
          </DialogContent>

        </Dialog>
        <Dialog
          fullScreen
          open={openLead}
          onClose={handleClose}
          TransitionComponent={Transition}
          sx={{
            width: "95%",
          }}
          classes={{
            paper: classes.dialog
          }}
        >
          <DialogTitle
            sx={{
              padding: "0px",
            }}
          ><MDBox
            color="primary"
            variant="gradient"
            opacity={1}
            pl={2}
            pr={2}
            pt={1}
            pb={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          // textAlign="center"
          >
              <MDTypography variant="h5" color="secondary">{popuptitle} <MDBadge badgeContent={popupGuid} variant="contained" container color="success" ></MDBadge> </MDTypography>
              <IconButton
                style={{ color: "black", cursor: "pointer" }}
                sx={{
                  "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
                }}
                onClick={() => setOpenLead(false)}
              >
                <CloseIcon />
              </IconButton>
            </MDBox>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="icon label tabs example"
                sx={{
                  // width: "20%",
                  width: "30%",
                  paddingLeft: 2,
                  height: "30px",
                  fontSize: "small",
                  fontWeight: "600",
                }}>
                <Tab icon={<CabinIcon />} sx={{ justifyContent: 'center', "&:hover": { background: "linear-gradient(90deg, rgba(1,28,64,1) 0%, rgba(41,108,198,1) 35%, rgba(0,110,255,1) 100%)", color: "white !important" }, "&.Mui-selected": { background: "linear-gradient(90deg, rgba(1,28,64,1) 0%, rgba(41,108,198,1) 35%, rgba(0,110,255,1) 100%)", color: "white !important" } }} label="Overview" value={0} />
                <Divider orientation="vertical" variant="middle" flexItem />
                <Tab icon={<SavingsOutlinedIcon />} sx={{ justifyContent: 'center', "&:hover": { background: "linear-gradient(90deg, rgba(1,28,64,1) 0%, rgba(41,108,198,1) 35%, rgba(0,110,255,1) 100%)", color: "white !important" }, "&.Mui-selected": { background: "linear-gradient(90deg, rgba(1,28,64,1) 0%, rgba(41,108,198,1) 35%, rgba(0,110,255,1) 100%)", color: "white !important" } }} label="Payments" value={1} />
              </Tabs>
            </Box>

          </DialogTitle>
          <DialogContent
            p={3}
            sx={{
              backgroundColor: "#eee",
              paddingTop: "30px !important"
            }}>
            {(tabValue == 0) && <OverviewLead updateData={updateData} type={"leads"} refresh={refreshGrid} close={handleClose} />}
            {(tabValue == 1) && <Payments updateData={updateData} />}
          </DialogContent>
        </Dialog>

        {/* Add popup */}

        {/* {showSelectedPopup && (
          <Popup
            selectedData={selectedData}
            onClose={closePopup}
          />
        )} */}




        <Dialog
          open={showSelectedPopup}
          onClose={closePopup}
          maxWidth='lg'
        >
          <DialogTitle
            pb={0}
          ><MDBox
            color="white"
            bgColor="info"
            variant="gradient"
            borderRadius="lg"
            shadow="lg"
            opacity={1}
            p={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
              <MDTypography variant="h4" color="white">Lead Assignment</MDTypography>
              <IconButton
                style={{ color: "white", cursor: "pointer" }}
                sx={{
                  "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
                }}
                onClick={closePopup}
              >
                <CloseIcon />
              </IconButton>
            </MDBox></DialogTitle>
          <DialogContent sx={{ m: 2, mt: 0, pb: 0 }}>
            <ShowSelectedLeads selectedRows={selectedData} onClose={closePopup} refresh={refreshGrid} />
          </DialogContent>
        </Dialog>

        <Dialog
          fullScreen
          open={openFilter}
          onClose={handleClose}
          TransitionComponent={TransitionDown}
          sx={{
            width: "50%",
            maxHeight: "6.5rem",
            justifyContent: "center"
          }}
          classes={{
            paper: classes.filter
          }}
        >
          <DialogTitle>
            <Grid container spacing={2}>
              <Grid item sm={5} xs={5} md={5}>
                <Typography variant="h5" sx={{ my: 0, textDecoration: "none", }}>
                  Column Name
                </Typography>
                <Autocomplete
                  name="assigned_to"
                  value={columnListValue || null}
                  style={{ width: "100%" }}
                  onChange={setColumnNameFilter}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                  options={columnsList?.filter((val) => !['Recordings', 'ID', 'First Name', 'Created Date', 'Modified Date', 'Actions'].includes(val.caption)).map((value) => { return value.caption ?? "" })}
                  sx={{
                    ".MuiOutlinedInput-root": { padding: "0px", height: 45 }
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={6} md={6}>
                <Typography variant="h5" sx={{ my: 0, textDecoration: "none", }}>
                  Column Values
                </Typography>
                <Autocomplete
                  name="assigned_to"
                  value={columnValueSelected || null}
                  style={{ width: "100%" }}
                  onChange={setColumnValueFilter}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                  // options={columnsValue?.map((value) => {
                  //   return value.product_name ? value.product_name : (value.employee_name ? value.employee_name : (value.lead_status ? value.lead_status : (value.lead_sub_status ? value.lead_sub_status : (value.payment_status ? value.payment_status : (value.option_value ? value.option_value : "")))));
                  // })}

                  // options={columnsValue?.map((value) => {
                  //   // return value.product_name ? value.product_name : (value.employee_name ? value.employee_name : (value.lead_status ? value.lead_status : (value.lead_sub_status ? value.lead_sub_status : (value.payment_status ? value.payment_status : (value.option_value ? value.option_value : (value.city ? value.city : ""))))));
                  //   return value.product_name ? value.product_name : (value.employee_name ? value.employee_name : (value.lead_status ? value.lead_status : (value.lead_sub_status ? value.lead_sub_status : (value.payment_status ? value.payment_status : (value.option_value ? value.option_value : "")))));
                  // })}

                  options={columnsValue?.map((value) => {
                    // return value.product_name ? value.product_name : (value.employee_name ? value.employee_name : (value.lead_status ? value.lead_status : (value.lead_sub_status ? value.lead_sub_status : (value.payment_status ? value.payment_status : (value.option_value ? value.option_value : (value.city ? value.city : ""))))));

                    // console.log("valueeeeeeee==>", value);
                    return value.product_name ? value.product_name :
                      (value.employee_name ? value.employee_name :
                        (value.lead_status ? value.lead_status :
                          (value.lead_sub_status ? value.lead_sub_status :
                            (value.payment_status ? value.payment_status :
                              (value.option_value ? value.option_value :
                                (value.city ? value.city :
                                  (value.category ? value.category : "")))))));

                  })}

                  sx={{
                    ".MuiOutlinedInput-root": { padding: "0px", height: 45 }
                  }}
                />
              </Grid>
              <Grid item sm={1} xs={1} md={1}>
                <Typography variant="h5" sx={{ my: 0, textDecoration: "none", }}>
                  Filter
                </Typography>
                <FilterAltIcon onClick={startFilter} color="secondary" sx={{
                  cursor: "pointer",
                  fontSize: "2.2rem !important",
                  "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
                }}
                />
              </Grid>
            </Grid>



          </DialogTitle>
        </Dialog>
      </MDBox >
    </MDBox >
  );
}

export default AllLeads;