/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable prettier/prettier */
import { Card, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Icon, IconButton, Switch } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import React, { useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import Footer from 'examples/Footer';
import VerifyMessagePopup from './VerifyMessagePopup'
import { forwardRef } from 'react'
import { useImperativeHandle } from 'react'
import Cookies from 'js-cookie'
import { LOGIN_AUTH_COOKIE } from 'Apis/config/ResponseHandler'
import { LOGIN_REFRESH_COOKIE } from 'Apis/config/ResponseHandler'
import { RIGHTS } from 'Apis/config/ResponseHandler'
import { Link, Navigate } from 'react-router-dom'
import { NOT_VERIFIED_OR_SUBMITTED } from 'Apis/config/ResponseHandler'
import { NavLink, useNavigate } from "react-router-dom";
import { EMP_USER_ID } from 'Apis/config/ResponseHandler'


const VerifyDialogBox = forwardRef((props, ref) => {
  const userGrid = useRef();
  const [open, setOpen] = useState(false);
  const [popuptitle, setPopupTitle] = useState("Update Personal Details");
  const [updateData, setUpdate] = useState("");
  const navigate = useNavigate();
  const handleClose = (event) => {
    Cookies.remove(LOGIN_AUTH_COOKIE);
    Cookies.remove(LOGIN_REFRESH_COOKIE);
    Cookies.remove(RIGHTS);
    Cookies.remove(NOT_VERIFIED_OR_SUBMITTED);
    Cookies.remove(EMP_USER_ID);
    setOpen(true);
  };
  const handleChanged = (event, id) => {
    
    setOpen(false)
    navigate("/Updateprofile", { state: { message:"navigatedToProfile"} });
   
  }

  useImperativeHandle(ref, () => ({
    openDialog(e) {
      setOpen(true);
    }
  }));
  return (
    <>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <Dialog
                open={open}
                onClose={handleClose}
                maxWidth='sm'
                sx={{
                  '.MuiDialogContent-root': { pl: 5, pr: 5, pb: 5, pt: 2 }
                }}
              >
                <DialogTitle
                ><MDBox
                  color="white"
                  bgColor="info"
                  variant="gradient"
                  borderRadius="lg"
                  shadow="lg"
                  opacity={1}
                  p={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                // textAlign="center"
                >
                    <MDTypography variant="h4" color="white">{popuptitle}</MDTypography>
                    <Link to="/authentication/sign-in"><IconButton
                      style={{ color: "white", cursor: "pointer" }}
                      sx={{
                        "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
                      }}
                      onClick={() =>handleClose() }
                    >
                      <CloseIcon />
                    </IconButton>
                    </Link>
                  </MDBox></DialogTitle>
                <DialogContent p={3}>
                  <VerifyMessagePopup updateData={updateData} close={handleChanged} />
                </DialogContent>
              </Dialog>
              <MDBox p={3}>
               
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </>
  )
})

export default VerifyDialogBox