/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import team2 from "assets/images/team-2.jpg";
import CabinIcon from '@mui/icons-material/Cabin';


// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import MDButton from "components/MDButton";
import { AppBar, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Icon, IconButton, List, ListItem, ListItemText, Slide, Switch, Tab, Tabs, TextField, Typography, useMediaQuery } from "@mui/material";
import React, { forwardRef, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { makeStyles } from "@mui/styles";
import { Button, Column, ColumnChooser, DataGrid, Lookup, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar, RequiredRule, NumericRule, RangeRule, StringLengthRule } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { Pager } from 'devextreme-react/tree-list';
import CloseIcon from '@mui/icons-material/Close';
import { UsersData } from "layouts/tables/data/mockdata";
import { GET } from "Apis/config/RequestType";
import { update_user } from "Apis/config/Url";
import { PUT } from "Apis/config/RequestType";
import DeletePopup from "layouts/master-data/delete-popup";
import { delete_user } from "Apis/config/Url";
import Caller from "Apis/config/Caller";
import { useNavigate } from "react-router-dom";
import { getEmployeeUserDetails } from "Apis/Auth/auth";
import CustomStore from "devextreme/data/custom_store";
import { change_user_status } from "Apis/config/Url";
import { POST } from "Apis/config/RequestType";
import { get_employee_user_details } from "Apis/config/Url";
import MDBadge from "components/MDBadge";
import Guid from 'devextreme/core/guid';
import { update_profile } from "Apis/config/Url";
import { getAllLeads } from "Apis/Auth/auth";
import { leads } from "Apis/config/Url";
import { get_product } from "Apis/config/Url";
import { account_tye_choice } from "Apis/Auth/auth";

import { delete_leads } from "Apis/config/Url";
import { Validator } from "devextreme-react";
import { getAllCrmAccount } from "Apis/Auth/auth";
import { get_all_crm_accounts } from "Apis/config/Url";
import { crm_account } from "Apis/config/Url";
import { delete_account } from "Apis/config/Url";
import { DELETE } from "Apis/config/RequestType";
import LaunchIcon from '@mui/icons-material/Launch';
import OverviewLead from "pages/organization/leads/single-lead/overview-lead";

const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    bottom: "0px",
    left: "2.5%",
    top: 40
  }
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let updateRowData = {}
const employeeUserUserDetails = new CustomStore({
  key: 'id',
  load: (loadOptions) => {
    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      "filter",
      "totalSummary",
      "group",
      "groupSummary",
      "isLoadingAll"
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {

        if (i === "skip") {
          let page = (loadOptions[i] + 10) / 10;
          if (page !== 1) {
            params += `page=${page}&`;
          }
        } else if (i === "sort") {
          let sortType = loadOptions[i][0]["desc"] ? "desc" : "asc";
          let sortColoumn = loadOptions[i][0]["selector"];
          params += `sortType=${sortType}&sortColoumn=${sortColoumn}&`;
        } else if (i === "filter") {
          let filterO = loadOptions[i][0]["filterValue"];
          params += `s=${filterO}&`;
        } else {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      }
    }),
      params = params.slice(0, -1);
    return getAllCrmAccount(params)
      .then((data) => ({
        data: data.data.data,
        totalCount: data.data.count,
        next: data.data.next,
        previous: data.data.previous
      }));
  },


  insert: (values) => Caller(crm_account, values, POST, false, false).then((res) => {

    values: JSON.stringify(values)
  }),

  update: (key, values) => Caller(crm_account + '?id=' + key, { ...updateRowData, ...values }, PUT, false, false).then((res) => {
    values: JSON.stringify(values)
  }),
  remove: (key) => Caller(delete_account + '?id=' + key, "", DELETE, false, false).then((res) => {
    values: JSON.stringify(res)
  }),


});
const productData = new CustomStore({
  key: 'Value',
  loadMode: 'raw',
  load: (options) => {
    return Caller(get_product + "?isLoadingAll=true&id", '', GET, false, false).then((res) => {
      return res.data.data
    })
  }
})

const isNotEmpty = (value) => {
  return value !== undefined && value !== null && value !== "";
}

const onRowUpdate = (value) => {
  updateRowData = value.oldData
  delete updateRowData.id;
  delete updateRowData.created_by;
  delete updateRowData.created_at;
}

const AllAccounts = () => {
  const classes = useStyles();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [popuptitle, setPopupTitle] = useState("Employee Data 1");
  const [updateData, setUpdate] = useState("");
  const userGrid = useRef();
  const [open, setOpen] = useState(false);
  const [openLead, setOpenLead] = useState(false);
  const [changes, setChanges] = React.useState([]);
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      if (reason !== 'backdropClick') {
        setUpdate("");
        setOpen(false);
        setOpenLead(false);
        setDeleteOpen(false);
      }
    }
  };
  //   const [editRowKey, setEditRowKey] = React.useState(null);
  //   const onAddButtonClick =(e) => {
  //     const key = new Guid().toString();
  //     setChanges([{
  //       key,
  //       type: 'insert',
  //       insertAfterKey: e.row.key,
  //     }]);
  //     setEditRowKey(key);
  //   };

  //   const isAddButtonVisible = React.useCallback(({ row }) => !row.isEditing, []);

  //   const onRowInserted = React.useCallback((e) => {
  //     e.component.navigateToRow(e.key);
  //   }, []);


  function cellRender(data) {
    return <img src={team2} style={{ borderRadius: "50%", width: "2.5rem", height: "2.5rem" }} />;
  }

  const selectPopupVisible = async (e) => {

    const formData = new FormData();
    formData.append("id", e.row.data.id);
    await Caller(get_employee_user_details + "?take=10&requireTotalCount=true&s=&id=" + e.row.data.employee_user_id, "", GET, false, true).then(async (res) => {
      if (res.success === true) {
        setUpdate(res.data.data);
      }
    });
    setPopupTitle("Update Employee");
    setOpen(true);
  }

  const refreshGrid = () => {
    userGrid.current.instance.refresh();
  }
  const deleteVisible = (e) => {
    setUpdate(e.row.data.id);
    setDeleteOpen(true);
  }
  const updateEmployee = (e) => {
    return navigate("/employees/update/" + e.row.data.employee_guid);
  }

  const handleChanged = async (event, id) => {

    let status = "";
    if (event.target.checked === false) {
      status = 0
    }
    else {
      status = 1
    }
    const formData = new FormData();
    formData.append("id", id);
    formData.append("active", status);
    await Caller(change_user_status + "?user_type=employee", formData, POST, false, true)
      .then((data) => {
        if (data.success === true) {
          refreshGrid();
        }
      });
  }

  const returnLoginStatus = (data) => {
    switch (data.value) {
      case 1:
        return <MDBadge badgeContent="Online" variant="gradient" color="success" size="xs" container />
      case 0:
        return <MDBadge badgeContent="Offline" variant="gradient" color="dark" size="xs" container />
      default:
        break;
    }
    // return <MDBadge badgeContent="Online" variant="gradient" color="success" container />
  }

  const renderGridStatus = (data) => {
    let func = "";
    let label = "";
    if (data.data.is_active == 'Yes') {
      func = true
      label = "Active"
    }
    else {
      func = false
      label = "Inactive"
    }

    return <FormControlLabel
      control={<Switch
        checked={func}
        color="secondary"
        onChange={(event) => handleChanged(event, data.data.employee_user_id)}
        inputProps={{ 'aria-label': 'controlled', role: 'switch' }}
      />}
      label={label} />
  }

  const renderProductColumn = (data) => {
    setPopupTitle(data.row.data.account_name ? data.row.data.account_name.toUpperCase() : "");
    return <IconButton color="success" onClick={() => { setOpenLead(true); setUpdate(data.row.data); }}>
      <LaunchIcon />
    </IconButton>
    // 
  }

  const renderGridCellStage = (data) => {
    switch (data.value) {
      case "Client":
        return <MDTypography style={{ color: 'white', background: 'linear-gradient(195deg, #0f9b0f 10%, #000000 360%)' }} variant="subtitle1">{data.value}</MDTypography>;
      case "Partner":
        return <MDTypography style={{ color: 'white', background: 'linear-gradient(195deg, #cc2b5e 10%, #a8c0ff 360%)' }} variant="subtitle1">{data.value}</MDTypography>;
      case "Vendor":
        return <MDTypography style={{ color: 'white', background: 'linear-gradient(195deg, #FFE000 10%, #f7797d 360%)' }} variant="subtitle1">{data.value}</MDTypography>;
      default:
        break;
    }
  }

  let deleteText = "Do you want to delete this Employee?";


  return (


    <MDBox pt={2} pb={1}>
      {deleteOpen && <DeletePopup deleteId={updateData} open={deleteOpen} deleteText={deleteText} close={handleClose} refresh={refreshGrid} path={delete_user + "?user_type=employee"} />}




      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='sm'
        sx={{
          '.MuiDialogContent-root': { pl: 5, pr: 5, pb: 5, pt: 2 }
        }}
      >
        <DialogTitle
        ><MDBox
          color="white"
          variant="gradient"
          borderRadius="lg"
          shadow="lg"
          opacity={1}
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        // textAlign="center"
        >
            <MDTypography variant="h4" color="white">{popuptitle}</MDTypography>
            <IconButton
              style={{ color: "white", cursor: "pointer" }}
              sx={{
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
              }}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </MDBox></DialogTitle>
        <DialogContent p={3}>

        </DialogContent>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='sm'
        sx={{
          '.MuiDialogContent-root': { pl: 5, pr: 5, pb: 5, pt: 2 }
        }}
      >
        <DialogTitle
        ><MDBox
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          shadow="lg"
          opacity={1}
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        // textAlign="center"
        >
            <MDTypography variant="h4" color="white">{popuptitle}</MDTypography>
            <IconButton
              style={{ color: "white", cursor: "pointer" }}
              sx={{
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
              }}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </MDBox></DialogTitle>
        <DialogContent p={3}>


        </DialogContent>
      </Dialog>
      {/* <EmployeesData1 data={updateData} close={handleClose} />
              <EmployeesData2 data={updateData} close={handleClose} /> */}
      <MDBox p={3}>
        <DataGrid
          id="gridContainer"
          keyExpr="id"
          dataSource={employeeUserUserDetails}
          ref={userGrid}
          showBorders={true}
          remoteOperations={true}
          wordWrapEnabled={true}
          showColumnLines={false}
          rowAlternationEnabled={true}
          allowColumnReordering={true}
          onRowUpdating={onRowUpdate}
        >
          <ColumnChooser
            enabled={true}
            mode="select"
          />
          <Pager
            visible={true}
            displayMode="full"
            showInfo={true}
            showNavigationButtons={true} />
          <Paging defaultPageSize={10} />
          <SearchPanel
            visible={true}
          />
          <Export
            enabled={true}
            fileName="Employees"
          />
          <Editing
            mode="row"
            allowAdding={true}
            allowUpdating={true}
            useIcons={true}
            allowDeleting={true}
          />
          <Column
            caption="View"
            alignment="center"
            cellRender={renderProductColumn}
            minWidth={80}
          ></Column>
          <Column
            caption="Account Name"
            alignment="center"
            dataField="account_name"
            minWidth={150}

          >
            <RequiredRule />
            <StringLengthRule message="Maximum 50 character allowed." max={50} />
          </Column>
          <Column
            caption="Account Type"
            dataField="account_type"
            alignment="center"
            cellRender={renderGridCellStage}
            minWidth={150}
          >
            <RequiredRule />
            <Lookup dataSource={account_tye_choice} valueExpr="lead_status" displayExpr="lead_status" />
          </Column>

          <Column
            caption="Account Priority"
            dataField="account_priority"
            alignment="center"
            minWidth={150}
          >
            <RequiredRule />
            <StringLengthRule message="Maximum 50 character allowed." max={50} />
          </Column>

          <Column
            caption="Industry"
            dataField="industry"
            alignment="center"
            minWidth={150}
          >
            <StringLengthRule message="Maximum 100 character allowed." max={100} />
          </Column>
          <Column
            caption="Comments"
            dataField="comments"
            alignment="center"
            minWidth={150}

          >

            <StringLengthRule message="Maximum 200 character allowed." max={200} />

          </Column>


          <Column type="buttons" dataField="Actions" minWidth={150}>
            {/* <Button icon="edit" hint="Edit" onClick={selectPopupVisible} /> */}
            {/* <Button hint="Delete" icon="trash" style={{ color: "red" }} onClick={deleteVisible} /> */}
          </Column>
          <Toolbar>

            <Item name="searchPanel" />
            <Item name="exportButton" />

            <Item location="after" name='addRowButton' showText='always' />

          </Toolbar>
          <Toolbar>

            <Item name="searchPanel" />
            <Item name="exportButton" />

            <Item location="after" name='addRowButton' showText='always' >



            </Item>
          </Toolbar>

        </DataGrid>

      </MDBox>
      <Dialog
        fullScreen
        open={openLead}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{
          width: "95%",
        }}
        classes={{
          paper: classes.dialog
        }}
      >
        <DialogTitle
          sx={{
            padding: "0px",
          }}
        ><MDBox
          color="primary"
          variant="gradient"
          opacity={1}
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        // textAlign="center"
        >
            <MDTypography variant="h4" color="secondary">{popuptitle}</MDTypography>
            <IconButton
              style={{ color: "black", cursor: "pointer" }}
              sx={{
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
              }}
              onClick={() => setOpenLead(false)}
            >
              <CloseIcon />
            </IconButton>
          </MDBox>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="icon label tabs example"
              sx={{
                width: "20%",
                paddingLeft: 2
              }}>
              <Tab icon={<CabinIcon />} label="Overview" value={0} />
              {/* <Divider orientation="vertical" variant="middle" flexItem />
                <Tab icon={<RowingIcon />} label="Activity" value={1} /> */}
            </Tabs>
          </Box>

        </DialogTitle>
        <DialogContent
          p={3}
          sx={{
            backgroundColor: "#eee",
            paddingTop: "30px !important"
          }}>
          {(tabValue == 0) && <OverviewLead updateData={updateData} type={"accounts"} />}
          {/* {(tabValue == 1) && <ActivityLog />} */}
        </DialogContent>
      </Dialog>



    </MDBox>
  );
}

export default AllAccounts;
