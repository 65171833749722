import React, { useState } from 'react'
import { Grid, InputAdornment, TextField } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import MDButton from 'components/MDButton';
import AddIcon from '@mui/icons-material/Add';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import { lead_email } from 'Apis/config/Url';
import Caller from 'Apis/config/Caller';
import { POST } from 'Apis/config/RequestType';
import { useEffect } from 'react';
import { TINYMCE_KEY } from 'Apis/config/ResponseHandler';

const SendEmail = (props) => {
    const [disabledSave, setDisabledSave] = useState();

    const [editorValues, setEditorValues] = useState({
        emailto: "",
        subject: "",
        body: ""
    })

    const textUpdate = (e) => {
        setEditorValues((state) => ({
            ...state,
            [`${e.target.name}`]: e.target.value
        }));
    }
    // const textValue = (content, editor) => {
    //     setEditorValues((state) => ({
    //         ...state, body: content
    //     }));
    // };

    const textValue = (e) => {

        setEditorValues((state) => ({
            ...state, body: e.target.value
        }));

    }

    const [isInitialRender, setIsInitialRender] = useState(true);

    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);
            if (props.editData) {
                setEditorValues((values) => ({
                    ...values,
                    body: props.editData.body ?? "",
                    subject: props.editData.subject ?? "",
                    emailto: props.editData.email_to ?? ""
                }))
            }
        }
    }, [])

    const submitMeeting = async (event) => {
        event.target.classList.add("was-validated");
        event.preventDefault();
        event.stopPropagation();
        setDisabledSave('disabled');
        if (!event.target.checkValidity()) {
            setDisabledSave('');
            return false;
        }
        const formData = new FormData();
        formData.append('lead_id', props.updateData.id.substring(3));
        formData.append('email_to', editorValues.emailto);
        formData.append('subject', editorValues.subject);
        formData.append('body', editorValues.body);
        await Caller(lead_email, formData, POST, false, true).then((res) => {
            if (res.success === true) {
                props.refresh();
                props.close();
            }
        })
    }

    const allTrue = () => {
        if (editorValues.emailto && editorValues.subject && editorValues.body) {
            return false
        } else {
            return true
        }
    }

    return (
        <Grid container >
            <Grid
                item
                xs={12}
                sx={{
                    borderBottom: 0.5,
                    borderColor: '#d4d4d4',
                    paddingBottom: 1
                }}
            >
                <TextField
                    variant="standard"
                    sx={{
                        width: '100%',
                        fontSize: "small"
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start" sx={{ '.MuiTypography-root': { font: 'inherit' } }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To:</InputAdornment>,
                        disableUnderline: true,
                    }}
                    name="emailto"
                    value={editorValues.emailto}
                    disabled={props.editData}
                    onChange={textUpdate}
                />
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    borderBottom: 0.5,
                    marginBottom: 1,
                    borderColor: '#d4d4d4',
                    paddingBottom: 1,
                    paddingTop: 1
                }}
            >
                <TextField
                    variant="standard"
                    sx={{
                        width: '100%',
                        fontSize: "small"
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start" sx={{ '.MuiTypography-root': { font: 'inherit' } }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Subject:</InputAdornment>,
                        disableUnderline: true,
                    }}
                    name="subject"
                    disabled={props.editData}
                    value={editorValues.subject}
                    onChange={textUpdate}
                />
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    width: '100%',
                    marginLeft: 2,
                    marginRight: 2
                }}
            >
                {/* <Editor
                    id="validationCustom03"
                    apiKey={TINYMCE_KEY}
                    required
                    name="body"
                    value={editorValues.body}
                    init={{
                        height: 320,
                        plugins: "image code",
                        directionality: "ltr",
                        menubar: false,
                        toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat | help",
                    }}
                    disabled={props.editData}
                    onEditorChange={textValue}
                /> */}

                <TextField
                    // label="Your Label"
                    multiline
                    rows={15}
                    variant="outlined"
                    fullWidth
                    // values={editorValues.body}
                    value={editorValues.body}
                    onChange={textValue}
                    disabled={props.editData}
                />

            </Grid>
            <Grid
                item
                xs={3}
                sx={{
                    paddingTop: 1,
                    marginLeft: 2,
                }}
            >
                {(!props.editData) && <MDButton startIcon={<SendAndArchiveIcon />} color='info' className={`action-button ${disabledSave}`} size="small" onClick={submitMeeting} disabled={allTrue()}>Send Mail</MDButton>}
            </Grid>
        </Grid>


    )
}

export default SendEmail