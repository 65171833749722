/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable prettier/prettier */
import { Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, IconButton } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import { Button, Column, DataGrid, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar } from 'devextreme-react/data-grid';
import { Pager } from 'devextreme-react/tree-list';
import 'devextreme/data/odata/store';
import React, { useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import AddUpdateTemplate from './add-update-template'
// import DeletePopup from '../delete-popup';
import Caller from 'Apis/config/Caller';
import { GET } from 'Apis/config/RequestType';
import { delete_product } from 'Apis/config/Url';
import { deleteTemplate } from 'Apis/config/Url';


import { getProductDetails } from 'Apis/Auth/auth';
import { getTemplateFromTableData } from 'Apis/Auth/auth';
import { getTemplateFromTable } from 'Apis/config/Url';
import CustomStore from 'devextreme/data/custom_store'
import { get_product } from 'Apis/config/Url';
import Footer from 'examples/Footer';

import DeletePopup from "layouts/master-data/delete-popup";


import DeleteTemplate from './deleteTemplatePopup';

const isNotEmpty = (value) => {
  return value !== undefined && value !== null && value !== "";
}
const templateDataDetails = new CustomStore({
  key: 'id',
  load(loadOptions) {
    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      "filter",
      "totalSummary",
      "group",
      "groupSummary",
      "isLoadingAll"
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {

        if (i === "skip") {
          let page = (loadOptions[i] + 25) / 25;
          if (page !== 1) {
            params += `page=${page}&`;
          }
        } else if (i === "sort") {
          let sortType = loadOptions[i][0]["desc"] ? "desc" : "asc";
          let sortColoumn = loadOptions[i][0]["selector"];
          params += `sortType=${sortType}&sortColoumn=${sortColoumn}&`;
        } else if (i === "filter") {
          let filterO = loadOptions[i][0]["filterValue"];
          params += `s=${filterO}&`;
        } else {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      }
    });
    params = params.slice(0, -1);
    return getTemplateFromTableData(params)
      .then((data) => ({
        data: data.data.data,
        totalCount: data.data.count,
        next: data.data.next,
        previous: data.data.previous
      }));
  },

});
const TemplatesTAb = () => {
  const userGrid = useRef();
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [popuptitle, setPopupTitle] = useState("Add Product");
  const [updateData, setUpdate] = useState("");

  const [deleteData, setDeleteData] = useState({
    id: "",
    template_name: "",
  })

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setUpdate("");
      setOpen(false);
      setDeleteOpen(false);
    }
  }

  const selectPopupVisible = async (e) => {
    await Caller(getTemplateFromTable + "?id=" + e.row.data.id + "&page=1&s=&take=25", "", GET, false, true).then(async (res) => {
      if (res.success === true) {
        setUpdate(res.data.data);
      }
    });
    setPopupTitle("Update Template");
    setOpen(true);
  }

  const deleteVisible = (e) => {
    // setUpdate(e.row.data.id);
    setDeleteData((deleteData) => ({
      ...deleteData,
      id: e.row.data.id,
      template_name: e.row.data.template_name,
    }));

    setDeleteOpen(true);
  }

  const refreshGrid = () => {
    userGrid.current.instance.refresh();
  }
  const refreshGridDelete = () => {
    userGrid.current.instance.refresh();
  }

  const popupDeleteText = "Do you want to delete this template?";


  const disableEditDelete = (e) => {
    if (e.row.data.status == "PENDING" || e.row.data.status == "In review") {
      return true;
    } else {
      return false;
    }
  }

  const renderTemplateBody = (data) => {
    return <div>{data.data.body.replace(/<\/?[^>]+(>|$)|&[a-zA-Z0-9]+;/g, "")}</div>
  }

  return (
    <>
      {deleteOpen && <DeleteTemplate deleteData={deleteData} deleteText={popupDeleteText} open={deleteOpen} close={handleClose} path={deleteTemplate} refresh={refreshGridDelete} />}

      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>

            <Dialog
              open={open}
              onClose={handleClose}
              disableEnforceFocus={true}
              maxWidth='lg'
              sx={{
                '.MuiDialogContent-root': { pl: 1, pr: 1, pb: 1, pt: 1 }
              }}
            >
              <DialogTitle
              ><MDBox
                color="white"
                bgColor="info"
                variant="gradient"
                borderRadius="lg"
                shadow="lg"
                opacity={1}
                p={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              // textAlign="center"
              >
                  <MDTypography variant="h4" color="white">{popuptitle}</MDTypography>
                  <IconButton
                    style={{ color: "white", cursor: "pointer" }}
                    sx={{
                      "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
                    }}
                    onClick={() => setOpen(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                </MDBox></DialogTitle>
              <DialogContent>
                <AddUpdateTemplate updateData={updateData} close={handleClose} refresh={refreshGrid} />
              </DialogContent>
            </Dialog>
            <MDBox p={3}>
              <DataGrid
                id="gridContainer"
                dataSource={templateDataDetails}
                keyExpr="id"
                ref={userGrid}
                showBorders={true}
                remoteOperations={true}
                wordWrapEnabled={true}
                showColumnLines={false}
                rowAlternationEnabled={true}

              >
                <Pager
                  visible={true}
                  displayMode="full"
                  showInfo={true}
                  showNavigationButtons={true} />
                <Paging defaultPageSize={25} />
                <SearchPanel
                  visible={true}
                />
                <Export
                  enabled={true}
                  fileName="WhatsApp Template"
                />
                <Editing
                  mode="row"
                  allowAdding={false}
                  allowUpdating={true}
                  useIcons={true}
                />
                <Column
                  caption="Template Name"
                  dataField="template_name"
                  alignment="center"
                  minWidth={150}
                />
                <Column
                  caption="Template Body"
                  dataField="body"
                  alignment="center"
                  cellRender={renderTemplateBody}
                  minWidth={150}
                />

                <Column
                  caption="Status"
                  dataField="status"
                  alignment="center"
                  minWidth={50}
                />

                <Column type="buttons" dataField="Actions" minWidth={150}>
                  <Button icon="edit" hint="Edit" onClick={selectPopupVisible} disabled={disableEditDelete} />
                  <Button hint="Delete" icon="trash" style={{ color: "red" }} onClick={deleteVisible} disabled={disableEditDelete} />
                </Column>
                <Toolbar>

                  <Item name="searchPanel" locateInMenu="auto" />
                  <Item name="exportButton" locateInMenu="auto" />
                  <Item location="after" locateInMenu="auto" >
                    <MDButton variant="gradient" color="success" onClick={() => { setOpen(true); setUpdate(""); setPopupTitle("Add Template"); }}>
                      Add&nbsp;
                      <Icon>add</Icon>&nbsp;
                    </MDButton>
                  </Item>
                </Toolbar>

              </DataGrid>
            </MDBox>
            {/* </Card> */}
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </>
  )
}

export default TemplatesTAb;