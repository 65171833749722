/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import team2 from "assets/images/team-2.jpg";


// Data
import MDButton from "components/MDButton";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Icon, IconButton, Switch, TextField, useMediaQuery } from "@mui/material";
import React, { useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { Button, Column, ColumnChooser, DataGrid,Lookup, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { Pager } from 'devextreme-react/tree-list';
import CloseIcon from '@mui/icons-material/Close';
import { UsersData } from "layouts/tables/data/mockdata";
import { GET } from "Apis/config/RequestType";
import { update_user } from "Apis/config/Url";
import { PUT } from "Apis/config/RequestType";
import DeletePopup from "layouts/master-data/delete-popup";
import { delete_user } from "Apis/config/Url";
import Caller from "Apis/config/Caller";
import { useNavigate } from "react-router-dom";
import { getEmployeeUserDetails } from "Apis/Auth/auth";
import CustomStore from "devextreme/data/custom_store";
import { change_user_status } from "Apis/config/Url";
import { POST } from "Apis/config/RequestType";
import { get_employee_user_details } from "Apis/config/Url";
import MDBadge from "components/MDBadge";
import Guid from 'devextreme/core/guid';
import { update_profile } from "Apis/config/Url";
import { leads } from "Apis/config/Url";
import { get_product } from "Apis/config/Url";
import { assigned_products } from "Apis/config/Url";
import { getAllAssignedProductUser } from "Apis/Auth/auth";
import { get_team_member_list } from "Apis/config/Url";
import { LOGIN_AUTH_COOKIE, LOGIN_REFRESH_COOKIE, LEAD_ASSIGN_MODE, UNIVERSAL_SEARCH_COOKIE } from 'Apis/config/ResponseHandler';
import Cookies from 'js-cookie';

let updateRowData = {}
const employeeUserUserDetails = new CustomStore({
    key: 'id',
    load:(loadOptions) =>{
      let params = "?";
      [
        "skip",
        "take",
        "requireTotalCount",
        "requireGroupCount",
        "sort",
        "filter",
        "totalSummary",
        "group",
        "groupSummary",
        "isLoadingAll"
      ].forEach((i) => {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
  
          if (i === "skip") {
            let page = (loadOptions[i] + 10) / 10;
            if (page !== 1) {
              params += `page=${page}&`;
            }
          } else if (i === "sort") {
            let sortType = loadOptions[i][0]["desc"] ? "desc" : "asc";
            let sortColoumn = loadOptions[i][0]["selector"];
            params += `sortType=${sortType}&sortColoumn=${sortColoumn}&`;
          } else if (i === "filter") {
            let filterO = loadOptions[i][0]["filterValue"];
            params += `s=${filterO}&`;
          } else {
            params += `${i}=${JSON.stringify(loadOptions[i])}&`;
          }
        }
      });
      params = params.slice(0, -1);
      return getAllAssignedProductUser(params)
        .then((data) => ({
          data: data.data.data,
          totalCount: data.data.count,
          next: data.data.next,
          previous: data.data.previous
        }))
    },  
  });


const isNotEmpty = (value) => {
  return value !== undefined && value !== null && value !== "";
}

const onRowUpdate = (value) => {
    updateRowData = value.oldData
    delete updateRowData.id; 
    delete updateRowData.product_id; 
    delete updateRowData.employee_user_id; 
    delete updateRowData.lead_status_name; 
    delete updateRowData.product_name; 
}

const AllProductAssigned = () => {  
  let universalSearchCookie = Cookies.get(UNIVERSAL_SEARCH_COOKIE);
  Cookies.set(UNIVERSAL_SEARCH_COOKIE, "")
    
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [popuptitle, setPopupTitle] = useState("Employee Data 1");
  const [updateData, setUpdate] = useState("");
  const userGrid = useRef();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [changes, setChanges] = React.useState([]);
  const navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      if (reason !== 'backdropClick') {
        setUpdate("");
        setOpen(false);
        setDeleteOpen(false);
      }
    }
  };
//   const [editRowKey, setEditRowKey] = React.useState(null);
//   const onAddButtonClick =(e) => {
//     const key = new Guid().toString();
//     setChanges([{
//       key,
//       type: 'insert',
//       insertAfterKey: e.row.key,
//     }]);
//     setEditRowKey(key);
//   };

//   const isAddButtonVisible = React.useCallback(({ row }) => !row.isEditing, []);

//   const onRowInserted = React.useCallback((e) => {
//     e.component.navigateToRow(e.key);
//   }, []);


  function cellRender(data) {
    return <img src={team2} style={{ borderRadius: "50%", width: "2.5rem", height: "2.5rem" }} />;
  }

  const selectPopupVisible = async (e) => {
    const formData = new FormData();
    formData.append("id", e.row.data.id);
    await Caller(get_employee_user_details + "?take=10&requireTotalCount=true&s=&id=" + e.row.data.employee_user_id, "", GET, false, true).then(async (res) => {
      if (res.success === true) {
        setUpdate(res.data.data);
      }
    });
    setPopupTitle("Update Employee");
    setOpen(true);
  }

  const refreshGrid = () => {
    userGrid.current.instance.refresh();
  }
  const deleteVisible = (e) => {
    setUpdate(e.row.data.employee_user_id);
    setDeleteOpen(true);
  }
  const updateEmployee = (e) => {
    return navigate("/employees/update/" + e.row.data.employee_guid);
  }

  const handleChanged = async (event, id) => {

    let status = "";
    if (event.target.checked === false) {
      status = 0
    }
    else {
      status = 1
    }
    const formData = new FormData();
    formData.append("id", id);
    formData.append("active", status);
    await Caller(change_user_status + "?user_type=employee", formData, POST, false, true)
      .then((data) => {
        if (data.success === true) {
          refreshGrid();
        }
      });
  }

  const returnLoginStatus = (data) => {
    switch (data.value) {
      case 1:
        return <MDBadge badgeContent="Online" variant="gradient" color="success" size="xs" container />
      case 0:
        return <MDBadge badgeContent="Offline" variant="gradient" color="dark" size="xs" container />
      default:
        break;
    }
    // return <MDBadge badgeContent="Online" variant="gradient" color="success" container />
  }

  const renderGridStatus = (data) => {
    let func = "";
    let label = "";
    if (data.data.is_active == 'Yes') {
      func = true
      label = "Active"
    }
    else {
      func = false
      label = "Inactive"
    }

    return <FormControlLabel
      control={<Switch
        checked={func}
        color="secondary"
        onChange={(event) => handleChanged(event, data.data.employee_user_id)}
        inputProps={{ 'aria-label': 'controlled', role: 'switch' }}
      />}
      label={label} />
  }

  let deleteText = "Do you want to delete this Employee?";


  return (
   
     
      <MDBox pt={2} pb={1}>
      {deleteOpen && <DeletePopup deleteId={updateData} open={deleteOpen} deleteText={deleteText} close={handleClose} refresh={refreshGrid} path={delete_user + "?user_type=employee"} />}
       
          
           
    
      <Dialog
                open={open}
                onClose={handleClose}
                maxWidth='sm'
                sx={{
                  '.MuiDialogContent-root': { pl: 5, pr: 5, pb: 5, pt: 2 }
                }}
              >
                <DialogTitle
                ><MDBox
                  color="white"
                  bgColor="info"
                  variant="gradient"
                  borderRadius="lg"
                  shadow="lg"
                  opacity={1}
                  p={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                // textAlign="center"
                >
                    <MDTypography variant="h4" color="white">{popuptitle}</MDTypography>
                    <IconButton
                      style={{ color: "white", cursor: "pointer" }}
                      sx={{
                        "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
                      }}
                      onClick={() => setOpen(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </MDBox></DialogTitle>
                
              </Dialog>
              {/* <EmployeesData1 data={updateData} close={handleClose} />
              <EmployeesData2 data={updateData} close={handleClose} /> */}
              <MDBox p={3}>
                <DataGrid
                  id="gridContainer"
                  keyExpr="employee_user_id"
                  dataSource={employeeUserUserDetails}
                  ref={userGrid}
                  showBorders={true}
                  remoteOperations={true}
                  wordWrapEnabled={true}
                  showColumnLines={false}
                  rowAlternationEnabled={true}
                  allowColumnReordering={true}
                
                >
                  <ColumnChooser
                    enabled={true}
                    mode="select"
                  />
                  <Pager
                    visible={true}
                    displayMode="full"
                    showInfo={true}
                    showNavigationButtons={true} />
                  <Paging defaultPageSize={10} />
                  <SearchPanel
                    visible={true}
                    defaultText={universalSearchCookie}
                  />
                  <Export
                    enabled={true}
                    fileName="All Assigned User"
                  />
                  <Editing
                    mode="row"
                    allowAdding={false}
                    allowUpdating={false}
                    useIcons={true}
                    allowDeleting={false}
                  />
                  <Column
                    caption="Product"
                    alignment="center"
                    dataField="product_name"
                    minWidth={150}
                  >
                   
                  </Column>
                  <Column
                    caption="Assigned To"
                    dataField="employee_name"
                    alignment="center"
                    minWidth={150}
                >
                  </Column>
                  
                <Column
                caption="Assigned Employee ID"
                dataField="employee_id"
                alignment="center"
                minWidth={150}
                ></Column>
                  <Column
                    caption="Assigned By"
                    dataField="assigned_by_name"
                    alignment="center"
                    minWidth={150}
                  >
                  </Column>
                 
                  <Toolbar>

                    <Item name="searchPanel" />
                    <Item name="exportButton" />
                                       
                  </Toolbar>

                </DataGrid>
                
              </MDBox>
            
         
       
      </MDBox>
  );
}

export default AllProductAssigned;
