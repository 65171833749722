/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable prettier/prettier */
import { Card, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Icon, IconButton, Switch } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import { Button, Column, DataGrid, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { Pager } from 'devextreme-react/tree-list';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import AddUpdateDesignation from './add-update-designation'
import DeletePopup from "layouts/master-data/delete-popup";
import Caller from 'Apis/config/Caller';
import { GET } from 'Apis/config/RequestType';
import { get_lookup, delete_lookup } from 'Apis/config/Url';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import CustomStore from 'devextreme/data/custom_store';
import { POST } from 'Apis/config/RequestType';
import { change_lookup_status } from 'Apis/config/Url';
import { get_related_type_designation } from 'Apis/config/Url';
import { getDesignationDepartment } from 'Apis/Auth/auth';
import { delete_related_type_designation } from 'Apis/config/Url';
import contained from 'assets/theme/components/button/contained';
import Footer from 'examples/Footer';

import Cookies from "js-cookie";
import { RIGHTS, UNIVERSAL_SEARCH_COOKIE } from "Apis/config/ResponseHandler";



const isNotEmpty = (value) => {
  return value !== undefined && value !== null && value !== "";
}

const Designation = () => {
  let universalSearchCookie = Cookies.get(UNIVERSAL_SEARCH_COOKIE);
  Cookies.set(UNIVERSAL_SEARCH_COOKIE, "")

  let rights = Cookies.get(RIGHTS) ?? "";
  let rightsArray = rights.split(',') ?? [];


  const DepartmentDesignationData = new CustomStore({

    key: 'id',
    load(loadOptions) {
      let params = "?";
      [
        "skip",
        "take",
        "requireTotalCount",
        "requireGroupCount",
        "sort",
        "filter",
        "totalSummary",
        "group",
        "groupSummary",
        "isLoadingAll"
      ].forEach((i) => {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {

          if (i === "skip") {
            let page = (loadOptions[i] + 25) / 25;
            if (page !== 1) {
              params += `page=${page}&`;
            }
          } else if (i === "sort") {
            let sortType = loadOptions[i][0]["desc"] ? "desc" : "asc";
            let sortColoumn = loadOptions[i][0]["selector"];
            params += `sortType=${sortType}&sortColoumn=${sortColoumn}&`;
          } else if (i === "filter") {
            let filterO = loadOptions[i][0]["filterValue"];
            params += `s=${filterO}&`;
          } else {
            params += `${i}=${JSON.stringify(loadOptions[i])}&`;
          }
        }
      });
      params = params.slice(0, -1);
      return getDesignationDepartment(params)
        .then((data) => ({
          data: data.data.data,
          totalCount: data.data.count,
          next: data.data.next,
          previous: data.data.previous
        }));
    },

  });


  const firstPath = location.pathname.split('/')[1];

  const userGrid = useRef();
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [popuptitle, setPopupTitle] = useState("Add");
  const [updateData, setUpdate] = useState("");
  const popupDeleteText = firstPath == 'departments' ? "Do you want to delete this Department data?" : "Do you want to delete this Designation data?";

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setUpdate("");
      setOpen(false);
      setDeleteOpen(false);
    }
  };

  const selectPopupVisible = async (e) => {
    await Caller(get_related_type_designation + "?id=" + e.row.data.id + "&page=1&s=&take=10", "", GET, false, true).then(async (res) => {
      if (res.success === true) {
        setUpdate(res.data.data);
      }
    });
    setPopupTitle(firstPath == 'departments' ? "Update Department" : "Update Designation");
    setOpen(true);
  }

  const deleteVisible = (e) => {
    setUpdate(e.row.data.id);
    setDeleteOpen(true);
  }

  const refreshGrid = () => {
    userGrid.current.instance.refresh();
  }
  const refreshGridDelete = () => {
    userGrid.current.instance.refresh();
  }
  const userType = Cookies.get('auth_usertype');

  const handleChanged = async (event, id) => {
    let current_id = id;
    let is_active = "";
    if (event.target.checked === false) {
      is_active = 0
    }
    else {
      is_active = 1
    }
    const formData = new FormData();
    formData.append("id", id);
    formData.append("status", is_active);
    await Caller(change_lookup_status, formData, POST, false, true)
      .then((data) => {
        if (data.success === true) {
          refreshGrid();
        }
      });
  }

  const renderGridStatus = (data) => {
    let func = "";
    let label = "";
    if (data.data.is_active == "Yes") {
      func = true
      label = "Active"
    }
    else {
      func = false
      label = "Inactive"
    }

    return <FormControlLabel
      control={<Switch
        checked={func}
        color="secondary"
        onChange={(event) => handleChanged(event, data.data.option_id)}
        inputProps={{ 'aria-label': 'controlled', role: 'switch' }}
      />}
      label={label} />
  }

  // const renderDepartmentDesignation = (data) => {
  //   return <a href="#" onClick={() => selectPopupVisible(data)}>{data.data.name}</a>
  // }
  const renderDepartmentDesignation = (data) => {
    return userType === '1' ? (
      <a href="#" onClick={() => selectPopupVisible(data)}>{data.data.name}</a>
    ) : (
      <span>{data.data.name}</span>
    );
  };
  return (
    <DashboardLayout>
      {deleteOpen && <DeletePopup deleteId={updateData} deleteText={popupDeleteText} open={deleteOpen} close={handleClose} path={delete_related_type_designation} refresh={refreshGridDelete} />}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h4" color="white">
                  {firstPath == 'departments' ? "Department" : "Designation"}
                </MDTypography>
              </MDBox>
              <Dialog
                open={open}
                onClose={handleClose}
                maxWidth='md'
                sx={{
                  '.MuiDialogContent-root': { pl: 5, pr: 5, pb: 5, pt: 2 }
                }}
              >
                <DialogTitle
                ><MDBox
                  color="white"
                  bgColor="info"
                  variant="gradient"
                  borderRadius="lg"
                  shadow="lg"
                  opacity={1}
                  p={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                // textAlign="center"
                >
                    <MDTypography variant="h4" color="white">{popuptitle}</MDTypography>
                    <IconButton
                      style={{ color: "white", cursor: "pointer" }}
                      sx={{
                        "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
                      }}
                      onClick={() => setOpen(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </MDBox></DialogTitle>
                <DialogContent p={3}>
                  <AddUpdateDesignation updateData={updateData} close={handleClose} refresh={refreshGrid} />
                </DialogContent>
              </Dialog>
              <MDBox p={3}>
                <DataGrid
                  id="gridContainer"
                  dataSource={DepartmentDesignationData}
                  keyExpr="id"
                  ref={userGrid}
                  showBorders={true}
                  remoteOperations={true}
                  wordWrapEnabled={true}
                  showColumnLines={false}
                  rowAlternationEnabled={true}
                >
                  <Pager
                    visible={true}
                    displayMode="full"
                    showInfo={true}
                    showNavigationButtons={true} />
                  <Paging defaultPageSize={25} />
                  <SearchPanel
                    visible={true}
                    defaultText={universalSearchCookie}
                  />
                  <Export
                    enabled={true}
                    fileName={firstPath == 'departments' ? "Department" : "Designation"}
                  />
                  <Editing
                    mode="row"
                    allowAdding={false}
                    // allowUpdating={true}
                    allowUpdating={userType === '1' && rightsArray.includes('100') && rightsArray.includes('108') && rightsArray.includes('201') ? true : false}
                    useIcons={true}
                  />

                  <Column
                    caption="ID"
                    dataField="id"
                    alignment="center"
                    // cellRender={renderDepartmentDesignation}
                    minWidth={100}
                  />

                  <Column
                    caption={firstPath == 'departments' ? "Department Name" : "Designation Name"}
                    dataField="name"
                    alignment="center"
                    cellRender={renderDepartmentDesignation}
                    minWidth={100}
                  />
                  <Column
                    caption={firstPath == 'departments' ? "Department Description" : "Designation Description"}

                    dataField="description"
                    alignment="center"
                    minWidth={200}
                  />
                  {/* <Column
                    caption="Status"
                    dataField="is_active"
                    alignment="center"
                    cellRender={renderGridStatus}
                    minWidth={100}
                  /> */}

                  {userType === '1' && rightsArray.includes('100') && rightsArray.includes('108') && rightsArray.includes('201') &&
                    <Column type="buttons" dataField="Actions" width={150}>
                      <Button icon="edit" hint="Edit" onClick={selectPopupVisible} />
                      <Button hint="Delete" icon="trash" style={{ color: "red" }} onClick={deleteVisible} />
                    </Column>
                  }
                  <Toolbar>
                    <Item name="searchPanel" locateInMenu="auto" />
                    <Item name="exportButton" locateInMenu="auto" />
                    {userType === '1' && rightsArray.includes('100') && rightsArray.includes('108') && rightsArray.includes('201') &&
                      <Item location="after" locateInMenu="auto">
                        <MDButton variant="gradient" color="success" onClick={() => { setOpen(true); setUpdate(""); setPopupTitle(firstPath == 'departments' ? "Add Department" : "Add Designation"); }}>
                          Add&nbsp;
                          <Icon>add</Icon>&nbsp;
                        </MDButton>
                      </Item>
                    }
                  </Toolbar>

                </DataGrid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  )
}

export default Designation;