import React from 'react';

const VideoViewer = ({ videoUrl }) => {
  return (
    <div
     
    >
      <video controls style={{ maxWidth: '100%', maxHeight: '100%' }}>
        <source src={videoUrl} type="video/mp4" />
      </video>
    </div>
  );
};

export default VideoViewer;