// /* eslint-disable react/jsx-boolean-value */
// /* eslint-disable react/destructuring-assignment */
// /* eslint-disable react/prop-types */
// /* eslint-disable no-unused-vars */
// /* eslint-disable arrow-body-style */
// /* eslint-disable react/function-component-definition */
// /* eslint-disable prettier/prettier */
import { CheckBox, FileUpload } from '@mui/icons-material';
import { Autocomplete, Box, Button, Card, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputAdornment, InputLabel, ListItemText, MenuItem, Modal, Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import MDButton from 'components/MDButton';
import React, { useEffect, useState } from 'react';
import Caller from 'Apis/config/Caller';
import { POST, GET } from 'Apis/config/RequestType';
import { create_user, update_user, department_designation, employee_re_verification } from 'Apis/config/Url';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import Footer from 'examples/Footer';
import { Link, useNavigate } from 'react-router-dom';
import { department, demat_roles, designation, employment_status, employment_type } from 'Apis/Auth/auth';
import { useSnackbar } from 'notistack';
import { get_employee_user_details } from 'Apis/config/Url';
import { get_product } from 'Apis/config/Url';
import { reporting_manager } from 'Apis/config/Url';
import BackupIcon from '@mui/icons-material/Backup';
import { BASE_URL } from 'Apis/config/Url';
import IconButton from '@mui/material/IconButton';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { LOGIN_AUTH_COOKIE } from 'Apis/config/ResponseHandler';
import { LOGIN_REFRESH_COOKIE } from 'Apis/config/ResponseHandler';
import { RIGHTS } from 'Apis/config/ResponseHandler';
import { NOT_VERIFIED_OR_SUBMITTED } from 'Apis/config/ResponseHandler';
import Cookies from 'js-cookie';
import PhoneNumberMask from 'components/InputModifier';
import CloseIcon from '@mui/icons-material/Close';
import FormGroup from '@mui/material/FormGroup';
import { activation_mail } from 'Apis/config/Url';
import { EMP_USER_ID } from 'Apis/config/ResponseHandler';
const moment = require("moment");

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: "10vw",
        },
    },
};


const EmployeesData1 = (props) => {

    let url_string = window.location.href;
    let myParam = url_string.split('update-')[1];
    let isProfile = props.isProfile ? props.isProfile : false;

    const { enqueueSnackbar } = useSnackbar();
    const [dbrowsData, dbsetRowsData] = useState([]);
    const [afterEmployee, setAfterEmployee] = useState({
        showEmployeePwd: false,
        showEmployeePwdMail: false,
        employeeMail: "",
        employeeMailError: "",
        copyMail: false,
        generatedPwd: "",
        employeeGuid: "",
        copyButtonText: "Copy",
    });
    const [values, setAllValues] = useState({
        employee_user_id: "",
        employee_id: "",
        first_name: '',
        last_name: "",
        email: "",
        mobile: "",
        official_mobile_number: "",
        personal_email_id: "",
        personal_contact_number: "",
        father_name: "",
        mother_name: "",
        contact_address: "",
        emergency_contact_name: "",
        emergency_contact_mobile_number_1: "",
        emergency_contact_mobile_number_2: "",
        insurance_agent_name: "",
        insurance_policy_number: "",
        sum_assured: "",
        extension: '',
        profile_pic: "",
        password: "",
        department_id: "",
        department: [],
        designation: [],
        designation_id: "",
        demat_roles: "",
        employment_type: "",
        employment_status: "",
        date_of_joining: "",
        reporting_manager_id: "",
        reporting_manager_val: "",
        reporting_manager: [],
        date_of_birth: "",
        gender: "Female",
        marital_status: "Single",
        uan: "",
        pan: "",
        aadhar: "",
        date_of_exit: "",
        products_value: [],
        assign_lead: false
    })

    const [products, setProducts] = useState([]);
    const navigate = useNavigate();
    const [disableDownloadButton, setDisableDownload] = useState(false);
    const [disabledSave, setDisabledSave] = useState();
    const [eidDisable, setEidDisable] = useState(false);
    const [eFieldsDisable, setEFieldsDisable] = useState(false);
    const [isSubmissionVerified, setIsSubmissionVerified] = useState(0);
    const [isSubmittedReview, setIsSubmittedReview] = useState(0);

    const [errors, setAllErrors] = useState({
        employee_id: "",
        first_name: '',
        last_name: "",
        email: "",
        mobile: "",
        official_mobile_number: "",
        personal_email_id: "",
        personal_contact_number: "",
        father_name: "",
        mother_name: "",
        contact_address: "",
        emergency_contact_name: "",
        emergency_contact_mobile_number_1: "",
        emergency_contact_mobile_number_2: "",
        insurance_agent_name: "",
        insurance_policy_number: "",
        sum_assured: "",
        extension: '',
        profile_pic: "",
        password: "",
        department: "",
        designation: "",
        demat_roles: "",
        employment_type: "",
        employment_status: "",
        date_of_joining: "",
        reporting_manager: "",
        date_of_birth: "",
        gender: "",
        marital_status: "",
        uan: "",
        pan: "",
        aadhar: "",
        date_of_exit: "",
        products: "",
    })


    const [isInitialRender, setIsInitialRender] = useState(true);
    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);

            if (myParam || isProfile) {
                Caller(isProfile ? get_employee_user_details + "?take=10&requireTotalCount=true&s=&id=" + myParam + "&isProfile=" + isProfile : get_employee_user_details + "?take=10&requireTotalCount=true&s=&id=" + myParam, "", GET, false, true).then(async (res) => {
                    if (res.success === true) {
                        // let arrayVal = [];
                        // for (const [key, value] of Object.entries(res.data.data[0].product_id)) {
                        //     if (value.product_id == 0) {
                        //         continue;
                        //     } else {
                        //         arrayVal.push(value['product_id']);
                        //     }

                        // }
                        const arrayVal = res.data.data[0].product_id ? res.data.data[0].product_id.split(',').map(Number) : [];
                        let arrayReporting = {};
                        Caller(reporting_manager + "?type=" + res.data.data[0].user_type, "", GET, false, true).then((dat) => {
                            if (dat.success === true) {
                                if (dat.data.length > 0) {
                                    arrayReporting = dat.data.find(o => o.employee_user_id == res.data.data[0].reporting_manager);
                                }

                                setAllValues((values) => ({
                                    ...values,
                                    reporting_manager: dat?.data,
                                    reporting_manager_val: arrayReporting ? arrayReporting.name : "",
                                }))
                            }

                        });
                        //setEidDisable(true);
                        //setEFieldsDisable(true);
                        setIsSubmissionVerified(res.data.data[0].is_submission_verified)
                        setIsSubmittedReview(res.data.data[0].is_submitted_review)

                        if (res?.data?.data[0].user_type == 5 && res?.data?.data[0].reporting_manager == null) {
                            setAllErrors((errors) => ({
                                ...errors,
                                reporting_manager: "Reporting manager required!"
                            }));
                        }

                        setAllValues((values) => ({
                            ...values,
                            employee_id: res.data.data[0].employee_id ? res.data.data[0].employee_id : "",
                            employee_user_id: res.data.data[0].employee_user_id ? res.data.data[0].employee_user_id : "",
                            first_name: res.data.data[0].first_name ? res.data.data[0].first_name : "",
                            last_name: res.data.data[0].last_name ? res.data.data[0].last_name : "",
                            email: res.data.data[0].username ? res.data.data[0].username : "",
                            mobile: res.data.data[0].mobile ? res.data.data[0].mobile : "",

                            // password: res.data.data[0].password ? res.data.data[0].password : "",

                            official_mobile_number: res.data.data[0].official_mobile_number ? res.data.data[0].official_mobile_number : "",
                            personal_contact_number: res.data.data[0].personal_contact_number ? res.data.data[0].personal_contact_number : "",
                            personal_email_id: res.data.data[0].personal_email_id ? res.data.data[0].personal_email_id : "",
                            father_name: res.data.data[0].father_name ? res.data.data[0].father_name : "",
                            mother_name: res.data.data[0].mother_name ? res.data.data[0].mother_name : "",
                            contact_address: res.data.data[0].contact_address ? res.data.data[0].contact_address : "",
                            emergency_contact_name: res.data.data[0].emergency_contact_name ? res.data.data[0].emergency_contact_name : "",
                            emergency_contact_mobile_number_1: res.data.data[0].emergency_contact_mobile_number_1 ? res.data.data[0].emergency_contact_mobile_number_1 : "",
                            emergency_contact_mobile_number_2: res.data.data[0].emergency_contact_mobile_number_2 ? res.data.data[0].emergency_contact_mobile_number_2 : "",
                            insurance_agent_name: res.data.data[0].insurance_agent_name ? res.data.data[0].insurance_agent_name : "",
                            insurance_policy_number: res.data.data[0].insurance_policy_number ? res.data.data[0].insurance_policy_number : "",
                            sum_assured: res.data.data[0].sum_assured ? res.data.data[0].sum_assured : "",
                            profile_pic: res.data.data[0].profile_pic ? res.data.data[0].profile_pic : "",
                            department_id: res.data.data[0].department ? res.data.data[0].department : "",
                            designation_id: res.data.data[0].designation ? res.data.data[0].designation : "",
                            demat_roles: res.data.data[0].user_type ? res.data.data[0].user_type : "",
                            employment_type: res.data.data[0].employment_type ? res.data.data[0].employment_type : "",
                            employment_status: res.data.data[0].employment_status ? res.data.data[0].employment_status : "",
                            date_of_joining: res.data.data[0].date_of_joining && res.data.data[0].date_of_joining != "00/00/0000" ? res.data.data[0].date_of_joining.split("/").reverse().join("-") : "",
                            reporting_manager_id: res.data.data[0].reporting_manager === 0 ? "" : res.data.data[0].reporting_manager,
                            date_of_birth: res.data.data[0].date_of_birth && res.data.data[0].date_of_birth != "00/00/0000" ? res.data.data[0].date_of_birth.split("/").reverse().join("-") : "",
                            gender: res.data.data[0].gender ? res.data.data[0].gender : "Female",
                            marital_status: res.data.data[0].marital_status ? res.data.data[0].marital_status : "Single",
                            uan: res.data.data[0].uan ? res.data.data[0].uan : "",
                            pan: res.data.data[0].pan ? res.data.data[0].pan : "",
                            aadhar: res.data.data[0].aadhar ? res.data.data[0].aadhar : "",
                            date_of_exit: res.data.data[0].date_of_exit && res.data.data[0].date_of_exit != "00/00/0000" ? res.data.data[0].date_of_exit.split("/").reverse().join("-") : "",
                            // products_value: res.data.data[0].product_id ? arrayVal : "",
                            products_value: arrayVal,
                            assign_lead: res.data.data[0].assign_lead == 0 || res.data.data[0].assign_lead == '0' ? false : true,
                        }))
                        if (res.data.data[0].document_files) {
                            dbsetRowsData(res.data.data[0].document_files)
                        }

                    }
                });
            }

            Caller(get_product + "?id=&take=10&requireTotalCount=true&page=1&isLoadingAll=true", "", GET, false, true).then(async (res) => {
                if (res.success === true) {
                    await setProducts(res.data.data);
                }
            });
            Caller(department_designation + "?type=Department", "", GET, false, true).then(async (res) => {
                if (res.success === true) {
                    await setAllValues((values) => ({
                        ...values,
                        department: res.data
                    }));
                }
            });
            Caller(department_designation + "?type=Designation", "", GET, false, true).then(async (res) => {
                if (res.success === true) {
                    await setAllValues((values) => ({
                        ...values,
                        designation: res.data
                    }));
                }
            });




        }
    }, [])

    const handleChange = (index, evnt) => {
        const { name, value, files } = evnt.target;
        let re;
        let modifiedValue;

        if (name === 'document_name') {
            re = /^[a-zA-Z_ ]*$/;
            modifiedValue = value.replace(/\s+/g, '_');

        } else if (name === 'document_number') {
            re = /^[a-zA-Z0-9]*$/;
            modifiedValue = value;
        }
        else if (name === 'file_name') {
            re = /^.{0,100}$/;
            let arr = ['png', 'jpeg', 'jpg', 'pdf'];

            const fileExtension = value.split('.').pop();
            if ((arr.includes(fileExtension)) && files[0].size <= 1000000) {
                if (evnt.target.value.match(re) || evnt.target.value === '') {
                    const rowsInput = [...dbrowsData];
                    rowsInput[index]["file_path"] = evnt.target.files[0], rowsInput[index]["file_name"] = evnt.target.files[0].name;
                    dbsetRowsData(rowsInput);
                }
            }
            if (!arr.includes(fileExtension)) {
                enqueueSnackbar(`Only png, jpg, jpeg, and pdf file allowed !`, { variant: 'error', autoHideDuration: 4000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
            }
            if (files[0].size > 1000000) {
                enqueueSnackbar(`File size exceeds the limit. Maximum file size allowed is 1 Mb !`, { variant: 'error', autoHideDuration: 4000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
            }

        }

        if (name === 'document_name' || name === 'document_number') {
            if (re.test(modifiedValue) || modifiedValue === '') {
                const rowsInput = [...dbrowsData];
                // (name == "file_name") ? (rowsInput[index]["file_path"] = evnt.target.files[0], rowsInput[index]["file_name"] = evnt.target.files[0].name) : rowsInput[index][name] = modifiedValue;
                rowsInput[index][name] = modifiedValue;
                dbsetRowsData(rowsInput);
            }
        }

    }

    const activateMail = (e) => {
        if (e.target.checked) {
            setAfterEmployee((values) => ({ ...values, showEmployeePwdMail: true }))
        } else {
            setAfterEmployee((values) => ({ ...values, showEmployeePwdMail: false }))
        }
    }

    const activateSelfMail = (e) => {
        if (e.target.checked) {
            setAfterEmployee((values) => ({ ...values, copyMail: true }))
        } else {
            setAfterEmployee((values) => ({ ...values, copyMail: false }))
        }
    }


    const copyToClipBoard = () => {
        navigator.clipboard.writeText(afterEmployee.generatedPwd)
        setAfterEmployee((values) => ({ ...values, copyButtonText: "Copied !" }))
    }

    const handleAfterEmployeeOpen = () => {
        setAfterEmployee(true);
    };
    const handleAfterEmployeeClose = async () => {
        setAfterEmployee((values) => ({ ...values, showEmployeePwd: false }))
        const formData = new FormData();
        formData.append('employee_guid', afterEmployee.employeeGuid);
        formData.append('password', afterEmployee.generatedPwd);
        formData.append('sendMail', afterEmployee.showEmployeePwdMail);
        formData.append('copyMail', afterEmployee.copyMail);

        await Caller(activation_mail, formData, POST, false, true).then((res) => {
            if (res.success === true) {
                navigate("/employees");
            }
        });
    };

    const autoGeneratePassword = (e) => {
        let length = 15,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+./-",
            retVal = "";
        for (let i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        setAllValues((values) => ({
            ...values,
            password: retVal
        }))
        setAllErrors((state) => ({
            ...state,
            [`${e.target.name}`]: ""
        }))
    }

    const textUpdate = (e) => {
        const re = /^[\-a-zA-Z0-9 ]+$/;
        if (re.test(e.target.value) || (e.target.value === "")) {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }))
        }
        setAllErrors((state) => ({
            ...state,
            [`${e.target.name}`]: ""
        }))
    }
    const nameUpdate = (e) => {
        const re = /^[a-zA-Z ]+$/;
        if (re.test(e.target.value) || (e.target.value === "")) {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }))
        }
        setAllErrors((state) => ({
            ...state,
            [`${e.target.name}`]: ""
        }))
    }

    const uanUpdate = (e) => {
        const re = /^[0-9]+$/;

        if (re.test(e.target.value) || e.target.value === "") {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }));
            if (e.target.value === '000000000000' || e.target.value.charAt(0) === '0') {
                setAllErrors((state) => ({
                    ...state,
                    [`${e.target.name}`]: "Invalid input."
                }));
            } else if (e.target.value.length < 12 && e.target.value != '') {
                setAllErrors((state) => ({
                    ...state,
                    [`${e.target.name}`]: "UAN Number Should be 12 Digits."
                }));
            } else {
                setAllErrors((state) => ({
                    ...state,
                    [`${e.target.name}`]: ""
                }));
            }
        } else {
            setAllErrors((state) => ({
                ...state,
                [`${e.target.name}`]: ""
            }));
        }
    };

    const addressUpdate = (e) => {
        const re = /^[a-zA-Z0-9 ,.:-]+$/;
        if (re.test(e.target.value) || e.target.value == "") {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }))
        }
        setAllErrors((state) => ({
            ...state,
            [`${e.target.name}`]: ""
        }))
    }
    const panUpdate = (e) => {
        const { name, value } = e.target;
        const alphaNumericRe = /^[a-zA-Z0-9]*$/;
        const alphaRe = /^[a-zA-Z]+$/;
        const numRe = /^[0-9]+$/;

        let error = "";

        if (value === "") {
            setAllErrors((state) => ({
                ...state,
                [name]: ""
            }));
        } else if (!alphaNumericRe.test(value)) {
            error = "PAN Number must be alphanumeric!";
        } else if (alphaRe.test(value)) {
            error = "PAN Number cannot contain only alphabets!";
        } else if (numRe.test(value)) {
            error = "PAN Number cannot contain only numbers!";
        }

        setAllValues((state) => ({
            ...state,
            [name]: value
        }));
        setAllErrors((state) => ({
            ...state,
            [name]: error
        }));
    }


    const aadharUpdate = (e) => {
        const re = /^[0-9]+$/;
        console.log("adhar=>", e.target.value)
        if (re.test(e.target.value) || (e.target.value === "")) {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }))
            if (e.target.value === '000000000000' || e.target.value.charAt(0) === '0') {
                setAllErrors((state) => ({
                    ...state,
                    [`${e.target.name}`]: "Invalid input."
                }));
            } else if (e.target.value.length < 12 && e.target.value != '') {
                setAllErrors((state) => ({
                    ...state,
                    [`${e.target.name}`]: "Aadhaar Number Should be 12 Digits."
                }));
            } else {
                setAllErrors((state) => ({
                    ...state,
                    [`${e.target.name}`]: ""
                }));
            }
        } else {
            setAllErrors((state) => ({
                ...state,
                [`${e.target.name}`]: ""
            }));
        }
    }

    const passUpdate = (e) => {
        setAllValues((state) => ({
            ...state,
            [`${e.target.name}`]: e.target.value
        }))
        setAllErrors((state) => ({
            ...state,
            [`${e.target.name}`]: ""
        }))
    }

    const officialEmailUpdate = (e) => {
        // Apply email regex
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const { name, value } = e.target;
        // Update value state
        setAllValues((state) => ({
            ...state,
            [name]: value
        }));
        // Update error state based on validation result
        if (value.trim() === "") {
            setAllErrors((state) => ({
                ...state,
                [name]: "email is not allowed to be empty"
            }));
        } else if (!re.test(value)) {
            setAllErrors((state) => ({
                ...state,
                [name]: "Invalid email address"
            }));
        } else {
            setAllErrors((state) => ({
                ...state,
                [name]: ""
            }));
        }
    };

    const personalEmailUpdate = (e) => {
        const inputValue = e.target.value;
        const inputName = e.target.name;

        // Apply email regex to the whole input value
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Update the state with the partial email address
        setAllValues((state) => ({
            ...state,
            [inputName]: inputValue
        }));

        // Validate the partial email address
        if (re.test(inputValue)) {
            // Clear the error if the partial email address is valid
            setAllErrors((state) => ({
                ...state,
                [inputName]: ""
            }));
        }
        else if (inputValue.trim() == '') {
            setAllErrors((state) => ({
                ...state,
                [inputName]: ""
            }));
        }
        else {
            // Set the error if the partial email address is invalid
            setAllErrors((state) => ({
                ...state,
                [inputName]: "Invalid email address"
            }));
        }
    };

    const onMobileUpdate = (e) => {
        const re = /^[1-9][0-9]*$/;
        // if (re.test(e.target.value) || (e.target.value === "")) {
        setAllValues((state) => ({
            ...state,
            [`${e.target.name}`]: e.target.value
        }))
        //}
        setAllErrors((state) => ({
            ...state,
            [`${e.target.name}`]: ""
        }))
    }





    const policyNumberhandler = (e) => {
        const { name, value } = e.target;
        let alphaRe = /^[a-zA-Z]*$/;
        let numRe = /^[0-9]*$/;
        let alphaNumericRe = /^[a-zA-Z0-9]*$/;

        if (value == "") {
            setAllValues((state) => ({
                ...state,
                [`${name}`]: value
            }));
            setAllErrors((state) => ({
                ...state,
                [`${name}`]: ""
            }));
        }
        else if (!alphaNumericRe.test(value)) {
            setAllErrors((state) => ({
                ...state,
                [`${name}`]: "Policy Number must be alphanumeric!"
            }));
        } else if (alphaRe.test(value)) {
            setAllErrors((state) => ({
                ...state,
                [`${name}`]: "Policy Number cannot contain only alphabets!"
            }));
            setAllValues((state) => ({
                ...state,
                [`${name}`]: value
            }));
        } else if (numRe.test(value)) {
            setAllErrors((state) => ({
                ...state,
                [`${name}`]: "Policy Number cannot contain only numbers!"
            }));
            setAllValues((state) => ({
                ...state,
                [`${name}`]: value
            }));
        } else if (value == "") {
            setAllErrors((state) => ({
                ...state,
                [`${name}`]: ""
            }));
            setAllValues((state) => ({
                ...state,
                [`${name}`]: value
            }));
        } else {
            setAllValues((state) => ({
                ...state,
                [`${name}`]: value
            }));
            setAllErrors((state) => ({
                ...state,
                [`${name}`]: ""
            }));
        }
    }

    const sumAssuredHandler = (e) => {
        const { name, value } = e.target;
        let re = /^[0-9]*$/;
        if (re.test(value) || (value === "")) {
            setAllValues((state) => ({
                ...state,
                [`${name}`]: value
            }))
        }
        setAllErrors((state) => ({
            ...state,
            [`${name}`]: ""
        }))
    }

    const dematRoleUpdate = async (e) => {

        setAllValues((values) => ({
            ...values,
            reporting_manager_val: "",
            reporting_manager_id: ''
        }));

        if (e.target.value !== 1) {
            await Caller(reporting_manager + "?type=" + e.target.value, "", GET, false, true)
                .then(async (res) => {
                    if (res.success === true) {
                        let data = res.data.filter((item) => !item.name.startsWith(values.employee_id));
                        setAllValues((values) => ({
                            ...values,
                            reporting_manager: data,
                            products_value: []
                        }));
                    }
                });
        }

        if (e.target.value === 5 && values.reporting_manager_val === "") {
            setAllErrors((errors) => ({
                ...errors,
                reporting_manager: "Reporting manager required!"
            }));
        } else {
            setAllErrors((errors) => ({
                ...errors,
                reporting_manager: ""
            }));
        }

        // Update the demat_roles regardless of the condition
        setAllValues((values) => ({
            ...values,
            demat_roles: e.target.value
        }));

        // Clear the error for the current target name
        setAllErrors((errors) => ({
            ...errors,
            [e.target.name]: ""
        }));
    };


    const addTableRows = (event) => {
        event.preventDefault();
        setDisableDownload(true);
        const rowsInput = {
            document_name: '',
            document_number: '',
            file_name: '',
            file_path: ""
        }
        if (dbrowsData !== undefined && dbrowsData.length < 10) {
            dbsetRowsData([...dbrowsData, rowsInput])
        } else if (dbrowsData.length < 10) {
            dbsetRowsData([rowsInput])
        }

    }

    const productSelected = (productsID) => {
        let names = [];
        products.forEach(prod => productsID.includes(prod.product_id) && names.push(prod.product_name));
        return names.join(", ");
    }

    const deleteTableRows = (index) => {
        const rows = [...dbrowsData];
        rows.splice(index, 1);
        if (index === "clear") {
            rows.splice(0, rows.length);
        }
        dbsetRowsData(rows);
    }

    const downloadFile = async (filePath, fileName) => {
        fetch(filePath)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.setAttribute(
                        'download',
                        fileName,
                    );

                    // Append to html link element page
                    document.body.appendChild(a);

                    // Start download
                    a.click();

                    // Clean up and remove the link
                    a.parentNode.removeChild(a);
                });
            });

    };
    const cancelClicked = () => {
        if (isProfile) {
            Cookies.remove(LOGIN_AUTH_COOKIE);
            Cookies.remove(LOGIN_REFRESH_COOKIE);
            Cookies.remove(RIGHTS);
            Cookies.remove(NOT_VERIFIED_OR_SUBMITTED);
            Cookies.remove(EMP_USER_ID);
            navigate("/login");
        }
        else {
            navigate("/employees")
        }

    }

    const addAdmin = async (event) => {
        event.target.classList.add("was-validated");
        setDisabledSave('disabled');
        event.preventDefault();
        event.stopPropagation();

        const formData = new FormData();
        formData.append('employee_id', values.employee_id);
        formData.append('first_name', values.first_name);
        formData.append('last_name', values.last_name);
        formData.append('email', values.email);
        formData.append('official_mobile_number', values.official_mobile_number.replace(/[^\d]/g, ""));
        formData.append('personal_contact_number', values.personal_contact_number.replace(/[^\d]/g, ""));
        formData.append('personal_email_id', values.personal_email_id);
        formData.append('father_name', values.father_name);
        formData.append('mother_name', values.mother_name);
        formData.append('contact_address', values.contact_address);
        formData.append('emergency_contact_name', values.emergency_contact_name);
        formData.append('emergency_contact_mobile_number_1', values.emergency_contact_mobile_number_1.replace(/[^\d]/g, ""));
        formData.append('emergency_contact_mobile_number_2', values.emergency_contact_mobile_number_2.replace(/[^\d]/g, ""));
        formData.append('insurance_agent_name', values.insurance_agent_name);
        formData.append('insurance_policy_number', values.insurance_policy_number);
        formData.append('sum_assured', values.sum_assured);
        formData.append('mobile', values.mobile.replace(/[^\d]/g, ""));
        formData.append('password', values.password);
        formData.append('department', values.department_id);
        formData.append('designation', values.designation_id);
        formData.append('user_type', values.demat_roles);
        formData.append('employment_type', values.employment_type);
        formData.append('employment_status', values.employment_status);
        formData.append('date_of_joining', values.date_of_joining ?? "");
        formData.append('reporting_manager', values.reporting_manager_id || "");
        formData.append('date_of_birth', values.date_of_birth ?? "");
        formData.append('gender', values.gender);
        formData.append('is_logged_in', 1);
        formData.append('marital_status', values.marital_status);
        formData.append('uan', values.uan);
        formData.append('pan', values.pan);
        formData.append('aadhar', values.aadhar);
        formData.append('date_of_exit', values.date_of_exit ?? "");
        formData.append('product_id', values.products_value.length > 0 ? values.products_value : "");
        formData.append('import_data_id', "");
        formData.append('assign_lead', values.assign_lead);
        if (isProfile) {
            formData.append('is_submission_verified', 1);
        }
        if (isSubmissionVerified == 1 && isSubmittedReview == 0) {
            formData.append('is_submitted_review', 1);
        }
        formData.append('document', dbrowsData ? JSON.stringify(dbrowsData) : '');
        for (let i = 0; i < dbrowsData.length; i++) {
            formData.append("files", dbrowsData[i].file_path);
        }

        for (const key in errors) {
            if (errors[key]) {
                setAllErrors((values) => ({
                    ...values,
                    [`${key}`]: errors[key],
                }))

                if (myParam != undefined) {

                    enqueueSnackbar(`Employee not updated, Please resolve errors.`, { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
                } else {

                    enqueueSnackbar(`Employee not added, Please resolve errors.`, { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
                }
                // enqueueSnackbar(`Employee not added, Please resolve errors.`, { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
                return;
            }
        }

        // formData.append('phone', 1234567890);
        await Caller(myParam || isProfile ? update_user + "?user_type=employee&id=" + values.employee_user_id : create_user + "?user_type=employee", formData, POST, false, true).then((res) => {
            if (res.success === true) {
                if (isProfile) {

                    enqueueSnackbar(`Profile submitted for review. Please check after sometime.`, { variant: 'success', autoHideDuration: 4000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
                    Cookies.remove(LOGIN_AUTH_COOKIE);
                    Cookies.remove(LOGIN_REFRESH_COOKIE);
                    Cookies.remove(RIGHTS);
                    Cookies.remove(NOT_VERIFIED_OR_SUBMITTED);
                    Cookies.remove(EMP_USER_ID);
                    navigate("/login");
                }
                else {
                    enqueueSnackbar(`Employee ${myParam ? "updated" : "added"} successfully!`, { variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
                    if (myParam) {
                        navigate("/employees");
                    } else {
                        setAfterEmployee((values) => ({ ...values, showEmployeePwd: true, generatedPwd: res.data.password, employeeGuid: res.data.employee_guid }))
                    }

                }
            }
            else {
                // if (res.success === false && res.error=='Employee Id Already Exists'){
                //     setAllErrors((values) => ({
                //         ...values,
                //         [`employee_id`]: res.error,
                //     }))
                // }

                setDisabledSave('');
                for (const [key, value] of Object.entries(res.error)) {
                    if (key === 'date_of_birth') {
                        setAllErrors((state) => ({
                            ...state,
                            [`date_of_birth`]: `date_of_birth must be less than or equal to "2009-12-31"`
                        }))
                    } else if (key === 'user_type') {
                        setAllErrors((state) => ({
                            ...state,
                            [`demat_roles`]: "Internal Designation is required"
                        }))
                    } else {
                        setAllErrors((values) => ({
                            ...values,
                            [`${key}`]: value,
                        }))
                    }
                }

                if (myParam != undefined) {

                    enqueueSnackbar(`Employee not updated, Please resolve errors.`, { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
                } else {

                    enqueueSnackbar(`Employee not added, Please resolve errors.`, { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
                }

                // enqueueSnackbar(`Employee not added, Please resolve errors.`, { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });

            }
        })
    }

    const selectReportingManager = (e, newValue, clickType) => {
        if (clickType == 'clear') {

            if (values.demat_roles === 5) {
                setAllValues((values) => ({
                    ...values,
                    reporting_manager_val: "",
                    reporting_manager_id: ''
                }));
                setAllErrors((state) => ({
                    ...state,
                    reporting_manager: "Reporting manager required!"
                }))
            } else {
                setAllValues((values) => ({
                    ...values,
                    reporting_manager_val: "",
                    reporting_manager_id: ''
                }));
                setAllErrors((state) => ({
                    ...state,
                    reporting_manager: ""
                }))
            }

        } else {
            let data = values.reporting_manager?.find(o => o.name == newValue);
            setAllValues((values) => ({
                ...values,
                reporting_manager_val: newValue ? newValue : "",
                reporting_manager_id: data ? data.employee_user_id : ''
            }));
            setAllErrors((state) => ({
                ...state,
                reporting_manager: ""
            }))
        }

    }

    const [checked, setChecked] = useState(false)

    const handleCheckChange = (e) => {
        setChecked(e.target.checked);
    }
    let SubmitForReverification = async (e) => {
        const formData = new FormData();
        formData.append('id', values.employee_user_id);
        formData.append('is_submission_verified', 0);
        await Caller(employee_re_verification, formData, POST, false, true).then((data) => {
            if (data.success == true) {
                navigate("/employees");
            }
        })
    }
    const [disableSubmit, setDisableSubmit] = useState(false);
    let doDisableSubmit = false;
    if (isProfile) {
        doDisableSubmit = !checked
    }
    else {
        doDisableSubmit = disableSubmit
    }
    // for the width of the button 
    let width = 120;
    if (isProfile) {
        width = 250
    }

    const dojHandler = (e) => {
        let doj = e.target.value;
        let dob = values.date_of_birth;

        if (dob) {
            if (doj < dob) {
                setAllValues((values) => ({
                    ...values,
                    date_of_joining: e.target.value
                }));
                setAllErrors((state) => ({
                    ...state,
                    date_of_birth: "Date of birth must be less than date of joining!"
                }));
            } else {
                setAllValues((values) => ({
                    ...values,
                    date_of_joining: e.target.value
                }));
            }
        } else {
            setAllValues((values) => ({
                ...values,
                date_of_joining: e.target.value
            }));
            setAllErrors((state) => ({
                ...state,
                [`${e.target.name}`]: ""
            }))
        }

    }

    const dobHandler = (e) => {
        let doj = values.date_of_joining;
        let dob = e.target.value;

        const cutoffDate = '2010-01-01';

        if (doj == "") {
            setAllValues((values) => ({
                ...values,
                date_of_birth: e.target.value
            }));
            setAllErrors((state) => ({
                ...state,
                date_of_birth: ""
            }));
        }
        else if (dob < cutoffDate && dob > doj) {
            // If date of birth is greater than January 1, 2010
            setAllValues((values) => ({
                ...values,
                date_of_birth: e.target.value
            }));
            setAllErrors((state) => ({
                ...state,
                date_of_birth: "Date of birth must be less than date of joining!"
            }));
        } else if (dob > doj) {
            setAllValues((values) => ({
                ...values,
                date_of_birth: e.target.value
            }));
            setAllErrors((state) => ({
                ...state,
                date_of_birth: "Date of birth cannot be greater than January 1, 2010."
            }));
        } else {
            // If date of birth is valid
            setAllValues((values) => ({
                ...values,
                date_of_birth: e.target.value
            }));
            setAllErrors((state) => ({
                ...state,
                date_of_birth: ""
            }));
        }
    }

    const dateOfExitHandler = (e) => {

        if (values.date_of_joining === "") {
            setAllValues((values) => ({
                ...values,
                date_of_exit: e.target.value
            }));
            setAllErrors((state) => ({
                ...state,
                date_of_exit: "Please Enter Date Of Joining First!"
            }));
        } else if (e.target.value === '') {
            setAllValues((values) => ({
                ...values,
                date_of_exit: e.target.value
            }));
            setAllErrors((state) => ({
                ...state,
                date_of_exit: ""
            }));
        } else if (e.target.value < values.date_of_joining) {
            setAllValues((values) => ({
                ...values,
                date_of_exit: e.target.value
            }));
            setAllErrors((state) => ({
                ...state,
                date_of_exit: "Date of Exit must be greater than or equal to date of joining!"
            }));
        } else {
            setAllValues((values) => ({
                ...values,
                date_of_exit: e.target.value
            }));
            setAllErrors((state) => ({
                ...state,
                date_of_exit: ""
            }));
        }
    }

    const assignLeadHandler = (e) => {
        // console.log("Assign lead = ", e.target.checked);

        setAllValues((values) => ({
            ...values,
            assign_lead: e.target.checked
        }))
    }

    return (
        <DashboardLayout>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h4" color="white">

                                    {isProfile ? "Update Profile" : myParam ? "Update Employee" : "Add Employee"}
                                </MDTypography>
                            </MDBox>
                            <MDBox p={3}>
                                <Box
                                    component="form"
                                    onSubmit={addAdmin}
                                    sx={{
                                        '#demo-row-radio-buttons-group-label': { fontSize: 'medium', textAlign: "left" },
                                        '.role-select-custom': { pt: 1.5, pb: 1.5 },
                                        '.add-label': { pb: 1 },
                                        '.radio-button-custom': { fontSize: 'small' }
                                    }}
                                    noValidate
                                >
                                    <Grid container spacing={{ xs: 5, md: 5 }} columns={{ xs: 4, sm: 8, md: 12 }} pl={4}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <MDTypography variant="h4" className='background-image-gradient'>
                                                Basic Information
                                            </MDTypography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Employee Id <sup style={{ color: "red" }}>*</sup></InputLabel>
                                            <TextField
                                                required
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name='employee_id'
                                                value={values.employee_id}
                                                onChange={textUpdate}
                                                error={!!errors.employee_id}
                                                disabled={eidDisable}
                                                helperText={errors.employee_id === "" ? '' : errors.employee_id}
                                                inputProps={{
                                                    maxLength: 100
                                                }}

                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>First Name <sup style={{ color: "red" }}>*</sup></InputLabel>
                                            <TextField
                                                required
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name='first_name'
                                                value={values.first_name}
                                                onChange={nameUpdate}
                                                error={!!errors.first_name}
                                                helperText={errors.first_name === "" ? '' : errors.first_name}
                                                inputProps={{
                                                    maxLength: 50
                                                }}

                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Last Name <sup style={{ color: "red" }}>*</sup></InputLabel>
                                            <TextField
                                                required
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name='last_name'
                                                value={values.last_name}
                                                onChange={nameUpdate}
                                                error={!!errors.last_name}
                                                helperText={errors.last_name === "" ? '' : errors.last_name}
                                                inputProps={{
                                                    maxLength: 50
                                                }}

                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Official Email ID<sup style={{ color: "red" }}>*</sup></InputLabel>
                                            <TextField
                                                required
                                                id=""
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name="email"
                                                value={values.email}
                                                onChange={officialEmailUpdate}
                                                error={!!errors.email}
                                                helperText={errors.email === "" ? '' : errors.email}
                                                inputProps={{
                                                    maxLength: 50
                                                }}
                                                type="email"
                                                disabled={eFieldsDisable}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Official Mobile Number<sup style={{ color: "red" }}>*</sup></InputLabel>
                                            <TextField
                                                required
                                                id=""
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name="official_mobile_number"
                                                value={values.official_mobile_number}
                                                onChange={onMobileUpdate}
                                                InputProps={{
                                                    inputComponent: PhoneNumberMask,
                                                }}
                                                error={!!errors.official_mobile_number}
                                                helperText={errors.official_mobile_number === "" ? '' : errors.official_mobile_number}
                                                type="phone"
                                                disabled={eFieldsDisable}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Reset Password</InputLabel>
                                            <TextField
                                                required
                                                id=""
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name="password"
                                                value={values.password}
                                                onChange={passUpdate}
                                                // onChange={onMobileUpdate}
                                                // InputProps={{
                                                //     inputComponent: PhoneNumberMask,
                                                //   }}
                                                error={!!errors.password}
                                                helperText={errors.password === "" ? '' : errors.password}
                                            // type="phone"
                                            // disabled={eFieldsDisable}
                                            // disabled
                                            />
                                            <Grid item xs={12} sm={12} md={12}
                                                sx={{ textAlign: 'right', marginRight: "5%" }}
                                            ><Button onClick={autoGeneratePassword}>Auto Generate Password</Button>
                                            </Grid>
                                        </Grid>

                                        {/* {(!myParam) && !isProfile && <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Password </InputLabel>
                                            <TextField

                                                id=""
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name="password"
                                                value={values.password}
                                                onChange={passUpdate}
                                                error={!!errors.password}
                                                helperText={errors.password === "" ? '' : errors.password}
                                                inputProps={{
                                                    minLength: 8
                                                }}
                                            />
                                            <Grid item xs={12} sm={12} md={12}
                                                sx={{ textAlign: 'right', marginRight: "5%" }}
                                            ><Button onClick={autoGeneratePassword}>Auto Generate Password</Button>
                                            </Grid>

                                        </Grid>} */}


                                        <Grid item xs={12} sm={12} md={12}>
                                            <MDTypography variant="h4" className='background-image-gradient'>
                                                Work Information
                                            </MDTypography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Department <sup style={{ color: "red" }}>*</sup></InputLabel>
                                            <Select
                                                required
                                                displayEmpty
                                                style={{ width: "90%", height: 45 }}
                                                name="department"
                                                variant="standard"
                                                defaultValue={values.department_id}
                                                value={values.department_id}
                                                error={!!errors.department}
                                                onChange={(e) => {
                                                    setAllValues((values) => ({
                                                        ...values,
                                                        department_id: e.target.value
                                                    }));
                                                    setAllErrors((state) => ({
                                                        ...state,
                                                        [`${e.target.name}`]: ""
                                                    }))
                                                }}
                                                MenuProps={MenuProps}
                                                disabled={eFieldsDisable}
                                            >
                                                <MenuItem value="">--Select--</MenuItem>
                                                {values.department?.map((value, key) => {
                                                    return <MenuItem key={key + '_department'} value={value.id} >{value.name}</MenuItem>;
                                                })}
                                            </Select>
                                            <FormHelperText error>{errors.department === "" ? '' : errors.department}</FormHelperText>
                                        </Grid>

                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>External Designation <sup style={{ color: "red" }}>*</sup></InputLabel>
                                            <Select
                                                required
                                                displayEmpty
                                                style={{ width: "90%", height: 45 }}
                                                name="designation"
                                                variant="standard"
                                                defaultValue={values.designation_id}
                                                value={values.designation_id}
                                                error={!!errors.designation}
                                                onChange={(e) => {
                                                    setAllValues((values) => ({
                                                        ...values,
                                                        designation_id: e.target.value
                                                    }));
                                                    setAllErrors((state) => ({
                                                        ...state,
                                                        [`${e.target.name}`]: ""
                                                    }))
                                                }}
                                                MenuProps={MenuProps}
                                                disabled={eFieldsDisable}
                                            >
                                                <MenuItem value="">--Select--</MenuItem>
                                                {values.designation?.map((value, key) => {
                                                    return <MenuItem key={key + '_designation'} value={value.id} >{value.name}</MenuItem>;
                                                })}
                                            </Select>
                                            <FormHelperText error>{errors.designation === "" ? '' : errors.designation}</FormHelperText>
                                        </Grid>

                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Internal Designation <sup style={{ color: "red" }}>*</sup></InputLabel>
                                            <Select
                                                required
                                                displayEmpty
                                                style={{ width: "90%", height: 45 }}
                                                name="demat_roles"
                                                variant="standard"
                                                defaultValue={values.demat_roles}
                                                error={!!errors.demat_roles}
                                                value={values.demat_roles}
                                                onChange={dematRoleUpdate}
                                                MenuProps={MenuProps}
                                                disabled={eFieldsDisable}
                                            >
                                                <MenuItem value="">--Select--</MenuItem>
                                                {demat_roles?.map((value, key) => {
                                                    return <MenuItem key={key + '_roles'} value={key + 1} >{value}</MenuItem>;
                                                })}
                                            </Select>
                                            <FormHelperText error>{errors.demat_roles === "" ? '' : errors.demat_roles}</FormHelperText>
                                            {(values.demat_roles == 4) && <Grid item xs={12} sm={12} md={12}
                                                sx={{ margin: "5% 10% auto 5%" }}
                                            >
                                                <InputLabel className='add-label'>Assign Product to Team Leader</InputLabel>
                                                {/* <Select
                                                    displayEmpty
                                                    style={{ width: "100%", height: 45 }}
                                                    name="products_value"
                                                    variant="standard"
                                                    multiple
                                                    defaultValue={values.products_value}
                                                    renderValue={productSelected}
                                                    value={values.products_value}
                                                    error={!!errors.products}
                                                    helperText={errors.products === "" ? '' : errors.products}
                                                    placeholder="--Select--"
                                                    onChange={(e) => {
                                                        const {
                                                            target: { value },
                                                        } = e;
                                                        setAllValues((values) => ({
                                                            ...values,
                                                            products_value: typeof value === 'string' ? value.split(',') : value
                                                        }))
                                                        setAllErrors((state) => ({
                                                            ...state,
                                                            [`${e.target.name}`]: ""
                                                        }))
                                                    }}
                                                    MenuProps={MenuProps}
                                                    disabled={eFieldsDisable}
                                                >
                                                    {products?.map((value, key) => {
                                                        return <MenuItem key={key} value={value.product_id}>
                                                            <Checkbox checked={values.products_value.indexOf(value.product_id) > -1} />
                                                            <ListItemText primary={value.product_name} sx={{ '.MuiTypography-root': { fontSize: "0.85rem" } }} />
                                                        </MenuItem>;
                                                    })}
                                                </Select> */}

                                                <Select
                                                    multiple
                                                    displayEmpty
                                                    style={{ width: "100%", height: 45 }}
                                                    name="products_value"
                                                    variant="standard"
                                                    renderValue={(selected) => productSelected(selected)}
                                                    value={values.products_value}
                                                    error={!!errors.products}
                                                    helperText={errors.products || ''}
                                                    onChange={(e) => {
                                                        const { value } = e.target;
                                                        // Ensure value is an array
                                                        const selectedValues = Array.isArray(value) ? value : value.split(',');

                                                        // Update state with selected values
                                                        setAllValues(prevValues => ({
                                                            ...prevValues,
                                                            products_value: selectedValues
                                                        }));

                                                        console.log('Selected values from onChange:', selectedValues); // Log selected values
                                                        console.log('Updated products_value:', selectedValues); // Log updated products_value

                                                        // Clear errors for this field
                                                        setAllErrors(state => ({
                                                            ...state,
                                                            [`${e.target.name}`]: ""
                                                        }));
                                                    }}
                                                    MenuProps={MenuProps}
                                                    disabled={eFieldsDisable}
                                                >
                                                    {products?.map((product) => (
                                                        <MenuItem key={product.product_id} value={product.product_id}>
                                                            <Checkbox checked={values.products_value.includes(product.product_id)} />
                                                            <ListItemText primary={product.product_name} sx={{ '.MuiTypography-root': { fontSize: "0.85rem" } }} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>

                                            </Grid>}

                                        </Grid>

                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Employment Type <sup style={{ color: "red" }}>*</sup></InputLabel>
                                            <Select
                                                required
                                                displayEmpty
                                                style={{ width: "90%", height: 45 }}
                                                name="employment_type"
                                                variant="standard"
                                                defaultValue={values.employment_type}
                                                value={values.employment_type}
                                                error={!!errors.employment_type}
                                                onChange={(e) => {
                                                    setAllValues((values) => ({
                                                        ...values,
                                                        employment_type: e.target.value
                                                    }));
                                                    setAllErrors((state) => ({
                                                        ...state,
                                                        [`${e.target.name}`]: ""
                                                    }))
                                                }}
                                                MenuProps={MenuProps}
                                                disabled={eFieldsDisable}
                                            >
                                                <MenuItem value="">--Select--</MenuItem>
                                                {employment_type?.map((value, key) => {
                                                    return <MenuItem key={key + '_employment_type'} value={value} >{value}</MenuItem>;
                                                })}
                                            </Select>
                                            <FormHelperText error>{errors.employment_type === "" ? '' : errors.employment_type}</FormHelperText>
                                        </Grid>

                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Employment Status <sup style={{ color: "red" }}>*</sup></InputLabel>
                                            <Select
                                                required
                                                displayEmpty
                                                style={{ width: "90%", height: 45 }}
                                                name="employment_status"
                                                variant="standard"
                                                defaultValue={values.employment_status}
                                                value={values.employment_status}
                                                error={!!errors.employment_status}
                                                onChange={(e) => {
                                                    setAllValues((values) => ({
                                                        ...values,
                                                        employment_status: e.target.value
                                                    }));
                                                    setAllErrors((state) => ({
                                                        ...state,
                                                        [`${e.target.name}`]: ""
                                                    }))
                                                }}
                                                MenuProps={MenuProps}
                                                disabled={eFieldsDisable}
                                            >
                                                <MenuItem value="">--Select--</MenuItem>
                                                {employment_status?.map((value, key) => {
                                                    return <MenuItem key={key + '_emp_status'} value={value} >{value}</MenuItem>;
                                                })}
                                            </Select>
                                            <FormHelperText error>{errors.employment_status === "" ? '' : errors.employment_status}</FormHelperText>
                                        </Grid>

                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Date of Joining</InputLabel>
                                            <TextField
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name='date_of_joining'
                                                value={values.date_of_joining}
                                                sx={{
                                                    '.MuiInputBase-input': { paddingTop: "15px", paddingBottom: "14.5px" }
                                                }}
                                                error={!!errors.date_of_joining}
                                                helperText={errors.date_of_joining === "" ? '' : errors.date_of_joining}
                                                onChange={dojHandler}

                                                type="date"
                                                inputProps={{
                                                    // min: '2024-04-03', // Set min attribute to a static date
                                                    // max: '2024-04-03' // Set max attribute to a static date
                                                }}
                                                disabled={eFieldsDisable}
                                            />
                                        </Grid>

                                        {/* Assign Lead Checkbox */}
                                        <Grid item xs={6} sm={6} md={6}>
                                            {/* <FormGroup>
                                                <FormControlLabel control={<Checkbox checked={values.assign_lead} onChange={assignLeadHandler} />} label={<span style={{ color: '#7B809A', fontWeight: 'normal' }}>Assign Lead</span>} />
                                            </FormGroup> */}
                                            <Checkbox checked={values.assign_lead} onChange={assignLeadHandler} /> Assign Lead
                                        </Grid>

                                        {(values.demat_roles != 1 && values.demat_roles != "") && <><Grid item xs={12} sm={12} md={12}>
                                            <MDTypography className='background-image-gradient' variant="h4">
                                                Hierarchy  Information
                                            </MDTypography>

                                        </Grid>
                                            <Grid item xs={6} sm={6} md={6}>
                                                <InputLabel className='add-label'>Reporting Manager {values.demat_roles == 5 ? <sup style={{ color: "red" }}>*</sup> : ''}</InputLabel>

                                                <Autocomplete
                                                    value={values.reporting_manager_val}
                                                    style={{ width: "90%" }}
                                                    onChange={selectReportingManager}
                                                    renderInput={(params) => (
                                                        <TextField {...params} variant="standard" />
                                                    )}
                                                    error={!!errors.reporting_manager}
                                                    options={values.reporting_manager?.map((value) => {
                                                        return value.name ?? "";
                                                    })}
                                                />
                                                {/* <Select
                                                    required
                                                    displayEmpty
                                                    style={{ width: "90%", height: 45 }}
                                                    name="reporting_manager"
                                                    variant="standard"
                                                    defaultValue={values.reporting_manager_id}
                                                    value={values.reporting_manager_id}
                                                    error={!!errors.reporting_manager}
                                                    onChange={(e) => {

                                                        setAllValues((values) => ({
                                                            ...values,
                                                            reporting_manager_id: e.target.value
                                                        }));
                                                    }}
                                                    MenuProps={MenuProps}
                                                >
                                                    <MenuItem value="">--Select--</MenuItem>
                                                    {values.reporting_manager?.map((value, key) => {
                                                        return <MenuItem key={key + '_manager'} value={value.employee_user_id} >{value.name}</MenuItem>;
                                                    })}
                                                </Select> */}
                                                <FormHelperText error>{errors.reporting_manager === "" ? '' : errors.reporting_manager}</FormHelperText>
                                            </Grid></>}

                                        <Grid item xs={12} sm={12} md={12}>
                                            <MDTypography className='background-image-gradient' variant="h4">
                                                Personal  Information
                                            </MDTypography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Personal Email ID</InputLabel>
                                            <TextField
                                                required
                                                id=""
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name="personal_email_id"
                                                value={values.personal_email_id}
                                                onChange={personalEmailUpdate}
                                                // onKeyUp={personalEmailUpdate}
                                                error={!!errors.personal_email_id}
                                                helperText={errors.personal_email_id === "" ? '' : errors.personal_email_id}
                                                inputProps={{
                                                    maxLength: 50
                                                }}
                                                type="email"
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Personal Contact Number</InputLabel>
                                            <TextField
                                                required
                                                id=""
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name="personal_contact_number"
                                                value={values.personal_contact_number}
                                                onChange={onMobileUpdate}
                                                error={!!errors.personal_contact_number}
                                                InputProps={{
                                                    inputComponent: PhoneNumberMask,
                                                }}
                                                helperText={errors.personal_contact_number === "" ? '' : errors.personal_contact_number}

                                                type="phone"
                                            />
                                        </Grid>


                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Date of Birth</InputLabel>
                                            <TextField
                                                // required
                                                variant="standard"
                                                value={values.date_of_birth}
                                                sx={{
                                                    '.MuiInputBase-input': { paddingTop: "15px", paddingBottom: "14.5px" }
                                                }}
                                                style={{ width: "90%" }}

                                                onChange={dobHandler}
                                                error={!!errors.date_of_birth}
                                                helperText={errors.date_of_birth === "" ? '' : errors.date_of_birth}
                                                name='date_of_birth'
                                                type="date"

                                                inputProps={{
                                                    max: '2010-01-01', // Set min attribute to a static date
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}></Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <FormLabel id="demo-row-radio-buttons-group-label">Gender <sup style={{ color: "red" }}>*</sup></FormLabel>
                                            <RadioGroup
                                                row
                                                name="row-radio-buttons-group"
                                                value={values.gender}
                                                onChange={(e) => {
                                                    setAllValues((values) => ({
                                                        ...values,
                                                        gender: e.target.value
                                                    }));
                                                    setAllErrors((state) => ({
                                                        ...state,
                                                        [`${e.target.name}`]: ""
                                                    }))
                                                }}
                                                error={!!errors.gender}
                                            >
                                                <FormControlLabel value="Female" control={<Radio />} label="Female" />
                                                <FormControlLabel value="Male" control={<Radio />} label="Male" />
                                                <FormControlLabel
                                                    value="Other"
                                                    control={<Radio />}
                                                    label="Other"
                                                />
                                            </RadioGroup>
                                            <FormHelperText error>{errors.gender === "" ? '' : errors.gender}</FormHelperText>
                                        </Grid>

                                        <Grid item xs={6} sm={6} md={6}>
                                            <FormLabel id="demo-row-radio-buttons-group-label">Marital Status <sup style={{ color: "red" }}>*</sup></FormLabel>
                                            <RadioGroup
                                                row
                                                name="row-radio-buttons-group"
                                                value={values.marital_status}
                                                onChange={(e) => {
                                                    setAllValues((values) => ({
                                                        ...values,
                                                        marital_status: e.target.value
                                                    }));
                                                    setAllErrors((state) => ({
                                                        ...state,
                                                        [`${e.target.name}`]: ""
                                                    }))
                                                }}
                                                error={!!errors.marital_status}
                                            >
                                                <FormControlLabel value="Single" control={<Radio />} label="Single" />
                                                <FormControlLabel value="Married" control={<Radio />} label="Married" />
                                            </RadioGroup>
                                            <FormHelperText error>{errors.marital_status === "" ? '' : errors.marital_status}</FormHelperText>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <MDTypography className='background-image-gradient' variant="h4">
                                                Family Details
                                            </MDTypography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Father Name</InputLabel>
                                            <TextField
                                                id=""
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name="father_name"
                                                value={values.father_name}
                                                onChange={nameUpdate}
                                                error={!!errors.father_name}
                                                helperText={errors.father_name === "" ? '' : errors.father_name}
                                                inputProps={{
                                                    maxLength: 50
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Mother Name</InputLabel>
                                            <TextField
                                                id=""
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name="mother_name"
                                                value={values.mother_name}
                                                onChange={nameUpdate}
                                                error={!!errors.mother_name}
                                                helperText={errors.mother_name === "" ? '' : errors.mother_name}
                                                inputProps={{
                                                    maxLength: 50
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <MDTypography className='background-image-gradient' variant="h4">
                                                Identity   Information
                                            </MDTypography>
                                        </Grid>

                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>UAN</InputLabel>
                                            <TextField
                                                id=""
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name="uan"
                                                value={values.uan}
                                                onChange={uanUpdate}
                                                error={!!errors.uan}
                                                helperText={errors.uan === "" ? '' : errors.uan}
                                                inputProps={{
                                                    maxLength: 12
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>PAN</InputLabel>
                                            <TextField
                                                id=""
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name="pan"
                                                value={values.pan.toLocaleUpperCase()}
                                                onChange={panUpdate}
                                                error={!!errors.pan}
                                                helperText={errors.pan === "" ? '' : errors.pan}
                                                inputProps={{
                                                    maxLength: 10
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Aadhaar</InputLabel>
                                            <TextField
                                                id=""
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name="aadhar"
                                                value={values.aadhar}
                                                onChange={aadharUpdate}
                                                error={!!errors.aadhar}
                                                helperText={errors.aadhar === "" ? '' : errors.aadhar}
                                                inputProps={{
                                                    maxLength: 12
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12}>
                                            <MDTypography variant="h4" className='background-image-gradient'>
                                                Contact Details
                                            </MDTypography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <InputLabel className='add-label'>Address</InputLabel>
                                            <TextField
                                                id=""
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name="contact_address"
                                                value={values.contact_address}
                                                onChange={addressUpdate}
                                                error={!!errors.contact_address}
                                                helperText={errors.contact_address === "" ? '' : errors.contact_address}
                                                inputProps={{
                                                    maxLength: 150
                                                }}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Mobile </InputLabel>
                                            <TextField
                                                required
                                                id=""
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name="mobile"
                                                value={values.mobile}
                                                onChange={onMobileUpdate}
                                                InputProps={{
                                                    inputComponent: PhoneNumberMask,
                                                  }}
                                                error={!!errors.mobile}
                                                helperText={errors.mobile === "" ? '' : errors.mobile}
                                               
                                            />
                                        </Grid> */}
                                        <Grid item xs={12} sm={12} md={12}>
                                            <MDTypography variant="h4" className='background-image-gradient'>
                                                Emergency Contact Details
                                            </MDTypography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Name</InputLabel>
                                            <TextField
                                                id=""
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name="emergency_contact_name"
                                                value={values.emergency_contact_name}
                                                onChange={nameUpdate}
                                                error={!!errors.emergency_contact_name}
                                                helperText={errors.emergency_contact_name === "" ? '' : errors.emergency_contact_name}
                                                inputProps={{
                                                    maxLength: 50
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Mobile Number 1</InputLabel>
                                            <TextField
                                                id=""
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name="emergency_contact_mobile_number_1"
                                                value={values.emergency_contact_mobile_number_1}
                                                onChange={onMobileUpdate}
                                                InputProps={{
                                                    inputComponent: PhoneNumberMask,
                                                }}
                                                error={!!errors.emergency_contact_mobile_number_1}
                                                helperText={errors.emergency_contact_mobile_number_1 === "" ? '' : errors.emergency_contact_mobile_number_1}

                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Mobile Number 2</InputLabel>
                                            <TextField
                                                id=""
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name="emergency_contact_mobile_number_2"
                                                value={values.emergency_contact_mobile_number_2}
                                                onChange={onMobileUpdate}
                                                InputProps={{
                                                    inputComponent: PhoneNumberMask,
                                                }}
                                                error={!!errors.emergency_contact_mobile_number_2}
                                                helperText={errors.emergency_contact_mobile_number_2 === "" ? '' : errors.emergency_contact_mobile_number_2}

                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <MDTypography variant="h4" className='background-image-gradient'>
                                                Health Insurance Information
                                            </MDTypography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Insurance Agent Name</InputLabel>
                                            <TextField
                                                id=""
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name="insurance_agent_name"
                                                value={values.insurance_agent_name}
                                                onChange={nameUpdate}
                                                error={!!errors.insurance_agent_name}
                                                helperText={errors.insurance_agent_name === "" ? '' : errors.insurance_agent_name}
                                                inputProps={{
                                                    maxLength: 50
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Policy Number</InputLabel>
                                            <TextField
                                                id=""
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name="insurance_policy_number"
                                                value={values.insurance_policy_number}
                                                onChange={policyNumberhandler}
                                                error={!!errors.insurance_policy_number}
                                                helperText={errors.insurance_policy_number === "" ? '' : errors.insurance_policy_number}
                                                inputProps={{
                                                    maxLength: 15
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Sum Assured</InputLabel>
                                            <TextField
                                                id=""
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name="sum_assured"
                                                value={values.sum_assured}
                                                onChange={sumAssuredHandler}
                                                error={!!errors.sum_assured}
                                                helperText={errors.sum_assured === "" ? '' : errors.sum_assured}
                                                inputProps={{
                                                    maxLength: 30
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <MDTypography variant="h4" className='background-image-gradient'>
                                                Document Upload
                                            </MDTypography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <TableContainer container={Paper}>
                                                <Table>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell width="25%"><strong>Document Name</strong></TableCell>
                                                            <TableCell width="25%"><strong>Document Number</strong></TableCell>
                                                            <TableCell width="25%" align="center"><strong>File Upload</strong></TableCell>
                                                            <TableCell width="25%" align="center"><MDButton color='success' onClick={addTableRows} disabled={dbrowsData.length == 10 ? true : false}>Add +</MDButton></TableCell>
                                                        </TableRow>
                                                        {dbrowsData?.map((data, key) => (
                                                            <TableRow
                                                                key={key + '_upload_docs'}
                                                            >
                                                                <TableCell width="25%">
                                                                    <TextField
                                                                        variant="standard"
                                                                        name='document_name'
                                                                        value={data.document_name}
                                                                        onChange={(e) => handleChange(key, e)}
                                                                        disabled={(myParam && data.id) ? true : false}
                                                                        inputProps={{
                                                                            maxLength: 50
                                                                        }}
                                                                        placeholder="Document Name"
                                                                    />
                                                                </TableCell>
                                                                <TableCell width="25%">
                                                                    <TextField
                                                                        variant="standard"
                                                                        name='document_number'
                                                                        value={data.document_number}
                                                                        disabled={(myParam && data.id) ? true : false}
                                                                        onChange={(e) => handleChange(key, e)}
                                                                        inputProps={{
                                                                            maxLength: 50
                                                                        }}
                                                                        placeholder="Document Number"
                                                                    />
                                                                </TableCell>
                                                                <TableCell width="25%" align="center">
                                                                    <MDTypography variant="h6">
                                                                        {data.file_name}
                                                                    </MDTypography>
                                                                    {(!myParam || myParam && !data.id) && <MDButton
                                                                        startIcon={<BackupIcon />}
                                                                        component="label"
                                                                        variant="outlined"
                                                                        className="upload-file-custom"
                                                                        color="info"
                                                                        id="outlined-required"
                                                                        required>
                                                                        Upload
                                                                        <input
                                                                            type="file"
                                                                            accept="/*"
                                                                            hidden
                                                                            onChange={(e) => handleChange(key, e)}
                                                                            value={data.file_path.files}
                                                                            name="file_name" />
                                                                    </MDButton>}
                                                                    {(myParam && data.id) && <IconButton color="success" aria-label="upload picture" component="label" onClick={(e) => downloadFile(BASE_URL + "public/document/employee_id_" + values.employee_user_id + "/" + data.file_path, data.file_name)}>
                                                                        <DownloadForOfflineIcon />
                                                                    </IconButton>}
                                                                </TableCell>
                                                                <TableCell width="25%" align="center">
                                                                    <Button variant="outlined" color="error" sx={{ color: 'red', borderColor: 'red' }} onClick={() => deleteTableRows(key)}>
                                                                        Remove
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12}>
                                            <MDTypography variant="h4" className='background-image-gradient'>
                                                Separation   Information
                                            </MDTypography>
                                        </Grid>
                                        {isProfile && <Grid item xs={6} sm={6} md={6}> </Grid>}
                                        {!isProfile && <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Date of Exit</InputLabel>
                                            <TextField
                                                variant="standard"
                                                value={values.date_of_exit}
                                                name='date_of_exit'
                                                sx={{
                                                    '.MuiInputBase-input': { paddingTop: "15px", paddingBottom: "14.5px" }
                                                }}
                                                style={{ width: "90%" }}

                                                onChange={dateOfExitHandler}
                                                error={!!errors.date_of_exit}
                                                helperText={errors.date_of_exit === "" ? '' : errors.date_of_exit}
                                                type="date"
                                                inputProps={{
                                                    min: values.date_of_joining, // Set min attribute to a static date
                                                }}
                                            />
                                        </Grid>}
                                        {isProfile && <Grid item xs={6} sm={6} md={6}>
                                        </Grid>}
                                        {isProfile && <Grid item xs={0.5} sm={0.5} md={0.5}>
                                            <Checkbox checked={checked} onChange={handleCheckChange} />
                                        </Grid>}
                                        {isProfile && <Grid item xs={10} sm={10} md={10}>
                                            <p>I hereby declare that the details furnished above are true and correct to the best of my knowledge and belief and I undertake to inform organization of
                                                any changes therein, immediately. In case any of the above information is found to be false or untrue or misleading or misrepresenting, I am
                                                aware that I may be held liable for it. </p>
                                        </Grid>}

                                    </Grid>
                                    <DialogActions
                                        sx={{
                                            background: "linear-gradient(195deg, #dee1e1 10%, #f4f4f4 360%)",
                                            marginTop: "5%",
                                            marginBottom: "0%",
                                            width: "100%",
                                            borderRadius: '0.5rem',
                                            '.action-button': { width: width }
                                        }}
                                    >
                                        {(isSubmissionVerified == 1 && isSubmittedReview == 0) && <MDButton color='error' className="action-button" size="large" style={{ width: "300px" }} onClick={SubmitForReverification}>Submit for re-verification</MDButton>}
                                        <MDButton color='success' className="action-button" type="submit" disabled={doDisableSubmit}>{isProfile ? "Submit for Approval" : isSubmissionVerified == 1 && isSubmittedReview == 0 ? "Verify" : myParam ? "Update" : "Save"}</MDButton>
                                        <MDButton color='warning' className="action-button" onClick={() => cancelClicked()}>Cancel</MDButton>
                                    </DialogActions>
                                </Box>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />


            <Dialog
                open={afterEmployee.showEmployeePwd}
                onClose={handleAfterEmployeeClose}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle id="alert-dialog-title">
                    <MDBox
                        color="white"
                        bgColor="info"
                        variant="gradient"
                        borderRadius="lg"
                        shadow="lg"
                        opacity={1}
                        p={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    // textAlign="center"
                    >
                        <MDTypography variant="h4" color="white" alignItems="center">New Employee Added</MDTypography>
                        {/* <IconButton
                        style={{ color: "white", cursor: "pointer" }}
                        sx={{
                            "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
                        }}
                        onClick={props.close}
                    >
                        <CloseIcon />
                    </IconButton> */}
                    </MDBox>
                </DialogTitle>
                <DialogContent p={3}>
                    <DialogContentText sx={{ color: "#000", }} >
                        <Box sx={{ mx: 'auto', width: 600 }}>
                            <h4>Share this credentials with your employee so that they can sign in</h4>
                        </Box>
                        <Divider />
                        <Box sx={{ mx: 'auto', width: 400, mb: 2, '& p': { pb: 1 } }}>
                            <p >Username : <code>{values.email}</code></p>
                            <p>Password : <span><code>{afterEmployee.generatedPwd}</code><MDButton onClick={copyToClipBoard} color='secondary' size="small" sx={{ float: 'right' }}>{afterEmployee.copyButtonText}</MDButton></span></p>
                        </Box>




                        <Box sx={{ mx: 'auto', width: 600 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        inputProps={{ "label": "Send Sign In Instructions" }}
                                        onChange={activateMail}
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                    />
                                }
                                sx={{ '& .MuiFormControlLabel-label': { fontWeight: '400' } }}
                                label="Send Sign In Instructions on mail"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        inputProps={{ "label": "Send Copy To Myself" }}
                                        onChange={activateSelfMail}
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                    />
                                }
                                sx={{ '& .MuiFormControlLabel-label': { fontWeight: '400' } }}
                                label="Send Copy To Myself"
                            />
                        </Box>


                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                    <MDButton color='primary' className="action-button" onClick={handleAfterEmployeeClose} >DONE</MDButton>
                </DialogActions>
            </Dialog >

        </DashboardLayout >


    )
}

export default EmployeesData1