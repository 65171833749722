/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable prettier/prettier */
import { Box, Card, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Icon, IconButton, Switch, InputLabel, Autocomplete, TextField, FormHelperText, CardHeader, CardContent, Typography, Button, InputAdornment, FormControl, Chip, Stack } from '@mui/material'
import MDButton from 'components/MDButton'
import { Column, DataGrid, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { Pager } from 'devextreme-react/tree-list';
import React, { useRef, useState, useEffect } from 'react'
import Caller from 'Apis/config/Caller';
import { POST, PUT, GET } from 'Apis/config/RequestType';
import { get_lead_employees_name } from 'Apis/config/Url';
import { update_bulk_lead_assign_mode } from 'Apis/config/Url';
import { useSnackbar } from "notistack";
import SearchIcon from '@mui/icons-material/Search';
import { ScrollView } from 'devextreme-react/scroll-view';
import Groups from 'pages/organization/group-template/groups/groups'
import { create_communication_group, update_communication_group, communication_group, check_whatsapp_group_name, check_mobile_number } from 'Apis/config/Url'
import { create_communication_contact } from 'Apis/config/Url';

const newContactSelectionFilter = ['first_name', 'notcontains', 'null'];

const createGroup = (props) => {

    const userGrid = useRef();
    const { enqueueSnackbar } = useSnackbar();
    const [disabledSave, setDisabledSave] = useState();
    const [selectedData, setSelectedData] = useState(null);
    const [contactData, setContactData] = useState([]);
    const [showStack, setShowStack] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedValueFromLeads, setSelectedValueFromLeads] = useState({});

    const [values, setAllValues] = useState({
        id: "",
        whatsapp_group_name: "",
        group_description: "",
        whatsapp_group_name_array: [],
        group_desc_array: [],
        mobile: "",
        mobile_array: []
    });

    const [errors, setAllErrors] = useState({
        whatsapp_group_name: "",
        group_description: "",
        id: "",
        mobile: ""
    });

    const handleRowDeselect = (deselectedRowId) => {
        // Remove the deselected row from contactData
        const updatedContactData = contactData.filter((contact) => contact.id !== deselectedRowId);
        setContactData(updatedContactData);
    };

    // Function to load contact data based on selected WhatsApp group name
    const loadContactData = async () => {
        const formData = new FormData();
        formData.append('id', props.updateData.id);
        // const selectedGroupName = values.whatsapp_group_name;
        await Caller(check_whatsapp_group_name, formData, POST, false, true).then(async (res) => {
            if (res.success == true) {
                setContactData(res.data);
                if (props.addLead) {
                    const formData = new FormData();
                    formData.append('bulk_mobile', res.data.map(({ mobile, ...rest }) => ({ mobile })).map(obj => obj.mobile));
                    await Caller(check_mobile_number, formData, POST, false, true).then((resData) => {
                        setAllValues((values) => ({
                            ...values,
                            mobile_array: resData.data
                        }));
                    });
                }

                const selectedRowIds = res.data.map((contact) => contact.id);
                setSelectedRowKeys(selectedRowIds);
            } else {
                console.error("Error fetching contact data:", res.data);

                setSelectedRowKeys([]);
            }
        })
    };

    const [isInitialRender, setIsInitialRender] = useState(true);

    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);
            setContactData(props.selectedRows || [])
            if (props.updateData) {
                setAllValues((values) => ({
                    ...values,
                    whatsapp_group_name: props.updateData.whatsapp_group_name,
                    group_description: props.updateData.group_description,
                }))
            }

            Caller(communication_group + "?isLoadingAll=true", "", GET, false, true).then(async (resData) => {
                await setAllValues((values) => ({
                    ...values,
                    whatsapp_group_name_array: resData.data.data,
                    group_desc_array: resData.data.data
                }));
            });

            if (props.addLead) {
                loadContactData();
            }
        }

    }, [isInitialRender, props, values.whatsapp_group_name])

    const onSelectionChanged = (e) => {
        e.component.getSelectedRowsData().then(data => {
            let selectedId = data.map((contact) => contact.id);
            setSelectedRowKeys(selectedId);
        })
       
    }

    const createGroupData = async (event) => {
        event.target.classList.add("was-validated");
        setDisabledSave('disabled');
        event.preventDefault();
        event.stopPropagation();
        const formData = new FormData();

        formData.append("whatsapp_group_name", values.whatsapp_group_name);
        formData.append("group_description", values.group_description);
        formData.append("contact_id", selectedRowKeys ?? []);

        if (props.updateData) {
            await Caller(update_communication_group + "?id=" + props.updateData.id, formData, POST, false, true).then((res) => {
                if (res.success === true) {
                    enqueueSnackbar("WhatsApp Group Updated Successfully.", {
                        variant: "success",
                        autoHideDuration: 2000,
                        TransitionProps: { direction: "left" },
                        anchorOrigin: { horizontal: "right", vertical: "top" },
                    });
                    props.refresh();
                    props.close();

                } else {
                    for (const [key, value] of Object.entries(res.error)) {
                        setAllErrors((values) => ({
                            ...values,
                            [`${key}`]: value,
                        }))
                    }
                }
            })

        }
        else {
            await Caller(create_communication_group, formData, POST, false, true).then((res) => {
                if (res.success === true) {
                    enqueueSnackbar("WhatsApp Group Created Successfully.", {
                        variant: "success",
                        autoHideDuration: 2000,
                        TransitionProps: { direction: "left" },
                        anchorOrigin: { horizontal: "right", vertical: "top" },
                    });
                    props.refresh();
                    props.onClose();

                } else {
                    for (const [key, value] of Object.entries(res.error)) {
                        setAllErrors((values) => ({
                            ...values,
                            [`${key}`]: value,
                        }))
                    }
                }
            })

        }

    }

    const checkDisabled = () => {
        if (
            (values.whatsapp_group_name !== '' || selectedData !== '') &&
            values.group_description !== ''
        ) {
            return false;
        } else {
            return true;
        }
    };

    const cancel = () => {
        // props.onClose();
        props.close();
    };

    const renderID = (data) => {
        return <div>{"FDWC-" + data.data.id}</div>
    }

    const handleChange = (event, newValue) => {
        setAllValues((prevValues) => ({
            ...prevValues,
            whatsapp_group_name: newValue || '',
        }));

        // Find the corresponding group_description for the selected whatsapp_group_name
        const selectedGroup = values.whatsapp_group_name_array.find(option => option.whatsapp_group_name === newValue);

        if (selectedGroup) {
            setAllValues((prevValues) => ({
                ...prevValues,
                group_description: selectedGroup.group_description,
            }));
        } else {
            // If no matching group_description found, reset it to an empty string or handle accordingly.
            setAllValues((prevValues) => ({
                ...prevValues,
                group_description: '',
            }));
        }

        // Check if the newValue is not in the options
        let isExistingVal = values.whatsapp_group_name_array.find(option => option.whatsapp_group_name === newValue);
        setShowStack(isExistingVal || !newValue ? false : true);
    };


    const handleInputChange = (event, newInputValue) => {
        setAllValues((prevValues) => ({
            ...prevValues,
            whatsapp_group_name: newInputValue,
        }));
        // Check if the newInputValue is not in the options
        setShowStack(!values.whatsapp_group_name_array.some(option => option.whatsapp_group_name === newInputValue));
    };

    const handleChangeMobile = (event, newValue) => {
        let selectedMobile = newValue.split('mobile: ', 10)[1].substring(0, 10);
        setSelectedValueFromLeads(values.mobile_array?.find(val => val.mobile == selectedMobile));
        setAllValues((prevValues) => ({
            ...prevValues,
            mobile: newValue
        }));
    };

    const addLeadToContact = () => {
        const formData = new FormData();
        formData.append("first_name", selectedValueFromLeads.first_name);
        formData.append("last_name", selectedValueFromLeads.last_name || "");
        formData.append("mobile", selectedValueFromLeads.mobile);
        formData.append("email", selectedValueFromLeads.email || "");
        formData.append("product", selectedValueFromLeads.product_id);
        formData.append("whatsapp_group_name", props.updateData.id)
        Caller(create_communication_contact, formData, POST, false, true).then((res) => {
            if (res.success === true) {
                enqueueSnackbar('Lead Converted To WhatsApp Contact !', { variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
                userGrid.current.instance.refresh();
                setIsInitialRender(true);
                setSelectedValueFromLeads({});
                setAllValues((prevValues) => ({
                    ...prevValues,
                    mobile: null
                }));
            }

        })
    }

    return (

        <Box
            component="form"
            onSubmit={createGroupData}
            sx={{
                '#demo-row-radio-buttons-group-label': { fontSize: 'medium', textAlign: "left" },
                '.role-select-custom': { width: "100%" },
                '.add-label': { pb: 1 },
                '.radio-button-custom': { fontSize: 'small' }
            }}
            noValidate
        >
            <Grid container spacing={2}>
                <Grid item sm={12} xs={12} md={8}>
                    {props.addLead && (
                        <Card>
                            <CardContent style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    value={values.mobile || null}
                                    style={{ width: "85%" }}
                                    onChange={handleChangeMobile}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Convert Lead To Contact"
                                            placeholder="Search Lead"
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                                disableUnderline: true,
                                            }}
                                        />
                                    )}
                                    options={values.mobile_array?.map((value) => {
                                        return `FD-${value.id}: ${value.first_name} (mobile: ${value.mobile})`;
                                    })}
                                />


                                <MDButton variant="contained" color="success" onClick={addLeadToContact} disabled={Object.values(selectedValueFromLeads).length === 0}>
                                    Add
                                </MDButton>
                            </CardContent>

                        </Card>
                    )}
                    <Card sx={{ mt: 2 }}>
                        <CardHeader title="Selected Contacts" sx={{ px: 3, pb: 0 }}>
                        </CardHeader>
                        <CardContent>
                            <DataGrid
                                id="gridContainer"
                                dataSource={contactData}
                                keyExpr="id"
                                ref={userGrid}
                                showBorders={true}
                                remoteOperations={true}
                                wordWrapEnabled={true}
                                showColumnLines={false}
                                rowAlternationEnabled={true}
                                defaultSelectionFilter={newContactSelectionFilter}
                                onInitialized={(e) => {
                                    e.component.selectAll();
                                }}
                                onSelectionChanged={onSelectionChanged}
                                selectedRowKeys={selectedRowKeys}
                                height="38vh"
                            >
                                <Pager
                                    visible={true}
                                    displayMode="full"
                                    showInfo={true}
                                // showNavigationButtons={true}
                                />
                                <Paging
                                    enabled={false}
                                />

                                <Selection
                                    mode="multiple"
                                    showCheckBoxesMode="always"
                                    deferred={true}
                                />

                                <Column
                                    caption="ID"
                                    dataField="id"
                                    alignment="center"
                                    // cellRender={renderID}
                                    minWidth={20}
                                />
                                <Column
                                    caption="First Name"
                                    dataField="first_name"
                                    alignment="center"
                                    minWidth={100}
                                />
                                <Column
                                    caption="Mobile"
                                    dataField="mobile"
                                    alignment="center"
                                    minWidth={100}
                                />
                                <Column
                                    caption="Email"
                                    dataField="email"
                                    alignment="center"
                                    minWidth={100}
                                />
                            </DataGrid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item sm={12} xs={12} md={4}>
                    <Card style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "left",
                        height: props.addLead ? "67.225vh" : "53.225vh"
                    }}>

                        <CardHeader title="WhatsApp Group Name" sx={{ px: 3, pb: 0 }} />
                        <CardContent>

                            <Autocomplete
                                name="whatsapp_group_name"
                                value={values.whatsapp_group_name}
                                freeSolo
                                style={{ width: "17vw" }}
                                onChange={handleChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Enter Group Name"
                                        variant="outlined"
                                        style={{ width: '29ch' }}
                                    />
                                )}
                                error={!!errors.whatsapp_group_name}
                                options={values.whatsapp_group_name_array?.map((value) => {
                                    return value.whatsapp_group_name ?? '';
                                })}
                                inputValue={values.whatsapp_group_name}
                                onInputChange={handleInputChange}
                            />


                            <FormHelperText error>
                                {errors.whatsapp_group_name === "" ? '' : errors.whatsapp_group_name}
                            </FormHelperText>

                            <FormHelperText error>{errors.whatsapp_group_name === "" ? '' : errors.whatsapp_group_name}</FormHelperText>

                        </CardContent>

                        <CardHeader title="Group Description" sx={{ px: 3, pb: 0 }}>
                        </CardHeader>
                        <CardContent>
                            <TextField
                                id="standard-basic"
                                name="group_description"
                                label="Type Description"
                                variant="outlined"
                                inputProps={{ style: { height: "60px", width: "30ch" } }}
                                value={values.group_description} // Bind to the state value
                                onChange={(e) =>
                                    setAllValues({
                                        ...values,
                                        group_description: e.target.value
                                    })
                                }
                            />
                        </CardContent>
                    </Card>
                </Grid>

                <DialogActions
                    sx={{
                        background: "linear-gradient(195deg, #dee1e1 10%, #f4f4f4 360%)",
                        marginTop: "5%",
                        marginBottom: "0%",
                        width: "100%",
                        // borderRadius: '0.5rem',
                        '.action-button': { width: 100 }
                    }}
                >
                    <MDButton color='success' className={`action-button ${disabledSave}`} type="submit" disabled={checkDisabled()}>{props.updateData ? "Update" : "Create"}</MDButton>
                    <MDButton color='warning' className="action-button" onClick={cancel}>Cancel</MDButton>
                </DialogActions>

            </Grid>
        </Box>

    )
}

export default createGroup;