/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import team2 from "assets/images/team-2.jpg";


// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import MDButton from "components/MDButton";
import { Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Icon, IconButton, InputLabel, ListItemText, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { Button, Column, ColumnChooser, DataGrid, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { Pager } from 'devextreme-react/tree-list';
import CloseIcon from '@mui/icons-material/Close';
import { UsersData } from "layouts/tables/data/mockdata";
import { GET } from "Apis/config/RequestType";
import { update_user } from "Apis/config/Url";
import { PUT } from "Apis/config/RequestType";
import DeletePopup from "layouts/master-data/delete-popup";
import { delete_user } from "Apis/config/Url";
import Caller from "Apis/config/Caller";
import { useNavigate } from "react-router-dom";
import { getEmployeeUserDetails } from "Apis/Auth/auth";
import CustomStore from "devextreme/data/custom_store";
import { change_user_status } from "Apis/config/Url";
import { POST } from "Apis/config/RequestType";
import { get_employee_user_details } from "Apis/config/Url";
import MDBadge from "components/MDBadge";
import { useSnackbar } from "notistack";
import BackupIcon from '@mui/icons-material/Backup';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { import_employee_bulk } from "Apis/config/Url";
import { getImportErrors } from "Apis/Auth/auth";
import ImportEmployeeErrors from "./import-employee-errors";
import { Tabs } from "devextreme-react";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

const ImportEmployeeData = (props) => {

    const [values, setAllValues] = useState({
        files: "",
        file_name: ""
    })
    const [errors, setAllErrors] = useState({
        files: ""
    })

    const { enqueueSnackbar } = useSnackbar();

    const uploadFile = async (event) => {
        event.target.classList.add("was-validated");
        event.preventDefault();
        event.stopPropagation();
        const formData = new FormData();
        formData.append("files", values.files);
        // formData.append("order_id", props.data.selectedOrderId);
        // formData.append("firm_number", props.data.selectedFirmHASH);
        await Caller(import_employee_bulk, formData, POST, false, true)
            .then((data) => {

                if (data.success === true) {
                    enqueueSnackbar(`Imported file successfully!`, { variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
                    props.changeTab(1);
                }
                else {
                    enqueueSnackbar(data.message, { variant: 'error', autoHideDuration: 3000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
                }
                setAllValues((values) => ({
                    ...values,
                    files: "",
                    file_name: ""
                }));
            });
    }

    const downloadFile = async (fileName) => {
        let a = document.createElement('a');
        a.href = './employee_import_data_template.xlsx';
        a.setAttribute(
            'download',
            fileName,
        );

        // Append to html link element page
        document.body.appendChild(a);

        // Start download
        a.click();

        // Clean up and remove the link
        a.parentNode.removeChild(a);

    };
    const handleChange = (e) => {
        setAllValues((values) => ({
            ...values,
            files: e.target.files[0],
            file_name: e.target.files[0].name
        }))
    }
    const allTrue = () => {
        if (values.files && values.file_name) {
            return false;
        }
        return true;
    }
    return (
        <MDBox
            color="white"
            bgColor="white"
            variant="gradient"
            borderRadius="lg"
            shadow="lg"
            opacity={1}
            p={2}
            mt={5}
            border="0.5px solid #eeeeee"
        >
            <FormControl id="create-course-form" component="form" onSubmit={uploadFile} fullWidth>
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }} pl={4} pr={4}>
                    <Grid item xs={12} sm={12} md={12}>
                        <MDTypography variant="h4" className='background-image-gradient'>
                            Document Upload
                        </MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} display="flex" justifyContent="flex-end">
                        <IconButton color="success" aria-label="upload picture" component="label" onClick={(e) => downloadFile("Bulk-Upload-Employee")}>
                            <MDTypography variant="h5" color="info">Download Template</MDTypography> &nbsp;<DownloadForOfflineIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} display="flex" justifyContent="space-between" alignItems="center">
                        <MDTypography variant="h5">
                            Bulk Employee Data:
                        </MDTypography>
                        {(values.file_name) && <Grid align="center">
                            <MDTypography variant="h5">
                                File Name
                            </MDTypography>
                            <MDTypography variant="subtitle2">
                                {values.file_name}
                            </MDTypography>
                        </Grid>}
                        <MDButton
                            startIcon={<BackupIcon />}
                            component="label"
                            variant="outlined"
                            className="upload-file-custom"
                            color="info"
                            id="outlined-required"
                            required>
                            Choose a file
                            <input
                                type="file"
                                accept=".csv"
                                hidden
                                onChange={handleChange}
                                name="files" />
                        </MDButton>
                    </Grid>

                </Grid>
                <DialogActions
                    sx={{
                        background: "linear-gradient(195deg, #dee1e1 10%, #f4f4f4 360%)",
                        marginTop: "5%",
                        marginBottom: "0%",
                        width: "100%",
                        borderRadius: '0.5rem',
                        '.action-button': { width: 120 }
                    }}
                >
                    <MDButton color='success' className={`action-button`} type="submit" disabled={allTrue()}>Upload</MDButton>

                </DialogActions>
            </FormControl>
        </MDBox>)
}

export default ImportEmployeeData