/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React context
import { useMaterialUIController, setLayout } from "context";
import ProuctAssignmentTab from "pages/organization/product-assignment/product-assignment/ProductAssignmentTab";
import Footer from "examples/Footer";
import { Card, Divider, Grid, IconButton, InputBase, Slide } from "@mui/material";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AccountBalanceOutlined, ApiOutlined, AssessmentOutlined, AssignmentIndOutlined, AssignmentOutlined, GroupAddOutlined, MonetizationOnOutlined, Search } from "@mui/icons-material";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";
import AllProductAssigned from "pages/organization/product-assignment/product-assignment/AllProductAssigned";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import theme from "assets/theme";
import AllDealsLeads from "pages/organization/leads/deals/deals-lead";
import { useState } from "react";
import Products from "layouts/master-data/products/products";
import Inventory2Icon from '@mui/icons-material/Inventory2';

import Cookies from "js-cookie";
import { RIGHTS } from "Apis/config/ResponseHandler";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


function ProductAssignment({ children }) {
  let params1 = (new URL(document.location)).searchParams;
  let rights = Cookies.get(RIGHTS) ?? "";
  let rightsArray = rights.split(',') ?? [];
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();
  const [value, setValue] = React.useState((params1.get("productsTab") ? 2 : (params1.get("productsAssignmentTab") ? 0 : 0)));
  const [checked, setChecked] = useState(true);
 

 

  const handleToggleChange = () => {
    setChecked((prev) => !prev);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  return (
    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        pl: 0,
        pt: 0,
        pr: 0,
        pb: 0,
        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(80) : pxToRem(250),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >

      <Grid spacing={0}>

        <Grid item xs={12}>
          <TabPanel value={value} index={0}>
            <Tabs
              orientation="horizontal"
              value={value}
              onChange={handleChange}
              sx={{ borderColor: 'divider', backgroundColor: '#f6f7fb', p: 1, pt: 2 }}
            >

              <Tab icon={<MonetizationOnOutlined />} sx={{ justifyContent: 'center', fontSize: pxToRem(13), p: 1, "&:hover": { backgroundColor: "#267feaad", color: "white !important" }, "&.Mui-selected": { backgroundColor: "#267feb", color: "white !important" } }} label="All Assigned Products" {...a11yProps(0)} />
              <Tab icon={<GroupAddOutlined />} sx={{ justifyContent: 'center', fontSize: pxToRem(13), p: 1, "&:hover": { backgroundColor: "#267feaad", color: "white !important" }, "&.Mui-selected": { backgroundColor: "#267feb", color: "white !important" } }} label="Assign Products" {...a11yProps(1)} />

              <Tab disabled={!rightsArray.includes('103') ? true : false} icon={<Inventory2Icon />} sx={{ justifyContent: 'center', fontSize: pxToRem(13), p: 1, "&:hover": { backgroundColor: "#267feaad", color: "white !important" }, "&.Mui-selected": { backgroundColor: "#267feb", color: "white !important" } }} label="Products" {...a11yProps(2)} />

            </Tabs>
            <MDBox pt={6} pb={3}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h4" color="white">
                        All Assigned Products Member
                      </MDTypography>
                    </MDBox>
                    <AllProductAssigned></AllProductAssigned>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
            <Footer />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Tabs
              orientation="horizontal"
              value={value}
              onChange={handleChange}
              sx={{ borderColor: 'divider', backgroundColor: '#f6f7fb', p: 1, pt: 2 }}
            >

              <Tab icon={<MonetizationOnOutlined />} sx={{ justifyContent: 'center', fontSize: pxToRem(13), p: 1, "&:hover": { backgroundColor: "#267feaad", color: "white !important" }, "&.Mui-selected": { backgroundColor: "#267feb", color: "white !important" } }} label="All Assigned Products" {...a11yProps(0)} />
              <Tab icon={<GroupAddOutlined />} sx={{ justifyContent: 'center', fontSize: pxToRem(13), p: 1, "&:hover": { backgroundColor: "#267feaad", color: "white !important" }, "&.Mui-selected": { backgroundColor: "#267feb", color: "white !important" } }} label="Assign Products" {...a11yProps(1)} />
              <Tab disabled={!rightsArray.includes('103') ? true : false} icon={<Inventory2Icon />} sx={{ justifyContent: 'center', fontSize: pxToRem(13), p: 1, "&:hover": { backgroundColor: "#267feaad", color: "white !important" }, "&.Mui-selected": { backgroundColor: "#267feb", color: "white !important" } }} label="Products" {...a11yProps(2)} />

            </Tabs>
            <MDBox pt={6} pb={3}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h4" color="white">
                        Assign Product
                      </MDTypography>
                    </MDBox>
                    <ProuctAssignmentTab></ProuctAssignmentTab>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
            <Footer />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Tabs
              orientation="horizontal"
              value={value}
              onChange={handleChange}
              sx={{ borderColor: 'divider', backgroundColor: '#f6f7fb', p: 1, pt: 2 }}
            >

              <Tab icon={<MonetizationOnOutlined />} sx={{ justifyContent: 'center', fontSize: pxToRem(13), p: 1, "&:hover": { backgroundColor: "#267feaad", color: "white !important" }, "&.Mui-selected": { backgroundColor: "#267feb", color: "white !important" } }} label="All Assigned Products" {...a11yProps(0)} />
              <Tab icon={<GroupAddOutlined />} sx={{ justifyContent: 'center', fontSize: pxToRem(13), p: 1, "&:hover": { backgroundColor: "#267feaad", color: "white !important" }, "&.Mui-selected": { backgroundColor: "#267feb", color: "white !important" } }} label="Assign Products" {...a11yProps(1)} />
              <Tab disabled={!rightsArray.includes('103') ? true : false} icon={<Inventory2Icon />} sx={{ justifyContent: 'center', fontSize: pxToRem(13), p: 1, "&:hover": { backgroundColor: "#267feaad", color: "white !important" }, "&.Mui-selected": { backgroundColor: "#267feb", color: "white !important" } }} label="Products" {...a11yProps(2)} />

            </Tabs>
            <Products />
          </TabPanel>

        </Grid>
      </Grid>
    </MDBox>

  );
}

// Typechecking props for the DashboardLayout
ProductAssignment.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProductAssignment;
