const { IMaskInput } = require("react-imask");
import PropTypes from 'prop-types';
import * as React from 'react';

const PhoneNumberMask = React.forwardRef(function PhoneNumberMask(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(#00) 000-0000"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  });
  
  PhoneNumberMask.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  export default PhoneNumberMask;