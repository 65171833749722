/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
/* eslint-disable prettier/prettier */
import { Box, DialogActions, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material'
import Caller from 'Apis/config/Caller';
import { POST } from 'Apis/config/RequestType';
import { update_product } from 'Apis/config/Url';
import { create_product } from 'Apis/config/Url';
import MDButton from 'components/MDButton';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

const AddUpdateProducts = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [disabledSave, setDisabledSave] = useState();
    const [values, setAllValues] = useState({
        product_id: '',
        product_name: "",
        product_details: ""
    })

    const [errors, setAllErrors] = useState({
        product_id: '',
        product_name: "",
        product_details: ""
    })

    const [isInitialRender, setIsInitialRender] = useState(true);
    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);
            if (props.updateData.length != 0) {
                setAllValues((values) => ({
                    ...values,
                    product_name: props.updateData[0].product_name,
                    product_details: props.updateData[0].product_details
                }))
            }
        }
    }, [])

    const textUpdateMax = (e) => {
        if ((e.target.value === "") || (e.target.value.length <= 200)) {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }))
        }
        setAllErrors((values) => ({
            ...values,
            [`${e.target.name}`]: ''
        }));
    }

    const textUpdate = (e) => {
        const re = /^[\-\:\&a-zA-Z0-9 ]+$/;
        if (re.test(e.target.value) || (e.target.value === "")) {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }))
        }
        setAllErrors((values) => ({
            ...values,
            [`${e.target.name}`]: ''
        }));
    }

    const allTrue = () => {
        if (values.product_name && values.product_details && errors.product_details === "" && errors.product_name == "") {
            return false
        } else {
            return true
        }
    }

    const addProduct = async (event) => {
        event.target.classList.add("was-validated");
        setDisabledSave('disabled');
        event.preventDefault();
        event.stopPropagation();
        const formData = new FormData();
        if (props.updateData) {
            formData.append("id", props.updateData[0].product_id)
        }
        formData.append("product_name", values.product_name.trim());
        formData.append('product_details', values.product_details.trim());

        if (props.updateData) {
            await Caller(update_product, formData, POST, false, true).then((res) => {
                if (res.success === true) {
                    enqueueSnackbar('Product updated successfully!', { variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
                    props.refresh();
                    props.close();

                }
                else if (res.success === false) {
                    setDisabledSave('');
                    for (const [key, value] of Object.entries(res.message['errors'])) {
                        setAllErrors((values) => ({
                            ...values,
                            [`${key}`]: value,
                        }))
                    }
                }
            })
                .catch(error => { alert(JSON.stringify(error.message)); })
        } else {
            await Caller(create_product, formData, POST, false, true).then((res) => {
                if (res.success === true) {
                    enqueueSnackbar('Product added successfully.', { variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
                    props.refresh();
                    props.close();
                }
                else if (res.success === false) {
                    setDisabledSave('');
                    for (const [key, value] of Object.entries(res.message['errors'])) {
                        setAllErrors((values) => ({
                            ...values,
                            [`${key}`]: value,
                        }))
                    }
                }
            })
                .catch(error => { alert(JSON.stringify(error.message)); })
        }

    }
    return (
        <Box
            component="form"
            onSubmit={addProduct}
            sx={{
                '#demo-row-radio-buttons-group-label': { fontSize: 'medium', textAlign: "left" },
                '.role-select-custom': { pt: 1.5, pb: 1.5 },
                '.add-label': { pb: 1 },
                '.radio-button-custom': { fontSize: 'small' }
            }}
            noValidate
        >
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <InputLabel className='add-label'>Product Name <sup style={{ color: "red" }}>*</sup></InputLabel>
                    <TextField
                        required
                        id="outlined-required"
                        style={{ width: "100%" }}
                        name="product_name"
                        value={values.product_name}
                        InputProps={{
                            inputProps: {
                                maxLength: 100, minLength: 1
                            }
                        }}
                        rows={2}
                        error={!!errors.product_name}
                        helperText={errors.product_name === "" ? '' : errors.product_name}
                        onChange={textUpdate}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                    <InputLabel className='add-label'>Product Details <sup style={{ color: "red" }}>*</sup></InputLabel>
                    <TextField
                        required
                        id="outlined-required"
                        style={{ width: "100%" }}
                        name="product_details"
                        multiline
                        rows={2}
                        value={values.product_details}
                        onChange={textUpdateMax}
                        error={!!errors.product_details}
                        InputProps={{
                            inputProps: {
                                maxLength: 200, minLength: 1
                            }
                        }}
                        helperText={errors.product_details === "" ? '' : errors.product_details}
                    />
                </Grid>
                
                <DialogActions
                    sx={{
                        background: "linear-gradient(195deg, #dee1e1 10%, #f4f4f4 360%)",
                        marginTop: "3%",
                        marginBottom: "0%",
                        width: "104%",
                        borderRadius: '0.5rem',
                        transform: "translateY(35%)",
                        '.action-button': { width: 100 }
                    }}
                >
                    <MDButton color='success' className={`action-button ${disabledSave}`} disabled={allTrue()} type="submit">{props.updateData ? "Update" : "Add"}</MDButton>
                    <MDButton color='warning' className="action-button" onClick={props.close}>Cancel</MDButton>
                </DialogActions>
            </Grid>
        </Box>
    )
}

export default AddUpdateProducts