import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { AppBar, Box, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Icon, IconButton, List, ListItem, ListItemText, Slide, Switch, Tab, Tabs, TextField, Typography, useMediaQuery, Autocomplete, Stack } from "@mui/material";
import React, { Suspense, forwardRef, useEffect, useRef, useState, useMemo } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Column, ColumnChooser, DataGrid, Lookup, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar, RequiredRule, NumericRule, RangeRule, StringLengthRule, Popup, Form, AsyncRule, HeaderFilter, ColumnHeaderFilter, FilterRow, Search, Scrolling } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { Pager } from 'devextreme-react/tree-list';
import CloseIcon from '@mui/icons-material/Close';
import { GET, POST, PUT } from "Apis/config/RequestType";
import { update_user, get_sub_product, get_lead_lookup_source_data, delete_leads, leads, duplicate_leads } from "Apis/config/Url";
import Caller from "Apis/config/Caller";
import { useNavigate } from "react-router-dom";
import CustomStore from "devextreme/data/custom_store";
import MDBadge from "components/MDBadge";
import { getAllLeads, leadSubStatus, leadStatus } from "Apis/Auth/auth";
import { getCommunicationContact } from 'Apis/Auth/auth';
import { EmailRule, PatternRule } from "devextreme-react/form";
import MDButton from "components/MDButton";
import { useSnackbar } from 'notistack';
import Cookies from "js-cookie";
import ToggleButton from '@mui/material/ToggleButton';
import { get_product } from "Apis/config/Url";
import CreateGroup from 'pages/organization/group-template/create-group/create-group'
import { get_team_member_list } from "Apis/config/Url";
import Avatar from "@mui/material/Avatar";
import ChatPages from "./chat-ui/chatPage";
import { get_related_type_designation, create_communication_contact, update_communication_contact, delete_communication_contact, communication_group } from 'Apis/config/Url';
import { RIGHTS, UNIVERSAL_SEARCH_COOKIE } from "Apis/config/ResponseHandler";
import { LOGIN_USERTYPE_COOKIE } from "Apis/config/ResponseHandler";
import AddIcon from '@mui/icons-material/Add';

const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    bottom: "0px",
    left: "2.5%",
    borderTopLeftRadius: '0.7rem',
    borderTopRightRadius: '0.7rem',
    top: 35
  }
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



let updateRowData = {};
let cancelEvent = false;

const contactDetails = new CustomStore({
  key: 'id',
  load: (loadOptions) => {

    // clearLookUpCache();

    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      "filter",
      "totalSummary",
      "group",
      "groupSummary",
      "isLoadingAll"
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {

        if (i === "skip") {
          let page = (loadOptions[i] + 25) / 25;
          if (page !== 1) {
            params += `page=${page}&`;
          }
        } else if (i === "sort") {
          let sortType = loadOptions[i][0]["desc"] ? "desc" : "asc";
          let sortColoumn = loadOptions[i][0]["selector"];
          params += `sortType=${sortType}&sortColoumn=${sortColoumn}&`;
        } else if (i === "filter") {
          let filterO = loadOptions[i][0]["filterValue"];
          params += `s=${filterO}&`;
        } else {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      }
    }),
      params = params.slice(0, -1);
    let response = getCommunicationContact(params)
      .then((data) => ({

        data: data.data.data,
        totalCount: data.data.count,
        next: data.data.next,
        previous: data.data.previous
      }));
    return response;
  },


  insert: (values) => Caller(create_communication_contact, values, POST, false, false).then((res) => {
    if (res.success === true) {
      cancelEvent = false;
    }
    else if (res.message == "Contact Already Exist...") {
      cancelEvent = true;
    }
  }),

  update: (key, values) => Caller(update_communication_contact + '?id=' + key, { ...updateRowData, ...values }, POST, false, false).then((res) => {
    values: JSON.stringify(values)
  }),
  // remove: (key) => Caller(delete_communication_contact + '?lead_guid=' + key, "", POST, false, false).then((res) => {
  remove: (key) => Caller(delete_communication_contact + '?id=' + key, "", POST, false, false).then((res) => {
    values: JSON.stringify(res)
  }),


});

const newContactFilteredDataset = new CustomStore({
  key: "id",
  load: (loadOptions) => {

    // clearLookUpCache();

    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      "filter",
      "totalSummary",
      "group",
      "groupSummary",
      "isLoadingAll"
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {

        if (i === "skip") {
          let page = (loadOptions[i] + 25) / 25;
          if (page !== 1) {
            params += `page=${page}&`;
          }
        } else if (i === "sort") {
          let sortType = loadOptions[i][0]["desc"] ? "desc" : "asc";
          let sortColoumn = loadOptions[i][0]["selector"];
          params += `sortType=${sortType}&sortColoumn=${sortColoumn}&`;
        } else if (i === "filter") {
          let filterO = loadOptions[i][0]["filterValue"];
          params += `s=${filterO}&`;
        } else {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      }
    }),
      params = params.slice(0, -1);
    // let response = getCommunicationContact(params ? params : "?&s=New Lead&requireTotalCount=true&isLoadingAll=true")
    let response = getCommunicationContact(params + "?requireTotalCount=true&isLoadingAll=true")
      // let response = getCommunicationContact(params)
      .then((data) => ({
        // data: data.data.data.filter(results => results.lead_status == "New Lead"),
        data: data.data.data,
        totalCount: data.data.count
      }));

    return response;
  }
});

// const productData = new CustomStore({
//   key: 'Value',
//   loadMode: 'raw',
//   load: (options) => {
//     return Caller(get_product + "?isLoadingAll=true&sortColoumn=product_name&sortType=ASC&id=", '', GET, false, false).then((res) => {
//       return res.data.data
//     })
//   },
// })


const whatsappGroupData = new CustomStore({
  key: 'Value',
  loadMode: 'raw',
  cacheRawData: false,
  load: (options) => {
    return Caller(communication_group + "?isLoadingAll=true&requireTotalCount=true&id=", '', GET, false, false).then((res) => {
      return res.data.data
    })
  },
})



const isNotEmpty = (value) => {
  return value !== undefined && value !== null && value !== "";
}

const onRowUpdate = (value) => {
  updateRowData = value.oldData;
  delete updateRowData.id;
}

const AddContacts = (props) => {
  let universalSearchCookie = Cookies.get(UNIVERSAL_SEARCH_COOKIE);
  Cookies.set(UNIVERSAL_SEARCH_COOKIE, "")
  let rights = Cookies.get(RIGHTS) ?? "";
  let rightsArray = rights.split(',') ?? [];
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [duplicateContact, setDuplicateContact] = useState(false);
  const [updateData, setUpdate] = useState("");
  const userGrid = useRef();
  const whatsappGroupRef = useRef();
  const newFilteredGrid = useRef();
  const [open, setOpen] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const [selectedData, setSelectedData] = useState(null);
  const [showSelectedPopup, setShowSelectedPopup] = useState(false);
  const [contactAssignBtnSelected, setContactAssignBtnSelected] = useState(false);
  const [currentChatUser, setCurrentChatUser] = useState({
    id: "",
    first_name: "",
    mobile: ""
  })


  useEffect(() => {
    if (duplicateContact) {
      cancelEvent = false;
      enqueueSnackbar('Contact Already Exists !', { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } })
    }
  }, [duplicateContact])

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setBulkOpen(false);
      cancelEvent = false;
      setUpdate("");
      setOpen(false);
      setOpenContact(false);
      setDeleteOpen(false);
    }

  };

  const refreshGrid = async () => {
    await whatsappGroupData.load();
    setContactAssignBtnSelected(false);
    if (contactAssignBtnSelected) {
      newFilteredGrid.current.instance.refresh();
    } else {
      userGrid.current.instance.refresh();
    }
  }


  const productData = new CustomStore({
    key: 'Value',
    loadMode: 'raw',
    load: (options) => {
      return Caller(get_product + "?isLoadingAll=true&sortColoumn=product_name&sortType=ASC&id=", '', GET, false, false).then((res) => {
        return res.data.data
      })
    },
  })


  // Render ID column
  const renderID = (data) => {
    return <div>{"FDWC-" + data.data.id}</div>
  }

  // On Row click.
  const onRowClick = (data) => {
    setUpdate(data.data);
    setCurrentChatUser({
      id: data.data.id,
      first_name: data.data.first_name,
      mobile: data.data.mobile
    })
    setOpenContact(true);
  }

  const handlePopupClose = (e) => {
    e.cancel = cancelEvent;
    if (cancelEvent === false) {
      setDuplicateContact(false);
    } else {
      setDuplicateContact(true);
    }
  }

  const setSubProductValue = (rowData, value) => {

    rowData.sub_product_id = null;
    rowData.product_id = value;
  }

  const CustomTab = ({ label, user }) => {
    return (
      <Tab
        value={label}
        label={
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Avatar alt={user.first_name} src={`https://material-ui.com/static/images/avatar/alt.jpg`} variant='rounded' />
            <Typography variant="h6" sx={{ ml: '0.5vw' }}>
              {user.first_name} {user.is_group ? '' : `(${user.mobile})`}
            </Typography>
          </Box>
        }
      />
    );
  };


  const openPopup = async () => {
    await sendSelectedRows();
    setShowSelectedPopup(true);
  };

  const closePopup = () => {
    setShowSelectedPopup(false);
  };

  const contactAssignBtnToggle = () => {
    setContactAssignBtnSelected(!contactAssignBtnSelected);
  };

  const sendSelectedRows = () => {
    if (newFilteredGrid.current && newFilteredGrid.current.instance) {

      const selectedRows = newFilteredGrid.current.instance.getSelectedRowsData();

      if (selectedRows) {
        setSelectedData(selectedRows);

      }
    }
  }

  const setProfile = (prof) => {
    if (prof.is_group) {
      setCurrentChatUser(prof)
    } else {
      setCurrentChatUser({
        id: prof.whatsapp_contact,
        first_name: prof.first_name,
        mobile: prof.mobile,
      })
    }
  }

  const renderLastName = (data) => {
    if (data.data.last_name == 'null' || data.data.last_name == null) {
      return <div>{""}</div>
    } else {
      return <div>{data.data.last_name}</div>;
    }
  }

  const renderEmail = (data) => {
    if (data.data.email === 'null' || data.data.email === null) {
      return <div>{""}</div>
    } else {
      return <div>{data.data.email}</div>;
    }
  }

  const toolbarItems = [
    {
      widget: 'dxButton',
      location: 'after',
      options: {
        text: 'Create Group Mode(Off)',
        stylingMode: 'text',
        elementAttr: {
          style: {
            color: 'white', // Change text color
            backgroundColor: '#F44335', // Change background color
          },
        },
        onClick: () => {
          contactAssignBtnToggle();
        },
      },
    },
    'searchPanel',
    'exportButton',
    'columnChooserButton',
    {
      widget: 'dxButton',
      location: 'after',
      // name: 'addRowButton',
      options: {
        name: 'addRowButton',
        icon: 'add',
        text: 'Add Contact',
        stylingMode: 'text',
        elementAttr: {
          style: {
            color: 'white',
            backgroundColor: '#4CAF50',
          },
          class: 'white-icon',
        },
        onClick: () => {
          userGrid.current.instance.addRow();
        },
      },
    },
  ];



  return (
    <MDBox pb={1}>
      <MDBox p={3}>

        {/* {!contactAssignBtnSelected && <ToggleButton
          value="check"
          selected={contactAssignBtnSelected}
          size="small"
          sx={{
            "&:hover": { backgroundColor: "#F44335", },
            backgroundColor: "#F44335", color: "#FFFFFF", font: "sans-serif", borderColor: "none", padding: "8px 18px", textTransform: 'capitalize',
          }}
          onClick={contactAssignBtnToggle}
        >
          {"Create Group Mode(off)"}
        </ToggleButton>} */}

        {!contactAssignBtnSelected && <DataGrid
          id="gridContainer"
          keyExpr="id"
          dataSource={contactDetails}
          ref={userGrid}
          showBorders={true}
          remoteOperations={true}
          wordWrapEnabled={true}
          showColumnLines={false}
          rowAlternationEnabled={true}
          allowColumnReordering={true}
          onRowUpdating={onRowUpdate}
          // onInitNewRow={setInitialValues}
          // onEditorPreparing={onEditorPreparing}
          onRowClick={onRowClick}
        >

          <ColumnChooser
            enabled={true}
            mode="select"
          />

          <Pager
            visible={true}
            displayMode="full"
            showInfo={true}
            showNavigationButtons={contactAssignBtnSelected ? false : true}
            allowedPageSizes={'all'}
          />
          <Paging defaultPageSize={25} />


          <SearchPanel
            visible={true}
            defaultText={universalSearchCookie}
          />
          {/* <Export
            // enabled={(Cookies.get('id') == 1 || Cookies.get('id') == 2)}
            enabled={true}
            fileName="Communication Contacts"
          /> */}

          <Export
            enabled={true}
            fileName="Communication Contacts"
          />
          <Editing
            mode="popup"
            allowAdding={true}
            allowUpdating={true}
            //remove update access from team leader and team member
            // allowUpdating={(rightsArray.includes('100') && rightsArray.includes('108') && !rightsArray.includes('201')) ? false : true}
            useIcons={true}
            //remove delete access from team leader and team member
            allowDeleting={true}
          >
            <Popup
              title="Contact Information"
              showTitle={true}
              width="60vw"
              height="70vh"
              dragEnabled={false}
              onHiding={handlePopupClose}
            />
            <Form>
              <Item itemType="group" colCount={2} colSpan={2}>
                <Item dataField="first_name" />
                <Item dataField="last_name" />
                <Item dataField="mobile" />
                <Item dataField="email" />
                <Item dataField="product" />
                <Item dataField="whatsapp_group_name" />
              </Item>
            </Form>
          </Editing>
          {/* <Column
            caption="View"
            alignment="center"
            cellRender={renderProductColumn}
            width={80}
            allowEditing={false}
          >
          </Column> */}

          <Column
            caption="ID"
            dataField="id"
            alignment="center"
            // cellRender={renderID}
            width={100}
            allowEditing={false}
          >
          </Column>
          <Column
            caption="First Name"
            dataField="first_name"
            alignment="center"
            minWidth={150}
          >
            <RequiredRule />
            <PatternRule
              pattern="^[a-zA-Z ]+$"
              message="The name should not contain digits"
            />
          </Column>

          <Column
            caption="Last Name"
            dataField="last_name"
            alignment="center"
            minWidth={150}
            cellRender={renderLastName}
          >
            {/* <RequiredRule /> */}
            <PatternRule
              pattern="^[a-zA-Z ]+$"
              message="The name should not contain digits"
            />
          </Column>

          <Column
            caption="Product"
            alignment="center"
            dataField="product"
            minWidth={150}
          // setCellValue={setSubProductValue}
          >
            <RequiredRule />
            <Lookup dataSource={productData} valueExpr="product_id" displayExpr="product_name" />
          </Column>
          <Column
            caption="WhatsApp Group Name"
            dataField="whatsapp_group_name"
            alignment="center"
            minWidth={150}
          >
            <Lookup ref={whatsappGroupRef} dataSource={whatsappGroupData} valueExpr="id" displayExpr="whatsapp_group_name" />
          </Column>

          <Column
            caption="Mobile"
            dataField="mobile"
            alignment="center"
            minWidth={150}
          >
            <NumericRule />
            <StringLengthRule message="Mobile must have at least 10 digits" min={10} max={10} />
            <RequiredRule />
          </Column>


          <Column
            caption="Email"
            dataField="email"
            alignment="center"
            cellRender={renderEmail}
            minWidth={150}
          // visible={false}
          >
            <EmailRule />
          </Column>
          <Column type="buttons"
            dataField="Actions"
            visible={(Cookies.get('id') == 1 || Cookies.get('id') == 2) ? true : false}
          >
          </Column>

          <Toolbar items={toolbarItems} />

          {/* <Toolbar>

            {(rightsArray.includes('119')) && (Cookies.get(LOGIN_USERTYPE_COOKIE) != 5) &&
              <Item name="contact_assignment" location="after">
                <ToggleButton
                  value="check"
                  selected={contactAssignBtnSelected}
                  size="small"
                  sx={{
                    "&:hover": { backgroundColor: "#F44335", },
                    backgroundColor: "#F44335", color: "#FFFFFF", font: "sans-serif", borderColor: "none", padding: "8px 18px", textTransform: 'capitalize',
                  }}
                  onClick={contactAssignBtnToggle}
                >
                  {contactAssignBtnSelected ? "Create Group Mode(on)" : "Create Group Mode(off)"}
                </ToggleButton>
              </Item>}

            <Item name="searchPanel" />
            <Item name="exportButton" locateInMenu="auto" />
            <Item name="columnChooserButton" locateInMenu="auto" />
            <Item name='addRowButton' showText='always' options={{ text: "Add Contacts" }}>
            </Item>

          </Toolbar> */}

        </DataGrid>}

        {contactAssignBtnSelected && <DataGrid
          id="gridContainer"
          keyExpr="id"
          dataSource={newContactFilteredDataset}
          ref={newFilteredGrid}
          showBorders={true}
          remoteOperations={false}
          wordWrapEnabled={true}
          showColumnLines={false}
          rowAlternationEnabled={true}
          allowColumnReordering={true}
        >
          <Pager
            visible={true}
            displayMode="full"
            showInfo={true}
            showNavigationButtons={contactAssignBtnSelected ? false : true}
            allowedPageSizes={'all'}
          />
          <SearchPanel
            visible={true}
          />
          <Paging defaultPageSize={25} />

          <Selection
            mode="multiple"
            showCheckBoxesMode="always"
            deferred={false}
          />
          <ColumnChooser
            enabled={true}
            mode="select"
          />

          <Column
            caption="ID"
            dataField="id"
            alignment="center"
            // cellRender={renderID}
            width={100}
            allowEditing={false}
          >
          </Column>
          <Column
            caption="Name"
            dataField="first_name"
            alignment="center"
            minWidth={150}
          >
            <RequiredRule />
            <PatternRule
              pattern="^[a-zA-Z ]+$"
              message="The name should not contain digits"
            />
          </Column>

          <Column
            caption="Product"
            alignment="center"
            dataField="product"
            minWidth={150}
            setCellValue={setSubProductValue}
          >
            <RequiredRule />
            <Lookup dataSource={productData} valueExpr="product_id" displayExpr="product_name" />
          </Column>
          <Column
            caption="WhatsApp Group Name"
            dataField="whatsapp_group_name"
            alignment="center"
            minWidth={150}
          >
            <Lookup dataSource={whatsappGroupData} valueExpr="id" displayExpr="whatsapp_group_name" />
          </Column>

          <Column
            caption="Mobile"
            dataField="mobile"
            alignment="center"
            minWidth={150}
          >
            <NumericRule />
            <StringLengthRule message="Mobile must have at least 10 digits" min={10} max={10} />
            <RequiredRule />
          </Column>


          <Column
            caption="Email"
            dataField="email"
            alignment="center"
            minWidth={150}
          >
            <EmailRule />
          </Column>

          <Toolbar>

            <Item location="before">
              <Typography variant="h5" sx={{ my: 0, textDecoration: "none", }}>
                Create Group Mode
              </Typography>
            </Item>


            {(rightsArray.includes('119')) && (Cookies.get(LOGIN_USERTYPE_COOKIE) != 5) &&
              <Item>
                <ToggleButton
                  value="check"
                  // contactAssignBtnSelected={contactAssignBtnSelected}
                  size="small"
                  sx={{
                    "&:hover": { backgroundColor: "#4da851", },
                    backgroundColor: "#4da851", color: "#FFFFFF", font: "sans-serif", borderColor: "none", padding: "8px 18px", textTransform: 'capitalize',
                  }}
                  onClick={contactAssignBtnToggle}
                >
                  Create Group Mode(On)
                </ToggleButton>
              </Item>}
            <Item name="searchPanel" />


            <Item
              location="after"
            >
              <MDButton color='success' size="small" onClick={openPopup}>Create Group</MDButton>
            </Item>

          </Toolbar>

        </DataGrid>}

        <Dialog
          open={showSelectedPopup}
          onClose={closePopup}
          maxWidth='lg'
        >
          <DialogTitle
            pb={0}
          ><MDBox
            color="white"
            bgColor="info"
            variant="gradient"
            borderRadius="lg"
            shadow="lg"
            opacity={1}
            p={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
              <MDTypography variant="h4" color="white">Create Group</MDTypography>
              <IconButton
                style={{ color: "white", cursor: "pointer" }}
                sx={{
                  "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
                }}
                onClick={closePopup}
              >
                <CloseIcon />
              </IconButton>
            </MDBox></DialogTitle>
          <DialogContent sx={{ m: 2, mt: 0, pb: 0 }}>
            <CreateGroup selectedRows={selectedData} onClose={closePopup} refresh={refreshGrid} />
          </DialogContent>
        </Dialog>

        <Dialog
          fullScreen
          open={openContact}
          onClose={handleClose}
          TransitionComponent={Transition}
          sx={{
            width: "95%",

          }}
          classes={{
            paper: classes.dialog,

          }}
        >
          <DialogTitle
            sx={{
              padding: "0px",

            }}
          ><MDBox
            color="primary"
            variant="gradient"
            opacity={1}
            pl={2}
            pr={2}
            pt={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"

          // textAlign="center"
          >
              <MDTypography variant="h5" color="secondary"> <MDBadge variant="contained" container color="success" ></MDBadge> </MDTypography>
              <IconButton
                style={{ color: "black", cursor: "pointer" }}
                sx={{
                  "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
                }}
                onClick={() => setOpenContact(false)}
              >
                <CloseIcon />
              </IconButton>
            </MDBox>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="icon label tabs example"
                sx={{
                  // width: "20%",
                  width: "25%",
                  paddingLeft: 2,
                  // height: "40px",
                  fontSize: "small",
                  fontWeight: "600",
                }}>
                <CustomTab mg user={currentChatUser} value={0} />
              </Tabs>
            </Box>

          </DialogTitle>
          <DialogContent
            p={3}
            sx={{
              overflowY: 'hidden',
              height: '100vh',
              backgroundColor: "#eee",
              paddingTop: "17px !important"
            }}>
            {(tabValue == 0) && <ChatPages currentChat={currentChatUser} updateData={updateData} type={"chat"} refresh={refreshGrid} close={handleClose} setProfile={setProfile} />}
          </DialogContent>
        </Dialog>



      </MDBox >
    </MDBox >
  );
}

export default AddContacts;




