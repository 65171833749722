/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import team2 from "assets/images/team-2.jpg";


// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import MDButton from "components/MDButton";
import { Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Icon, IconButton, InputLabel, ListItemText, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { Button, Column, ColumnChooser, DataGrid, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { Pager } from 'devextreme-react/tree-list';
import CloseIcon from '@mui/icons-material/Close';
import { UsersData } from "layouts/tables/data/mockdata";
import { GET } from "Apis/config/RequestType";
import { update_user } from "Apis/config/Url";
import { PUT } from "Apis/config/RequestType";
import DeletePopup from "layouts/master-data/delete-popup";
import { delete_user } from "Apis/config/Url";
import Caller from "Apis/config/Caller";
import { useNavigate } from "react-router-dom";
import { getEmployeeUserDetails } from "Apis/Auth/auth";
import CustomStore from "devextreme/data/custom_store";
import { change_user_status } from "Apis/config/Url";
import { POST } from "Apis/config/RequestType";
import { get_employee_user_details } from "Apis/config/Url";
import MDBadge from "components/MDBadge";
import styled from "@emotion/styled";
import { buttonUnstyledClasses } from "@mui/base";
import { useSnackbar } from "notistack";
import BackupIcon from '@mui/icons-material/Backup';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { import_employee_bulk } from "Apis/config/Url";
import { getImportErrors } from "Apis/Auth/auth";
import { delete_related_employee_error } from "Apis/config/Url";



const ImportEmployeeErrors = (props) => {

    const [deleteOpen, setDeleteOpen] = useState(false);
    const [popuptitle, setPopupTitle] = useState("Employee Data 1");
    const [updateData, setUpdate] = useState("");
    const userGrid = useRef();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    let url_string = window.location.href;

    const { enqueueSnackbar } = useSnackbar();

    const [values, setAllValues] = useState({
        files: "",
        file_name: ""
    })
    const [errors, setAllErrors] = useState({
        files: ""
    })

    const [isInitialRender, setIsInitialRender] = useState(true);
    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);
        }
    }, [])

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            if (reason !== 'backdropClick') {
                setUpdate("");
                setOpen(false);
                setDeleteOpen(false);
            }
        }
    };


    function cellRender(data) {
        return <img src={team2} style={{ borderRadius: "50%", width: "2.5rem", height: "2.5rem" }} />;
    }

    const refreshGrid = () => {
        userGrid.current.instance.refresh();
    }
    const deleteVisible = (e) => {
        setUpdate(e.row.data.id);
        setDeleteOpen(true);
    }
    const updateError = (e) => {
        return navigate("/bulk-employee-upload/update-errors-" + e.row.data.import_data_id);
    }

    let deleteText = "Do you want to delete this Error?";


    return (
        <MDBox
            color="white"
            bgColor="white"
            variant="gradient"
            borderRadius="lg"
            shadow="lg"
            opacity={1}
            p={2}
            mt={5}
            border="0.5px solid #eeeeee"
        >
            {deleteOpen && <DeletePopup deleteId={updateData} open={deleteOpen} deleteText={deleteText} close={handleClose} refresh={refreshGrid} path={delete_related_employee_error + "/?id=" + updateData} />}
            <DataGrid
                id="gridContainer"
                keyExpr="id"
                dataSource={props.gridData}
                ref={userGrid}
                showBorders={true}
                remoteOperations={true}
                wordWrapEnabled={true}
                showColumnLines={false}
                rowAlternationEnabled={true}
            >
                <ColumnChooser
                    enabled={true}
                    mode="select"
                />
                <Pager
                    visible={true}
                    displayMode="full"
                    showInfo={true}
                    showNavigationButtons={true} />
                <Paging defaultPageSize={10} />
                <SearchPanel
                    visible={true}
                />
                <Export
                    enabled={true}
                    fileName="Import Errors"
                />
                <Editing
                    mode="row"
                    allowAdding={false}
                    allowUpdating={true}
                    useIcons={true}
                />
                <Column
                    caption="Column Name"
                    dataField="column_name"
                    alignment="center"
                    minWidth={150}
                />

                <Column
                    caption="Error"
                    dataField="error_message"
                    alignment="center"
                    minWidth={350}
                />
                <Column
                    caption="Row Number"
                    dataField="row_number"
                    alignment="center"
                    minWidth={150}
                />

                <Column type="buttons" dataField="Actions" minWidth={150}>
                    <Button icon="edit" hint="Edit" onClick={updateError} />
                    <Button hint="Delete" icon="trash" style={{ color: "red" }} onClick={deleteVisible} />
                </Column>
                <Toolbar>

                    <Item name="searchPanel" />
                    <Item name="exportButton" />

                </Toolbar>
            </DataGrid>
        </MDBox>

    );
}

export default ImportEmployeeErrors;
