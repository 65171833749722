/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import team2 from "assets/images/team-2.jpg";


// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import MDButton from "components/MDButton";
import { Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Icon, IconButton, InputLabel, ListItemText, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { Button, Column, ColumnChooser, DataGrid, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { Pager } from 'devextreme-react/tree-list';
import CloseIcon from '@mui/icons-material/Close';
import { UsersData } from "layouts/tables/data/mockdata";
import { GET } from "Apis/config/RequestType";
import { update_user } from "Apis/config/Url";
import { PUT } from "Apis/config/RequestType";
import DeletePopup from "layouts/master-data/delete-popup";
import { delete_user } from "Apis/config/Url";
import Caller from "Apis/config/Caller";
import { useNavigate } from "react-router-dom";
import { getEmployeeUserDetails } from "Apis/Auth/auth";
import CustomStore from "devextreme/data/custom_store";
import { change_user_status } from "Apis/config/Url";
import { POST } from "Apis/config/RequestType";
import { get_employee_user_details } from "Apis/config/Url";
import MDBadge from "components/MDBadge";
import { useSnackbar } from "notistack";
import BackupIcon from '@mui/icons-material/Backup';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { import_employee_bulk } from "Apis/config/Url";
import { getImportErrors } from "Apis/Auth/auth";

import { Tabs } from "devextreme-react";

import ImportLeadData from "./import-lead-data";
import ImportLeaLogs from "./import-lead-logs";
import { getLeadImportLogs } from "Apis/Auth/auth";
import { getImportErrorsLead } from "Apis/Auth/auth";
import ImportLeadErrors from "./import-lead-errors";


const isNotEmpty = (value) => {
    return value !== undefined && value !== null && value !== "";
}


const importErrors = new CustomStore({
    key: 'import_data_id',
    load(loadOptions) {
        let params = "?";
        [
            "skip",
            "take",
            "requireTotalCount",
            "requireGroupCount",
            "sort",
            "filter",
            "totalSummary",
            "group",
            "groupSummary",
            "isLoadingAll"
        ].forEach((i) => {
            if (i in loadOptions && isNotEmpty(loadOptions[i])) {

                if (i === "skip") {
                    let page = (loadOptions[i] + 10) / 10;
                    if (page !== 1) {
                        params += `page=${page}&`;
                    }
                } else if (i === "sort") {
                    let sortType = loadOptions[i][0]["desc"] ? "desc" : "asc";
                    let sortColoumn = loadOptions[i][0]["selector"];
                    params += `sortType=${sortType}&sortColoumn=${sortColoumn}&`;
                } else if (i === "filter") {
                    let filterO = loadOptions[i][0]["filterValue"];
                    params += `s=${filterO}&`;
                } else {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            }
        });
        params = params.slice(0, -1);
        return getImportErrorsLead(params)
            .then((data) => ({
                data: data.data.data,
                totalCount: data.data.count,
                next: data.data.next,
                previous: data.data.previous
            }))
    },

});
const importLogs = new CustomStore({
    key: 'id',
    load(loadOptions) {
        let params = "?";
        [
            "skip",
            "take",
            "requireTotalCount",
            "requireGroupCount",
            "sort",
            "filter",
            "totalSummary",
            "group",
            "groupSummary",
            "isLoadingAll"
        ].forEach((i) => {
            if (i in loadOptions && isNotEmpty(loadOptions[i])) {

                if (i === "skip") {
                    let page = (loadOptions[i] + 10) / 10;
                    if (page !== 1) {
                        params += `page=${page}&`;
                    }
                } else if (i === "sort") {
                    let sortType = loadOptions[i][0]["desc"] ? "desc" : "asc";
                    let sortColoumn = loadOptions[i][0]["selector"];
                    params += `sortType=${sortType}&sortColoumn=${sortColoumn}&`;
                } else if (i === "filter") {
                    let filterO = loadOptions[i][0]["filterValue"];
                    params += `s=${filterO}&`;
                } else {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            }
        });
        params = params.slice(0, -1);
        return getLeadImportLogs(params)
            .then((data) => ({
                data: data.data.data,
                totalCount: data.data.count,
                next: data.data.next,
                previous: data.data.previous
            }))
    },

});

export const tabs = [
    {
        id: 0,
        text: 'Import Data',
        icon: '',
    },
    {
        id: 1,
        text: 'Import Logs',
        icon: '',
    },
    {
        id: 2,
        text: 'Import Errors',
        icon: '',
    },
];


const BulkLeadUpload = () => {

    const [deleteOpen, setDeleteOpen] = useState(false);
    const [popuptitle, setPopupTitle] = useState("Employee Data 1");
    const [updateData, setUpdate] = useState("");
    const userGrid = useRef();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    let url_string = window.location.href;
    let myParam = url_string.split('update/')[1];

    const { enqueueSnackbar } = useSnackbar();
    const [dbrowsData, dbsetRowsData] = useState([]);


    const [isInitialRender, setIsInitialRender] = useState(true);
    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);
        }
    }, [])





    function cellRender(data) {
        return <img src={team2} style={{ borderRadius: "50%", width: "2.5rem", height: "2.5rem" }} />;
    }

    const selectPopupVisible = async (e) => {
        const formData = new FormData();
        formData.append("id", e.row.data.id);
        await Caller(get_employee_user_details + "?take=10&requireTotalCount=true&s=&id=" + e.row.data.employee_user_id, "", GET, false, true).then(async (res) => {
            if (res.success === true) {
                setUpdate(res.data.data);
            }
        });
        setPopupTitle("Update Employee");
        setOpen(true);
    }

    const refreshGrid = () => {
        userGrid.current.instance.refresh();
    }
    const deleteVisible = (e) => {
        setUpdate(e.row.data.employee_user_id);
        setDeleteOpen(true);
    }
    const updateEmployee = (e) => {
        return navigate("/employees/update/" + e.row.data.employee_guid);
    }

    const handleChanged = async (event, id) => {

        let status = "";
        if (event.target.checked === false) {
            status = 0
        }
        else {
            status = 1
        }
        const formData = new FormData();
        formData.append("id", id);
        formData.append("active", status);
        await Caller(change_user_status + "?user_type=employee", formData, POST, false, true)
            .then((data) => {
                if (data.success === true) {
                    refreshGrid();
                }
            });
    }

    const returnLoginStatus = (data) => {
        switch (data.value) {
            case 1:
                return <MDBadge badgeContent="Online" variant="gradient" color="success" size="xs" container />
            case 0:
                return <MDBadge badgeContent="Offline" variant="gradient" color="dark" size="xs" container />
            default:
                break;
        }
        // return <MDBadge badgeContent="Online" variant="gradient" color="success" container />
    }

    let deleteText = "Do you want to delete this Employee?";

    let [selectedIndex, setselectedIndex] = useState(0)
    let onTabsSelectionChanged = (args) => {
        if (args === 0 || args === 1 || args === 2) {
            setselectedIndex(args);
        }
        if (args.name === 'selectedIndex') {
            setselectedIndex(args.value)
        }
    }

    return (
        <DashboardLayout>
         
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6} key="employees_grid_1">
                    <Grid item xs={12} key="employees_grid_2">
                        <Card>
                          
                            <MDBox p={3}>
                                <Tabs
                                    dataSource={tabs}
                                    selectedIndex={selectedIndex}
                                    onOptionChanged={onTabsSelectionChanged}
                                />
                                {selectedIndex == 0 && <ImportLeadData changeTab={onTabsSelectionChanged} />}
                                {selectedIndex == 1 && <ImportLeaLogs gridData={importLogs} />}
                                {selectedIndex == 2 && <ImportLeadErrors gridData={importErrors} changeTab={onTabsSelectionChanged}/>}
                            </MDBox>

                        </Card>
                    </Grid>
                </Grid>
            </MDBox >
       
        </DashboardLayout >
    );
}

export default BulkLeadUpload;
