/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable prettier/prettier */
import { Card, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Icon, IconButton, Switch } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import { Button, Column, DataGrid, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar, Lookup, RequiredRule } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { Pager } from 'devextreme-react/tree-list';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import React, { useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import AddUpdateBroadcast from './addUpdateBroadcast'
import DeletePopup from "layouts/master-data/delete-popup";
import Caller from 'Apis/config/Caller';
import { GET } from 'Apis/config/RequestType';
import CustomStore from 'devextreme/data/custom_store';
import { getCommunicationBroadcast } from 'Apis/Auth/auth';
import { deleteBroadcast } from 'Apis/config/Url';
import { getBroadcast } from 'Apis/config/Url';
import { getWhatsappTemplateType } from 'Apis/config/Url'
import moment from 'moment';
import Footer from 'examples/Footer';

import Cookies from "js-cookie";
import { RIGHTS, UNIVERSAL_SEARCH_COOKIE } from "Apis/config/ResponseHandler";

const isNotEmpty = (value) => {
    return value !== undefined && value !== null && value !== "";
}

const broadcastData = new CustomStore({

    key: 'id',
    load(loadOptions) {
        let params = "?";
        [
            "skip",
            "take",
            "requireTotalCount",
            "requireGroupCount",
            "sort",
            "filter",
            "totalSummary",
            "group",
            "groupSummary",
            "isLoadingAll"
        ].forEach((i) => {
            if (i in loadOptions && isNotEmpty(loadOptions[i])) {

                if (i === "skip") {
                    let page = (loadOptions[i] + 25) / 25;
                    if (page !== 1) {
                        params += `page=${page}&`;
                    }
                } else if (i === "sort") {
                    let sortType = loadOptions[i][0]["desc"] ? "desc" : "asc";
                    let sortColoumn = loadOptions[i][0]["selector"];
                    params += `sortType=${sortType}&sortColoumn=${sortColoumn}&`;
                } else if (i === "filter") {
                    let filterO = loadOptions[i][0]["filterValue"];
                    params += `s=${filterO}&`;
                } else {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            }
        });
        params = params.slice(0, -1);
        return getCommunicationBroadcast(params)
            .then((data) => ({
                data: data.data.data,
                totalCount: data.data.count,
                next: data.data.next,
                previous: data.data.previous
            }));
    },

});

const templateData = new CustomStore({
    key: 'Value',
    loadMode: 'raw',
    load: (options) => {
        return Caller(getWhatsappTemplateType, "", GET, false, true).then((res) => {
            return res.data.data
        })
    }
});

const Broadcast = () => {
    let universalSearchCookie = Cookies.get(UNIVERSAL_SEARCH_COOKIE);
    Cookies.set(UNIVERSAL_SEARCH_COOKIE, "")

    let rights = Cookies.get(RIGHTS) ?? "";
    let rightsArray = rights.split(',') ?? [];

    const userGrid = useRef();
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [popuptitle, setPopupTitle] = useState("Add");
    const [updateData, setUpdate] = useState("");
    const popupDeleteText = "Do you want to delete this Broadcast data?";

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setUpdate("");
            setOpen(false);
            setDeleteOpen(false);
        }
    };

    const selectPopupVisible = async (e) => {
        await Caller(getBroadcast + "?id=" + e.row.data.id + "", "", GET, false, true).then(async (res) => {
            if (res.success === true) {
                setUpdate(res.data.data);
            }
        });
        setPopupTitle("Update Broadcast");
        setOpen(true);
    }

    const deleteVisible = (e) => {
        setUpdate(e.row.data.id);
        setDeleteOpen(true);
    }

    const refreshGrid = () => {
        userGrid.current.instance.refresh();
    }
    const refreshGridDelete = () => {
        userGrid.current.instance.refresh();
    }

    const dateTimeRender = (data) => {
        return <div> {moment(data.data.date_time).format('DD/MM/YYYY hh:mm:ss')} </div>
    }

    return (
        <DashboardLayout>
            {deleteOpen && <DeletePopup deleteId={updateData} deleteText={popupDeleteText} open={deleteOpen} close={handleClose} path={deleteBroadcast} refresh={refreshGridDelete} />}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h4" color="white">
                                    Broadcast
                                </MDTypography>
                            </MDBox>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                maxWidth='md'
                                sx={{
                                    '.MuiDialogContent-root': { pl: 5, pr: 5, pb: 5, pt: 2 }
                                }}
                            >
                                <DialogTitle
                                ><MDBox
                                    color="white"
                                    bgColor="info"
                                    variant="gradient"
                                    borderRadius="lg"
                                    shadow="lg"
                                    opacity={1}
                                    p={2}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                // textAlign="center"
                                >
                                        <MDTypography variant="h4" color="white">{popuptitle}</MDTypography>
                                        <IconButton
                                            style={{ color: "white", cursor: "pointer" }}
                                            sx={{
                                                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
                                            }}
                                            onClick={() => setOpen(false)}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </MDBox></DialogTitle>
                                <DialogContent p={3}>
                                    <AddUpdateBroadcast updateData={updateData} close={handleClose} refresh={refreshGrid} />
                                </DialogContent>
                            </Dialog>
                            <MDBox p={3}>
                                <DataGrid
                                    id="gridContainer"
                                    dataSource={broadcastData}
                                    keyExpr="id"
                                    ref={userGrid}
                                    showBorders={true}
                                    remoteOperations={true}
                                    wordWrapEnabled={true}
                                    showColumnLines={false}
                                    rowAlternationEnabled={true}
                                >
                                    <Pager
                                        visible={true}
                                        displayMode="full"
                                        showInfo={true}
                                        showNavigationButtons={true} />
                                    <Paging defaultPageSize={25} />
                                    <SearchPanel
                                        visible={true}
                                        defaultText={universalSearchCookie}
                                    />
                                    <Export
                                        enabled={true}
                                        fileName="Broadcast"
                                    />
                                    <Editing
                                        mode="row"
                                        allowAdding={false}
                                        // allowUpdating={true}
                                        allowUpdating={rightsArray.includes('100') && rightsArray.includes('108') && rightsArray.includes('117') ? true : false}
                                        useIcons={true}
                                    />
                                    <Column
                                        caption="Broadcast Name"
                                        dataField="broadcast_name"
                                        alignment="center"
                                        minWidth={150}
                                    />

                                    <Column
                                        caption="Description"
                                        dataField="description"
                                        alignment="center"
                                        minWidth={100}
                                    />

                                    <Column
                                        caption="Date & Time"
                                        dataField="date_time"
                                        alignment="center"
                                        minWidth={200}
                                        // cellRender={dateTimeRender}
                                        dataType="datetime"
                                        format="MM/dd/yyyy, hh:mm a"
                                    />

                                    <Column
                                        caption="Whatsapp Group"
                                        dataField="whatsapp_group_name"
                                        alignment="center"
                                        minWidth={150}
                                    >
                                        {/* <Lookup dataSource={whatsappgroup} /> */}
                                    </Column>

                                    <Column
                                        caption="Templates"
                                        dataField="template"
                                        alignment="center"
                                        minWidth={150}
                                    >
                                        <Lookup dataSource={templateData} valueExpr="id" displayExpr="template_name" />
                                    </Column>

                                    {rightsArray.includes('100') && rightsArray.includes('108') && rightsArray.includes('117') &&
                                        <Column type="buttons" dataField="Actions" minWidth={150}>
                                            <Button icon="edit" hint="Edit" onClick={selectPopupVisible} />
                                            <Button hint="Delete" icon="trash" style={{ color: "red" }} onClick={deleteVisible} />
                                        </Column>
                                    }
                                    <Toolbar>
                                        <Item name="searchPanel" locateInMenu="auto" />
                                        <Item name="exportButton" locateInMenu="auto" />
                                        {rightsArray.includes('100') && rightsArray.includes('108') && rightsArray.includes('117') &&
                                            <Item location="after" locateInMenu="auto">
                                                <MDButton variant="gradient" color="success" onClick={() => { setUpdate(""); setPopupTitle("Add Broadcast"); setOpen(true); }}>
                                                    Add&nbsp;
                                                    <Icon>add</Icon>&nbsp;
                                                </MDButton>
                                            </Item>
                                        }
                                    </Toolbar>

                                </DataGrid>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default Broadcast;