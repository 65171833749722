/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import team2 from "assets/images/team-2.jpg";


// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import MDButton from "components/MDButton";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Icon, IconButton, Switch, TextField, useMediaQuery } from "@mui/material";
import { useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { Button, Column, ColumnChooser, DataGrid, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { Pager } from 'devextreme-react/tree-list';
import CloseIcon from '@mui/icons-material/Close';
import { UsersData } from "layouts/tables/data/mockdata";
import { GET } from "Apis/config/RequestType";
import { update_user } from "Apis/config/Url";
import { PUT } from "Apis/config/RequestType";
import DeletePopup from "layouts/master-data/delete-popup";
import { delete_user } from "Apis/config/Url";
import Caller from "Apis/config/Caller";
import { useNavigate } from "react-router-dom";
import { getEmployeeUserDetails } from "Apis/Auth/auth";
import CustomStore from "devextreme/data/custom_store";
import { change_user_status } from "Apis/config/Url";
import { POST } from "Apis/config/RequestType";
import { get_employee_user_details } from "Apis/config/Url";
import MDBadge from "components/MDBadge";
const isNotEmpty = (value) => {
  return value !== undefined && value !== null && value !== "";
}


const employeeUserUserDetails = new CustomStore({
  key: 'employee_user_id',
  load(loadOptions) {
    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      "filter",
      "totalSummary",
      "group",
      "groupSummary",
      "isLoadingAll"
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {

        if (i === "skip") {
          let page = (loadOptions[i] + 10) / 10;
          if (page !== 1) {
            params += `page=${page}&`;
          }
        } else if (i === "sort") {
          let sortType = loadOptions[i][0]["desc"] ? "desc" : "asc";
          let sortColoumn = loadOptions[i][0]["selector"];
          params += `sortType=${sortType}&sortColoumn=${sortColoumn}&`;
        } else if (i === "filter") {
          let filterO = loadOptions[i][0]["filterValue"];
          params += `s=${filterO}&`;
        } else {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      }
    });
    params = params.slice(0, -1);
    return getEmployeeUserDetails(params)
      .then((data) => ({
        data: data.data.data,
        totalCount: data.data.count,
        next: data.data.next,
        previous: data.data.previous
      }))
  },

});

const MyLeads = () => {

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [popuptitle, setPopupTitle] = useState("Employee Data 1");
  const [updateData, setUpdate] = useState("");
  const userGrid = useRef();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      if (reason !== 'backdropClick') {
        setUpdate("");
        setOpen(false);
        setDeleteOpen(false);
      }
    }
  };

  function cellRender(data) {
    return <img src={team2} style={{ borderRadius: "50%", width: "2.5rem", height: "2.5rem" }} />;
  }

  const selectPopupVisible = async (e) => {
    const formData = new FormData();
    formData.append("id", e.row.data.id);
    await Caller(get_employee_user_details + "?take=10&requireTotalCount=true&s=&id=" + e.row.data.employee_user_id, "", GET, false, true).then(async (res) => {
      if (res.success === true) {
        setUpdate(res.data.data);
      }
    });
    setPopupTitle("Update Employee");
    setOpen(true);
  }

  const refreshGrid = () => {
    userGrid.current.instance.refresh();
  }
  const deleteVisible = (e) => {
    setUpdate(e.row.data.employee_user_id);
    setDeleteOpen(true);
  }
  const updateEmployee = (e) => {
    return navigate("/employees/update/" + e.row.data.employee_guid);
  }

  const handleChanged = async (event, id) => {

    let status = "";
    if (event.target.checked === false) {
      status = 0
    }
    else {
      status = 1
    }
    const formData = new FormData();
    formData.append("id", id);
    formData.append("active", status);
    await Caller(change_user_status + "?user_type=employee", formData, POST, false, true)
      .then((data) => {
        if (data.success === true) {
          refreshGrid();
        }
      });
  }

  const returnLoginStatus = (data) => {
    switch (data.value) {
      case 1:
        return <MDBadge badgeContent="Online" variant="gradient" color="success" size="xs" container />
      case 0:
        return <MDBadge badgeContent="Offline" variant="gradient" color="dark" size="xs" container />
      default:
        break;
    }
    // return <MDBadge badgeContent="Online" variant="gradient" color="success" container />
  }

  const renderGridStatus = (data) => {
    let func = "";
    let label = "";
    if (data.data.is_active == 'Yes') {
      func = true
      label = "Active"
    }
    else {
      func = false
      label = "Inactive"
    }

    return <FormControlLabel
      control={<Switch
        checked={func}
        color="secondary"
        onChange={(event) => handleChanged(event, data.data.employee_user_id)}
        inputProps={{ 'aria-label': 'controlled', role: 'switch' }}
      />}
      label={label} />
  }

  let deleteText = "Do you want to delete this Employee?";


  return (
    <>
     
      {deleteOpen && <DeletePopup deleteId={updateData} open={deleteOpen} deleteText={deleteText} close={handleClose} refresh={refreshGrid} path={delete_user + "?user_type=employee"} />}
      <MDBox pt={2} pb={1}>
        
          
           
      <Card>
              {/* <EmployeesData1 data={updateData} close={handleClose} />
              <EmployeesData2 data={updateData} close={handleClose} /> */}
              <MDBox p={3}>
                <DataGrid
                  id="gridContainer"
                  keyExpr="employee_user_id"
                  dataSource={employeeUserUserDetails}
                  ref={userGrid}
                  showBorders={true}
                  remoteOperations={true}
                  wordWrapEnabled={true}
                  showColumnLines={false}
                  rowAlternationEnabled={true}
                >
                  <ColumnChooser
                    enabled={true}
                    mode="select"
                  />
                  <Pager
                    visible={true}
                    displayMode="full"
                    showInfo={true}
                    showNavigationButtons={true} />
                  <Paging defaultPageSize={10} />
                  <SearchPanel
                    visible={true}
                  />
                  <Export
                    enabled={true}
                    fileName="Employees"
                  />
                  <Editing
                    mode="row"
                    allowAdding={false}
                    allowUpdating={true}
                    useIcons={true}
                  />
                  <Column
                    caption="First Name"
                    dataField="first_name"
                    alignment="center"
                    minWidth={150}
                  />

                  <Column
                    caption="Last Name"
                    dataField="last_name"
                    alignment="center"
                    minWidth={150}
                  />
                  
                  
                  <Column
                    caption="Email"
                    dataField="email"
                    alignment="center"
                    minWidth={150}
                  />
                  <Column
                    caption="Mobile"
                    dataField="mobile"
                    alignment="center"
                    format="(###)-###-####"
                    minWidth={150}
                  />
                  <Column
                    caption="Lead Source"
                    dataField="lead_source"
                    alignment="center"
                    minWidth={150}
                    visible={false}
                  />
                  <Column
                    caption="Investment"
                    alignment="center"
                    dataField="investment"
                    minWidth={150}
                  />
                  <Column
                    caption="Expected Profit "
                    alignment="center"
                    dataField="expected_profit"
                    minWidth={150}
                    visible={false}
                  />
                  
                  <Column
                    caption="Employement Type"
                    alignment="center"
                    dataField="employment_type"
                    minWidth={150}
                    visible={false}
                  />
                  <Column
                    caption="Lead Status"
                    alignment="center"
                    dataField="lead_status"
                    cellRender={renderGridStatus}
                    minWidth={150}
                    visible={false}
                  />
                  <Column
                    caption="Street"
                    alignment="center"
                    dataField="street"
                    minWidth={150}
                    visible={false}
                  />
                  <Column
                    caption="City"
                    alignment="center"
                    dataField="city"
                    minWidth={150}
                    visible={false}
                  />
                  <Column
                    caption="State"
                    alignment="center"
                    dataField="state"
                    minWidth={150}
                    visible={false}
                  />
                  <Column
                    caption="Zip Code"
                    alignment="center"
                    dataField="zip_code"
                    minWidth={150}
                    visible={false}
                  />
                  <Column
                    caption="Product"
                    alignment="center"
                    dataField="product"
                    minWidth={150}
                    visible={false}
                  />
                  <Column
                    caption="Assigned To"
                    alignment="center"
                    dataField="assigned_to"
                    minWidth={150}
                    visible={false}
                  />
                  <Column
                    caption="Aadhar Number"
                    alignment="center"
                    dataField="aadhar"
                    minWidth={150}
                    visible={false}
                  />
                  <Column
                    caption="Created Date"
                    alignment="center"
                    dataField="created_at"
                    minWidth={150}
                    visible={false}
                  />
                  <Column type="buttons" dataField="Actions" minWidth={150}>
                    <Button icon="edit" hint="Edit" onClick={updateEmployee} />
                    <Button hint="Delete" icon="trash" style={{ color: "red" }} onClick={deleteVisible} />
                  </Column>
                  <Toolbar>

                    <Item name="searchPanel" />
                    <Item name="exportButton" />
                    <Item name="columnChooserButton"
                      locateInMenu="auto" />
                    <Item location="after">
                      <MDButton variant="gradient" color="success" onClick={() => { navigate("/employees/create"); setUpdate(""); setPopupTitle("Add Employees"); }}>
                        Add&nbsp;
                        <Icon>add</Icon>&nbsp;
                      </MDButton>
                    </Item>
                  </Toolbar>

                </DataGrid>
                
              </MDBox>
            
         
       </Card>
      </MDBox>
      <Footer />
    </>
  );
}

export default MyLeads;
