import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material'
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { Button } from 'devextreme-react'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { delete_lookup } from 'Apis/config/Url';
import Caller from 'Apis/config/Caller';
import { DELETE, POST } from 'Apis/config/RequestType';
import { useSnackbar } from 'notistack';

const DeleteTemplate = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };

    const deleteData = async (event) => {
        event.target.classList.add("was-validated");
        event.preventDefault();
        event.stopPropagation();
        const formData = new FormData();
        formData.append("id", props.deleteData.id);
        formData.append("template_name", props.deleteData.template_name);

        await Caller(props.path, formData, POST, false, true).then((res) => {
            if (res.success === true) {
                enqueueSnackbar('Deleted successfully.', { variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
                props.refresh();
                props.close();
            }
        })
            .catch(error => { alert(JSON.stringify(error.message)); })

    }

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle id="alert-dialog-title">
                <MDBox
                    color="white"
                    bgColor="info"
                    variant="gradient"
                    borderRadius="lg"
                    shadow="lg"
                    opacity={1}
                    p={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                // textAlign="center"
                >
                    <MDTypography variant="h4" color="white" alignItems="center">Confirm Delete</MDTypography>
                    <IconButton
                        style={{ color: "white", cursor: "pointer" }}
                        sx={{
                            "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
                        }}
                        onClick={props.close}
                    >
                        <CloseIcon />
                    </IconButton>
                </MDBox>
            </DialogTitle>
            <DialogContent p={3}>
                <DialogContentText id="alert-dialog-description" fontWeight='fontWeightMedium'>
                    {props.deleteText}
                </DialogContentText>
            </DialogContent>
            <DialogActions >

                <MDButton color='success' className="action-button" onClick={deleteData}>Yes</MDButton>
                <MDButton color='warning' className="action-button" onClick={props.close}>No</MDButton>
            </DialogActions>
        </Dialog >
    )
}

export default DeleteTemplate