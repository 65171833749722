import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Chip, Divider, Typography } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import Caller from 'Apis/config/Caller';
import { lead_stage } from 'Apis/config/Url';
import { GET } from 'Apis/config/RequestType';
import { steps } from 'Apis/Auth/auth';
import { POST } from 'Apis/config/RequestType';
import { leads } from 'Apis/config/Url';
import { PUT } from 'Apis/config/RequestType';
import MDButton from 'components/MDButton';
import MDBadge from 'components/MDBadge';


const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <AssignmentIndIcon />,
        2: <PhoneCallbackIcon />,
        3: <ConnectWithoutContactIcon />,
        4: <VpnLockIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

export default function ProgressBar(props) {

    let updateData = props.updateData ?? "";

    const [activeStep, setActiveStep] = useState(0);
    const [stepLabelVisible, setStepLabelVisible] = useState(false);
    const [setBadgeContent, setSelectedBadgeContent] = useState("");
    const [reopenVisible, setReopenVisible] = useState(false);

    const handleActiveStepper = (e, index, sub) => {
        const formData = new FormData();
        if (reopenVisible === false) {
            setActiveStep(index);
            switch (index) {
                case 0:
                    setSelectedBadgeContent(sub ? sub : "Open");
                    formData.append('lead_status', "New Lead");
                    formData.append('lead_sub_status', sub ? sub : "Open");
                    break;
                case 1:
                    setSelectedBadgeContent(sub ? sub : "Callback");
                    formData.append('lead_status', "Follow Up");
                    formData.append('lead_sub_status', sub ? sub : "Callback");
                    break;
                case 2:
                    setSelectedBadgeContent(null);
                    formData.append('lead_status', "Interested");
                    formData.append('lead_sub_status', sub ? sub : "");
                    break;
                case 3:
                    formData.append('lead_status', "Closed");
                    formData.append('lead_sub_status', sub ? sub : "");
                    setReopenVisible(true);
                    break;
                default:
                    formData.append('lead_status', "Not Interested");
                    formData.append('lead_sub_status', sub ? sub : "");
                    break;
            }
            Caller(lead_stage + '?lead_guid=' + updateData.lead_guid, formData, POST, false, true).then((res) => {
                if (res.success === true) {
                    props.refresh();
                }
            });
        } else {
            e.stopPropagation();
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(-1);
        setReopenVisible(true);
        const formData = new FormData();
        formData.append('lead_status', "Not Interested");
        Caller(lead_stage + '?lead_guid=' + updateData.lead_guid, formData, POST, false, true).then(async (res) => {
            if (res.success === true) {
                props.refresh();
            }
        });
    };
    const handleClosed = () => {
        setActiveStep(2);
        const formData = new FormData();
        formData.append('lead_status', "Interested");
        Caller(lead_stage + '?lead_guid=' + updateData.lead_guid, formData, POST, false, true).then(async (res) => {
            if (res.success === true) {
                props.refresh();
            }
        });
    };

    const setLeadStatus = (lead_status) => {
        switch (lead_status) {
            case "Closed":
                setActiveStep(3);
                setReopenVisible(true);
                break;
            case "New Lead":
                setSelectedBadgeContent("Open");
                setActiveStep(0);
                break;
            case "Follow Up":
                setSelectedBadgeContent("Callback");
                setActiveStep(1);
                break;
            case "Interested":
                setSelectedBadgeContent("Interested");
                setActiveStep(2);
                break;
            case "Not Interested":
                setActiveStep(-1);
                setReopenVisible(true);
                break;
            default:
                break;
        }
    }

    const setLabel = (step) => {
        props.openCallSummary('', step === 2 ? "Interested" : setBadgeContent);
    }

    useEffect(() => {
        setLeadStatus(updateData.lead_status);
    }, [])

    useEffect(() => {
        if (props.subStatus.status || props.subStatus.status === 0) {
            handleActiveStepper("", props.subStatus.status, props.subStatus.sub_status);
        }
    }, [props])

    return (
        <Stack
            spacing={4}
            sx={{
                width: '100%'
            }}>
            <Stack direction="row" spacing={1} display="flex" justifyContent="space-between">
                <Stack direction="row" spacing={1}>
                    <Chip label={updateData.option_value} color="warning" style={{ color: "white" }} />
                    {/* <Chip label={updateData.investment ? '₹ ' + updateData.investment : '₹ 0'} color="primary" style={{ color: "white" }} /> */}
                    <Chip label={updateData.investment ? /^[0]+$/.test(updateData.investment) ? '₹ 0' : '₹ ' + updateData.investment : '₹ 0'} color="primary" style={{ color: "white" }} />
                    <Chip label={updateData.expected_profit ? '₹ ' + updateData.expected_profit : '₹ 0'} color="success" style={{ color: "white" }} avatar={<QueryStatsIcon style={{ color: "white" }} />} />
                </Stack>
                <Stack direction="row" spacing={1}>
                    {(!reopenVisible) && <><Chip label="Interested" color="info" style={{ color: "white" }} onClick={handleClosed} />
                        <Chip label="Not Interested" color="error" style={{ color: "white" }} onClick={handleReset} /></>}
                    {(reopenVisible) && <Chip label="Reopen" color="secondary" onClick={() => setReopenVisible(false)} />}
                </Stack>
            </Stack>
            <Stack
                direction="row"
                spacing={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Stepper
                    alternativeLabel
                    activeStep={activeStep}
                    style={{
                        background: "white",
                        boxShadow: "none",
                        width: "70%"
                    }}
                    connector={<ColorlibConnector />}
                >
                    {steps.map((label, index) => (
                        <Step
                            key={label}
                        >
                            <StepLabel
                                StepIconComponent={ColorlibStepIcon}
                                onClick={(e) => handleActiveStepper(e, index, null)}
                                style={{ cursor: (reopenVisible === false) ? "pointer" : "" }}
                                optional={(index === activeStep) && (index != 3) ? (<MDBadge badgeContent={setBadgeContent} variant="contained" container color="warning" size="xs" />) : null}
                            >{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {(activeStep !== -1 && activeStep != 3) && <MDButton variant="gradient" color="success" style={{ width: "20%" }} onClick={() => setLabel(activeStep)}>
                    FOLLOW UP
                </MDButton>}
            </Stack>
        </Stack >
    );
}