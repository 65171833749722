import React, { createContext, useState, useContext, useEffect } from "react";

import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import "./index.css";
import { SnackbarProvider, useSnackbar } from 'notistack';


// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <SnackbarProvider maxSnack={1}>
          <App />
        </SnackbarProvider>
      </MaterialUIControllerProvider>
    </BrowserRouter>
);
