/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable prettier/prettier */
import { Card, DialogActions, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Autocomplete, Button, Box, Typography } from '@mui/material'
import { Editor } from '@tinymce/tinymce-react'
import Caller from 'Apis/config/Caller'
import { POST } from 'Apis/config/RequestType'
import { GET } from 'Apis/config/RequestType'
// import { update_email_type } from 'Apis/config/Url'
// import { get_email_type } from 'Apis/config/Url'
import { getWhatsappTemplateType } from 'Apis/config/Url'
import { createTemplate } from 'Apis/config/Url'
import { updateTemplate } from 'Apis/config/Url'
import { getUploadSessionId } from 'Apis/config/Url'
import { getHandlerId } from 'Apis/config/Url'
import { uploadFile } from 'Apis/config/Url'
import { TINYMCE_KEY } from 'Apis/config/ResponseHandler';



import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState, useRef } from 'react'
import AttachmentIcon from '@mui/icons-material/Attachment';
import IconButton from '@mui/material/IconButton';
// import { ProgressBar } from 'react-loader-spinner';
import { ClipLoader } from 'react-spinners';
import { sendTemplate } from 'Apis/config/Url'
// import { ScrollView } from 'devextreme-react';

const ChatTemplates = (props) => {

  // console.log(props);

  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [templateFound, setTemplateFound] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const [uploadSessionid, setUploadSessionid] = useState("");
  const [mediaHandlerId, setMediaHandlerId] = useState("");
  const [imageId, setImageId] = useState("");
  // const [variableCounter, setVariableCounter] = useState(1);
  const [variableCounter, setVariableCounter] = useState(0);
  const [textFields, setTextFields] = useState([]);
  const [wordCount, setWordCount] = useState(0);
  const [patternCounter, setPatternCounter] = useState(0);
  const [compareArray, setCompareArray] = useState([]);
  const [charCounter, setCharCounter] = useState(0);
  const editorRef = useRef(null);
  const [editorWordCount, setEditorWordCount] = useState(0);
  const [editorCharacterCount, setEditorCharacterCount] = useState(0);

  // const [AddChat, setAddChat] = useState(false);
  const [values, setAllValues] = useState({
    // email_value_id: "",

    template_data: [],
    template_id: "",
    template_name: "",

    subject: "",
    body: "",

    footer: "",
    btn_name: "",
    calling_number: "",

    variable_value: [],
    url_link: "",
    url_btn_name: ""
    // manual_template_name: "",
    // AddChat: false
  })
  const [errors, setAllErrors] = useState({
    template_id: "",
    template_name: "",

    // manual_template_name: "",
    subject: "",
    footer: "",
    btn_name: "",
    calling_number: "",
    url_link: "",
    url_btn_name: ""

  })

  const [isInitialRender, setIsInitialRender] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Caller(getWhatsappTemplateType, "", GET, false, true);
        if (res.success === true) {
          await setAllValues((values) => ({
            ...values,
            template_data: res.data.data,
          }));
        }
        if (props.updateData) {
          await setAllValues((values) => ({
            ...values,

            template_id: props.updateData[0].id,
            template_name: props.updateData[0].template_name,
            body: props.updateData[0].body,
            footer: props.updateData[0].footer,
            btn_name: props.updateData[0].btn_name,
            calling_number: props.updateData[0].calling_number,
            url_link: props.updateData[0].url_link,
            url_btn_name: props.updateData[0].url_btn_name,
          }));
          setImageName(props.updateData[0].image_name)
          setImageId(props.updateData[0].image_id)
          setMediaHandlerId(props.updateData[0].media_handler_id)
          let variableArray = props.updateData[0].variable_value ? props.updateData[0].variable_value.split(',') : "";
          setCompareArray(variableArray)
          // get length of variableArray and set to the counter
          setVariableCounter(variableArray.length)

          setTextFields(variableArray)

        }
        setIsLoading(false); // Set loading to false when data is fetched successfully
      } catch (error) {
        console.error(error);
        // Handle any errors here
        setIsLoading(false); // Set loading to false on error as well
      }
    };

    if (isInitialRender) {
      setIsInitialRender(false);
      fetchData();
    }
  }, []);

  const textUpdate = (e) => {
    const re = /^[a-zA-Z0-9 ]+$/;
    if (re.test(e.target.value) || (e.target.value === "")) {
      setAllValues((state) => ({
        ...state,
        [`${e.target.name}`]: e.target.value
      }))
    }
    setAllErrors((values) => ({
      ...values,
      [`${e.target.name}`]: ''
    }));
  }

  const urlUpdate = (e) => {
    const re = /^[^\d\s]*$/;

    if (re.test(e.target.value) || (e.target.value === "")) {
      setAllValues((state) => ({
        ...state,
        [`${e.target.name}`]: e.target.value
      }))
    }
    setAllErrors((values) => ({
      ...values,
      [`${e.target.name}`]: ''
    }));
  }

  const footerTextUpdate = (e) => {
    const re = /^[a-zA-Z0-9 !@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/;

    if ((re.test(e.target.value) || (e.target.value === "")) && e.target.value.length < 60) {
      setAllValues((state) => ({
        ...state,
        [`${e.target.name}`]: e.target.value
      }))
    }
    setAllErrors((values) => ({
      ...values,
      [`${e.target.name}`]: ''
    }));
  }

  const callingNumber = (e) => {
    const re = /^[0-9]+$/;
    if (re.test(e.target.value) || (e.target.value === "")) {
      setAllValues((state) => ({
        ...state,
        [`${e.target.name}`]: e.target.value
      }))
    }
    setAllErrors((values) => ({
      ...values,
      [`${e.target.name}`]: ''
    }));
  }

  const textValue = (content, editor) => {
    // Check if the entered content ends with <p>&nbsp;</p>
    if (content.endsWith('<p>&nbsp;</p><p>&nbsp;</p>')) {

      // If it ends with <p>&nbsp;</p>, remove additional occurrences
      let cleanedString = content.substring(0, content.lastIndexOf('<p>&nbsp;</p>'));

      // Update the content state
      setAllValues((state) => ({
        ...state,
        body: cleanedString
      }));

    } else {

      // Split the string into an array of paragraphs
      const paragraphs = content.split('<p>&nbsp;</p>');

      // Filter out empty strings (resulting from consecutive <p>&nbsp;</p>)
      const filteredParagraphs = paragraphs.filter(p => p.trim() !== '');

      // Join the paragraphs back into a single string
      const cleanedString = filteredParagraphs.join('<p>&nbsp;</p>');

      // Update the content state
      setAllValues((state) => ({
        ...state,
        body: content
      }));

    }

    if (editorCharacterCount > 500) {
      enqueueSnackbar('Only 500 characters are allowed!', { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
    }

    const words = values.body.split(/\s+/);
    const count = words.filter(word => word !== '').length;
    setWordCount(count)

    const patternRegex = /{{\d+}}/g;
    let patternCount = [];
    patternCount = content.match(patternRegex);
    if (patternCount?.length ? patternCount?.length > 0 : patternCount != null) {

      if (!props.updateData) {
        setTextFields([]);
        for (let i = 1; i <= patternCount.length; i++) {
          setTextFields((variableCounter) => [...variableCounter, '']);
        }
      } else {
        if (compareArray.length <= patternCount.length || compareArray.length >= patternCount.length || compareArray.length !== patternCount.length) {
          setTextFields([]);
          for (let i = 1; i <= patternCount.length; i++) {
            setTextFields((variableCounter) => [...variableCounter, '']);
          }
        }

      }
    } else {
      setTextFields([]);
    }

    if (patternCount != null && patternCount.length >= 0) {
      setPatternCounter(patternCount.length);
      setVariableCounter(patternCount.length);
    } else {
      setPatternCounter(0);
      setVariableCounter(0);
    }
  };

  const selectAssignedTo = (e, newValue, clickType) => {
    setAllErrors((errors) => ({
      ...errors,
      assigned_to: ""
    }))
    if (clickType == 'clear') {
      setAllValues((values) => ({
        ...values,
        assigned_to_id: "",
        assigned_to_val: "",
      }));
    } else {
      let data = values.team_members?.find(o => o.employee_name == newValue);

      setAllValues((values) => ({
        ...values,
        assigned_to_value: newValue ? newValue : "",
        assigned_to_id: data ? data.employee_user_id : ''
      }));
      setAllErrors((errors) => ({
        ...errors,
        assigned_to_id: ""
      }))
    }

  }

  const handleChange = (event, newValue, clickType) => {

    if (clickType == 'clear') {
      setAllValues((values) => ({
        ...values,
        template_id: "",
        template_name: "",
        body: "",
        footer: "",
        btn_name: "",
        calling_number: "",
        url_link: "",
        url_btn_name: ""
      }));
    } else {
      let data = values.template_data?.find(o => o.template_name == newValue);
      let variable_array = data.variable_value ? data.variable_value.split(',') : null;
      setAllValues((values) => ({
        ...values,
        template_id: data ? data.id : "",
        template_name: newValue ? newValue : "",
        body: data ? data.body : "",

        footer: data ? data.footer : "",
        btn_name: data ? data.btn_name : "",
        calling_number: data ? data.calling_number : "",
        url_link: data ? data.url_link : "",
        url_btn_name: data ? data.url_btn_name : "",
        // variable_value: data ? data.variable_value : "",
        // variable_value: data ? variable_array : "",
      }));
      setAllErrors((errors) => ({
        ...errors,
        template_id: ""
      }))

      setImageId(data.image_id || "")
      setImageName(data.image_name)
      setTextFields(variable_array);

    }


    // Check if the newValue is not in the options
    let isExistingVal = values.template_data.find(option => option.template_name === newValue);
    if (isExistingVal) {
      setTemplateFound(true);
    } else {
      setTemplateFound(false);
    }

    // Check if the newValue is not in the options
    // let isExistingVal = values.whatsapp_group_name_array.find(option => option.whatsapp_group_name === newValue);
    // setShowStack(isExistingVal || !newValue ? false : true);
  };

  const handleInputChange = (event, newInputValue) => {

    const re = /^[a-z0-9_]+$/;
    if (re.test(newInputValue) || newInputValue === "") {
      setAllValues((values) => ({
        ...values,
        template_name: newInputValue,
      }));
    }

    let isExistingVal = values.template_data.find(option => option.template_name === newInputValue);
    if (isExistingVal) {
      setTemplateFound(true);
    } else {
      setTemplateFound(false);

    }
  };

  const addVariable = () => {
    //Increment the counter
    setVariableCounter((prevCounter) => {
      const newCounter = prevCounter + 1;

      let a = "{{" + newCounter + "}}";

      // Get the TinyMCE editor instance
      const editor = editorRef.current.editor;

      // Insert the text at the cursor position
      editor.execCommand('mceInsertContent', false, a);

      // Append a new TextField to the array
      // setTextFields((prevTextFields) => [...prevTextFields, '']);

      if (wordCount < 4 && newCounter === 1) {
        enqueueSnackbar('Message Length is too short to using Variable.', { variant: 'error', autoHideDuration: 3000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
      } else if (wordCount < 7 && newCounter === 2) {
        enqueueSnackbar('Message Length is too short to using Variable.', { variant: 'error', autoHideDuration: 3000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
      } else if (wordCount < 10 && newCounter === 3) {
        enqueueSnackbar('Message Length is too short to using Variable.', { variant: 'error', autoHideDuration: 3000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
      } else if (wordCount < 13 && newCounter === 4) {
        enqueueSnackbar('Message Length is too short to using Variable.', { variant: 'error', autoHideDuration: 3000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
      }

      return newCounter;
    });

  };

  const isAnotherFunctionDisabled = () => {
    const re = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/
    if ((wordCount < 4 && variableCounter == 1) || (wordCount < 7 && variableCounter == 2) || (wordCount < 10 && variableCounter == 3) || (wordCount < 13 && variableCounter == 4) || (values.body == "") || (values.template_name == "") || (editorCharacterCount > 500) || (values.calling_number && values.calling_number.length < 10) || (values.calling_number && values.btn_name == "") || (values.url_link && re.test(values.url_link) != true) || (values.url_link && values.url_btn_name == "")) {
      return true;
    } else {
      return false;
    }

  }

  const handleTextFieldChange = (index) => (event) => {
    const newTextFields = [...textFields];
    newTextFields[index] = event.target.value;
    setTextFields(newTextFields);
    if (props.setVariableValue) {
      props.setVariableValue(newTextFields);
    }
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {

      let file_length = file.size;
      let file_type = file.type;
      let file_name = file.name;

      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        enqueueSnackbar('Only jpeg or png is allowed !', { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });

        await setSelectedImage(null);
        await setImageName("");
        return;
      }

      if (file.size > 5000000) {
        enqueueSnackbar('Image File Should be Equal or Less Than 5MB !', { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });

        await setSelectedImage(null);
        await setImageName("");
        return;
      }

      let imageWidth;
      let imageHeight;

      // Create a new FileReader instance
      const reader = new FileReader();

      reader.onload = async function (e) {
        // Create a new Image instance
        const img = new Image();

        // When the image is loaded, get its width and height
        img.onload = async function () {

          imageWidth = this.width;
          imageHeight = this.height;

          if ((this.width > 1125 || this.height > 600)) {

            enqueueSnackbar('Width and Height Of Image Should be Equal or Less than 1125px by 600px !', { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
            await setSelectedImage(null);
            // Get file details
            await setImageName("");
            return;
          }

        };

        // Set the image source to the FileReader result
        img.src = e.target.result;
      };

      // Read the file as a data URL
      reader.readAsDataURL(file);

      // Set selected image
      setSelectedImage(URL.createObjectURL(file));
      // Get file details
      setImageName(file.name)

      const formData = new FormData();
      formData.append('file', file);

      await Caller(uploadFile, formData, POST, false, true).then(async (resData) => {
        setImageId(resData.data.data.id || "");
      });

      // get session id for file upload
      await Caller(getUploadSessionId + `?file_length=${file_length}&file_type=${file_type}&file_name=${file_name}`, " ", POST, false, true).then(async (resData) => {
        formData.append('session_id', resData.data.id);
        await Caller(getHandlerId, formData, POST, false, true).then(async (resData1) => {
          setMediaHandlerId(resData1.data.h);

        });
      });
    }

  };

  const saveTemplate = async (event) => {
    event.target.classList.add("was-validated");
    event.preventDefault();
    event.stopPropagation();
    if (!event.target.checkValidity()) {
      return false;
    }

    const formData = new FormData();

    formData.append("id", props.updateData ? props.updateData[0].id : "");
    formData.append("category", "MARKETING");

    formData.append("media_handler_id", mediaHandlerId || "");
    formData.append("image_name", imageName || "");
    formData.append("image_id", imageId || "");

    formData.append("type", "BODY");
    // formData.append("text", "You gain a new customer.");
    // Split the string into an array of paragraphs
    const paragraphs = values.body.split('<p>&nbsp;</p>');
    // Filter out empty strings (resulting from consecutive <p>&nbsp;</p>)
    const filteredParagraphs = paragraphs.filter(p => p.trim() !== '');
    // Join the paragraphs back into a single string
    let cleanedString = filteredParagraphs.join('<p>&nbsp;</p>');

    // formData.append("text", values.body);
    formData.append("text", cleanedString);
    formData.append("language_code", "en_US");
    formData.append("name", values.template_name);
    // formData.append("name", "new_customer");
    formData.append("sub_category", "ORDER_DETAILS");
    formData.append("footer", values.footer || "");
    formData.append("btn_name", values.btn_name || "");
    formData.append("calling_number", values.calling_number || "");
    formData.append("variables", textFields || "");
    formData.append("url_link", values.url_link || "");
    formData.append("url_btn_name", values.url_btn_name || "");
    formData.append("template_id", props.updateData ? props.updateData[0].template_id : "");

    if (props.updateData) {
      // updateTemplate

      await Caller(updateTemplate, formData, POST, false, true).then((res) => {
        if (res.success === true) {
          enqueueSnackbar('Message Template updated successfully!', { variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
          props.refresh();
          props.close();
        }
        else if (res.success === false) {
          for (const [key, value] of Object.entries(res.message['errors'])) {
            setAllErrors((values) => ({
              ...values,
              [`${key}`]: value,
            }))
          }
        }
      }).catch(error => { alert(JSON.stringify(error.message)); });
    } else if (props.chatData) {
      const formData1 = new FormData();
      formData1.append("components_type", "BODY");
      formData1.append("body_text", values.body);
      formData1.append("language_code", "en_US");
      formData1.append("template_name", values.template_name);
      formData1.append("media_type", "");
      formData1.append("media_link", "");
      formData1.append("image_id", imageId);
      formData1.append("variable_value", textFields);
      if (props.mobileArray.length > 0) {
        formData1.append("mobileArray", props.mobileArray);
      } else {
        formData1.append("mobile", props.chatData);
      }

      await Caller(sendTemplate, formData1, POST, false, true).then((res) => {
        if (res.success === false || res.success === null) {
          enqueueSnackbar('Message Couldn\'t Be Sent !', { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
        } else {
          props.setChat(values.body, res.data.template_id);
          props.onClose();
          props.sortContact();

        }
      })
    }
    else {
      await Caller(createTemplate, formData, POST, false, true).then((res) => {
        if (res.success === true) {
          enqueueSnackbar('WhatsApp Template Added Successfully!', { variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
          props.refresh();
          props.close();
        }
        else if (res.success === false) {
          // for (const [key, value] of Object.entries(res.message['errors'])) {
          //   setAllErrors((values) => ({
          //     ...values,
          //     [`${key}`]: value,
          //   }))
          // }

          enqueueSnackbar(res.data.error.error_user_msg, { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });

        }
      });
    }
  }


  useEffect(() => {
    if (editorRef.current && editorRef.current.editor) {
      const editor = editorRef.current.editor;

      // Create a custom status bar
      const statusBarContainer = document.createElement('div');
      statusBarContainer.id = 'status-bar-container';
      editor.getElement().parentNode.appendChild(statusBarContainer);

      // Update the status bar with word and character count
      const updateStatusBar = () => {
        const wordCount = editor.plugins.wordcount.getCount();
        const charCount = editor.getContent({ format: 'text' }).length;

        setEditorWordCount(wordCount)
        setEditorCharacterCount(charCount)
        // statusBarContainer.innerHTML = `Words: ${wordCount} | Characters: ${charCount}`;
      };

      // Listen for input events to update the status bar
      editor.on('init', updateStatusBar);
      editor.on('keyup', updateStatusBar);

      return () => {
        // Clean up event listeners when component unmounts
        editor.off('init', updateStatusBar);
        editor.off('keyup', updateStatusBar);
      };
    }
  }, [editorRef, values.body]);



  return (
    <>
      {isLoading ? (
        <div className='alert alert-light' style={{ padding: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <ClipLoader
            height="80"
            width="80"
            ariaLabel="progress-bar-loading"
            wrapperStyle={{}}
            wrapperClass="progress-bar-wrapper"
            borderColor='#F4442E'
            barColor='#51E5FF'
          />

        </div>
      ) : (
        <MDBox
          component="form"
          onSubmit={saveTemplate}

          sx={{
            '.role-select-custom': { width: "40%" },
            '.add-label': { pb: 1 },
          }}>

          <Grid container spacing={6} >

            <Grid item xs={12} >

              <MDBox sx={{ px: 3 }} >
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} >

                  <Grid item xs={12} sm={12} md={12}>
                    <InputLabel className='add-label'>Template Type <sup style={{ color: "red" }} >*</sup></InputLabel>
                    <Autocomplete
                      name="template_name"
                      value={values.template_name}
                      freeSolo
                      style={{ width: "17vw" }}
                      onChange={handleChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder='Select Template'
                          variant="outlined"
                          style={{ width: '29ch' }}
                        />
                      )}
                      error={!!errors.template_name}
                      options={values.template_data?.map((value) => {
                        return value.template_name ?? '';
                      })}
                      inputValue={values.template_name}
                      onInputChange={handleInputChange}
                      disabled={!!props.broadcastData}
                    />

                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <InputLabel className='add-label'>Template Header</InputLabel>
                    <div>
                      <input
                        type="file"
                        accept="image/*"
                        id="image-upload"
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                      />
                      <label htmlFor="image-upload">
                        <Button variant="contained" component="span" style={{ color: 'white' }} disabled={!!props.broadcastData}>
                          Choose Image
                        </Button>
                      </label>
                      {/* {selectedImage && <img src={selectedImage} alt="Selected" style={{ height: '200px', width: '300px',marginLeft: '150px' }} />} */}
                      {(selectedImage || imageName) && <p>Selected file: {imageName}</p>}
                    </div>

                  </Grid>


                  <Grid item xs={12} sm={12} md={12}>

                    <Button variant="contained" component="span" style={{ color: 'white', marginLeft: '770px'/*,marginBottom: '10px',float:'right'*/ }} disabled={!!props.broadcastData} onClick={addVariable} >
                      Add Variable
                    </Button>
                    <InputLabel className='add-label'>Template Body <sup style={{ color: "red" }}>*</sup></InputLabel>

                    <Editor
                      id="validationCustom03"
                      apiKey={TINYMCE_KEY}
                      required
                      name="body"
                      value={values.body}
                      disabled={!!(props.chatData || props.broadcastData)}
                      init={{
                        height: 500,
                        // setup: setupEditor,
                        // max_chars: 5,
                        plugins: 'advlist autolink autosave link image lists charmap print preview hr anchor pagebreak table searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking table contextmenu directionality emoticons template textcolor paste fullpage textcolor colorpicker textpattern wordcount ',
                        menubar: true,
                        directionality: "ltr",
                        // statusbar: false,
                        statusbar: true,
                        font_formats: 'Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n',
                        toolbar1:
                          "undo redo insert | blocks | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | fontfamily fontsize | forecolor backcolor emoticons codesample help | wordcount",
                      }}

                      onEditorChange={textValue}
                      ref={editorRef}
                    />

                    <Typography variant="paragraph" sx={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", color: '#7B809A', fontSize: '14px' }}>
                      Words : {editorWordCount} character : {editorCharacterCount}
                    </Typography>

                    <Box display="flex">
                      {textFields && textFields.map((value, index) => (
                        <TextField
                          key={index}
                          label={`Variable ${index + 1}`}
                          variant="outlined"
                          value={value}
                          onChange={handleTextFieldChange(index)}
                          style={{ marginRight: '10px', marginTop: '10px' }}
                          required
                        />
                      ))}
                    </Box>

                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <InputLabel className='add-label'>Template Footer</InputLabel>
                    <TextField
                      // required
                      id="outlined-required"
                      name='footer'
                      value={values.footer}
                      error={errors.footer}
                      helperText={errors.footer === "" ? '' : errors.footer}
                      style={{ width: "100%" }}
                      InputProps={{
                        inputProps: {
                          maxLength: 60, minLength: 1,
                          placeholder: "Enter Template Footer",
                        }
                      }}
                      disabled={!!(props.chatData || props.broadcastData)}
                      onChange={footerTextUpdate}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <InputLabel className='add-label'>Calling Number </InputLabel>
                    <TextField
                      // required
                      id="outlined-required"
                      name='calling_number'
                      value={values.calling_number}
                      error={errors.calling_number}
                      helperText={errors.calling_number === "" ? '' : errors.calling_number}
                      style={{ width: "100%" }}
                      InputProps={{
                        inputProps: {
                          maxLength: 10, minLength: 10,
                          placeholder: "Enter Mobile Number",
                        }
                      }}
                      disabled={!!(props.chatData || props.broadcastData)}
                      onChange={callingNumber}
                    />
                  </Grid>

                  {values.calling_number && <Grid item xs={12} sm={12} md={12}>
                    <InputLabel className='add-label'>Button Text <sup style={{ color: "red" }} >*</sup></InputLabel>
                    <TextField
                      // required
                      id="outlined-required"
                      name='btn_name'
                      value={values.btn_name}
                      error={errors.btn_name}
                      helperText={errors.btn_name === "" ? '' : errors.btn_name}
                      style={{ width: "100%" }}
                      disabled={!!(props.chatData || props.broadcastData)}
                      InputProps={{
                        inputProps: {
                          maxLength: 100, minLength: 1,
                          placeholder: "Enter Button Name",
                        }
                      }}
                      onChange={textUpdate}
                    />
                  </Grid>}

                  <Grid item xs={12} sm={12} md={12}>
                    <InputLabel className='add-label'>URL Link</InputLabel>
                    <TextField
                      // required
                      id="outlined-required"
                      name='url_link'
                      value={values.url_link}
                      error={errors.url_link}
                      helperText={errors.url_link === "" ? '' : errors.url_link}
                      style={{ width: "100%" }}
                      disabled={!!(props.chatData || props.broadcastData)}
                      InputProps={{
                        inputProps: {
                          maxLength: 250, minLength: 1,
                          placeholder: "Enter URL",
                        }
                      }}
                      onChange={urlUpdate}
                    />
                  </Grid>

                  {values.url_link && <Grid item xs={12} sm={12} md={12}>
                    <InputLabel className='add-label'>URL Button Name <sup style={{ color: "red" }} >*</sup></InputLabel>
                    <TextField
                      // required
                      id="outlined-required"
                      name='url_btn_name'
                      value={values.url_btn_name}
                      error={errors.url_btn_name}
                      helperText={errors.url_btn_name === "" ? '' : errors.url_btn_name}
                      style={{ width: "100%" }}
                      disabled={!!(props.chatData || props.broadcastData)}
                      InputProps={{
                        inputProps: {
                          maxLength: 100, minLength: 1,
                          placeholder: "Enter URL Button Name",
                        }
                      }}
                      onChange={textUpdate}
                    />
                  </Grid>}

                </Grid>

              </MDBox>
              {(props.broadcastData == undefined || !props.broadcastData) && <DialogActions
                sx={{
                  background: "linear-gradient(195deg, #dee1e1 10%, #f4f4f4 360%)",
                  mt: 2,
                  mb: 1,
                  ml: 1,
                  mr: 1,
                  borderRadius: '0.5rem',
                  '.action-button': { width: 100 }
                }}
              >
                <MDButton color='success' style={{ marginRight: "2%" }} type="submit" disabled={(props.chatData && !templateFound) || isAnotherFunctionDisabled()} >{props.updateData ? "Update" : (props.chatData ? "Send Message" : "Add")}</MDButton>
                {/* <MDButton color='success' style={{ marginRight: "2%" }} type="submit" disabled={isAnotherFunctionDisabled(true)} >{props.updateData ? "Update" : (props.chatData ? "Send Message" : "Add")}</MDButton> */}
              </DialogActions>}
              {/* </Card> */}
            </Grid>
          </Grid>

        </MDBox>
      )}
    </>
  )
}

export default ChatTemplates