/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
/* eslint-disable prettier/prettier */
import { ExpandMore } from "@mui/icons-material";
import {
  Box,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import Caller from "Apis/config/Caller";
import { PUT } from "Apis/config/RequestType";
import { GET, POST } from "Apis/config/RequestType";
import { get_related_type_designation } from "Apis/config/Url";
import { update_lookup } from "Apis/config/Url";
import { add_calls } from "Apis/config/Url";
import { edit_calls } from "Apis/config/Url";

import { expiry_date } from "Apis/config/Url";
import { get_symbols } from "Apis/config/Url";
import { live_price } from "Apis/config/Url";
import { getSymbols } from 'Apis/Auth/auth';


import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

import "./styles.css";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "10vw",
    },
  },
};

/// whenever, there will be a need to reset the value. simply, It can be called. 
const initialSetAllValues = {
  product: "OPTION",
  call_type: "INTRADAY",
  stock_code: "none",
  buy_sell: "BUY",
  price: "",
  expiry_date: "none",
  option_name: "none",
  strike_price: "none",
  target_1: "",
  target_2: "",
  target_3: "",
  is_target_1_acheived: "",
  is_target_2_acheived: "",
  is_target_3_acheived: "",
  stop_loss: "",
  is_stop_loss_achieved: "",
  is_app: "true",
  created_at: "",
  updated_at: "",
  checkExpiryDisabled: false
}

const AddUpdateCalls = (props) => {
  const [visible, setVisible] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [disabledSave, setDisabledSave] = useState();
  const [values, setAllValues] = useState(initialSetAllValues);

  //expiry_date data
  const [expiry, setExpiry] = useState({
    ex_date: [],
    exp_monthThrusday: [],
    exp_monthTuesday: [],
    exp_weekThrusday: [],
    exp_weekTuesday: [],
    selected_expiryList: [],
  });

  const [symbols, setSymbols] = useState({
    symbols: [],
  });

  const [strikes_list, setStrikeList] = useState({
    strikes: [],
  });

  const [errors, setAllErrors] = useState({
    product: "",
    call_type: "",
    stock_code: "",
    buy_sell: "",
    price: "",
    expiry_date: "",
    option_name: "",
    strike_price: "",
    target_1: "",
    target_2: "",
    target_3: "",
    is_target_1_acheived: "",
    is_target_2_acheived: "",
    is_target_3_acheived: "",
    stop_loss: "",
    is_stop_loss_achieved: "",
    is_app: "",
    created_at: "",
    updated_at: "",
  });

  const textUpdate = (e) => {
    const re = /^[a-zA-Z0-9 ]+$/;
    if (re.test(e.target.value) || e.target.value === "") {
      setAllValues((state) => ({
        ...state,
        [`${e.target.name}`]: e.target.value,
      }));
    }
    setAllErrors((values) => ({
      ...values,
      [`${e.target.name}`]: "",
    }));
  };



  const priceUpdate = (f) => {
    // const re = /^\d+$/;   //original
    const re = /^\d{1,}(\.\d{0,2})?$/;

    var charLength = f.target.value.length;
    if (re.test(f.target.value) || f.target.value === "") {
      setAllValues((state) => ({
        ...state,
        [`${f.target.name}`]: f.target.value,
      }));
    }
    setAllErrors((values) => ({
      ...values,
      [`${f.target.name}`]: "",
    }));
  };

  const digitsUpdate = (f) => {
    // const re = /^\d+$/;   //original
    const re = /^\d{1,}(\.\d{0,2})?$/;
    var charLength = f.target.value.length;
    if (re.test(f.target.value) || f.target.value === "") {
      setAllValues((state) => ({
        ...state,
        [`${f.target.name}`]: f.target.value,
      }));
    }
    setAllErrors((values) => ({
      ...values,
      [`${f.target.name}`]: "",
    }));
  };


  const [isInitialRender, setIsInitialRender] = useState(true);
  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      if (props.updateData) {
        setAllValues((values) => ({
          ...values,
          // name: props.updateData[0].name,
          // description: props.updateData[0].description
          product: props.updateData.product,
          call_type: props.updateData.call_type,
          stock_code: props.updateData.stock_code,
          buy_sell: props.updateData.buy_sell,
          price: props.updateData.price,
          expiry_date: props.updateData.expiry_date,
          option_name: props.updateData.option_name,
          strike_price: props.updateData.strike_price,
          target_1: props.updateData.target_1,
          target_2: props.updateData.target_2,
          target_3: props.updateData.target_3,
          stop_loss: props.updateData.stop_loss,
          is_app: props.updateData.is_app === 1 ? "true" : "false",
        }));
      }

      Caller(expiry_date, "", GET, false, true).then(async (res) => {
        if (res.success === true) {

          var expiry_date_from_saved_data;

          if (props.updateData.stock_code == "FINNIFTY" && props.updateData.product == "FUTURE") {
            expiry_date_from_saved_data = res.data.exp_monthTuesday

          } else if (props.updateData.stock_code != "FINNIFTY" && props.updateData.product == "FUTURE") {
            expiry_date_from_saved_data = res.data.exp_monthThrusday

          }
          if (props.updateData.stock_code == "FINNIFTY" && props.updateData.product == "OPTION") {
            expiry_date_from_saved_data = res.data.exp_weekTuesday
          } else if (props.updateData.stock_code.includes("NIFTY") && props.updateData.product == "OPTION") {
            expiry_date_from_saved_data = res.data.exp_weekThrusday
          } else if (!props.updateData.stock_code.includes("NIFTY") && props.updateData.product == "OPTION") {
            expiry_date_from_saved_data = res.data.exp_monthThrusday
          }

          await setExpiry((values) => ({
            ...values,
            exp_monthThrusday: res.data.exp_monthThrusday,
            exp_monthTuesday: res.data.exp_monthTuesday,
            exp_weekThrusday: res.data.exp_weekThrusday,
            exp_weekTuesday: res.data.exp_weekTuesday,
            selected_expiryList: expiry_date_from_saved_data
          }));
        }
      });

      Caller(get_symbols, "", GET, false, true).then(async (res) => {
        if (res.success === true) {
          await setSymbols((values) => ({
            ...values,
            symbols: res.data,
          }));
        }
      });
      Caller(get_symbols + `?params=strike_list&symbol=${props.updateData.stock_code}`, '', GET, false, true).then(async (res) => {
        if (res.success === true) {
          await setStrikeList((values) => ({
            ...values,
            strikes: res.data,
          }));
        }
      });

    }

    if (values.product == "OPTION") {
      Caller(expiry_date, "", GET, false, true).then((res) => {
        if (res.success === true) {
          setExpiry((values) => ({
            ...values,
            ex_date: res.data,
            exp_monthThrusday: res.data.exp_monthThrusday,
            exp_monthTuesday: res.data.exp_monthTuesday,
            exp_weekThrusday: res.data.exp_weekThrusday,
            exp_weekTuesday: res.data.exp_weekTuesday,
          }));
        }
      });
    }

  }, []);

  const productOption = [
    { key: "EQUITY", value: "EQUITY" },
    { key: "FUTURE", value: "FUTURE" },
    // { key: "OPTION", value: "OPTION" },
  ];

  const callTypeOption = [
    // { key: "INTRADAY", value: "INTRADAY" },
    { key: "DELIVERY", value: "DELIVERY" },
  ];

  const buySellOption = [
    // { key: "BUY", value: "BUY" },
    { key: "SELL", value: "SELL" },
  ];

  const optionOption = [
    { key: "CE", value: "CE" },
    { key: "PE", value: "PE" },
  ];

  const isAppOption = [
    // { key: "YES", value: "true" },
    { key: "NO", value: "false" },
  ];

  const handleOptionTypeChange = async (e) => {

    /// Switch case for all dropdowns
    // switch (e.target.name) {

    //   case "product": {

    //     if (e.target.value != values.exchange) {
    //       setAllValues(initialSetAllValues);
    //     }
    //     // setAllValues((values) => ({ ...values, expiry_date: "none", stock_code:"none" }));

    //     //check value of product is future and hit expiry_date api
    //     if (true || e.target.value == "FUTURE") {  // all time should be true. No condition applied
    //       Caller(expiry_date, "", GET, false, true).then((res) => {
    //         if (res.success === true) {
    //           setExpiry((values) => ({
    //             ...values,
    //             ex_date: res.data,
    //             exp_monthThrusday: res.data.exp_monthThrusday,
    //             exp_monthTuesday: res.data.exp_monthTuesday,
    //             exp_weekThrusday: res.data.exp_weekThrusday,
    //             exp_weekTuesday: res.data.exp_weekTuesday,
    //           }));
    //         }
    //       });

    //     }
    //     await setAllValues((values) => ({ ...values, product: e.target.value }));
    //     break;
    //   }
    //   case "stock_code": {

    //     if (e.target.value != values.exchange) {
    //       setAllValues((values) => ({ ...values, expiry_date: "none", price: "" }));
    //     }

    //     //IF value of product choosn as "option"
    //     if (values.product == "OPTION") {
    //       /// calling "/symbols/?params=strike_list&symbol=${e.target.value}" will return strike price related to selected Symbol
    //       await Caller(get_symbols + `?params=strike_list&symbol=${e.target.value}`, '', GET, false, true).then(async (res) => {
    //         if (res.success === true) {
    //           await setStrikeList((values) => ({
    //             ...values,
    //             strikes: res.data,
    //           }));
    //           // await setStrikeList((values) => ({
    //           //   ...values,
    //           //   strikes: res.data,
    //           // }));
    //         } else {
    //           await setStrikeList((values) => ({
    //             ...values,
    //             strikes: [],
    //           }));
    //         }
    //       });
    //     }

    //     setAllValues((values) => ({ ...values, expiry_date: "none", strike_price: "none", checkExpiryDisabled: false }));

    //     // if stock_Code = none
    //     if (e.target.value == "none") {
    //       setAllValues((values) => ({ ...values, expiry_date: "none", checkExpiryDisabled: true }));
    //     }

    //     if (e.target.value == "FINNIFTY" && values.product == "FUTURE") {

    //       setExpiry((values) => ({
    //         ...values,
    //         selected_expiryList: expiry.exp_monthTuesday
    //       }))
    //     } else if (e.target.value != "FINNIFTY" && values.product == "FUTURE") {
    //       setExpiry((values) => ({
    //         ...values,
    //         selected_expiryList: expiry.exp_monthThrusday
    //       }))
    //     }


    //     if (e.target.value == "FINNIFTY" && values.product == "OPTION") {
    //       setExpiry((values) => ({
    //         ...values,
    //         selected_expiryList: expiry.exp_weekTuesday
    //       }))
    //     } else if (e.target.value.includes("NIFTY") && values.product == "OPTION") {
    //       setExpiry((values) => ({
    //         ...values,
    //         selected_expiryList: expiry.exp_weekThrusday
    //       }))
    //     } else if (!e.target.value.includes("NIFTY") && values.product == "OPTION") {

    //       setExpiry((values) => ({
    //         ...values,
    //         selected_expiryList: expiry.exp_monthThrusday
    //       }))
    //     }
    //     await setAllValues((values) => ({ ...values, stock_code: e.target.value }));
    //     break;
    //   }
    //   case "call_type": {
    //     await setAllValues((values) => ({ ...values, call_type: e.target.value }));
    //     break;
    //   }
    //   case "buy_sell": {
    //     await setAllValues((values) => ({ ...values, buy_sell: e.target.value }));
    //     break;
    //   }
    //   case "expiry_date": {

    //     // if (values.product == "FUTURE") {
    //     if (e.target.value == "none") {
    //       setAllValues((values) => ({
    //         ...values,
    //         price: "",
    //       }));
    //     } else {

    //       let symbol_data = symbols.symbols;

    //       let path_result = symbol_data.find(o =>
    //         o.symbol === values.stock_code && o.type === (values.product === "OPTION" ? "OPTIDX" : (values.product === "FUTURE" ? "FUTIDX" : (values.product === "EQUITY" ? "STOCK" : "")))
    //       )

    //       if (path_result) {
    //         let path = path_result.path;
    //         let final_path = path.slice(0, -7) + e.target.value.toUpperCase();

    //         setAllValues((values) => ({ ...values, price: "", }));
    //         // await Caller(live_price + "?path=" + 'NSE_FUTIDX_NIFTY_27JUL2023.json', '', GET, false, true).then(async (res) => {
    //         await Caller(live_price + "?path=" + `${final_path}.json`, '', GET, false, true).then((res) => {
    //           if (res.success === true) {
    //             setAllValues((values) => ({
    //               ...values,
    //               price: res.data.LTP,
    //             }));
    //           } else {
    //             setAllValues((values) => ({
    //               ...values,
    //               price: ""
    //             }));
    //           }
    //         });
    //       } else {
    //         enqueueSnackbar("Right now! We don't have live data for this script.", {
    //           variant: "warning",
    //           autoHideDuration: 2000,
    //           TransitionProps: { direction: "left" },
    //           anchorOrigin: { horizontal: "right", vertical: "top" },
    //         });

    //         setAllValues((values) => ({
    //           ...values,
    //           price: "",
    //         }));
    //       }

    //     }
    //     // }


    //     await setAllValues((values) => ({ ...values, expiry_date: e.target.value }));
    //     break;
    //   }
    //   case "option_name": {
    //     await setAllValues((values) => ({ ...values, option_name: e.target.value }));
    //     break;
    //   }
    //   case "is_app": {
    //     await setAllValues((values) => ({ ...values, is_app: e.target.value }));
    //     break;
    //   }

    //   case "strike_price": {
    //     await setAllValues((values) => ({ ...values, strike_price: e.target.value }));
    //     break;
    //   }
    // }

  };

  /// Sending response
  const addLookup = async (event) => {
    event.target.classList.add("was-validated");
    setDisabledSave("disabled");
    event.preventDefault();
    event.stopPropagation();
    const formData = new FormData();

    formData.append("product", values.product);
    formData.append("call_type", values.call_type);
    formData.append("stock_code", values.stock_code);
    formData.append("buy_sell", values.buy_sell);
    formData.append("price", values.price);

    if (values.product == "FUTURE" || values.product == "OPTION") {
      formData.append("expiry_date", values.expiry_date);
    } else {
      formData.append("expiry_date", null);
    }

    if (values.product == "OPTION") {
      formData.append("option_name", values.option_name);
      formData.append("strike_price", values.strike_price);
    } else {
      formData.append("option_name", null);
      formData.append("strike_price", null);
    }

    formData.append("target_1", values.target_1);
    formData.append("target_2", values.target_2);
    formData.append("target_3", values.target_3);
    formData.append("stop_loss", values.stop_loss);
    formData.append("is_app", values.is_app);

    /// If required fields are empty and user want to sent by editing inspect
    /// from browser then It will return to false response.
    if (allTrue()) {
      return false;
    }

    if (props.updateData) {
      await Caller(edit_calls + "?id=" + props.updateData.id, formData, POST, false, true)
        .then((res) => {
          if (res.success === true) {
            enqueueSnackbar("Data Updated Successfully!", {
              variant: "success",
              autoHideDuration: 2000,
              TransitionProps: { direction: "left" },
              anchorOrigin: { horizontal: "right", vertical: "top" },
            });
            props.refresh();
            props.close();
          } else if (res.success === false) {
            setDisabledSave("");
            for (const [key, value] of Object.entries(res.message["errors"])) {
              setAllErrors((values) => ({
                ...values,
                [`${key}`]: value,
              }));
            }
          }
        })
        .catch((error) => {
          alert(JSON.stringify(error.message));
        });
    } else {
      await Caller(add_calls, formData, POST, false, true)
        .then((res) => {
          if (res.success === true) {
            enqueueSnackbar("Added successfully.", {
              variant: "success",
              autoHideDuration: 2000,
              TransitionProps: { direction: "left" },
              anchorOrigin: { horizontal: "right", vertical: "top" },
            });
            props.refresh();
            props.close();
          } else if (res.success === false) {
            setDisabledSave("");
            for (const [key, value] of Object.entries(res.message["errors"])) {
              setAllErrors((values) => ({
                ...values,
                [`${key}`]: value,
              }));
            }
          }
        })
        .catch((error) => {
          alert(JSON.stringify(error.message));
        });
    }
  };

  /// validating all required fields
  const allTrue = () => {
    if (
      values.product !== "none" &&
      values.call_type !== "none" &&
      values.stock_code !== "none" &&
      values.buy_sell !== "none" &&
      values.price &&
      (values.product !== "EQUITY" ? values.expiry_date !== "none" : true) &&
      (values.product == "OPTION" ? values.option_name !== "none" : true) &&
      (values.product == "OPTION" ? values.strike_price !== "none" : true) &&
      values.target_1 &&
      values.stop_loss &&
      (values.is_app == "false" || values.is_app == "true" || values.is_app != "none")
    ) {
      return false;
    } else {
      return true;
    }
  };


  return (
    <Box
      component="form"
      onSubmit={addLookup}
      sx={{
        width: 750,
        height: 430,

        "#demo-row-radio-buttons-group-label": { fontSize: "medium", textAlign: "left" },
        ".role-select-custom": { width: "100%" },
        ".add-label": { pb: 1 },
        ".radio-button-custom": { fontSize: "small" },
      }}
      noValidate
    >
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid
          item
          xs={2}
          sm={4}
          md={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <InputLabel className="add-label">Products :<sup style={{ color: "red" }}>*</sup></InputLabel>
          <Select
            name="product"
            IconComponent={() => <ExpandMore className="dropdown-arrow" />}
            style={{
              width: "60%",
              padding: "10px",
              height: "3.3em",
              borderRadius: "5px",
              marginBottom: "20px",
            }}
            displayEmpty
            defaultValue={props.updateData.product ?? "EQUITY"}
            value={values.product}
            onChange={handleOptionTypeChange}
            required
            MenuProps={MenuProps}
          >
            <MenuItem value="OPTION">OPTION</MenuItem>
            {productOption?.map((e, key) => {
              return (
                <MenuItem key={key} value={e.value}>
                  {e.key}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>

        <Grid
          item
          xs={2}
          sm={4}
          md={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <InputLabel className="add-label">
            Script Name:<sup style={{ color: "red" }}>*</sup>
          </InputLabel>
          <Select
            name="stock_code"
            IconComponent={() => <ExpandMore className="dropdown-arrow" />}
            style={{ width: "60%", padding: "10px", height: "3.3em", borderRadius: "5px", marginBottom: "20px", }}
            displayEmpty
            // disabled={props.updateData ? true : false}
            defaultValue={props.updateData.stock_code ?? "none"}
            value={values.stock_code}
            onChange={handleOptionTypeChange}
            required
            MenuProps={MenuProps}
          >
            <MenuItem value="none">--Select--</MenuItem>

            {symbols.symbols?.map((value, key) => {
              return (
                <MenuItem key={key.id} value={value.symbol}>
                  {value.symbol}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>

        <Grid
          item
          xs={2}
          sm={4}
          md={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={
            values.product == "FUTURE" || values.product == "OPTION"
              ? "element-visible"
              : "element-hidden"
          }
        >
          <InputLabel className="add-label">
            Expiry Date :<sup style={{ color: "red" }}>*</sup>
          </InputLabel>
          <Select
            name="expiry_date"
            IconComponent={() => <ExpandMore className="dropdown-arrow" />}
            style={{ width: "60%", padding: "10px", height: "3.3em", borderRadius: "5px", marginBottom: "20px", }}
            displayEmpty
            // disabled={props.updateData ? true : false}
            defaultValue={props.updateData.expiry_date ?? "none"}
            value={values.expiry_date}
            onChange={handleOptionTypeChange}
            // required
            MenuProps={MenuProps}
            //disable dropdown when value of stock_code is none.
            disabled={values.checkExpiryDisabled}
          >
            <MenuItem value="none">--Select--</MenuItem>
            {expiry.selected_expiryList?.map((value, key) => {
              return (
                <MenuItem key={key} value={value}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>

      </Grid>

      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {/* <Grid item xs={2} sm={4} md={4}> */}
        <Grid
          item
          xs={2}
          sm={4}
          md={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <InputLabel className="add-label">
            Call Type :<sup style={{ color: "red" }}>*</sup>
          </InputLabel>

          <Select
            name="call_type"
            IconComponent={() => <ExpandMore className="dropdown-arrow" />}
            style={{ width: "60%", padding: "10px", height: "3.3em", borderRadius: "5px" }}
            displayEmpty
            // disabled={props.updateData ? true : false}
            defaultValue={props.updateData.call_type ?? "none"}
            value={values.call_type}
            onChange={handleOptionTypeChange}
            required
            MenuProps={MenuProps}
          >
            <MenuItem value="INTRADAY">INTRADAY</MenuItem>
            {callTypeOption?.map((e, key) => {
              return (
                <MenuItem key={key} value={e.value}>
                  {e.key}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>

        {/* <Grid
          item
          xs={2}
          sm={4}
          md={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <InputLabel className="add-label">
            Script Name:<sup style={{ color: "red" }}>*</sup>
          </InputLabel>
          <Select
            name="stock_code"
            IconComponent={() => <ExpandMore className="dropdown-arrow" />}
            style={{ width: "60%", padding: "10px", height: "3.3em", borderRadius: "5px" }}
            displayEmpty
            // disabled={props.updateData ? true : false}
            defaultValue={props.updateData.stock_code ?? "none"}
            value={values.stock_code}
            onChange={handleOptionTypeChange}
            required
            MenuProps={MenuProps}
          >
            <MenuItem value="none">--Select--</MenuItem>

            {symbols.symbols?.map((value, key) => {
              return (
                <MenuItem key={key.id} value={value.symbol}>
                  {value.symbol}
                </MenuItem>
              );
            })}
          </Select>
        </Grid> */}

        <Grid
          item
          xs={2}
          sm={4}
          md={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <InputLabel className="add-label">
            Buy Sell :<sup style={{ color: "red" }}>*</sup>
          </InputLabel>
          <Select
            name="buy_sell"
            IconComponent={() => <ExpandMore className="dropdown-arrow" />}
            style={{ width: "60%", padding: "10px", height: "3.3em", borderRadius: "5px" }}
            displayEmpty
            // disabled={props.updateData ? true : false}
            defaultValue={props.updateData.buy_sell ?? "none"}
            value={values.buy_sell}
            onChange={handleOptionTypeChange}
            required
            MenuProps={MenuProps}
          >
            <MenuItem value="BUY">BUY</MenuItem>
            {buySellOption?.map((e, key) => {
              return (
                <MenuItem key={key} value={e.value}>
                  {e.key}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>

        <Grid
          item
          xs={2}
          sm={4}
          md={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <InputLabel className="add-label">
            Price :<sup style={{ color: "red" }}>*</sup>
          </InputLabel>
          <TextField
            required
            id="outlined-required"
            name="price"
            // type="number"
            value={values.price}
            error={errors.price}
            helperText={errors.price === "" ? "" : errors.price}
            style={{
              width: "60%",
              overflow: "hidden",
            }}
            onFocus={(e) =>
              e.target.addEventListener(
                "wheel",
                function (e) {
                  e.preventDefault();
                },
                { passive: false }
              )
            }
            inputProps={{ maxLength: 14 }}
            onChange={priceUpdate}
          />
        </Grid>

        <>
          <style>
            {`
            .element-visible { display: inline-flex }
            .element-hidden { display: none }
            `}
          </style>

          <Grid
            item
            xs={2}
            sm={4}
            md={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={values.product == "OPTION" ? "element-visible" : "element-hidden"}
          >
            <InputLabel className="add-label">
              Option :<sup style={{ color: "red" }}>*</sup>
            </InputLabel>
            <Select
              name="option_name"
              IconComponent={() => <ExpandMore className="dropdown-arrow" />}
              style={{ width: "60%", padding: "10px", height: "3.3em", borderRadius: "5px" }}
              displayEmpty
              // disabled={props.updateData ? true : false}
              defaultValue={props.updateData.option_name ?? "none"}
              value={values.option_name}
              onChange={handleOptionTypeChange}
              // required
              MenuProps={MenuProps}
            >
              <MenuItem value="none">--Select--</MenuItem>
              {optionOption?.map((e, key) => {
                return (
                  <MenuItem key={key} value={e.value}>
                    {e.key}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>

          <Grid
            item
            xs={2}
            sm={4}
            md={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={values.product == "OPTION" ? "element-visible" : "element-hidden"}
          >
            <InputLabel className="add-label">
              Strike Rate :<sup style={{ color: "red" }}>*</sup>
            </InputLabel>
            <Select
              name="strike_price"
              IconComponent={() => <ExpandMore className="dropdown-arrow" />}
              style={{ width: "60%", padding: "10px", height: "3.3em", borderRadius: "5px" }}
              displayEmpty
              // disabled={props.updateData ? true : false}
              defaultValue={props.updateData.strike_price ?? "none"}
              value={values.strike_price}
              onChange={handleOptionTypeChange}
              required
              MenuProps={MenuProps}
            >
              <MenuItem value="none">--Select--</MenuItem>

              {strikes_list.strikes.sort(function (a, b) { return b - a })?.map((value, key) => {
                return (
                  <MenuItem key={key} value={value}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
            {/* <TextField
              // required
              id="outlined-required"
              name="strike_price"
              // type="number"
              defaultValue={props.updateData.option_name}
              value={values.strike_price}
              error={errors.strike_price}
              helperText={errors.strike_price === "" ? "" : errors.strike_price}
              style={{ width: "60%" }}
              inputProps={{ maxLength: 9 }}
              onChange={digitsUpdate}
            /> */}
          </Grid>
        </>

        <Grid
          item
          xs={2}
          sm={4}
          md={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <InputLabel className="add-label">
            Target 1 :<sup style={{ color: "red" }}>*</sup>
          </InputLabel>
          <TextField
            required
            id="outlined-required"
            name="target_1"
            // type="number"
            value={values.target_1}
            error={errors.target_1}
            helperText={errors.target_1 === "" ? "" : errors.target_1}
            style={{ width: "60%" }}
            inputProps={{ maxLength: 9 }}
            onChange={digitsUpdate}
          />
        </Grid>

        <Grid
          item
          xs={2}
          sm={4}
          md={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <InputLabel className="add-label">Target 2 :</InputLabel>
          <TextField
            id="outlined-required"
            name="target_2"
            // type="number"
            value={values.target_2}
            error={errors.target_2}
            helperText={errors.target_2 === "" ? "" : errors.target_2}
            style={{ width: "60%" }}
            onFocus={(e) =>
              e.target.addEventListener(
                "wheel",
                function (e) {
                  e.preventDefault();
                },
                { passive: false }
              )
            }
            inputProps={{ maxLength: 9 }}
            onChange={digitsUpdate}
          />
        </Grid>

        <Grid
          item
          xs={2}
          sm={4}
          md={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <InputLabel className="add-label">Target 3 :</InputLabel>
          <TextField
            id="outlined-required"
            name="target_3"
            // type="number"
            value={values.target_3}
            error={errors.target_3}
            helperText={errors.target_3 === "" ? "" : errors.target_3}
            style={{ width: "60%" }}
            onFocus={(e) =>
              e.target.addEventListener(
                "wheel",
                function (e) {
                  e.preventDefault();
                },
                { passive: false }
              )
            }
            inputProps={{ maxLength: 9 }}
            onChange={digitsUpdate}
          />
        </Grid>

        <Grid
          item
          xs={2}
          sm={4}
          md={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <InputLabel className="add-label">
            Stop Loss :<sup style={{ color: "red" }}>*</sup>
          </InputLabel>
          <TextField
            required
            id="outlined-required"
            name="stop_loss"
            // type="number"
            value={values.stop_loss}
            error={errors.stop_loss}
            helperText={errors.stop_loss === "" ? "" : errors.stop_loss}
            style={{ width: "60%" }}
            onFocus={(e) =>
              e.target.addEventListener(
                "wheel",
                function (e) {
                  e.preventDefault();
                },
                { passive: false }
              )
            }
            inputProps={{ maxLength: 9 }}
            onChange={digitsUpdate}
          />
        </Grid>

        <Grid
          item
          xs={2}
          sm={4}
          md={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <InputLabel className="add-label">
            Is App :<sup style={{ color: "red" }}>*</sup>
          </InputLabel>
          <Select
            name="is_app"
            IconComponent={() => <ExpandMore className="dropdown-arrow" />}
            style={{ width: "60%", padding: "10px", height: "3.3em", borderRadius: "5px" }}
            displayEmpty
            // disabled={props.updateData ? true : false}
            defaultValue={
              props.updateData.is_app == 1
                ? "true"
                : props.updateData.is_app == 0
                  ? "false"
                  : "none"
            }
            value={values.is_app}
            onChange={handleOptionTypeChange}
            required
            MenuProps={MenuProps}
          >
            <MenuItem value="true">YES</MenuItem>
            {isAppOption?.map((e, key) => {
              return (
                <MenuItem key={key} value={e.value}>
                  {e.key}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>

        <DialogActions
          sx={{
            background: "linear-gradient(195deg, #dee1e1 10%, #f4f4f4 360%)",
            // marginTop: "10%",
            marginTop: (values.product == "EQUITY" || values.product == "FUTURE") ? "10%" : "1%",
            marginBottom: "0%",
            width: "104%",
            borderRadius: "0.5rem",
            transform: "translateY(35%)",
            ".action-button": { width: 100 },
          }}
        >
          <MDButton
            color="success"
            className={`action-button ${disabledSave}`}
            type="submit"
            disabled={allTrue()}
          >
            {props.updateData ? "Update" : "Add"}
          </MDButton>
          <MDButton color="warning" className="action-button" onClick={props.close}>
            Cancel
          </MDButton>
        </DialogActions>
      </Grid>
    </Box >
  );
};

export default AddUpdateCalls;
