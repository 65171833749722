import { alpha, Box, Button, Card, CardContent, CardHeader, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Menu, MenuItem, Modal, setRef, Slide, Stack } from '@mui/material'
import theme from 'assets/theme';
import MDButton from 'components/MDButton';
import { Item } from 'devextreme-react/tabs';
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard';
import React, { createRef, forwardRef, memo, useState } from 'react'
import EmailActivities from './email-activities';
import ProgressBar from './progress-bar';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import TtyIcon from '@mui/icons-material/Tty';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import styled from '@emotion/styled';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { makeStyles } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';
import Meeting from './meeting';
import CallSummary from './call-summary';
import Note from './note';
import SendEmail from './send-email';
import LeadInfo from './lead-info';
import RelatedContacts from './related-contacts';
import ContactInfo from 'pages/organization/contact/all-contact/contact-info';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useRef } from 'react';
import MDAvatar from 'components/MDAvatar';
import Caller from 'Apis/config/Caller';
import { POST } from 'Apis/config/RequestType';
import { call_lead } from 'Apis/config/Url';
import { Device } from '@twilio/voice-sdk';
import { useNavigate, Link } from 'react-router-dom';
import { ConstructionOutlined, ContentCutOutlined, FormatColorReset } from '@mui/icons-material';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import { lead_call_summary } from 'Apis/config/Url';
import { post_call_data } from 'Apis/config/Url';
import moment from 'moment';
import { get_related_admin_user } from 'Apis/config/Url';
import { GET } from 'Apis/config/RequestType';
import AddContact from 'pages/organization/communication-assignment/communication-assignment/AddContacts'
import { create_communication_contact } from 'Apis/config/Url';
import { check_mobile_number } from 'Apis/config/Url'
import { useSnackbar } from 'notistack';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Cookies from "js-cookie";
import { LOGIN_AUTH_COOKIE, LOGIN_REFRESH_COOKIE, LOGIN_USERTYPE_COOKIE, RIGHTS, EMP_USER_ID } from 'Apis/config/ResponseHandler';
import { WHATSAPP_REDIRECT_URL } from 'Apis/config/ResponseHandler';

const useStyles = makeStyles({
    dialog: {
        position: 'absolute',
        borderTopLeftRadius: '0.7rem',
        borderTopRightRadius: '0.7rem',
        bottom: "0px",
        left: "140%",
        top: "calc(100vh - 75%)"
    }
});

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


const OverviewLead = (props) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = useState(null);
    const [officialPhone, setOfficialPhone] = useState();
    const [openMeeting, setOpenMeeting] = useState(false);
    const [callSummary, setCallSummary] = useState(false);
    const [editData, setEditData] = useState();
    const [activeSteps, setActiveSteps] = useState();
    const [subStatus, setSubStatus] = useState({
        status: null,
        sub_status: null
    });
    const [note, setNote] = useState(false);
    const [email, setEmail] = useState(false);
    let timeline = useRef();
    const open = Boolean(anchorEl);

    /* -------------- CALL SETUP ------------- */
    const [callPopup, setCallPopup] = useState(false);
    const [device, setDevice] = useState();
    const [conn, setConn] = useState();
    const [callStatus, setCallStatus] = useState();
    const [ready, setReady] = useState(false);
    const [muteCall, setMuteCall] = useState(false);
    const [muteCallVisible, setMuteCallVisible] = useState(false);
    const [contactExists, setContactExists] = useState(false);
    const navigate = useNavigate();

    const generateToken = useCallback(async () => {
        let data = await fetch(`https://dematappservices-1541-dev.twil.io/token?identity=firstdemat`)
            .then((response) => {
                return response.json().then((data) => {
                    return data.accessToken;
                }).catch((err) => {
                })
            });
        return data;
    }, [])

    const [isInitialRender, setIsInitialRender] = useState(true);

    useEffect(() => {
        (async () => {
            if (isInitialRender) {
                setIsInitialRender(false);
                const formData = new FormData();
                formData.append('mobile', props.updateData.mobile);
                await Caller(check_mobile_number, formData, POST, false, true).then((res) => {
                    if (res.success === true) {
                        setContactExists(true);
                    }
                })
                await Caller(get_related_admin_user, "", GET, false, true).then(async (res) => {
                    if (res.success === true) {
                        setOfficialPhone(res.data[0].official_mobile_number)
                    }
                })
                let token = await generateToken().then(data => {
                    return data
                })
                const device = new Device(token);
                setDevice(device);
            }
        })()
    }, [])

    const callSetup = async (event) => {
        // prevents form submission and page refresh
        setReady(true);
        let optmizedPhoneNumber;
        device.getMaxListeners(100);
        if (props.updateData.mobile.length > 10) {
            let lastCharacters = props.updateData.mobile.substr(props.updateData.mobile.length - 10);
            optmizedPhoneNumber = "+91" + lastCharacters;
        }
        else {
            optmizedPhoneNumber = "+91" + props.updateData.mobile;
        }

        let call = await device.connect({
            params: {
                From: "+91" + officialPhone,
                To: optmizedPhoneNumber
            }
        });

        setConn(call);
        setMuteCallVisible(true);

        call.on('incoming', connection => {
            // immediately accepts incoming connection
            connection.accept();
            setCallStatus(connection.status());
        });

        call.on('ready', device => {
            // setCallStatus(`Calling ${props.updateData.first_name.toUpperCase()}`);
            setCallStatus(`Calling ${props.updateData.first_name}`);
        });

        call.on('connect', connection => {
            setMuteCallVisible(true);
            setCallStatus("Ongoing Call");
        });

        call.on('disconnect', connection => {
            setMuteCallVisible(false);
            setCallStatus("Call Connection Closed");
        });

        call.on('error', connection => {
            setMuteCallVisible(false)
            setCallStatus("Please Try Again !");
        });
    }

    const callDisconnect = () => {
        setReady(false);
        device.disconnectAll();
    }

    const resetCallStatus = () => {
        setCallStatus(null);
    }


    const handleCallClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            if (reason !== 'backdropClick') {
                if (conn) {
                    callDisconnect();
                    const formData1 = new FormData();
                    formData1.append('callSid', conn.parameters.CallSid);
                    Caller(call_lead, formData1, POST, false, true).then((res) => {
                        if (res.success == true && res.data.length != 0) {
                            let duration = Math.abs(new Date() - new Date(res.data[0].dateCreated))
                            const formData = new FormData();
                            formData.append('lead_id', props.updateData.id);
                            formData.append('call_date', res.data[0].dateCreated);
                            formData.append('call_from', "");
                            formData.append('call_to', props.updateData.mobile);
                            formData.append('duration', moment.utc(duration).format('HH:mm:ss'));
                            formData.append('call_sid', res.data[0].callSid);
                            formData.append('account_sid', res.data[0].accountSid);
                            formData.append('recording_sid', res.data[0].sid);
                            Caller(post_call_data, formData, POST, false, true);

                            let call_description = `<table style=\"height: 100%; width: 76%; margin-left: auto; margin-right: auto;\" border=\"0\" cellspacing=\"0\" cellpadding=\"0\" align=\"center\">\r\n
                            <tbody>\r\n<tr>\r\n
                            <td width: 100%;\">Call SID: ${res.data[0].callSid}</td>
                            </tr>
                            <tr>\r\n
                            <td width: 100%;\">Call From: ${props.updateData.mobile}</td>
                            </tr>
                            <tr>\r\n
                            <td width: 100%;\">Call To: ${props.updateData.mobile}</td>
                            </tr>
                            <tr>\r\n
                            <td width: 100%;\">Call Duration: ${moment.utc(duration).format('HH:mm:ss')}</td>
                            </tr>
                            <tr>\r\n
                            <td width: 100%;\">Call Date: ${res.data[0].dateCreated}</td>
                            </tr>
                            </table>`;

                            const formData2 = new FormData();
                            formData2.append('lead_id', props.updateData.id);
                            formData2.append('call_date', res.data[0].dateCreated.substring(0, 16));
                            formData2.append('call_description', call_description);
                            formData2.append('call_description_raw', JSON.stringify(res.data[0]));
                            Caller(lead_call_summary, formData2, POST, false, true).then((res) => {
                                if (res.success === true) {
                                    openRefresh();
                                }

                            })
                        }
                    })
                }
                setMuteCall(false);
                setMuteCallVisible(false);
                setCallPopup(false);
                startBrowserBack();
            }
        }
    };

    const stopBrowserBack = (callback) => {
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = () => {
            window.history.pushState(null, "", window.location.href);
            callback();
        };
    };

    const startBrowserBack = () => {
        window.onpopstate = undefined;
        window.history.back();
    };

    /* -------------- CALL SETUP ------------- */

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };



    const handleClose = () => {
        setAnchorEl(null);
    };
    const openRefresh = (e) => {
        timeline.current.rerender();
    };
    const openMeetingFunc = (data) => {
        setEditData(data ? data : "");
        setOpenMeeting(true);
    };
    const openCallSummaryFunc = (data, steps) => {
        setActiveSteps(steps);
        setEditData(data ? data : "");
        setCallSummary(true);
    };
    const setProgressSteps = (status, sub) => {
        setSubStatus((values) => ({
            ...values,
            status: status,
            sub_status: sub
        }));
    };

    const openNoteFunc = (data) => {
        setEditData(data ? data : "");
        setNote(true);
    };
    const openEmailFunc = (data) => {
        setEditData(data ? data : "");
        setEmail(true);
    };
    const handleModalClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            if (reason !== 'backdropClick') {
                setOpenMeeting(false);
                setCallSummary(false);
                setNote(false);
                setEmail(false);
            }
        }
    };

    // const convertToContact = () => {
    //     const formData = new FormData();
    //     formData.append("first_name", props.updateData.first_name);
    //     formData.append("last_name", props.updateData.last_name || "");
    //     formData.append("mobile", props.updateData.mobile);
    //     formData.append("email", props.updateData.email || "");
    //     formData.append("product", props.updateData.product_id);

    //     Caller(create_communication_contact, formData, POST, false, true).then((res) => {
    //         if (res.success === true) {
    //             enqueueSnackbar('Lead Converted To WhatsApp Contact !', { variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
    //             props.close();
    //         }

    //     })

    // };


    const handleWhatsapp = () => {
        // setOpenCommunication(!openCommunication);

        let luc = Cookies.get(LOGIN_USERTYPE_COOKIE)
        let lac = Cookies.get(LOGIN_AUTH_COOKIE)
        let lrc = Cookies.get(LOGIN_REFRESH_COOKIE)
        let right = Cookies.get(RIGHTS)
        let eui = Cookies.get(EMP_USER_ID)

        let mobile = props.updateData.mobile
        if (luc && lac && lrc && right && eui && mobile) {
            const url = `${WHATSAPP_REDIRECT_URL}?LOGIN_USERTYPE_COOKIE=${luc}&LOGIN_AUTH_COOKIE=${lac}&LOGIN_REFRESH_COOKIE=${lrc}&r=${right}&eui=${eui}&mobile=${props.updateData.mobile}&first_name=${props.updateData.first_name}&last_name=${props.updateData.last_name}&contact_id=${props.updateData.contact_id}&contact_updated_at=${props.updateData.contact_updated_at}`;
            window.open(url, '_blank');
        } else {
            console.error('One or more required cookies or mobile number are missing.');
        }
    }

    return (
        <Grid container spacing={2} >
            <Stack direction="row" spacing={2}>
                <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12} marginLeft={2} sx={{
                        paddingTop: "0 !IMPORTANT",
                    }} >
                        <Stack direction="row" spacing={1} sx={{
                            paddingTop: "0",
                        }} display="flex" justifyContent="space-between">
                            <Stack
                                direction="row"
                                spacing={1}
                                sx={{
                                    paddingBottom: "1.5%",
                                    paddingTop: "1.5%",
                                    paddingLeft: "2%"
                                }}>
                                <MDButton startIcon={<SendIcon />} color='info' className="action-button" size="small" onClick={() => setEmail(true)}>New Email</MDButton>
                                <Button startIcon={<AddIcon />} color='secondary' onClick={handleClick} >Add Activity</Button>
                                <StyledMenu
                                    id="demo-customized-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'demo-customized-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={() => { setEditData(); setOpenMeeting(true); handleClose(); }} disableRipple>
                                        <MeetingRoomIcon />
                                        Meeting
                                    </MenuItem>
                                    <MenuItem onClick={() => { openCallSummaryFunc(null, null); handleClose(); }} disableRipple>
                                        <TtyIcon />
                                        Call Summary
                                    </MenuItem>
                                    <Divider sx={{ my: 0.5 }} />
                                    <MenuItem onClick={() => { setEditData(); setNote(true); handleClose(); }} disableRipple>
                                        <DocumentScannerIcon />
                                        Note
                                    </MenuItem>
                                </StyledMenu>
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={1}
                                sx={{
                                    paddingBottom: "1.5%",
                                    paddingTop: "1.5%",
                                    paddingRight: "2%",
                                }}
                            >
                                {/* {!contactExists && <Button startIcon={<AddIcon />} color='secondary' onClick={convertToContact}>Convert To WhatsApp Contact</Button>}
                                {contactExists && <Link to="/communicationContacts" style={{ display: "flex", alignItems: "center", marginRight: "2vw" }}> <WhatsAppIcon fontSize="medium" style={{ color: 'green' }} /> </Link>} */}
                                <MDButton startIcon={<WhatsAppIcon />} variant="gradient" color="info" onClick={handleWhatsapp}>
                                    Whatsapp
                                </MDButton>

                                {/* Enable Call Popup */}
                                <MDButton startIcon={<WifiCalling3Icon />} variant="gradient" color="info" onClick={() => {
                                    setCallPopup(true);
                                    stopBrowserBack()
                                }}>
                                    Call
                                </MDButton>
                            </Stack>
                        </Stack>
                    </Grid>

                    {
                        // (props.updateData.product_name === "Advisory") &&

                        <Grid item xs={12} marginLeft={2} sx={{
                            paddingTop: "0 !IMPORTANT",
                        }}>
                            <Card
                                style={{
                                    // maxHeight: 550,
                                    overflow: "hidden"
                                }}
                            >
                                <CardHeader
                                    title={props.type == "leads" ? "Lead Info" : "Contact Info"}
                                    sx={{
                                        paddingBottom: 1,
                                        borderBottom: 0.5,
                                        borderColor: '#d4d4d4'
                                    }}

                                />
                                <CardContent
                                    sx={{
                                        paddingTop: 2
                                    }}
                                    style={{
                                        // maxHeight: "72.5vh",
                                        // maxHeight: "500vh",

                                    }}
                                >
                                    {(props.type == "leads") && <LeadInfo updateData={props.updateData} refresh={props.refresh} close={props.close} refreshOverview={() => { setIsInitialRender(true) }} />}
                                </CardContent>

                            </Card>
                        </Grid>

                    }

                    {(props.type != "accounts") &&

                        <Grid item xs={12} marginLeft={2}>
                            <Card>
                                <CardHeader
                                    title="Lead Stages"
                                    sx={{
                                        paddingBottom: 1,
                                        borderBottom: 0.5,
                                        borderColor: '#d4d4d4'
                                    }}
                                />
                                <CardContent
                                    sx={{
                                        paddingTop: 2
                                    }}

                                >
                                    <ProgressBar updateData={props.updateData} close={handleModalClose} refresh={props.refresh} openCallSummary={openCallSummaryFunc} openMeeting={openMeetingFunc} subStatus={subStatus} mainRefreshed={isInitialRender} />
                                </CardContent>

                            </Card>
                        </Grid>

                    }
                    <Grid item xs={12} marginLeft={2}>
                        <Card
                        >
                            <CardHeader
                                title="Email & Activities"
                                sx={{
                                    paddingBottom: 1,
                                    borderBottom: 0.5,
                                    borderColor: '#d4d4d4'
                                }}
                            />

                            <CardContent
                                sx={{
                                    backgroundColor: "#eee"
                                }}
                                style={{
                                    minHeight: "63.5vh",
                                    maxHeight: "63.5vh",
                                    overflow: "hidden",
                                    overflowY: "scroll"
                                }}
                            >
                                <EmailActivities ref={timeline} updateData={props.updateData} openMeeting={openMeetingFunc} openCallSummary={openCallSummaryFunc} openNote={openNoteFunc} openEmail={openEmailFunc} />
                            </CardContent>

                        </Card>
                    </Grid>
                </Grid>
                {/* <Grid item xs={4}>
                    <Card
                        style={{
                            // maxHeight: 550,
                            overflow: "hidden",
                            // height: '50vw'
                        }}
                    >
                        <CardHeader
                            title={props.type == "leads" ? "Lead Info" : "Contact Info"}
                            sx={{
                                paddingBottom: 1,
                                borderBottom: 0.5,
                                borderColor: '#d4d4d4'
                            }}
                        />
                        <CardContent
                            sx={{
                                paddingTop: 2
                            }}
                            style={{
                                // maxHeight: "72.5vh",
                                maxHeight: "500vh",
                                overflow: "hidden",
                                overflowY: "scroll"
                            }}
                        >
                            {(props.type == "leads") && <LeadInfo updateData={props.updateData} refresh={props.refresh} close={props.close} />}
                            {(props.type == "accounts") && <ContactInfo updateData={props.updateData} refresh={props.refresh} close={props.close} />}
                        </CardContent>
                    </Card>
                </Grid> */}
            </Stack >
            <Dialog
                fullScreen
                open={openMeeting}
                onClose={handleModalClose}
                TransitionComponent={Transition}
                sx={{
                    maxWidth: "40%",
                }}
                classes={{
                    paper: classes.dialog
                }}
            >
                <DialogTitle
                    sx={{
                        paddingTop: 0,
                        paddingBottom: 0.5,
                        borderBottom: 1,
                        borderColor: '#d4d4d4'
                    }}
                ><MDBox
                    color="primary"
                    variant="gradient"
                    opacity={1}
                    pt={2}
                    pl={0.5}
                    pr={0.5}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"

                >
                        <MDTypography variant="h4" color="secondary">Meeting</MDTypography>
                        <IconButton
                            style={{ color: "black", cursor: "pointer" }}
                            sx={{
                                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
                            }}
                            onClick={() => setOpenMeeting(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </MDBox>
                </DialogTitle>
                <DialogContent
                    sx={{
                        paddingTop: "15px !important"
                    }}>
                    <Meeting updateData={props.updateData} close={handleModalClose} refresh={openRefresh} editData={editData} />
                </DialogContent>
            </Dialog>
            <Dialog
                fullScreen
                open={callSummary}
                onClose={handleModalClose}
                TransitionComponent={Transition}
                sx={{
                    maxWidth: "40%",
                }}
                classes={{
                    paper: classes.dialog
                }}
            >
                <DialogTitle
                    sx={{
                        paddingTop: 0,
                        paddingBottom: 0.5,
                        borderBottom: 1,
                        borderColor: '#d4d4d4'
                    }}
                ><MDBox
                    color="primary"
                    variant="gradient"
                    opacity={1}
                    pt={2}
                    pl={0.5}
                    pr={0.5}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                // textAlign="center"
                >
                        <MDTypography variant="h4" color="secondary">Call Summary</MDTypography>
                        <IconButton
                            style={{ color: "black", cursor: "pointer" }}
                            sx={{
                                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
                            }}
                            onClick={() => setCallSummary(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </MDBox>
                </DialogTitle>
                <DialogContent
                    sx={{
                        paddingTop: "15px !important"
                    }}>
                    <CallSummary updateData={props.updateData} close={handleModalClose} refresh={openRefresh} editData={editData} steps={activeSteps} setProgressSteps={setProgressSteps} />
                </DialogContent>
            </Dialog>
            <Dialog
                fullScreen
                open={note}
                onClose={handleModalClose}
                TransitionComponent={Transition}
                sx={{
                    maxWidth: "40%",
                }}
                classes={{
                    paper: classes.dialog
                }}
            >
                <DialogTitle
                    sx={{
                        paddingTop: 0,
                        paddingBottom: 0.5,
                        borderBottom: 1,
                        borderColor: '#d4d4d4'
                    }}
                ><MDBox
                    color="primary"
                    variant="gradient"
                    opacity={1}
                    pt={2}
                    pl={0.5}
                    pr={0.5}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                // textAlign="center"
                >
                        <MDTypography variant="h4" color="secondary">Note</MDTypography>
                        <IconButton
                            style={{ color: "black", cursor: "pointer" }}
                            sx={{
                                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
                            }}
                            onClick={() => setNote(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </MDBox>
                </DialogTitle>
                <DialogContent
                    sx={{
                        paddingTop: "15px !important"
                    }}>
                    <Note updateData={props.updateData} close={handleModalClose} refresh={openRefresh} editData={editData} />
                </DialogContent>
            </Dialog>
            <Dialog
                fullScreen
                open={email}
                onClose={handleModalClose}
                TransitionComponent={Transition}
                sx={{
                    maxWidth: "40%",
                }}
                classes={{
                    paper: classes.dialog
                }}
            >
                <DialogTitle
                    sx={{
                        paddingTop: 0,
                        paddingBottom: 0.5,
                        borderBottom: 1,
                        borderColor: '#d4d4d4'
                    }}
                ><MDBox
                    color="primary"
                    variant="gradient"
                    opacity={1}
                    pt={2}
                    pl={0.5}
                    pr={0.5}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                // textAlign="center"
                >
                        <MDTypography variant="h4" color="secondary">Email</MDTypography>
                        <IconButton
                            style={{ color: "black", cursor: "pointer" }}
                            sx={{
                                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
                            }}
                            onClick={() => setEmail(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </MDBox>
                </DialogTitle>
                <DialogContent
                    sx={{
                        paddingTop: "8px !important",
                        paddingLeft: "0px !important",
                        paddingRight: "0px !important"
                    }}>
                    <SendEmail updateData={props.updateData} close={handleModalClose} refresh={openRefresh} editData={editData} />
                </DialogContent>
            </Dialog>
            {/* ---------------CALL POPUP---------------- */}
            <Dialog
                open={callPopup}
                onClose={handleCallClose}
                maxWidth='md'
                sx={{
                    '.MuiDialogContent-root': { pl: 5, pr: 5, pb: 5, pt: 2 }
                }}
            >
                <DialogTitle
                ><MDBox
                    color="white"
                    bgColor="info"
                    variant="gradient"
                    borderRadius="lg"
                    shadow="lg"
                    opacity={1}
                    p={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                // textAlign="center"
                >
                        <MDTypography variant="h4" color="white">Call Screen</MDTypography>
                        <WifiCalling3Icon />

                    </MDBox></DialogTitle>

                <DialogContent
                    p={3}
                >
                    {
                        ready
                            ? (<MDBox
                                sx={{
                                    height: '30vh',
                                }}
                            >
                                <Stack spacing={2}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}

                                >
                                    <MDAvatar bgColor="secondary" >
                                        <RecordVoiceOverIcon />
                                    </MDAvatar>
                                    <Chip label={callStatus ? callStatus : "Call will begin shortly.."} color="primary" style={{ color: "white", width: "250px" }} />
                                    {muteCallVisible && <>{!muteCall && <MDButton variant="gradient" color="secondary" iconOnly onClick={() => { setMuteCall(true); conn.mute(true) }} style={{ marginTop: "40%" }}>
                                        <MicIcon />
                                    </MDButton>}
                                        {muteCall && <MDButton variant="gradient" color="error" iconOnly onClick={() => { setMuteCall(false); conn.mute(false) }} style={{ marginTop: "40%" }}>
                                            <MicOffIcon />
                                        </MDButton>}</>}
                                </Stack>
                            </MDBox>)
                            : <div>
                                {/* <strong>Place Call to Client: {props.updateData.first_name.toUpperCase()} ?</strong> */}
                                <strong>Place Call to Client: {props.updateData.first_name} ?</strong>
                            </div>
                    }
                </DialogContent>
                <DialogActions
                    sx={{
                        background: "linear-gradient(195deg, #dee1e1 10%, #f4f4f4 360%)",
                        marginBottom: 2,
                        marginLeft: 2,
                        marginRight: 2,
                        borderRadius: '0.5rem',
                        justifyContent: 'center'
                    }}
                >
                    {!ready && <MDButton color='success' className="action-button" onClick={callSetup}>Place Call</MDButton>}
                    {/* {(callStatus && (callStatus === "Call Connection Closed")) && <MDButton color='success' className="action-button" onClick={callSetup}>Call Again</MDButton>} */}
                    {/* <MDButton color='warning' className="action-button" onClick={ () => {openCallSummaryFunc(null, null);handleCallClose();}}>Drop Call</MDButton> */}
                    {!ready && <MDButton color='warning' className="action-button" onClick={handleCallClose}>Drop Call</MDButton>}
                    {ready && <MDButton color='warning' className="action-button" onClick={() => { openCallSummaryFunc(null, null); handleCallClose(); resetCallStatus(); }}>Drop Call</MDButton>}
                </DialogActions>
            </Dialog>
            {/* ---------------CALL POPUP---------------- */}
            {
                (props.type == "accounts") && <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            title="Related Contacts"
                            sx={{
                                paddingBottom: 1,
                                borderBottom: 0.5,
                                borderColor: '#d4d4d4'
                            }}
                        />
                        <CardContent
                            sx={{
                                paddingTop: 2
                            }}
                            style={{
                                maxHeight: "20.5vh",
                                overflow: "hidden",
                                overflowY: "scroll"
                            }}
                        >
                            <RelatedContacts updateData={props.updateData} close={handleModalClose} editData={editData} />
                        </CardContent>

                    </Card>
                </Grid>
            }

        </Grid >

    )
}

export default OverviewLead;