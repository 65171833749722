/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
/* eslint-disable prettier/prettier */
import { Autocomplete, Box, Dialog, DialogContent, DialogTitle, DialogActions, IconButton, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Snackbar, TextField } from '@mui/material'
import Caller from 'Apis/config/Caller';
import { PUT, GET, POST } from 'Apis/config/RequestType';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Button, Column, DataGrid, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar } from 'devextreme-react/data-grid';
import AddTemplates from 'pages/organization/group-template/chatTemplates/add-update-template'
import MDTypography from "components/MDTypography";
import CloseIcon from '@mui/icons-material/Close';
import { createBroadcast } from 'Apis/config/Url'
import { getWhatsappTemplateType } from 'Apis/config/Url'
import { updateBroadcast } from 'Apis/config/Url'
import { communication_group } from 'Apis/config/Url'

import { sendBroadcastMessage } from 'Apis/config/Url'
import { getMobile } from 'Apis/config/Url';
import { getMetaTemplate } from 'Apis/config/Url';
import { getTemplateFromTable } from 'Apis/config/Url';


const AddUpdateBroadcast = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [disabledSave, setDisabledSave] = useState();
    const [date_time, setDateTimeValue] = useState("");
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [whatsappTemplateData, setWhatsappTemplateData] = useState();
    const [buttonType, setButtonType] = useState(null);

    const [values, setAllValues] = useState({
        email_value: [],
        email_value_id: "",
        template_name: "",

        broadcast_name: "",
        description: "",

        whatsapp_group_array: [],
        whatsapp_group: "",

        template: "",
        mobile_number_array: [],

        meta_template: [],

        image_id: "",
        variable_value: [],
        body_text: "",

    })

    const [errors, setAllErrors] = useState({
        broadcast_name: "",
        description: "",
        date_time: "",
        whatsapp_group: "",
        template: "",
    });


    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setDialogOpen(false);


        }
    }


    const handleDateChange = (event) => {

        // Input datetime string
        const datetimeString = event.target.value;

        // Convert datetime string to Date object
        const datetime = new Date(datetimeString);

        // Format the Date object to the desired format
        const formattedDatetime = `${datetime.getFullYear()}-${(datetime.getMonth() + 1).toString().padStart(2, '0')}-${datetime.getDate().toString().padStart(2, '0')} ${datetime.getHours().toString().padStart(2, '0')}:${datetime.getMinutes().toString().padStart(2, '0')}:00`;

        console.log("Date and time = ", event.target.value);
        console.log("formattedDatetime = ", formattedDatetime);

        // setDateTimeValue(event.target.value);
        setDateTimeValue(formattedDatetime);
    };

    const textUpdate = (e) => {
        const re = /^[a-zA-Z0-9 ]+$/;
        if (re.test(e.target.value) || (e.target.value === "")) {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }))
        }
        setAllErrors((values) => ({
            ...values,
            [`${e.target.name}`]: ''
        }));
    }

    const [isInitialRender, setIsInitialRender] = useState(true);

    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);
            if (props.updateData) {

                Caller(getWhatsappTemplateType, "", GET, false, true).then((res) => {
                    setAllValues((values) => ({
                        ...values,
                        email_value: res.data.data,
                    }));
                })

                setAllValues((values) => ({
                    ...values,
                    broadcast_name: props.updateData[0]?.broadcast_name ?? "",
                    description: props.updateData[0]?.description ?? "",
                    whatsapp_group: props.updateData[0]?.whatsapp_group ?? "",
                    template: props.updateData[0]?.template ?? "",
                    email_value_id: props.updateData[0]?.template ?? "",

                    // template_name: data ? data.template_name : "",
                }));
                setDateTimeValue(props.updateData[0]?.date_time ?? "");


                Caller(getMobile + "?WHATSAPP_GROUP_NAME=" + props.updateData[0].whatsapp_group, "", GET, false, true).then((res) => {
                    setAllValues((values) => ({
                        ...values,
                        mobile_number_array: res.data,
                    }));
                })

            }

            Caller(communication_group + "?isLoadingAll=true", "", GET, false, true).then(async (resData) => {
                await setAllValues((values) => ({
                    ...values,
                    whatsapp_group_array: resData.data.data,
                }));
            });

            Caller(getWhatsappTemplateType, "", GET, false, true).then((res) => {

                setAllValues((values) => ({
                    ...values,
                    email_value: res.data.data,

                }));

                let data = res.data.data.find(o => o.id === props.updateData[0]?.template);
                setAllValues((values) => ({
                    ...values,
                    template_name: data ? data.template_name : "",
                }));

            })

            Caller(getMetaTemplate, "", GET, false, true).then(async (resData) => {

                await setAllValues((values) => ({
                    ...values,
                    meta_template: resData.data.data,
                }));
            });
            if (props.updateData) {
                Caller(getTemplateFromTable + "?id=" + props.updateData[0].template + "&page=1&s=&take=25", "", GET, false, true).then(async (res) => {
                    if (res.success === true) {
                        setWhatsappTemplateData(res.data.data);
                        setAllValues((values) => ({
                            ...values,
                            image_id: res.data?.data[0]?.image_id ? res.data?.data[0]?.image_id : "",
                            variable_value: res.data?.data[0]?.variable_value ? res.data?.data[0]?.variable_value : [],
                            body_text: res.data.data[0].body || "",
                        }));
                    }

                });
            }

        }

    }, []);

    const handleTemplateTypeChange = async (e) => {
        let data = values.email_value.find(o => o.id === e.target.value);
        setAllValues((values) => ({
            ...values,
            email_value_id: e.target.value,
            template_name: data ? data.template_name : "",
        }));
    }

    const templateData = async (id) => {

        await Caller(getTemplateFromTable + "?id=" + id + "&page=1&s=&take=25", "", GET, false, true).then(async (res) => {
            if (res.success === true) {
                setWhatsappTemplateData(res.data.data);
                setAllValues((values) => ({
                    ...values,
                    image_id: res.data?.data[0]?.image_id ? res.data?.data[0]?.image_id : "",
                    variable_value: res.data?.data[0]?.variable_value ? res.data?.data[0]?.variable_value : [],
                    body_text: res.data.data[0].body,
                }));
            }

        });
        setDialogOpen(true);
    }

    const handleWhatsappChange = async (e) => {
        Caller(getMobile + "?WHATSAPP_GROUP_NAME=" + e.target.value, "", GET, false, true).then((res) => {
            setAllValues((values) => ({
                ...values,
                mobile_number_array: res.data,
            }));
        })

        setAllValues((values) => ({
            ...values,
            whatsapp_group: e.target.value,
        }));
    }

    const addLookup = async (event) => {
        event.target.classList.add("was-validated");
        setDisabledSave('disabled');
        event.preventDefault();
        event.stopPropagation();

        const formData = new FormData();

        formData.append('broadcast_name', values.broadcast_name);
        formData.append('description', values.description);
        if (buttonType == "schedule") {
            formData.append('date_time', date_time || "");
        }
        formData.append('whatsapp_group', values.whatsapp_group);
        formData.append('template', values.email_value_id);
        formData.append('template_name', values.template_name);
        formData.append('language_code', "en_us");
        // formData.append('components_type', "header");
        formData.append('mobile', values.mobile_number_array);
        if (values.image_id) {
            formData.append('image_id', values.image_id);
        }

        if (values.variable_value) {
            formData.append('variable_value', values.variable_value);
        }
        formData.append('body_text', values.body_text);
        // formData.append('data', values.email_value);

        if (props.updateData) {

            // await Caller(sendBroadcastMessage + "?id=" + props.updateData[0].id, formData, POST, false, true).then((res) => {
            await Caller(sendBroadcastMessage + "", formData, POST, false, true).then(async (res) => {
                if (res.success === true) {
                    enqueueSnackbar((buttonType == "schedule") ? 'Broadcast Message Scheduled Successfully!' : 'Message Sent Successfully!', { variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
                    await Caller(updateBroadcast + "?id=" + props.updateData[0].id, formData, POST, false, true).then((res) => {
                        if (res.success == true) {
                            props.refresh();
                            props.close();
                        }
                    })
                }
                else if (res.success === false) {
                    setDisabledSave('');
                    for (const [key, value] of Object.entries(res.message['errors'])) {
                        setAllErrors((values) => ({
                            ...values,
                            [`${key}`]: value,
                        }))
                    }
                }
            }).catch(error => { alert(JSON.stringify(error.message)); })
        }
        else {
            await Caller(createBroadcast, formData, POST, false, true).then(async (res) => {
                if (res.success === true) {
                    enqueueSnackbar('Broadcast Message Created Successfully!', { variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });

                    await Caller(sendBroadcastMessage + "", formData, POST, false, true).then(async (res) => {
                        if (res.success === true) {
                            
                        }

                    }).catch(error => { alert(JSON.stringify(error.message)); })

                    props.refresh();
                    props.close();

                }
                else if (res.success === false) {
                    setDisabledSave('');
                    for (const [key, value] of Object.entries(res.message['errors'])) {
                        setAllErrors((values) => ({
                            ...values,
                            [`${key}`]: value,
                        }))
                    }
                }
            }).catch(error => { alert(JSON.stringify(error.message)); })
        }

    }

    const handleButtonClick = (buttonType) => {
        setButtonType(buttonType);
    };

    const allBroadcastTrue = () => {
        if (values.broadcast_name && values.description && date_time && values.whatsapp_group && values.email_value_id && values.template_name && values.mobile_number_array) {
            return false;
        } else {
            return true;
        }
    }

    const allTrue = () => {
        if (values.broadcast_name && values.description && values.whatsapp_group && values.email_value_id && values.template_name && values.mobile_number_array) {
            return false;
        } else {
            return true;
        }
    }

    return (
        <Box
            component="form"
            onSubmit={addLookup}
            sx={{
                '#demo-row-radio-buttons-group-label': { fontSize: 'medium', textAlign: "left" },
                '.role-select-custom': { width: "100%" },
                '.add-label': { pb: 1 },
                '.radio-button-custom': { fontSize: 'small' }
            }}
            noValidate
        >
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={6} sm={6} md={6}>
                    <InputLabel className='add-label'>Broadcast Name<sup style={{ color: "red" }}>*</sup></InputLabel>
                    <TextField
                        required
                        id="outlined-required"
                        name='broadcast_name'
                        value={values.broadcast_name}
                        error={errors.broadcast_name}
                        helperText={errors.broadcast_name === "" ? '' : errors.broadcast_name}
                        style={{ width: "100%" }}
                        InputProps={{
                            inputProps: {
                                maxLength: 100, minLength: 1
                            }
                        }}
                        onChange={textUpdate}
                    />
                </Grid>

                <Grid item xs={6} sm={6} md={6}>
                    <InputLabel className='add-label'>Description<sup style={{ color: "red" }}>*</sup></InputLabel>
                    <TextField
                        required
                        id="outlined-required"
                        name='description'
                        value={values.description}
                        error={errors.description}
                        helperText={errors.description === "" ? '' : errors.description}
                        style={{ width: "100%" }}
                        InputProps={{
                            inputProps: {
                                maxLength: 100, minLength: 1,
                            }
                        }}
                        onChange={textUpdate}
                    />
                </Grid>

                <Grid item xs={6} sm={6} md={6}>
                    <InputLabel className='add-label'>Date & Time</InputLabel>
                    <TextField
                        id="datetime-local"
                        // label="Date & Time"
                        type="datetime-local"
                        value={date_time}
                        onChange={handleDateChange}
                        sx={{ width: "100%" }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            ...props.inputProps,
                            min: (new Date).toISOString().slice(0, 16), // Set min attribute to the current date and time
                        }}
                    />
                </Grid>

                <Grid item xs={6} sm={6} md={6}>
                    <InputLabel className='add-label'>Whatsapp Group<sup style={{ color: "red" }}>*</sup></InputLabel>
                    <FormControl className='role-select-custom'>
                        <Select
                            style={{ height: 45 }}
                            displayEmpty
                            defaultValue={values.whatsapp_group}
                            value={values.whatsapp_group}
                            onChange={handleWhatsappChange}
                        >
                            <MenuItem value="" disabled>-- Select Group --</MenuItem>

                            {values.whatsapp_group_array?.map((e, key) => {
                                return (
                                    <MenuItem key={key} value={e.id}
                                    // onClick={() => setDialogOpen(e.id)}
                                    >
                                        {e.whatsapp_group_name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6} sm={6} md={6}>
                    <InputLabel className='add-label'>Templates<sup style={{ color: "red" }}>*</sup></InputLabel>
                    <FormControl className='role-select-custom'>
                        <Select
                            style={{ height: 45 }}
                            displayEmpty
                            defaultValue={values.email_value_id}
                            value={values.email_value_id}
                            onChange={handleTemplateTypeChange}
                        >
                            <MenuItem value="" disabled>
                                -- Select Templates --
                            </MenuItem>
                            {values.email_value?.map((e, key) => {
                                return (
                                    <MenuItem key={key} value={e.id} onClick={() => { templateData(e.id) }} >
                                        {e.template_name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>


                <DialogActions
                    sx={{
                        background: "linear-gradient(195deg, #dee1e1 10%, #f4f4f4 360%)",
                        marginTop: "10%",
                        marginBottom: "0%",
                        width: "104%",
                        borderRadius: '0.5rem',
                        transform: "translateY(35%)",
                        '.action-button': { width: "100%" }
                    }}
                >
                    {props.updateData && <MDButton color='success' type="submit" className="action-button" disabled={allTrue()} onClick={() => handleButtonClick("send_now")}>Send Now</MDButton>}
                    <MDButton color='success' className={`action-button ${disabledSave}`} type="submit" disabled={allBroadcastTrue()} onClick={() => handleButtonClick("schedule")}>{props.updateData ? "Update Schedule" : "Schedule"}</MDButton>
                    <MDButton color='warning' className="action-button" onClick={props.close}>Cancel</MDButton>
                </DialogActions>

                <Dialog
                    open={isDialogOpen}
                    // onClose={closeDialog}
                    disableEnforceFocus={true}
                    fullWidth
                    maxWidth="md"
                    sx={{ '& .css-jd3bb0-MuiModal-root-MuiDialog-root .MuiDialogContent-root': { pl: 0 } }}
                >
                    {/* <DialogTitle>Add Templates</DialogTitle> */}
                    <DialogTitle
                    ><MDBox
                        color="white"
                        bgColor="info"
                        variant="gradient"
                        borderRadius="lg"
                        shadow="lg"
                        opacity={1}
                        p={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    // textAlign="center"
                    >
                            <MDTypography variant="h4" color="white">Add Template</MDTypography>
                            <IconButton
                                style={{ color: "white", cursor: "pointer" }}
                                sx={{
                                    "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
                                }}
                                onClick={() => setDialogOpen(false)}
                            >
                                <CloseIcon />
                            </IconButton>
                        </MDBox></DialogTitle>
                    <DialogContent>

                        <AddTemplates
                            broadcastData={true}
                            updateData={whatsappTemplateData}
                            close={handleClose}
                            refresh={() => props.refresh()}
                            setVariableValue={(newValue) => {
                                setAllValues((values) => ({
                                    ...values,
                                    variable_value: newValue,
                                }));
                            }} />

                    </DialogContent>

                </Dialog>
            </Grid>
        </Box>

    )
}

export default AddUpdateBroadcast;