/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import team2 from "assets/images/team-2.jpg";


// Data
import MDButton from "components/MDButton";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon, IconButton, TextField, useMediaQuery } from "@mui/material";
import { useRef, useState } from "react";

import { Button, Column, DataGrid, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { Pager } from 'devextreme-react/tree-list';
import CloseIcon from '@mui/icons-material/Close';
import AddUpdateUser from "./add-update-user";
import { update_user } from "Apis/config/Url";
import { PUT } from "Apis/config/RequestType";
import DeletePopup from "layouts/master-data/delete-popup";
import { delete_user ,get_related_admin_user} from "Apis/config/Url";
import Caller from "Apis/config/Caller";
import { getAdminUserDetails } from "Apis/Auth/auth";
import CustomStore from 'devextreme/data/custom_store';
import { GET } from "Apis/config/RequestType";

const isNotEmpty = (value) => {
  return value !== undefined && value !== null && value !== "";
  }

const adminUserDetails = new CustomStore({
  key: 'user_id',
  load(loadOptions) {
      let params = "?";
      [
          "skip",
          "take",
          "requireTotalCount",
          "requireGroupCount",
          "sort",
          "filter",
          "totalSummary",
          "group",
          "groupSummary",
          "isLoadingAll"
      ].forEach((i) => {
          if (i in loadOptions && isNotEmpty(loadOptions[i])) {

              if (i === "skip") {
                  let page = (loadOptions[i] + 10) / 10;
                  if (page !== 1) {
                      params += `page=${page}&`;
                  }
              } else if (i === "sort") {
                  let sortType = loadOptions[i][0]["desc"] ? "desc" : "asc";
                  let sortColoumn = loadOptions[i][0]["selector"];
                  params += `sortType=${sortType}&sortColoumn=${sortColoumn}&`;
              } else if (i === "filter") {
                  let filterO = loadOptions[i][0]["filterValue"];
                  params += `s=${filterO}&`;
              } else {
                  params += `${i}=${JSON.stringify(loadOptions[i])}&`;
              }
          }
      });
      params = params.slice(0, -1);
      return getAdminUserDetails(params)
          .then((data) => ({
              data: data.data.data,
              totalCount: data.data.count
          }));
  },

});

const  AdminUsers = () => {
 
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [popuptitle, setPopupTitle] = useState("Add Admin");
  const [updateData, setUpdate] = useState("");
  const userGrid = useRef();
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      if (reason !== 'backdropClick') {
        setUpdate("");
        setOpen(false);
        setDeleteOpen(false);
      }
    }
  };

  function cellRender(data) {
    return <img src={team2} style={{ borderRadius: "50%", width: "2.5rem", height: "2.5rem" }} />;
  }

  const selectPopupVisible = async (e) => {
    const formData = new FormData();
    formData.append("id", e.row.data.id);
    await Caller(get_related_admin_user  + "?id="+e.row.data.user_id, "", GET, false, true).then(async (res) => {
      if (res.success === true) {
        setUpdate(res.data.data);
      }
    });
    setPopupTitle("Update Admin");
    setOpen(true);
  }

  const deleteVisible = (e) => {
    setUpdate(e.row.data.user_id);
    setDeleteOpen(true);
  }
  const popupDeleteText = "Do you want to delete this admin?";
  return (
    <DashboardLayout>
      {deleteOpen && <DeletePopup deleteId={updateData} deleteText = {popupDeleteText} open={deleteOpen} close={handleClose} path={delete_user + "?user_type=admin"} />}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h4" color="white">
                  Admin Users
                </MDTypography>
              </MDBox>
              <Dialog
                open={open}
                onClose={handleClose}
                maxWidth='md'
                sx={{
                  '.MuiDialogContent-root': { pl: 5, pr: 5, pb: 5, pt: 2 }
                }}
              >
                <DialogTitle
                ><MDBox
                  color="white"
                  bgColor="info"
                  variant="gradient"
                  borderRadius="lg"
                  shadow="lg"
                  opacity={1}
                  p={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                // textAlign="center"
                >
                    <MDTypography variant="h4" color="white">{popuptitle}</MDTypography>
                    <IconButton
                      style={{ color: "white", cursor: "pointer" }}
                      sx={{
                        "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
                      }}
                      onClick={() => setOpen(false)}
                    >
                      <CloseIcon />
                    </IconButton>

                  </MDBox>

                </DialogTitle>
                <DialogContent>
                  <AddUpdateUser data={updateData} close={handleClose} />
                </DialogContent>

              </Dialog>
              <MDBox p={3}>
                <DataGrid
                  id="gridContainer"
                  dataSource={adminUserDetails}
                  keyExpr="user_id"
                  ref={userGrid}
                  
                  showBorders={true}
                  remoteOperations={true}
                  wordWrapEnabled={true}
                  showColumnLines={false}
                  showRowLines={true}
                  
                  rowAlternationEnabled={true}
                >
                  <Pager
                    visible={true}
                    displayMode="full"
                    showInfo={true}
                    showNavigationButtons={true} />
                  <Paging defaultPageSize={10} />
                  <SearchPanel
                    visible={true}
                  />
                  <Export
                    enabled={true}
                    fileName="AdminUsers"
                  />
                  <Editing
                    mode="row"
                    allowAdding={false}
                    allowUpdating={true}
                    useIcons={true}
                  />
                  <Column
                    caption="First Name"
                    dataField="first_name"
                    alignment="center"  
                  />
                  <Column
                    caption="Last Name"
                    dataField="last_name"
                    alignment="center"
                  />
                  <Column
                    caption="Email Address"
                    dataField="username"
                    alignment="center"
                    minWidth={150}
                  />
                  <Column
                    caption="Phone"
                    dataField="phone"
                    alignment="center"
                    format="(###)-###-####"
                    minWidth={100}
                  />
                  <Column
                    caption="Mobile"
                    dataField="mobile"
                    alignment="center"
                    format="(###)-###-####"
                    minWidth={100}
                  />
                  <Column
                    caption="Extension"
                    dataField="extension"
                    alignment="center"
                    minWidth={100}
                  />
                  <Column
                    caption="Active"
                    alignment="center"
                    dataField="is_active"
                    // cellRender={this.renderGridStatus}
                    minWidth={100}
                  />
                  <Column type="buttons" dataField="Actions" minWidth={150}>
                    <Button icon="edit" hint="Edit" onClick={selectPopupVisible} />
                    <Button hint="Delete" icon="trash" style={{ color: "red" }} onClick={deleteVisible} />
                  </Column>
                  <Toolbar>

                    <Item name="searchPanel" />
                    <Item name="exportButton" />
                    <Item location="after">
                      <MDButton variant="gradient" color="success" onClick={() => { setOpen(true); setUpdate(""); setPopupTitle("Add Admin Users"); }}>
                        Add&nbsp;
                        <Icon>add</Icon>&nbsp;
                      </MDButton>
                    </Item>
                  </Toolbar>

                </DataGrid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AdminUsers;
