/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable prettier/prettier */
import { Card, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Icon, IconButton, Switch, Autocomplete, TextField, Box, Tab, Tabs, Slide, Typography } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { Button, Column, DataGrid, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { Pager } from 'devextreme-react/tree-list';
import React, { useRef, useState, forwardRef } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import AddUpdateGroups from './add-update-groups'
import DeletePopup from "layouts/master-data/delete-popup";
import Caller from 'Apis/config/Caller';
import CustomStore from 'devextreme/data/custom_store';
import { getCommunicationGroup } from 'Apis/Auth/auth';
import { delete_communication_group, update_communication_group } from 'Apis/config/Url';
import CreateGroup from 'pages/organization/group-template/create-group/create-group'
import Cookies from "js-cookie";
import { RIGHTS, UNIVERSAL_SEARCH_COOKIE } from "Apis/config/ResponseHandler";
import { ScrollView } from 'devextreme-react';
import { makeStyles } from "@mui/styles";
import MDBadge from "components/MDBadge";
import Avatar from "@mui/material/Avatar";
import ChatPages from "pages/organization/communication-assignment/communication-assignment/chat-ui/chatPage"


const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    bottom: "0px",
    left: "2.5%",
    borderTopLeftRadius: '0.7rem',
    borderTopRightRadius: '0.7rem',
    top: 35
  }
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const isNotEmpty = (value) => {
  return value !== undefined && value !== null && value !== "";
}

const GroupData = new CustomStore({

  key: 'id',
  load(loadOptions) {
    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      "filter",
      "totalSummary",
      "group",
      "groupSummary",
      "isLoadingAll"
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {

        if (i === "skip") {
          let page = (loadOptions[i] + 25) / 25;
          if (page !== 1) {
            params += `page=${page}&`;
          }
        } else if (i === "sort") {
          let sortType = loadOptions[i][0]["desc"] ? "desc" : "asc";
          let sortColoumn = loadOptions[i][0]["selector"];
          params += `sortType=${sortType}&sortColoumn=${sortColoumn}&`;
        } else if (i === "filter") {
          let filterO = loadOptions[i][0]["filterValue"];
          params += `s=${filterO}&`;
        } else {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      }
    });
    params = params.slice(0, -1);
    let response = getCommunicationGroup(params)
      .then((data) => ({

        data: data.data.data,
        totalCount: data.data.count,
        next: data.data.next,
        previous: data.data.previous
      }));
    return response
  },

});


const Group = () => {

  let universalSearchCookie = Cookies.get(UNIVERSAL_SEARCH_COOKIE);
  Cookies.set(UNIVERSAL_SEARCH_COOKIE, "")

  let rights = Cookies.get(RIGHTS) ?? "";
  let rightsArray = rights.split(',') ?? [];

  const firstPath = location.pathname.split('/')[1];

  const userGrid = useRef();
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [popuptitle, setPopupTitle] = useState("Add");
  const [updateData, setUpdate] = useState("");
  const [openChat, setOpenChat] = useState(false);
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const popupDeleteText = "Do you want to delete this Group data?";
  const [currentChatUser, setCurrentChatUser] = useState({
    id: "",
    name: ""
  })

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setUpdate("");
      setOpen(false);
      setDeleteOpen(false);
      setOpenChat(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const selectPopupVisible = async (e) => {
    setUpdate(e.row.data);
    setPopupTitle("Update Group");
    setOpen(true);
    setOpenChat(false);
  }

  const deleteVisible = (e) => {
    setUpdate(e.row.data.id);
    setDeleteOpen(true);
  }

  const refreshGrid = () => {
    userGrid.current.instance.refresh();
  }
  const refreshGridDelete = () => {
    userGrid.current.instance.refresh();
  }

  const renderGridStatus = (data) => {
    let func = "";
    let label = "";
    if (data.data.is_active == "Yes") {
      func = true
      label = "Active"
    }
    else {
      func = false
      label = "Inactive"
    }

  }

  const renderDepartmentDesignation = (data) => {

    return <a href="#" onClick={() => selectPopupVisible(data)}>{data.data.name}</a>

  }

  const setProfile = (prof) => {
    setCurrentChatUser(prof)
  }

  const departments = ['ajay', 'new', 'sound']

  const onRowClick = (data) => {
    setOpenChat(true);
    setCurrentChatUser({
      id: data.data.id,
      name: data.data.first_name
    })
  }

  const CustomTab = ({ label, user }) => {
    return (
      <Tab
        value={label}
        label={
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Avatar alt={user.name} src={`https://material-ui.com/static/images/avatar/1.jpg`} />
            <Typography variant="h6" sx={{ marginLeft: '8px !important' }}>
              {user.name}
            </Typography>
          </Box>
        }
      />
    );
  };

  return (

    <>
      {deleteOpen && <DeletePopup deleteId={updateData} deleteText={popupDeleteText} open={deleteOpen} close={handleClose} path={delete_communication_group} refresh={refreshGridDelete} />}
      <MDBox>
        <Grid container spacing={6}>
          <Grid item xs={12}>

            <Dialog
              open={open}
              onClose={handleClose}
              maxWidth='lg'
              sx={{
                '.MuiDialogContent-root': { pl: 5, pr: 5, pb: 5, pt: 2 }
              }}
            >
              <DialogTitle
              ><MDBox
                color="white"
                bgColor="info"
                variant="gradient"
                borderRadius="lg"
                shadow="lg"
                opacity={1}
                p={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"

              >
                  <MDTypography variant="h4" color="white">{popuptitle}</MDTypography>
                  <IconButton
                    style={{ color: "white", cursor: "pointer" }}
                    sx={{
                      "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
                    }}
                    onClick={() => setOpen(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                </MDBox></DialogTitle>
              <DialogContent p={3}>
                <CreateGroup updateData={updateData} close={handleClose} refresh={refreshGrid} addLead={true} />
              </DialogContent>
            </Dialog>
            <MDBox p={3}>
              <DataGrid
                id="gridContainer"
                dataSource={GroupData}
                keyExpr="id"
                ref={userGrid}
                showBorders={true}
                remoteOperations={true}
                wordWrapEnabled={true}
                showColumnLines={false}
                rowAlternationEnabled={true}
                // onRowClick={onRowClick}
              >
                <Pager
                  visible={true}
                  displayMode="full"
                  showInfo={true}
                  showNavigationButtons={true} />
                <Paging defaultPageSize={25} />
                <SearchPanel
                  visible={true}
                  defaultText={universalSearchCookie}
                />
                <Export
                  enabled={true}
                  fileName="Communication Group"
                />
                <Editing
                  mode="row"
                  allowAdding={false}
                  allowUpdating={true}
                  useIcons={true}
                />
                <Column
                  caption="Whatsapp Group Name"
                  dataField="whatsapp_group_name"
                  alignment="center"
                  minWidth={100}
                />
                <Column
                  caption="Group Description"
                  dataField="group_description"
                  alignment="center"
                  minWidth={200}
                />


                {rightsArray.includes('100') && rightsArray.includes('108') && rightsArray.includes('119') &&
                  <Column type="buttons" dataField="Actions" minWidth={150}>
                    <Button icon="edit" hint="Edit" onClick={selectPopupVisible} />
                    <Button hint="Delete" icon="trash" style={{ color: "red" }} onClick={deleteVisible} />
                  </Column>
                }
                <Toolbar>
                  <Item name="searchPanel" locateInMenu="auto" />
                  <Item name="exportButton" locateInMenu="auto" />

                </Toolbar>

              </DataGrid>
            </MDBox>

          </Grid>
        </Grid>

        <Dialog
          fullScreen
          open={openChat}
          onClose={handleClose}
          TransitionComponent={Transition}
          sx={{
            width: "95%",

          }}
          classes={{
            paper: classes.dialog,

          }}
        >
          <DialogTitle
            sx={{
              padding: "0px",

            }}
          ><MDBox
            color="primary"
            variant="gradient"
            opacity={1}
            pl={2}
            pr={2}
            pt={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
              <MDTypography variant="h5" color="secondary"> <MDBadge variant="contained" container color="success" ></MDBadge> </MDTypography>
              <IconButton
                style={{ color: "black", cursor: "pointer" }}
                sx={{
                  "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
                }}
                onClick={() => setOpenChat(false)}
              >
                <CloseIcon />
              </IconButton>
            </MDBox>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="icon label tabs example"
                sx={{
                  width: "25%",
                  paddingLeft: 2,
                  fontSize: "small",
                  fontWeight: "600",
                }}>

                <CustomTab mg label="Harry" user={currentChatUser} value={0} />

              </Tabs>
            </Box>

          </DialogTitle>
          <DialogContent
            p={3}
            sx={{
              overflowY: 'hidden',
              height: '100vh',
              backgroundColor: "#eee",
              paddingTop: "17px !important"
            }}>
            {(tabValue == 0) && <ChatPages updateData={updateData} type={"chat"} refresh={refreshGrid} close={handleClose} setProfile={setProfile} />}

          </DialogContent>
        </Dialog>


      </MDBox>


    </>
  )
}

export default Group;










