/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import team2 from "assets/images/team-2.jpg";
import CabinIcon from '@mui/icons-material/Cabin';


// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import MDButton from "components/MDButton";
import { AppBar, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Icon, IconButton, List, ListItem, ListItemText, Slide, Switch, Tab, Tabs, TextField, Typography, useMediaQuery } from "@mui/material";
import React, { forwardRef, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { makeStyles } from "@mui/styles";
import { Button, Column, ColumnChooser, DataGrid, Lookup, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar, RequiredRule, NumericRule, RangeRule, StringLengthRule } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { Pager } from 'devextreme-react/tree-list';
import CloseIcon from '@mui/icons-material/Close';
import { UsersData } from "layouts/tables/data/mockdata";
import { GET } from "Apis/config/RequestType";
import { update_user } from "Apis/config/Url";
import { PUT } from "Apis/config/RequestType";
import DeletePopup from "layouts/master-data/delete-popup";
import { delete_user } from "Apis/config/Url";
import Caller from "Apis/config/Caller";
import { useNavigate } from "react-router-dom";
import { getEmployeeUserDetails } from "Apis/Auth/auth";
import CustomStore from "devextreme/data/custom_store";
import { change_user_status } from "Apis/config/Url";
import { POST } from "Apis/config/RequestType";
import { get_employee_user_details } from "Apis/config/Url";
import MDBadge from "components/MDBadge";
import Guid from 'devextreme/core/guid';
import { update_profile } from "Apis/config/Url";
import { getAllLeads } from "Apis/Auth/auth";
import { leads } from "Apis/config/Url";
import { get_product } from "Apis/config/Url";
import { account_tye_choice } from "Apis/Auth/auth";

import { delete_leads } from "Apis/config/Url";
import { Validator } from "devextreme-react";
import { getAllCrmAccount } from "Apis/Auth/auth";
import { get_all_crm_accounts } from "Apis/config/Url";
import { crm_account } from "Apis/config/Url";
import { delete_account } from "Apis/config/Url";
import { DELETE } from "Apis/config/RequestType";
import { contact_type_choice } from "Apis/Auth/auth";
import { getAllCrmContact } from "Apis/Auth/auth";
import { crm_contact } from "Apis/config/Url";
import { delete_contact } from "Apis/config/Url";

const useStyles = makeStyles({
    dialog: {
        position: 'absolute',
        bottom: "0px",
        left: "2.5%",
        top: 40
    }
});

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let updateRowData = {}
const employeeUserUserDetails = new CustomStore({
    key: 'id',
    load: (loadOptions) => {
        let params = "?";
        [
            "skip",
            "take",
            "requireTotalCount",
            "requireGroupCount",
            "sort",
            "filter",
            "totalSummary",
            "group",
            "groupSummary",
            "isLoadingAll"
        ].forEach((i) => {
            if (i in loadOptions && isNotEmpty(loadOptions[i])) {

                if (i === "skip") {
                    let page = (loadOptions[i] + 10) / 10;
                    if (page !== 1) {
                        params += `page=${page}&`;
                    }
                } else if (i === "sort") {
                    let sortType = loadOptions[i][0]["desc"] ? "desc" : "asc";
                    let sortColoumn = loadOptions[i][0]["selector"];
                    params += `sortType=${sortType}&sortColoumn=${sortColoumn}&`;
                } else if (i === "filter") {
                    let filterO = loadOptions[i][0]["filterValue"];
                    params += `s=${filterO}&`;
                } else {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            }
        }),
            params = params.slice(0, -1);
        return getAllCrmContact(params)
            .then((data) => ({
                data: data.data.data,
                totalCount: data.data.count,
                next: data.data.next,
                previous: data.data.previous
            }));
    },


    insert: (values) => Caller(crm_contact, values, POST, false, false).then((res) => {

        values: JSON.stringify(values)
    }),

    update: (key, values) => Caller(crm_contact + '?id=' + key, { ...updateRowData, ...values }, PUT, false, false).then((res) => {
        values: JSON.stringify(values)
    }),
    remove: (key) => Caller(delete_contact + '?id=' + key, "", DELETE, false, false).then((res) => {
        values: JSON.stringify(res)
    }),


});
const productData = new CustomStore({
    key: 'Value',
    loadMode: 'raw',
    load: (options) => {
        return Caller(get_product + "?isLoadingAll=true&id", '', GET, false, false).then((res) => {
            return res.data.data
        })
    }
})

const isNotEmpty = (value) => {
    return value !== undefined && value !== null && value !== "";
}

const onRowUpdate = (value) => {
    updateRowData = value.oldData
    delete updateRowData.id;
    delete updateRowData.created_by;
    delete updateRowData.created_at;
}

const RelatedContacts = () => {
    const classes = useStyles();
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [popuptitle, setPopupTitle] = useState("Employee Data 1");
    const [updateData, setUpdate] = useState("");
    const userGrid = useRef();
    const [open, setOpen] = useState(false);
    const [openLead, setOpenLead] = useState(false);
    const [changes, setChanges] = React.useState([]);
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            if (reason !== 'backdropClick') {
                setUpdate("");
                setOpen(false);
                setOpenLead(false);
                setDeleteOpen(false);
            }
        }
    };
    //   const [editRowKey, setEditRowKey] = React.useState(null);
    //   const onAddButtonClick =(e) => {
    //     const key = new Guid().toString();
    //     setChanges([{
    //       key,
    //       type: 'insert',
    //       insertAfterKey: e.row.key,
    //     }]);
    //     setEditRowKey(key);
    //   };

    //   const isAddButtonVisible = React.useCallback(({ row }) => !row.isEditing, []);

    //   const onRowInserted = React.useCallback((e) => {
    //     e.component.navigateToRow(e.key);
    //   }, []);


    function cellRender(data) {
        return <img src={team2} style={{ borderRadius: "50%", width: "2.5rem", height: "2.5rem" }} />;
    }

    const selectPopupVisible = async (e) => {

        const formData = new FormData();
        formData.append("id", e.row.data.id);
        await Caller(get_employee_user_details + "?take=10&requireTotalCount=true&s=&id=" + e.row.data.employee_user_id, "", GET, false, true).then(async (res) => {
            if (res.success === true) {
                setUpdate(res.data.data);
            }
        });
        setPopupTitle("Update Employee");
        setOpen(true);
    }

    const refreshGrid = () => {
        userGrid.current.instance.refresh();
    }
    const deleteVisible = (e) => {
        setUpdate(e.row.data.id);
        setDeleteOpen(true);
    }
    const updateEmployee = (e) => {
        return navigate("/employees/update/" + e.row.data.employee_guid);
    }

    const handleChanged = async (event, id) => {

        let status = "";
        if (event.target.checked === false) {
            status = 0
        }
        else {
            status = 1
        }
        const formData = new FormData();
        formData.append("id", id);
        formData.append("active", status);
        await Caller(change_user_status + "?user_type=employee", formData, POST, false, true)
            .then((data) => {
                if (data.success === true) {
                    refreshGrid();
                }
            });
    }

    const returnLoginStatus = (data) => {
        switch (data.value) {
            case 1:
                return <MDBadge badgeContent="Online" variant="gradient" color="success" size="xs" container />
            case 0:
                return <MDBadge badgeContent="Offline" variant="gradient" color="dark" size="xs" container />
            default:
                break;
        }
        // return <MDBadge badgeContent="Online" variant="gradient" color="success" container />
    }

    const renderGridStatus = (data) => {
        let func = "";
        let label = "";
        if (data.data.is_active == 'Yes') {
            func = true
            label = "Active"
        }
        else {
            func = false
            label = "Inactive"
        }

        return <FormControlLabel
            control={<Switch
                checked={func}
                color="secondary"
                onChange={(event) => handleChanged(event, data.data.employee_user_id)}
                inputProps={{ 'aria-label': 'controlled', role: 'switch' }}
            />}
            label={label} />
    }

    const renderProductColumn = (data) => {
        setPopupTitle(data.value.toUpperCase());
        return <MDTypography
            variant="h5"
            color="primary"
            textGradient
            display="inline-flex"
            onClick={() => setOpenLead(true)}
        >
            {data.value}&nbsp;&nbsp;Open
        </MDTypography>
    }

    let deleteText = "Do you want to delete this Employee?";
    return (
        <MDBox p={3}>
            <DataGrid
                id="gridContainer"
                keyExpr="id"
                dataSource={employeeUserUserDetails}
                ref={userGrid}
                showBorders={true}
                remoteOperations={true}
                wordWrapEnabled={true}
                showColumnLines={false}
                rowAlternationEnabled={true}
                allowColumnReordering={true}
                onRowUpdating={onRowUpdate}
            >
                <Pager
                    visible={true}
                    displayMode="full"
                    showInfo={true}
                    showNavigationButtons={true} />
                <Paging defaultPageSize={10} />
                <Column
                    caption="Contact Name"
                    alignment="center"
                    dataField="contact_name"
                    minWidth={150}

                >
                    <RequiredRule />
                    <StringLengthRule message="Maximum 50 character allowed." max={50} />
                </Column>

                <Column
                    caption="Contact Email"
                    dataField="contact_email"
                    alignment="center"
                    minWidth={150}

                >

                    <StringLengthRule message="Maximum 200 character allowed." max={200} />

                </Column>

                <Column
                    caption="Contact Type"
                    dataField="contact_type"
                    alignment="center"
                    minWidth={150}
                >
                    <RequiredRule />
                    <Lookup dataSource={contact_type_choice} valueExpr="lead_status" displayExpr="lead_status" />
                </Column>

                <Column
                    caption="Contact Company"
                    dataField="contact_company"
                    alignment="center"
                    minWidth={150}
                >
                    <RequiredRule />
                    <StringLengthRule message="Maximum 100 character allowed." max={100} />
                </Column>

                <Column
                    caption="Contact Priority"
                    dataField="contact_priority"
                    alignment="center"
                    minWidth={150}
                >
                    <StringLengthRule message="Maximum 100 character allowed." max={50} />
                </Column>
                <Column
                    caption="Comments"
                    dataField="comments"
                    alignment="center"
                    minWidth={150}
                >
                    <StringLengthRule message="Maximum 200 character allowed." max={200} />

                </Column>
                <Column
                    caption="Contact Title"
                    dataField="contact_title"
                    alignment="center"
                    minWidth={150}

                >

                    <StringLengthRule message="Maximum 200 character allowed." max={200} />

                </Column>

                <Column
                    caption="Contact Phone"
                    dataField="contact_phone"
                    alignment="center"
                    minWidth={150}

                >
                    <NumericRule />
                    <StringLengthRule message="Maximum 200 character allowed." min={10} max={12} />

                </Column>

            </DataGrid>

        </MDBox>
    )
}

export default RelatedContacts