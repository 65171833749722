/* eslint-disable no-unused-vars */
import Dashboard from "layouts/dashboard";
import Billing from "layouts/billing";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import Login from "layouts/authentication/sign-in/login";
import AdminUsers from "layouts/tables/users/admin-users";
import Lookup from "layouts/master-data/lookup/lookup";
import EmailTemplates from "layouts/master-data/email-templates/email-templates";
import Onboarding from "layouts/master-data/onboarding/onboarding";
import Products from "layouts/master-data/products/products";
import SendEmail from "layouts/master-data/send-email/send-email";
import CreatePassword from "layouts/authentication/create-password";
import EmployeesData2 from "layouts/tables/users/add-employees-data-2";
import Employees from "pages/organization/employees/employees";
import CreateEmployees from "pages/organization/employees/create";
import Designation from "pages/organization/designation/designation";
import BulkEmployeeUpload from "pages/organization/employees/bulk-employee-upload";
import UpdateProfile from "layouts/profile/components/Header/updateProfile";
import UpdateEmployeeErrors from "pages/organization/employees/update-employee-errors";
import AllLeads from "pages/organization/leads/all-leads/allLeads";
import LeadsNavbar from "LeadsNavbar/LeadsNavbar";
import ProdutAssignmentNavbar from "ProductAssignmentNavbar/ProdutAssignmentNavbar";
import Assignments from "Communication/assignments/assignments";
import CommunicationContacts from "Communication/communicationContacts/communicationContacts";
import Broadcast from "Communication/Broadcast/broadcast"
import Chats from "Communication/chat/chat";

import Calls from "stockoption/calls/calls";
import Strikes from "stockoption/symbols/strikes";

import AllPayments from "pages/organization/leads/payments/all_payments";
const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Update Profile",
    key: "updateprofile",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/updateprofile",
    component: <UpdateProfile />,
  },
  {
    type: "collapse",
    name: "Master Data",
    key: "master",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/products",
    component: <Products />,
  },
  {
    type: "collapse",
    name: "Lookup Options",
    key: "lookup",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/lookup",
    component: <Lookup />,
  },
  {
    type: "collapse",
    name: "Email-Templates",
    key: "emailtemplates",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/emailtemplates",
    component: <EmailTemplates />,
  },
  {
    type: "collapse",
    name: "Products",
    key: "products",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/products",
    component: <Products />,
  },
  {
    type: "collapse",
    name: "Admin Users",
    key: "adminusers",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/adminusers",
    component: <AdminUsers />,
  },
  {
    type: "collapse",
    name: "Employees",
    key: "employees",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/employees",
    component: <Employees />,
  },
  {
    type: "collapse",
    name: "Designation",
    key: "designation",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/designations",
    component: <Designation />,
  },
  {
    type: "collapse",
    name: "Departments",
    key: "departments",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/departments",
    component: <Designation />,
  }, {
    type: "collapse",
    name: "Product Assignment",
    key: "product-assignment",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/product-assignment",
    component: <ProdutAssignmentNavbar />,
  }, {
    type: "collapse",
    name: "assignments",
    key: "assignments",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/assignments",
    component: <Assignments />,
  }, {
    type: "collapse",
    name: "communicationContacts",
    key: "communicationContacts",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/communicationContacts",
    component: <CommunicationContacts />,

  }, {
    type: "collapse",
    name: "chat",
    key: "chat",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/chat",
    component: <Chats />,
  }, {
    type: "collapse",
    name: "broadcast",
    key: "broadcast",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/broadcast",
    component: <Broadcast />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "login",
    name: "Sign In",
    key: "authenticationsign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <Login />,
  },
  {
    name: "collapse",
    key: "authenticationcreate-password",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/create-password",
    component: <CreatePassword />
  },
  {
    type: "collapse",
    name: "Employees Data 1",
    key: "employeescreate",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/employees/create",
    component: <CreateEmployees />,
  },
  {
    type: "collapse",
    name: "Update Employees",
    key: "employeesupdate",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/employees/update-:id",
    component: <CreateEmployees />,
  },
  {
    type: "collapse",
    name: "Update Employees Errors",
    key: "bulk-employee-uploadupdateerrors",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/bulk-employee-upload/update-errors-:id",
    component: <UpdateEmployeeErrors />,
  },
  {
    type: "collapse",
    name: "Bulk Employee Upload",
    key: "bulk-employee-upload",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/bulk-employee-upload",
    component: <BulkEmployeeUpload />,
  },
  {
    type: "collapse",
    name: "Sales CRM",
    key: "sales_crm",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/sales",
    component: <LeadsNavbar />,
  },


  {
    type: "collapse",
    name: "calls",
    key: "calls",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/calls",
    component: <Calls />,
  },

  {
    type: "collapse",
    name: "strikes",
    key: "symbols",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/symbols",
    component: <Strikes />,
  },

  {
    type: "collapse",
    name: "all_payments",
    key: "all_payments",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/all_payment",
    component: <AllPayments />,
  },
];

export default routes;
