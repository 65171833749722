/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable prettier/prettier */
import { Card, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Icon, IconButton, Switch } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import { Button, Column, DataGrid, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { Pager } from 'devextreme-react/tree-list';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';

import AddUpdateCalls from './add-update-calls'

import DeletePopup from "layouts/master-data/delete-popup";
import Caller from 'Apis/config/Caller';
import { GET } from 'Apis/config/RequestType';
import CustomStore from 'devextreme/data/custom_store';
import { POST } from 'Apis/config/RequestType';
import { change_lookup_status } from 'Apis/config/Url';
import { delete_related_type_designation } from 'Apis/config/Url';
import Footer from 'examples/Footer';
import { getCallsData } from 'Apis/Auth/auth';

import moment from 'moment';
// moment.tz.setDefault("Asia/Kolkata");
import { LOGIN_AUTH_COOKIE, LOGIN_REFRESH_COOKIE, LEAD_ASSIGN_MODE, UNIVERSAL_SEARCH_COOKIE } from 'Apis/config/ResponseHandler';
import Cookies from 'js-cookie';

const isNotEmpty = (value) => {
  return value !== undefined && value !== null && value !== "";
}


/// Calls screen function.
const calls = () => {
  let universalSearchCookie = Cookies.get(UNIVERSAL_SEARCH_COOKIE);
  Cookies.set(UNIVERSAL_SEARCH_COOKIE, "")

  const callsData = new CustomStore({

    key: 'id',
    load(loadOptions) {
      let params = "?";
      [
        "skip",
        "take",
        "requireTotalCount",
        "requireGroupCount",
        "sort",
        "filter",
        "totalSummary",
        "group",
        "groupSummary",
        "isLoadingAll"
      ].forEach((i) => {

        if (i in loadOptions && isNotEmpty(loadOptions[i])) {

          if (i === "skip") {
            let page = (loadOptions[i] + 10) / 10;
            if (page !== 1) {
              params += `page=${page}&`;
            }
          } else if (i === "sort") {
            let sortType = loadOptions[i][0]["desc"] ? "desc" : "asc";
            let sortColoumn = loadOptions[i][0]["selector"];
            params += `sortType=${sortType}&sortColoumn=${sortColoumn}&`;
          } else if (i === "filter") {
            let filterO = loadOptions[i][0]["filterValue"];
            params += `s=${filterO}&`;

          } else {
            params += `${i}=${JSON.stringify(loadOptions[i])}&`;
          }
        }
      });


      params = params.slice(0, -1);
      return getCallsData(params)
        .then((data) => ({
          data: data.data.data,
          totalCount: data.data.count,
          next: data.data.next,
          previous: data.data.previous

        }));
    },

  });

  const firstPath = location.pathname.split('/')[1];

  const userGrid = useRef();
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [popuptitle, setPopupTitle] = useState("Add");
  const [updateData, setUpdate] = useState("");
  const popupDeleteText = firstPath == 'departments' ? "Do you want to delete this Department data?" : "Do you want to delete this Designation data?";

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setUpdate("");
      setOpen(false);
      setDeleteOpen(false);
    }
  };

  const selectPopupVisible = async (e) => {
    setUpdate(e.row.data);
    setOpen(true)
    switch (e) {
      case "edit":
        setOpen(true)
    }
    // await Caller(get_related_type_designation + "?id=" + e.row.data.id + "&page=1&s=&take=10", "", GET, false, true).then(async (res) => {
    //   if (res.success === true) {
    // setUpdate(res.data.data);
    // }
    // });
    setPopupTitle("Update Call");
    setOpen(true);
  }

  const deleteVisible = (e) => {
    setUpdate(e.row.data.id);
    setDeleteOpen(true);
  }

  const refreshGrid = () => {
    userGrid.current.instance.refresh();
  }
  const refreshGridDelete = () => {
    userGrid.current.instance.refresh();
  }

  const handleChanged = async (event, id) => {
    let current_id = id;
    let is_active = "";
    if (event.target.checked === false) {
      is_active = 0
    }
    else {
      is_active = 1
    }
    const formData = new FormData();
    formData.append("id", id);
    formData.append("status", is_active);
    await Caller(change_lookup_status, formData, POST, false, true)
      .then((data) => {
        if (data.success === true) {
          refreshGrid();
        }
      });
  }

  const renderGridStatus = (data) => {
    let func = "";
    let label = "";
    if (data.data.is_target_1_acheived == "Yes" || data.data.is_target_1_acheived == true) {
      return "Yes"
    }
    else {
      return "No"
    }
  }


  /* Format null value to empty  */
  const null_to_empty = (data) => {
    if (data.value == 'null') {
      return "";
    } else {
      return data.value;
    }

  }

  /// is_target_2_acheived
  const renderGridStatus1 = (data) => {
    let func = "";
    let label = "";
    if (data.data.is_target_2_acheived == "Yes" || data.data.is_target_2_acheived == true) {
      return "Yes"
    }
    else {
      return "No"
    }
  }

  /// is_target_3_acheived
  const renderGridStatus2 = (data) => {
    let func = "";
    let label = "";
    if (data.data.is_target_3_acheived == "Yes" || data.data.is_target_3_acheived == true) {
      return "Yes"
    }
    else {
      return "No"
    }
  }

  /// is_stop_loss_achieved
  const renderGridStatus3 = (data) => {
    let func = "";
    let label = "";
    if (data.data.is_stop_loss_achieved == "Yes" || data.data.is_stop_loss_achieved == true) {
      return "Yes"
    }
    else {
      return "No"
    }
  }

  // is_app
  const renderGridStatus4 = (data) => {
    let func = "";
    let label = "";
    if (data.data.is_app == "Yes" || data.data.is_app == true) {
      return "Yes"
    }
    else {
      return "No"
    }
  }

  const renderCreatedDateCallData = (data) => {
    return <div> {moment(data.data.created_at).format('DD/MM/YYYY hh:mm:ss')} </div>
  }

  const renderUpdatedDateCallData = (data) => {
    if (data.data.updated_at == null || data.data.updated_at == "") {
      return <div> {""} </div>

    } else {
      return <div> {moment(data.data.updated_at).format('DD/MM/YYYY hh:mm:ss')} </div>
    }
  }



  const renderCallData = (data) => {
    return <a href="#" onClick={() => selectPopupVisible(data)}>{data.data.name}</a>
  }

  return (
    <DashboardLayout>
      {deleteOpen && <DeletePopup deleteId={updateData} deleteText={popupDeleteText} open={deleteOpen} close={handleClose} path={delete_related_type_designation} refresh={refreshGridDelete} />}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h4" color="white">
                  Calls
                </MDTypography>
              </MDBox>
              <Dialog
                open={open}
                onClose={handleClose}
                maxWidth='sx'
                sx={{
                  '.MuiDialogContent-root': { pl: 5, pr: 5, pb: 5, pt: 2 }
                }}
              >
                <DialogTitle
                ><MDBox
                  color="white"
                  bgColor="info"
                  variant="gradient"
                  borderRadius="lg"
                  shadow="lg"
                  opacity={1}
                  p={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                // textAlign="center"
                >
                    <MDTypography variant="h4" color="white">{popuptitle}</MDTypography>
                    <IconButton
                      style={{ color: "white", cursor: "pointer" }}
                      sx={{
                        "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
                      }}
                      onClick={() => setOpen(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </MDBox></DialogTitle>
                <DialogContent p={3}>
                  <AddUpdateCalls updateData={updateData} close={handleClose} refresh={refreshGrid} />
                </DialogContent>
              </Dialog>
              <MDBox p={3}>
                <DataGrid
                  id="gridContainer"
                  dataSource={callsData}
                  keyExpr="id"
                  ref={userGrid}
                  showBorders={true}
                  remoteOperations={true}
                  wordWrapEnabled={true}
                  showColumnLines={false}
                  rowAlternationEnabled={true}
                >
                  <Pager
                    visible={true}
                    displayMode="full"
                    showInfo={true}
                    showNavigationButtons={true} />
                  <Paging defaultPageSize={10} />
                  <SearchPanel
                    visible={true}
                    defaultText={universalSearchCookie}
                  />
                  <Export
                    enabled={true}
                    fileName="Calls"
                  />
                  <Editing
                    mode="row"
                    allowAdding={false}
                    allowUpdating={true}
                    useIcons={true}
                  />

                  <Column
                    caption="Product"
                    dataField="product"
                    alignment="center"
                    minWidth={100}
                  />

                  <Column
                    caption="Call Type"
                    dataField="call_type"
                    alignment="center"
                    minWidth={100}
                  />

                  <Column
                    caption="Stock Code"
                    dataField="stock_code"
                    alignment="center"
                    minWidth={100}
                  />

                  <Column
                    caption="Buy Sell"
                    dataField="buy_sell"
                    alignment="center"
                    minWidth={100}
                  />

                  <Column
                    caption="Price"
                    dataField="price"
                    alignment="center"
                    minWidth={100}
                  />

                  <Column
                    caption="Expiry Date"
                    dataField="expiry_date"
                    alignment="center"
                    minWidth={100}
                    cellRender={null_to_empty}
                  />

                  <Column
                    caption="Option"
                    dataField="option_name"
                    alignment="center"
                    minWidth={100}
                    cellRender={null_to_empty}
                  />

                  <Column
                    caption="Strike Rate"
                    dataField="strike_price"
                    alignment="center"
                    minWidth={100}
                    cellRender={null_to_empty}
                  />

                  <Column
                    caption="Target 1"
                    dataField="target_1"
                    alignment="center"
                    minWidth={100}
                  />
                  <Column
                    caption="Target 2"
                    dataField="target_2"
                    alignment="center"
                    minWidth={100}
                  />
                  <Column
                    caption="Target 3"
                    dataField="target_3"
                    alignment="center"
                    minWidth={100}
                  />

                  <Column
                    caption="Is Target 1 Acheived"
                    dataField="is_target_1_acheived"
                    alignment="center"
                    minWidth={150}
                    cellRender={renderGridStatus}
                  />
                  <Column
                    caption="Is Target 2 Acheived"
                    dataField="is_target_2_acheived"
                    alignment="center"
                    minWidth={150}
                    cellRender={renderGridStatus1}
                  />
                  <Column
                    caption="Is Target 3 Acheived"
                    dataField="is_target_3_acheived"
                    alignment="center"
                    cellRender={renderGridStatus2}
                    minWidth={150}
                  />


                  <Column
                    caption="Stop Loss"
                    dataField="stop_loss"
                    alignment="center"
                    minWidth={100}
                  />

                  <Column
                    caption="Is Stop Loss Achieved"
                    dataField="is_stop_loss_achieved"
                    alignment="center"
                    cellRender={renderGridStatus3}
                    minWidth={200}
                  />

                  <Column
                    caption="is app"
                    dataField="is_app"
                    alignment="center"
                    cellRender={renderGridStatus4}
                    minWidth={100}
                  />

                  <Column
                    caption="Created At"
                    dataField="created_at"
                    alignment="center"
                    minWidth={200}
                    cellRender={renderCreatedDateCallData}
                  />

                  <Column
                    caption="Updated At"
                    dataField="updated_at"
                    alignment="center"
                    minWidth={200}
                    cellRender={renderUpdatedDateCallData}
                  />

                  <Column type="buttons" dataField="Actions" minWidth={150}>
                    <Button icon="edit" hint="Edit" onClick={(e) => selectPopupVisible(e)} />
                    {/* <Button hint="Delete" icon="trash" style={{ color: "red" }} onClick={deleteVisible} /> */}
                  </Column>
                  <Toolbar>
                    <Item name="searchPanel" locateInMenu="auto" />
                    <Item name="exportButton" locateInMenu="auto" />
                    <Item location="after" locateInMenu="auto">
                      <MDButton variant="gradient" color="success" onClick={() => { setOpen(true); setUpdate(""); setPopupTitle("Add Calls"); }}>
                        Add&nbsp;
                        <Icon>add</Icon>&nbsp;
                      </MDButton>
                    </Item>
                  </Toolbar>

                </DataGrid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  )
}

export default calls