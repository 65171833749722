/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components



// Data
import MDButton from "components/MDButton";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Icon, IconButton, Switch, TextField, useMediaQuery } from "@mui/material";
import React, { useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { Button, Column, ColumnChooser, DataGrid, Lookup, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar, RequiredRule } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { Pager } from 'devextreme-react/tree-list';
import CloseIcon from '@mui/icons-material/Close';
import { UsersData } from "layouts/tables/data/mockdata";
import { GET } from "Apis/config/RequestType";
import { update_user } from "Apis/config/Url";
import { PUT } from "Apis/config/RequestType";
import DeletePopup from "layouts/master-data/delete-popup";
import { delete_user } from "Apis/config/Url";
import Caller from "Apis/config/Caller";
import { useNavigate } from "react-router-dom";
import { getEmployeeUserDetails } from "Apis/Auth/auth";
import CustomStore from "devextreme/data/custom_store";
import { change_user_status } from "Apis/config/Url";
import { POST } from "Apis/config/RequestType";
import { get_employee_user_details } from "Apis/config/Url";
import MDBadge from "components/MDBadge";
import Guid from 'devextreme/core/guid';
import { update_profile } from "Apis/config/Url";
import { leads } from "Apis/config/Url";
import { get_product } from "Apis/config/Url";
import { assigned_products } from "Apis/config/Url";
import { getAllAssignedProductUser } from "Apis/Auth/auth";
import { get_team_member_list } from "Apis/config/Url";
import { SelectBox } from "devextreme-react";
import { DELETE } from "Apis/config/RequestType";
import { useSnackbar } from 'notistack';
let updateRowData = {}




const employeeUserUserDetails = new CustomStore({
  key: 'id',
  load: (loadOptions) => {

    let testleadValue = loadOptions?.filter?.find(item => Array.isArray(item) && item[1] === "=" && item[0] === "Actions")?.[2];
   
    clearLookUpCache();
    let params = "?";

    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      "filter",
      "totalSummary",
      "group",
      "groupSummary",
      "isLoadingAll"
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        if (i === "skip") {
          let page = (loadOptions[i] + 10) / 10;
          if (page !== 1) {
            params += `page=${page}&`;
          }
        } else if (i === "sort") {
          let sortType = loadOptions[i][0]["desc"] ? "desc" : "asc";
          let sortColoumn = loadOptions[i][0]["selector"];
          params += `sortType=${sortType}&sortColoumn=${sortColoumn}&`;
        }

        else if (i === "filter") {
          // let filterO = loadOptions[i][0]["filterValue"];
          let filterO = testleadValue;
          params += `s=${filterO}&`;
        }
        else {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      }
    });

    params = params.slice(0, -1);
    return getAllAssignedProductUser(params)
      .then((data) => ({
        data: data.data.data,
        totalCount: data.data.count,
        next: data.data.next,
        previous: data.data.previous
      }))
  },
  // Other methods...

  insert: (values) => Caller(assigned_products, values, POST, false, false).then((res) => {


    values: JSON.stringify(values)

    // console.log("INSERT RES = ", res);

    if (res.success == true) {
      console.log("TRUE");
    } else {
      console.log("FALSE");

      // const { enqueueSnackbar } = useSnackbar();
      // enqueueSnackbar('Failed to insert data', { variant: 'error' });


      // enqueueSnackbar("Lead Info Updated Successfully.", {
      //   variant: "success",
      //   autoHideDuration: 1000,
      //   TransitionProps: { direction: "left" },
      //   anchorOrigin: { horizontal: "right", vertical: "top" },
      // });

      // const showSnackbar = () => {
      //   enqueueSnackbar("Lead Info Updated Successfully.", {
      //     variant: "success",
      //     autoHideDuration: 1000,
      //     TransitionProps: { direction: "left" },
      //     anchorOrigin: { horizontal: "right", vertical: "top" },
      //   });
      // }

      // showSnackbar();

    }

  }),

  update: (key, values) => Caller(leads + '?id=' + key, { ...updateRowData, ...values }, PUT, false, false).then((res) => {
    values: JSON.stringify(values)
  }),

  remove: (key) => Caller(assigned_products + '?id=' + key, "", DELETE, false, false).then((res) => {
    values: JSON.stringify(res)
  }),
});


const productData = new CustomStore({
  key: 'Value',
  loadMode: 'raw',
  load: (options) => {
    return Caller(get_product + "?isLoadingAll=true&id", '', GET, false, false).then((res) => {
      return res.data.data
    })
  }
});


const teamMemberData = new CustomStore({
  key: 'Value',
  loadMode: 'raw',
  load: (options) => {
    return Caller(get_team_member_list, '', GET, false, false).then((res) => {

      return res.data
    })
  }
});


const clearLookUpCache = () => {
  productData.clearRawDataCache();
  teamMemberData.clearRawDataCache();
}

const isNotEmpty = (value) => {
  return value !== undefined && value !== null && value !== "";
}

const onRowUpdate = (value) => {
  updateRowData = value.oldData
  delete updateRowData.id;
  delete updateRowData.product_id;
  delete updateRowData.employee_user_id;
  delete updateRowData.lead_status_name;
  delete updateRowData.product_name;
}

const ProuctAssignmentTab = () => {

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [popuptitle, setPopupTitle] = useState("Employee Data 1");
  const [updateData, setUpdate] = useState("");
  const userGrid = useRef();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [changes, setChanges] = React.useState([]);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      if (reason !== 'backdropClick') {
        setUpdate("");
        setOpen(false);
        setDeleteOpen(false);
      }
    }
  };

  // const showSnackbar = () => {
  //   enqueueSnackbar("Lead Info Updated Successfully.", {
  //     variant: "success",
  //     autoHideDuration: 1000,
  //     TransitionProps: { direction: "left" },
  //     anchorOrigin: { horizontal: "right", vertical: "top" },
  //   });
  // }

  function cellRender(data) {
    return <img src={team2} style={{ borderRadius: "50%", width: "2.5rem", height: "2.5rem" }} />;
  }

  const selectPopupVisible = async (e) => {
    const formData = new FormData();
    formData.append("id", e.row.data.id);
    await Caller(get_employee_user_details + "?take=10&requireTotalCount=true&s=&id=" + e.row.data.employee_user_id, "", GET, false, true).then(async (res) => {
      if (res.success === true) {
        setUpdate(res.data.data);
      }
    });
    setPopupTitle("Update Employee");
    setOpen(true);
  }

  const groupChanged = () => {

    Caller(assigned_products + "?take=10&requireTotalCount=true&product_id=30", '', GET, false, false).then((res) => {

      return employeeUserUserDetails(res.data);
    });
    refreshGrid();

  }


  const refreshGrid = () => {
    userGrid.current.instance.refresh();
  }
  const deleteVisible = (e) => {
    setUpdate(e.row.data.employee_user_id);
    setDeleteOpen(true);
  }
  const updateEmployee = (e) => {
    return navigate("/employees/update/" + e.row.data.employee_guid);
  }

  const handleChanged = async (event, id) => {

    let status = "";
    if (event.target.checked === false) {
      status = 0
    }
    else {
      status = 1
    }
    const formData = new FormData();
    formData.append("id", id);
    formData.append("active", status);
    await Caller(change_user_status + "?user_type=employee", formData, POST, false, true)
      .then((data) => {
        if (data.success === true) {
          refreshGrid();
        }
      });
  }

  const returnLoginStatus = (data) => {
    switch (data.value) {
      case 1:
        return <MDBadge badgeContent="Online" variant="gradient" color="success" size="xs" container />
      case 0:
        return <MDBadge badgeContent="Offline" variant="gradient" color="dark" size="xs" container />
      default:
        break;
    }
    // return <MDBadge badgeContent="Online" variant="gradient" color="success" container />
  }

  const setInitialValues = (e) => {
    clearLookUpCache();
  }

  const renderGridStatus = (data) => {
    let func = "";
    let label = "";
    if (data.data.is_active == 'Yes') {
      func = true
      label = "Active"
    }
    else {
      func = false
      label = "Inactive"
    }

    return <FormControlLabel
      control={<Switch
        checked={func}
        color="secondary"
        onChange={(event) => handleChanged(event, data.data.employee_user_id)}
        inputProps={{ 'aria-label': 'controlled', role: 'switch' }}
      />}
      label={label} />
  }

  let deleteText = "Do you want to delete this Employee?";

  return (


    <MDBox pt={2} pb={1}>
      {deleteOpen && <DeletePopup deleteId={updateData} open={deleteOpen} deleteText={deleteText} close={handleClose} refresh={refreshGrid} path={delete_user + "?user_type=employee"} />}



      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='sm'
        sx={{
          '.MuiDialogContent-root': { pl: 5, pr: 5, pb: 5, pt: 2 }
        }}
      >
        <DialogTitle
        ><MDBox
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          shadow="lg"
          opacity={1}
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        // textAlign="center"
        >
            <MDTypography variant="h4" color="white">{popuptitle}</MDTypography>
            <IconButton
              style={{ color: "white", cursor: "pointer" }}
              sx={{
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
              }}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </MDBox></DialogTitle>

      </Dialog>
      {/* <EmployeesData1 data={updateData} close={handleClose} />
              <EmployeesData2 data={updateData} close={handleClose} /> */}
      <MDBox p={3}>
        <DataGrid
          id="gridContainer"
          keyExpr="id"
          dataSource={employeeUserUserDetails}
          ref={userGrid}
          showBorders={true}
          remoteOperations={true}
          wordWrapEnabled={true}
          showColumnLines={false}
          rowAlternationEnabled={true}
          allowColumnReordering={true}
          onRowUpdating={onRowUpdate}
          onInitNewRow={setInitialValues}
        >
          <ColumnChooser
            enabled={true}
            mode="select"
          />
          <Pager
            visible={true}
            displayMode="full"
            showInfo={true}
            showNavigationButtons={true} />
          <Paging defaultPageSize={10} />
          <SearchPanel
            visible={true}
          />
          <Export
            enabled={true}
            fileName="Assigned Products"
          />
          <Editing
            mode="row"
            allowAdding={true}
            allowUpdating={false}
            useIcons={true}
            allowDeleting={true}
          />
          <Column
            caption="Product"
            alignment="center"
            dataField="product_id"
            minWidth={150}
            searchExpr="product_name"
          >
            <Lookup dataSource={productData} valueExpr="product_id" displayExpr="product_name" />
            <RequiredRule />
          </Column>

          <Column
            caption="Assigned To"
            dataField="employee_user_id"
            alignment="center"
            minWidth={150}
          >
            <Lookup dataSource={teamMemberData} valueExpr="employee_user_id" displayExpr="name" />
            <RequiredRule />
          </Column>




          <Column type="buttons" dataField="Actions" minWidth={150}>
            {/* <Button icon="edit" hint="Edit" onClick={selectPopupVisible} />
                    <Button hint="Delete" icon="trash" style={{ color: "red" }} onClick={deleteVisible} /> */}
          </Column>
          <Toolbar>
            {/*<Item location="before" name="searchPanel" >
                  <SelectBox  width="225"  valueExpr="product_id"  displayExpr="product_name" dataSource={productData} onValueChanged={groupChanged}></SelectBox>
                  </Item>*/}
            <Item name="searchPanel" />
            <Item name="exportButton" />
            <Item name="columnChooserButton"
              locateInMenu="auto" />
            <Item location="after" name='addRowButton' showText='always' title='a' >
              {/* <MDButton variant="gradient" color="success" onClick={onAddButtonClick}>
                        Add&nbsp;
                        <Icon>add</Icon>&nbsp;
                      </MDButton> */}


            </Item>
          </Toolbar>

        </DataGrid>

      </MDBox>



    </MDBox>
  );
}

export default ProuctAssignmentTab;