import { get_employee_user_details } from 'Apis/config/Url';
import { get_related_type_designation } from 'Apis/config/Url';
import { import_employee_logs } from 'Apis/config/Url';
import { import_employee_errors } from 'Apis/config/Url';
import { assigned_products } from 'Apis/config/Url';
import { get_all_crm_contact } from 'Apis/config/Url';
import { all_activities } from 'Apis/config/Url';
import { lead_import_erros } from 'Apis/config/Url';
import { lead_import_logs } from 'Apis/config/Url';
import { get_all_crm_accounts } from 'Apis/config/Url';
import { get_all_deals_lead } from 'Apis/config/Url';
import { all_leads } from 'Apis/config/Url';
import { communication_contact } from 'Apis/config/Url';
import { communication_group } from 'Apis/config/Url';
import { getBroadcast } from 'Apis/config/Url';
import { get_product, get_admin_user, get_lookup, send_password_mail } from 'Apis/config/Url';
import Cookies from 'js-cookie';

import Caller from '../config/Caller';
import { DELETE, GET, POST } from '../config/RequestType';

import { RIGHTS } from "../config/ResponseHandler";
import { get_calls } from 'Apis/config/Url';
import { get_symbols } from 'Apis/config/Url';

import { get_payment } from 'Apis/config/Url';

import { getAssignedCommuniction } from 'Apis/config/Url';
import { getAssignedTeamMember } from 'Apis/config/Url';
import { getTemplateFromTable } from 'Apis/config/Url';
import { chat_history } from 'Apis/config/Url';
import { user_chat_history } from 'Apis/config/Url';
import { get_lead_employees_city } from 'Apis/config/Url';

export const getAdminUserDetails = async (param, payload) => {
    let data = false;

    data = await Caller(get_admin_user + param, payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}
export const getEmployeeUserDetails = async (param, payload) => {
    let data = false;

    data = await Caller(get_employee_user_details + param, payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}

export const getProductDetails = async (param, payload) => {
    let data = false;
    data = await Caller(get_product + param + "&id=", payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}

export const getLookupData = async (param, payload) => {
    let data = false;
    data = await Caller(get_lookup + param + "&id=", payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}

export const sendPasswordMail = async (param, payload) => {
    let data = false;

    data = await Caller(send_password_mail + "?id=" + param, payload, POST, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}

export const getDesignationDepartment = async (param, payload) => {

    const firstPath = location.pathname.split('/')[1];

    let data = false;
    data = await Caller(get_related_type_designation + param + "&id=&req_type=" + firstPath, payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}
export const getAllLeads = async (param, payload) => {
    let data = false;
    data = await Caller(all_leads + (param ? param + "&id=" : ""), payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}

export const getEmployeeCity = async () => {
    let data = false;
    data = await Caller(get_lead_employees_city, '', GET, false, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    // console.log("city = ", data.data.data);
    return data.data.data;
}

export const getAllCrmAccount = async (param, payload) => {
    let data = false;
    data = await Caller(get_all_crm_accounts + param + "&id=", payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}


export const getAllCrmContact = async (param, payload) => {
    let data = false;
    data = await Caller(get_all_crm_contact + param + "&id=", payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}

export const getImportErrors = async (param, payload) => {
    let data = false;

    data = await Caller(import_employee_errors + param + "&id=", payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}
export const getImportLogs = async (param, payload) => {
    let data = false;

    data = await Caller(import_employee_logs + param + "&id=", payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}

export const getAllAssignedProductUser = async (param, payload) => {
    let data = false;
    data = await Caller(assigned_products + param + "&employee_user_id=", payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}
// api to get all deals
export const getAllDeals = async (param, payload) => {
    let data = false;
    data = await Caller(get_all_deals_lead + param + "&id=", payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}

export const getAllActivities = async (param, payload) => {
    let data = false;
    data = await Caller(all_activities + param + "&lead_id=", payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}

export const getLeadImportLogs = async (param, payload) => {
    let data = false;

    data = await Caller(lead_import_logs + param + "&id=", payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}
export const getImportErrorsLead = async (param, payload) => {
    let data = false;

    data = await Caller(lead_import_erros + param + "&id=", payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}

export const getCallsData = async (param, payload) => {
    let data = false;

    data = await Caller(get_calls + param + "&id=", payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}

export const getSymbols = async (param, payload) => {
    let data = false;
    data = await Caller(get_symbols + param, payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}

export const getAllSymbol = async (param, payload) => {
    let data = false;

    data = await Caller("get_all_symbols" + param + "&id=", payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}

export const getPaymentsData = async (param, payload) => {
    let data = false;

    // data = await Caller(get_payment + param + "&id=", payload, GET, false)
    data = await Caller(get_payment + param + "", payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}

// export const getAssignedCommunication = async (param, payload) => {
export const getAllAssignedCommunication = async (param, payload) => {
    let data = false;
    data = await Caller(getAssignedCommuniction + param + "", payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}

export const getAllAssignedTeamMember = async (param, payload) => {
    let data = false;
    data = await Caller(getAssignedTeamMember + param + "", payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}

export const getCommunicationContact = async (param, payload) => {
    let data = false;
    data = await Caller(communication_contact + param + "", payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}

export const getCommunicationGroup = async (param, payload) => {
    let data = false;
    data = await Caller(communication_group + param + "", payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}


export const getCommunicationBroadcast = async (param, payload) => {
    let data = false;
    data = await Caller(getBroadcast + param + "", payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}

export const getWhatsAppChatHistory = async (param, payload) => {
    let data = false;
    data = await Caller(chat_history + param + "", payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}

export const getWhatsAppUserChatHistory = async (param, payload) => {
    let data = false;
    data = await Caller(user_chat_history + param + "", payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}


export const getTemplateFromTableData = async (param, payload) => {
    let data = false;
    data = await Caller(getTemplateFromTable + param + "", payload, GET, false)
        .catch(error => { alert(JSON.stringify(error.message)); })
    return data;
}

export const department = ["Dep A", "Dep B", "Dep C"];
export const designation = ["CEO", "Market Head", "Supervisor"];
export const demat_roles = ["Admin", "Director", "Manager", "Team Leader", "Team Member"];
export const employment_type = ["Temporary", "Consultant", "Permanent"];
export const employment_status = ['Active', 'Inactive', 'Terminated'];
export const lead_status = ['New Lead', 'Follow Up', 'Interested', 'Closed'];
export const steps = ['New Lead', 'Follow Up', 'Interested', 'Closed'];
export const subSteps = ['Open', 'Call back', 'Not Reachable'];
export const leadSubstagesForCall = ['Open', 'Callback', 'Not Reachable', 'Interested'];

export const permissions = Cookies.get(RIGHTS);

export const account_tye_choice = [{ lead_status: 'Client', status: 'Client' }, { lead_status: 'Vendor', status: 'Vendor' }, { lead_status: 'Partner', status: 'Partner' }]
export const contact_type_choice = [{ lead_status: 'Qualified Lead', status: 'Qualified Lead' }, { lead_status: 'Customer', status: 'Customer' }, { lead_status: 'Partner', status: 'Partner' }, { lead_status: 'Vendor', status: 'Vendor' }]
export const leadStatus = [{ lead_status: 'New Lead', status_type: 'New Lead' }, { lead_status: 'Follow Up', status_type: 'Follow Up' }, { lead_status: 'Interested', status_type: 'Interested' }, { lead_status: 'Closed', status_type: 'Closed' }, { lead_status: 'Not Interested', status_type: 'Not Interested' }]
export const leadSubStatus = [{ lead_sub_status: 'Open', status_type: 'New Lead' }, { lead_sub_status: 'Callback', status_type: 'Follow Up' }, { lead_sub_status: 'Not Reachable', status_type: 'Follow Up' }]

export const dealStage = [{ deal_stage: 'New', stage: 'New' }, { deal_stage: 'Discovery', stage: 'Discovery' }, { deal_stage: 'Proposal', stage: 'Proposal' }, { deal_stage: 'Negotiation', stage: 'Negotiation' }, { deal_stage: 'Won', stage: 'Won' }, { deal_stage: 'Lost', stage: 'Lost' }]
export const dealPriority = [{ deal_priority: 'High', priority_at: 'High' }, { deal_priority: 'Medium', priority_at: 'Medium' }, { deal_priority: 'Low', priority_at: 'Low' }]
export const applyLeadFilter = new FormData();


export const departments = ['Sales & Marketing', 'Support', 'Compliance']
export const product = ['Advisory', 'Course', 'Trading Demat Account', 'Mutual Fund', 'Wealth', 'Insurance']

