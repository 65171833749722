/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
/* eslint-disable prettier/prettier */
import { ExpandMore } from '@mui/icons-material';
import { Box, DialogActions, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Snackbar, TextField } from '@mui/material'
import Caller from 'Apis/config/Caller';
import { GET, POST } from 'Apis/config/RequestType';
import { update_lookup } from 'Apis/config/Url';
import { get_lookup_type, create_lookup } from 'Apis/config/Url';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

const AddUpdateLookup = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [disabledSave, setDisabledSave] = useState();
    const [values, setAllValues] = useState({
        option_type: [],
        option_type_id: "",
        option_value: "",
        status: "",
    })

    const [errors, setAllErrors] = useState({
        option_type: '',
        option_value: "",
        status: "",
    })


    const textUpdate = (e) => {
        const re = /^[a-zA-Z0-9 ]+$/;
        if (re.test(e.target.value) || (e.target.value === "")) {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }))
        }
        setAllErrors((values) => ({
            ...values,
            [`${e.target.name}`]: ''
        }));
    }

    const [isInitialRender, setIsInitialRender] = useState(true);
    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);
            Caller(get_lookup_type, "", GET, false, true).then(async (res) => {
                if (res.success === true) {
                    await setAllValues((values) => ({
                        ...values,
                        option_type: res.data
                    }));
                }
            });
            if (props.updateData) {
                setAllValues((values) => ({
                    ...values,
                    option_value: props.updateData[0].option_value,
                    option_type_id: props.updateData[0].type_id
                }))
            }

        }
    }, [])

    const handleOptionTypeChange = async (e) => {
        await setAllValues((values) => ({ ...values, option_type_id: e.target.value }));
        // await setFirmDetails({ option: e.target.value });
    }

    const addLookup = async (event) => {
        event.target.classList.add("was-validated");
        setDisabledSave('disabled');
        event.preventDefault();
        event.stopPropagation();
        const formData = new FormData();
        if (props.updateData) {
            formData.append("id", props.updateData[0].option_id)
        }
        formData.append("option_value", values.option_value);
        formData.append('option_type', values.option_type_id);

        if (props.updateData) {
            await Caller(update_lookup, formData, POST, false, true).then((res) => {
                if (res.success === true) {
                    enqueueSnackbar('Lookup Options updated successfully!', { variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
                    props.refresh();
                    props.close();

                }
                else if (res.success === false) {
                    setDisabledSave('');
                    if (res.message == 'Department/Role name already exists') {
                    enqueueSnackbar(res.message, { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });

                        
                    } else {
                        for (const [key, value] of Object.entries(res.message['errors'])) {
                            setAllErrors((values) => ({
                                ...values,
                                [`${key}`]: value,
                            }))
                        }
                    }
                }
            })
                // .catch(error => { alert(JSON.stringify(error.message)); })
        }
        else {
            await Caller(create_lookup, formData, POST, false, true).then((res) => {
                if (res.success === true) {
                    enqueueSnackbar('Lookup Options added successfully.', { variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
                    props.refresh();
                    props.close();
                }
                else if (res.success === false) {
                    
                    setDisabledSave('');
                    if (res.message == 'Department/Role name already exists') {
                    enqueueSnackbar(res.message, { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });

                        
                    } else {
                        for (const [key, value] of Object.entries(res.message['errors'])) {
                            setAllErrors((values) => ({
                                ...values,
                                [`${key}`]: value,
                            }))
                        }
                    }
                    
                }
            })
                // .catch(error => { alert(JSON.stringify(error.message)); })
        }

    }

    const allTrue = () => {
        if (values.option_type_id && values.option_value) {
            return false;
        }
        return true;
    }
    return (
        <Box
            component="form"
            onSubmit={addLookup}
            sx={{
                '#demo-row-radio-buttons-group-label': { fontSize: 'medium', textAlign: "left" },
                '.role-select-custom': { width: "100%" },
                '.add-label': { pb: 1 },
                '.radio-button-custom': { fontSize: 'small' }
            }}
            noValidate
        >
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <InputLabel className='add-label'>Lookup Name <sup style={{ color: "red" }}>*</sup></InputLabel>
                    <FormControl className='role-select-custom'>
                        <Select
                            // IconComponent={() => (
                            //     <ExpandMore className="dropdown-arrow" />
                            // )}
                            style={{ height: 45 }}
                            displayEmpty
                            disabled={props.updateData ? true : false}
                            defaultValue={values.option_type_id}
                            value={values.option_type_id}
                            onChange={handleOptionTypeChange}
                            required
                        >
                            <MenuItem value="">--Select--</MenuItem>
                            {values.option_type?.map((e, key) => {
                                return <MenuItem key={key} value={e.type_id} >{e.name}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <InputLabel className='add-label'>Option <sup style={{ color: "red" }}>*</sup></InputLabel>
                    <TextField
                        required
                        id="outlined-required"
                        name='option_value'
                        value={values.option_value}
                        error={!!errors.option_value}
                        helperText={errors.option_value === "" ? '' : errors.option_value}
                        style={{ width: "100%" }}
                        InputProps={{
                            inputProps: {
                                maxLength: 100, minLength: 1
                            }
                        }}
                        onChange={textUpdate}
                    />
                </Grid>

                {/* <Grid item xs={6} sm={6} md={6}>
                    <FormControl className='radio-group-custom'>
                        <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            
                            <FormControlLabel className='radio-button-custom' value={0} control={<Radio />} label="Inactive" />
                        </RadioGroup>
                    </FormControl>
                </Grid> */}
                <DialogActions
                    sx={{
                        background: "linear-gradient(195deg, #dee1e1 10%, #f4f4f4 360%)",
                        marginTop: "10%",
                        marginBottom: "0%",
                        width: "104%",
                        borderRadius: '0.5rem',
                        transform: "translateY(35%)",
                        '.action-button': { width: 100 }
                    }}
                >
                    <MDButton color='success' className={`action-button ${disabledSave}`} type="submit" disabled={allTrue()}>{props.updateData ? "Update" : "Add"}</MDButton>
                    <MDButton color='warning' className="action-button" onClick={props.close}>Cancel</MDButton>
                </DialogActions>
            </Grid>
        </Box>
    )
}

export default AddUpdateLookup