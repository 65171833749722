import * as React from 'react';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import AllLeads from 'pages/organization/leads/all-leads/allLeads';
import Tabs from 'devextreme-react/tabs';
import { useRef, useState } from 'react';
import './Navbar.css'
import MyLeads from 'pages/organization/leads/my-leads/MyLeads';
import ProductAssignment from 'examples/LayoutContainers/ProductAssignment';

// const blue = {
//   50: '#F0F7FF',
//   100: '#C2E0FF',
//   200: '#80BFFF',
//   300: '#66B2FF',
//   400: '#3399FF',
//   500: '#007FFF',
//   600: '#0072E5',
//   700: '#0059B2',
//   800: '#004C99',
//   900: '#003A75',
// };

// const grey = {
//   50: '#f6f8fa',
//   100: '#eaeef2',
//   200: '#d0d7de',
//   300: '#afb8c1',
//   400: '#8c959f',
//   500: '#6e7781',
//   600: '#57606a',
//   700: '#424a53',
//   800: '#32383f',
//   900: '#24292f',
// };

// const Tab = styled(TabUnstyled)`
//   font-family: IBM Plex Sans, sans-serif;
//   color: white;
//   cursor: pointer;
//   font-size: 0.875rem;
//   font-weight: bold;
//   background-color: transparent;
//   width: 100%;
//   padding: 12px;
//   margin: 6px 6px;
//   border: none;
//   border-radius: 7px;
//   display: flex;
//   justify-content: center;

//   &:hover {
//     background-color: ${blue[400]};
//   }

//   &:focus {
//     color: #fff;
//     outline: 3px solid ${blue[200]};
//   }

//   &.${tabUnstyledClasses.selected} {
//     background-color: #fff;
//     color: ${blue[600]};
//   }

//   &.${buttonUnstyledClasses.disabled} {
//     opacity: 0.5;
//     cursor: not-allowed;
//   }
// `;

// const TabPanel = styled(TabPanelUnstyled)`
//   width: 100%;
//   font-family: IBM Plex Sans, sans-serif;
//   font-size: 0.875rem;
// `;

// const TabsList = styled(TabsListUnstyled)(
//   ({ theme }) => `
//   min-width: 400px;
//   background-color: ${blue[500]};
//   border-radius: 12px;
//   margin-bottom: 16px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   align-content: space-between;
//   box-shadow: 0px 4px 8px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};
//   `,
// );
export const tabs = [
  {
    id: 0,
    text: 'All Leads',
    icon: '',
    content: 'User tab content',
  },
  {
    id: 1,
    text: 'My Leads',
    icon: '',
    content: 'Comment tab content',
  },
  {
    id: 2,
    text: 'Unasssigned Leads',
    icon: '',
    content: 'Find tab content',
  },
];

export default function ProdutAssignmentNavbar() {
  let  [selectedIndex,setselectedIndex]=useState(0)
 let onTabsSelectionChanged=(args)=> {
    if (args.name === 'selectedIndex') {
      setselectedIndex(args.value)
      }
    }
  
  return (
    <ProductAssignment>
      <DashboardNavbar></DashboardNavbar>
      {/* <div id="tabs">
          <div className="tabs_selected">
          <Tabs
            dataSource={tabs}
            selectedIndex={selectedIndex}
            onOptionChanged={onTabsSelectionChanged}
          />
          </div>
          </div>
          {selectedIndex==0 && <AllLeads></AllLeads>}
          {selectedIndex==1 && <MyLeads></MyLeads>}
          {selectedIndex==2 && <MyLeads></MyLeads>} */}
          {/* {selectedIndex==2 && <Div>unassigned leads pending</Div>} */}
      {/* <TabsUnstyled defaultValue={0}>
      <TabsList>
        <Tab>All Leads</Tab>
        <Tab>My Leads</Tab>
        <Tab>Unassigned Leads</Tab>
      </TabsList>
      <TabPanel value={0}><AllLeads></AllLeads></TabPanel>
      <TabPanel value={1}>Second page</TabPanel>
      <TabPanel value={2}>Third page</TabPanel>
    </TabsUnstyled> */}
    </ProductAssignment>
  );
}