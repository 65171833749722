/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable prettier/prettier */
import { Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, IconButton } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import { Button, Column, DataGrid, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar } from 'devextreme-react/data-grid';
import { Pager } from 'devextreme-react/tree-list';
import 'devextreme/data/odata/store';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import { UsersData } from 'layouts/tables/data/mockdata'
import React, { useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import AddUpdateProducts from './add-update-products'
import DeletePopup from '../delete-popup';
import Caller from 'Apis/config/Caller';
import { GET } from 'Apis/config/RequestType';
import { delete_product } from 'Apis/config/Url';
import { getProductDetails } from 'Apis/Auth/auth';
import CustomStore from 'devextreme/data/custom_store'
import { get_product } from 'Apis/config/Url';
import Footer from 'examples/Footer';
import { LOGIN_AUTH_COOKIE, LOGIN_REFRESH_COOKIE, LEAD_ASSIGN_MODE, UNIVERSAL_SEARCH_COOKIE } from 'Apis/config/ResponseHandler';
import Cookies from 'js-cookie';

const isNotEmpty = (value) => {
  return value !== undefined && value !== null && value !== "";
}
const productDataDetails = new CustomStore({
  key: 'product_id',
  load(loadOptions) {
    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      "filter",
      "totalSummary",
      "group",
      "groupSummary",
      "isLoadingAll"
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {

        if (i === "skip") {
          let page = (loadOptions[i] + 10) / 10;
          if (page !== 1) {
            params += `page=${page}&`;
          }
        } else if (i === "sort") {
          let sortType = loadOptions[i][0]["desc"] ? "desc" : "asc";
          let sortColoumn = loadOptions[i][0]["selector"];
          params += `sortType=${sortType}&sortColoumn=${sortColoumn}&`;
        } else if (i === "filter") {
          let filterO = loadOptions[i][0]["filterValue"];
          params += `s=${filterO}&`;
        } else {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      }
    });
    params = params.slice(0, -1);
    return getProductDetails(params)
      .then((data) => ({
        data: data.data.data,
        totalCount: data.data.count,
        next: data.data.next,
        previous: data.data.previous
      }));
  },

});
const Products = () => {
  let universalSearchCookie = Cookies.get(UNIVERSAL_SEARCH_COOKIE);
  Cookies.set(UNIVERSAL_SEARCH_COOKIE, "")

  const userGrid = useRef();
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [popuptitle, setPopupTitle] = useState("Add Product");
  const [updateData, setUpdate] = useState("");

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setUpdate("");
      setOpen(false);
      setDeleteOpen(false);
    }
  }

  const selectPopupVisible = async (e) => {
    await Caller(get_product + "?id=" + e.row.data.product_id + "&page=1&s=&take=10", "", GET, false, true).then(async (res) => {
      if (res.success === true) {
        setUpdate(res.data.data);
      }
    });
    setPopupTitle("Update Product");
    setOpen(true);
  }

  const deleteVisible = (e) => {
    setUpdate(e.row.data.product_id);
    setDeleteOpen(true);
  }

  const refreshGrid = () => {
    userGrid.current.instance.refresh();
  }
  const refreshGridDelete = () => {
    userGrid.current.instance.refresh();
  }

  const popupDeleteText = "Do you want to delete this product?";

  return (
    <>
      {deleteOpen && <DeletePopup deleteId={updateData} deleteText={popupDeleteText} open={deleteOpen} close={handleClose} path={delete_product} refresh={refreshGridDelete} />}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h4" color="white">
                  Products
                </MDTypography>
              </MDBox>
              <Dialog
                open={open}
                onClose={handleClose}
                maxWidth='lg'
                sx={{
                  '.MuiDialogContent-root': { pl: 5, pr: 5, pb: 5, pt: 2 }
                }}
              >
                <DialogTitle
                ><MDBox
                  color="white"
                  bgColor="info"
                  variant="gradient"
                  borderRadius="lg"
                  shadow="lg"
                  opacity={1}
                  p={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                // textAlign="center"
                >
                    <MDTypography variant="h4" color="white">{popuptitle}</MDTypography>
                    <IconButton
                      style={{ color: "white", cursor: "pointer" }}
                      sx={{
                        "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
                      }}
                      onClick={() => setOpen(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </MDBox></DialogTitle>
                <DialogContent>
                  <AddUpdateProducts updateData={updateData} close={handleClose} refresh={refreshGrid} />
                </DialogContent>
              </Dialog>
              <MDBox p={3}>
                <DataGrid
                  id="gridContainer"
                  dataSource={productDataDetails}
                  keyExpr="product_id"
                  ref={userGrid}
                  showBorders={true}
                  remoteOperations={true}
                  wordWrapEnabled={true}
                  showColumnLines={false}
                  rowAlternationEnabled={true}

                >
                  <Pager
                    visible={true}
                    displayMode="full"
                    showInfo={true}
                    showNavigationButtons={true} />
                  <Paging defaultPageSize={10} />
                  <SearchPanel
                    visible={true}
                    defaultText={universalSearchCookie}
                  />
                  <Export
                    enabled={true}
                    fileName="Product-List"
                  />
                  <Editing
                    mode="row"
                    allowAdding={false}
                    allowUpdating={true}
                    useIcons={true}
                  />
                  <Column
                    caption="Product Name"
                    dataField="product_name"
                    alignment="center"
                    minWidth={150}
                  />
                  <Column
                    caption="Product Details"
                    dataField="product_details"
                    alignment="center"
                    minWidth={150}
                  />

                  <Column type="buttons" dataField="Actions" minWidth={150}>
                    <Button icon="edit" hint="Edit" onClick={selectPopupVisible} />
                    <Button hint="Delete" icon="trash" style={{ color: "red" }} onClick={deleteVisible} />
                  </Column>
                  <Toolbar>

                    <Item name="searchPanel" locateInMenu="auto"/>
                    <Item name="exportButton" locateInMenu="auto"/>
                    <Item location="after" locateInMenu="auto" >
                      <MDButton variant="gradient" color="success" onClick={() => { setOpen(true); setUpdate(""); setPopupTitle("Add Products"); }}>
                        Add&nbsp;
                        <Icon>add</Icon>&nbsp;
                      </MDButton>
                    </Item>
                  </Toolbar>

                </DataGrid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </>
  )
}

export default Products