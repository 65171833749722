/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Data
import { Autocomplete, Box, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, InputAdornment, InputLabel, ListItemText, MenuItem, Modal, Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from "react";
import { Button, Column, ColumnChooser, DataGrid, Lookup, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar, RequiredRule } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { Pager } from 'devextreme-react/tree-list';
import CloseIcon from '@mui/icons-material/Close';
import { GET } from "Apis/config/RequestType";
import { PUT } from "Apis/config/RequestType";
import DeletePopup from "layouts/master-data/delete-popup";
import { delete_user } from "Apis/config/Url";
import Caller from "Apis/config/Caller";
import { useNavigate } from "react-router-dom";
import { getEmployeeUserDetails, product } from "Apis/Auth/auth";
import CustomStore from "devextreme/data/custom_store";
import { change_user_status } from "Apis/config/Url";
import { POST } from "Apis/config/RequestType";
import { leads } from "Apis/config/Url";
import { assigned_products } from "Apis/config/Url";
import { getAllAssignedProductUser } from "Apis/Auth/auth";
import { get_team_member_list } from "Apis/config/Url";
import { SelectBox } from "devextreme-react";
import { DELETE } from "Apis/config/RequestType";
import IconButton from '@mui/material/IconButton';

import { getAllAssignedTeamMember } from "Apis/Auth/auth";
import { createAssignTeamMember } from "Apis/config/Url";
import { updateAssignedTemMember } from "Apis/config/Url";
import { deleteAssignedTeamMember } from "Apis/config/Url";

import { get_related_type_designation } from "Apis/config/Url";
import { getTeamLeader } from "Apis/config/Url";
import { getTeamMember } from "Apis/config/Url";
import { get_product } from "Apis/config/Url";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "10vw",
    },
  },
};


let updateRowData = {}
const assignedTeamMemberDetails = new CustomStore({
  key: 'id',
  load: (loadOptions) => {
    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      "filter",
      "totalSummary",
      "group",
      "groupSummary",
      "isLoadingAll"
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        if (i === "skip") {
          let page = (loadOptions[i] + 25) / 25;
          if (page !== 1) {
            params += `page=${page}&`;
          }
        } else if (i === "sort") {
          let sortType = loadOptions[i][0]["desc"] ? "desc" : "asc";
          let sortColoumn = loadOptions[i][0]["selector"];
          params += `sortType=${sortType}&sortColoumn=${sortColoumn}&`;
        } else if (i === "filter") {
          let filterO = loadOptions[i][0]["filterValue"];
          params += `s=${filterO}&`;
        } else {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      }
    });
    params = params.slice(0, -1);
    return getAllAssignedTeamMember(params)
      .then((data) => ({
        data: data.data.data,
        totalCount: data.data.count,
        next: data.data.next,
        previous: data.data.previous
      }))
  },

  insert: (values) => Caller(createAssignTeamMember, values, POST, false, false).then((res) => {

    values: JSON.stringify(values)
  }),

  update: (key, values) => Caller(updateAssignedTemMember + '?id=' + key, { ...updateRowData, ...values }, POST, false, false).then((res) => {
    values: JSON.stringify(values)
  }),

  remove: (key) => Caller(deleteAssignedTeamMember + '?id=' + key, "", POST, false, false).then((res) => {
    values: JSON.stringify(res)
  }),

});


const teamMemberData = new CustomStore({
  key: 'Value',
  loadMode: 'raw',
  load: (options) => {
    return Caller(getTeamLeader, '', GET, false, false).then((res) => {
      return res.data
    })
  }
});


// get the team member in the team member lookup
const getTeamMemberData = new CustomStore({
  key: 'Value',
  loadMode: 'raw',
  load: (options) => {
    return Caller(getTeamMember, '', POST, false, false).then((res) => {
      return res.data
    })
  }
});

const productData = new CustomStore({
  key: 'Value',
  loadMode: 'raw',
  load: (options) => {
    return Caller(get_product + "?isLoadingAll=true&sortColoumn=product_name&sortType=ASC&id=", '', GET, false, false).then((res) => {
      return res.data.data
    })
  }
});

const departmentData = new CustomStore({
  key: 'Value',
  loadMode: 'raw',
  load: (options) => {
    return Caller(get_related_type_designation + "?isLoadingAll=true&requireTotalCount=true&req_type=departments&id=", '', GET, false, false).then((res) => {
      return res.data.data
    })
  }
});


const isNotEmpty = (value) => {
  return value !== undefined && value !== null && value !== "";
}

const onRowUpdate = (value) => {
  updateRowData = value.oldData
  delete updateRowData.id;
  delete updateRowData.product_id;
  delete updateRowData.employee_user_id;
  delete updateRowData.product_name;
}


const AssignedTeamMembers = () => {

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [popuptitle, setPopupTitle] = useState("Employee Data 1");
  const [updateData, setUpdate] = useState("");
  const [columnsList, setColumnsList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const userGrid = useRef();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [state, setState] = useState({
    product_array: [],
    product: '',
    product_id: '',

    department_array: [],
    department: '',
    department_id: '',
  })
  const [departmentFilterValueSelected, setDepartmentFilterValueSelected] = useState();
  const [productFilterValueSelected, setProductFilterValueSelected] = useState();
  const [departmentArray, setDepartmentArray] = useState([]);
  const [productArray, setProductArray] = useState([]);

  const [allErrors, setAllErrors] = useState({
    product: '',
    product_id: '',
    department: '',
    department_id: '',
  })

  useEffect(() => {
    Caller(get_related_type_designation + "?isLoadingAll=true&requireTotalCount=true&req_type=departments&id=", '', GET, false, true).then((res) => {
      setDepartmentArray(res.data.data)
    });
    Caller(get_product + "?isLoadingAll=true&sortColoumn=product_name&sortType=ASC&id=", '', GET, false, true).then((res) => {
      setProductArray(res.data.data)
    })
  }, [])

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      if (reason !== 'backdropClick') {
        setUpdate("");
        setOpen(false);
        setDeleteOpen(false);
      }
    }
  };

  const refreshGrid = () => {
    userGrid.current.instance.refresh();
  }

  const filterDataGridColumns = async (filterColumn, filterValue, columnIndex, filterType) => {
    let ds = userGrid.current.instance.getDataSource();
    await ds.filter([{ 0: filterColumn, 1: filterType, 2: filterValue, columnIndex: columnIndex, filterValue: filterValue }]);
    await ds.load();
  }

  const clearFilter = async () => {
    let ds = userGrid.current.instance.getDataSource();
    ds.filter(null);
    ds.load();
  }

  const setDepartmentColumnValueFilter = (e, newValue, clickType) => {
    if (clickType == 'clear') {
      setDepartmentFilterValueSelected('');
      clearFilter();
    } else {
      setDepartmentFilterValueSelected(newValue);
    }
    if (newValue) {
      let product_id = productArray?.find(val => val.product_name == productFilterValueSelected);
      let department_id = departmentArray?.find(val => val.name == newValue);
      filterDataGridColumns('department', "&department=" + department_id.id + (productFilterValueSelected ? ("&product_id=" + product_id.product_id) : ""), 0, "=")
    }
  }

  const setProductColumnValueFilter = (e, newValue, clickType) => {
    if (clickType == 'clear') {
      setProductFilterValueSelected('');
      clearFilter();
    } else {
      setProductFilterValueSelected(newValue);
    }
    if (newValue) {
      let product_id = productArray?.find(val => val.product_name == newValue);
      let department_id = departmentArray?.find(val => val.name == departmentFilterValueSelected);
      filterDataGridColumns('product_id', "&product_id=" + product_id.product_id + (departmentFilterValueSelected ? ("&department=" + department_id.id) : ""), 1, "=")
    }
  }

  return (

    <MDBox pt={2} pb={1}>
      {deleteOpen && <DeletePopup deleteId={updateData} open={deleteOpen} deleteText={deleteText} close={handleClose} refresh={refreshGrid} path={delete_user + "?user_type=employee"} />}

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='sm'
        sx={{
          '.MuiDialogContent-root': { pl: 5, pr: 5, pb: 5, pt: 2 }
        }}
      >
        <DialogTitle
        ><MDBox
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          shadow="lg"
          opacity={1}
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        // textAlign="center"
        >
            <MDTypography variant="h4" color="white">{popuptitle}</MDTypography>
            <IconButton
              style={{ color: "white", cursor: "pointer" }}
              sx={{
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
              }}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </MDBox></DialogTitle>

      </Dialog>
      <MDBox p={3} pt={0}>
        <DataGrid
          id="gridContainer"
          keyExpr="id"
          dataSource={assignedTeamMemberDetails}
          ref={userGrid}
          showBorders={true}
          remoteOperations={true}
          wordWrapEnabled={true}
          showColumnLines={false}
          rowAlternationEnabled={true}
          allowColumnReordering={true}
          onRowUpdating={onRowUpdate}
        >
          <ColumnChooser
            enabled={true}
            mode="select"
          />
          <Pager
            visible={true}
            displayMode="full"
            showInfo={true}
            showNavigationButtons={true} />
          <Paging defaultPageSize={25} />
          <SearchPanel
            visible={true}
          />
          <Export
            enabled={true}
            fileName="Assigned Team Members"
          />
          <Editing
            mode="row"
            allowAdding={true}
            allowUpdating={true}
            useIcons={true}
          // allowDeleting={true}
          />

          <Column
            caption="Department"
            alignment="center"
            dataField="department"
            minWidth={150}
            allowEditing={false}
          >
            <Lookup dataSource={departmentData} valueExpr="id" displayExpr="name" />
          </Column>
          <Column
            caption="Product"
            alignment="center"
            dataField="product_id"
            minWidth={150}
            allowEditing={false}
          >
            <RequiredRule />
            <Lookup dataSource={productData} valueExpr="product_id" displayExpr="product_name" />
          </Column>

          <Column
            caption="Team Leaders"
            alignment="center"
            // dataField="full_name"
            dataField="team_leader"
            minWidth={150}
            allowEditing={false}
          >
            <Lookup dataSource={teamMemberData} valueExpr="employee_user_id" displayExpr="name" />
            <RequiredRule />
          </Column>

          <Column
            caption="Team Members"
            dataField="team_member"
            alignment="center"
            minWidth={150}
          >
            <Lookup dataSource={getTeamMemberData} valueExpr="employee_user_id" displayExpr="employee_name" />
            <RequiredRule />
          </Column>

          <Column type="buttons" dataField="Actions" minWidth={150}>
          </Column>

          <Toolbar >
            <Item location="before">
              <Autocomplete
                name="department"
                value={departmentFilterValueSelected || null}
                style={{ width: "15vw", display: "flex", justifyContent: "center", textAlign: "center" }}
                onChange={setDepartmentColumnValueFilter}
                renderInput={(params) => (
                  <TextField {...params} label="Department" />
                )}
                error={!!allErrors.department}

                options={departmentArray?.map((value) => {
                  return value ? value.name : "";
                })}

                sx={{
                  ".MuiOutlinedInput-root": { padding: "0px", height: 45 }
                }}
              />
            </Item>

            <Item location="before">
              <Autocomplete
                value={productFilterValueSelected || null}
                style={{ width: "15vw" }}
                sx={{
                  '& .MuiOutlinedInput-root .MuiAutocomplete-input':
                    { padding: '2px 3px 2px 4px' }
                }}
                onChange={setProductColumnValueFilter}
                renderInput={(params) => (
                  <TextField {...params} label="Product" />
                )}
                error={!!allErrors.product}
                options={productArray?.map((value) => {
                  return value ? value.product_name : "";
                })}

              />

            </Item>

            <Item name="searchPanel" />
            <Item name="exportButton" />
            <Item name="columnChooserButton"
              locateInMenu="auto" />
            {/* <Item location="after" name='addRowButton' showText='always' title='a' > </Item> */}

          </Toolbar>

        </DataGrid>
      </MDBox>
    </MDBox>
  );
}

export default AssignedTeamMembers;
