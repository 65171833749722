/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import team2 from "assets/images/team-2.jpg";
import CabinIcon from '@mui/icons-material/Cabin';


// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import MDButton from "components/MDButton";
import { AppBar, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Icon, IconButton, List, ListItem, ListItemText, Slide, Switch, Tab, Tabs, TextField, Typography, useMediaQuery } from "@mui/material";
import React, { forwardRef, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { makeStyles } from "@mui/styles";
import { Button, Form, Column, ColumnChooser, DataGrid, Lookup, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar, RequiredRule, NumericRule, RangeRule, StringLengthRule, Popup } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { Pager } from 'devextreme-react/tree-list';
import CloseIcon from '@mui/icons-material/Close';
import { UsersData } from "layouts/tables/data/mockdata";
import { GET } from "Apis/config/RequestType";
import { update_user } from "Apis/config/Url";
import { PUT } from "Apis/config/RequestType";
import DeletePopup from "layouts/master-data/delete-popup";
import { delete_user } from "Apis/config/Url";
import Caller from "Apis/config/Caller";
import { useNavigate } from "react-router-dom";
import { getEmployeeUserDetails } from "Apis/Auth/auth";
import CustomStore from "devextreme/data/custom_store";
import { change_user_status } from "Apis/config/Url";
import { POST } from "Apis/config/RequestType";
import { get_employee_user_details } from "Apis/config/Url";
import MDBadge from "components/MDBadge";
import Guid from 'devextreme/core/guid';
import { update_profile } from "Apis/config/Url";
import { getAllLeads } from "Apis/Auth/auth";
import { leads } from "Apis/config/Url";
import { get_product } from "Apis/config/Url";
import { delete_lead } from "Apis/config/Url";
import { leadStatus } from "Apis/Auth/auth";
import RowingIcon from '@mui/icons-material/Rowing';
import OverviewLead from "../single-lead/overview-lead";
import ActivityLog from "../single-lead/activity-log";

import { delete_leads } from "Apis/config/Url";
import { SelectBox, TagBox, Validator } from "devextreme-react";
import { getAllDeals } from "Apis/Auth/auth";
import { dealStage } from "Apis/Auth/auth";
import { dealPriority } from "Apis/Auth/auth";
import { deals_lead } from "Apis/config/Url";
import { delete_deals_lead } from "Apis/config/Url";
import { DELETE } from "Apis/config/RequestType";
import { get_all_crm_accounts } from "Apis/config/Url";
import { save_account_deals_id } from "Apis/config/Url";
import { get_all_crm_contact } from "Apis/config/Url";
import {
  ButtonItem,
  GroupItem,
  SimpleItem,
  Label,
  CompareRule,
  EmailRule,
  PatternRule,
  AsyncRule,
} from 'devextreme-react/form';
import labelTemplates from './labelTemplates.js';

const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    bottom: "0px",
    left: "2.5%",
    top: 40
  }
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let updateRowData = {}
let accountsIds = []
let contactIds = []
const dealsLeadData = new CustomStore({
  key: 'id',
  load: (loadOptions) => {
    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      "filter",
      "totalSummary",
      "group",
      "groupSummary",
      "isLoadingAll"
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {

        if (i === "skip") {
          let page = (loadOptions[i] + 10) / 10;
          if (page !== 1) {
            params += `page=${page}&`;
          }
        } else if (i === "sort") {
          let sortType = loadOptions[i][0]["desc"] ? "desc" : "asc";
          let sortColoumn = loadOptions[i][0]["selector"];
          params += `sortType=${sortType}&sortColoumn=${sortColoumn}&`;
        } else if (i === "filter") {
          let filterO = loadOptions[i][0]["filterValue"];
          params += `s=${filterO}&`;
        } else {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      }
    }),
      params = params.slice(0, -1);
    return getAllDeals(params)
      .then((data) => ({
        data: data.data.data,
        totalCount: data.data.count,
        next: data.data.next,
        previous: data.data.previous
      }));
  },


  insert: (values) => Caller(deals_lead, values, POST, false, false).then((res) => {

    {
      let arrData = []

      for (let i in accountsIds[0]) {
        arrData.push({ 'deals_id': res.data.id, 'account_id': accountsIds[0][i] })
      }
      for (let i in contactIds[0]) {
        arrData.push({ 'deals_id': res.data.id, 'contact_id': contactIds[0][i] })
      }
      // for (let i in accountsIds[0]) {
      //   arrData.push({'deals_id':res.data.id,'account_id':accountsIds[0][i]})
      // }

      Caller(save_account_deals_id, arrData, POST, false, false)
      values: JSON.stringify(values)
    }
  }),

  update: (key, values) => Caller(deals_lead + '?id=' + key, { ...updateRowData, ...values }, PUT, false, false).then((res) => {

    values: JSON.stringify(values)
  }),
  remove: (key) => Caller(delete_deals_lead + '?id=' + key, "", DELETE, false, false).then((res) => {
    values: JSON.stringify(res)
  }),


});
const productData = new CustomStore({
  key: 'Value',
  loadMode: 'raw',
  load: (options) => {
    return Caller(get_product + "?isLoadingAll=true&id", '', GET, false, false).then((res) => {
      return res.data.data
    })
  }
})

const isNotEmpty = (value) => {
  return value !== undefined && value !== null && value !== "";
}

const onRowUpdate = (value) => {
  updateRowData = value.oldData
  updateRowData['insert_accounts'] = [];
  updateRowData['remove_accounts'] = [];
  updateRowData['insert_contacts'] = [];
  updateRowData['remove_contacts'] = [];
  // 
  if (value.newData && value.newData.account_id) {
    let oldAccounts = updateRowData.account_id != null ? updateRowData.account_id.split(',') : [];
    for (let i in value.newData.account_id) {
      if (!oldAccounts.includes(value.newData.account_id[i])) {
        updateRowData['insert_accounts'].push({ 'deals_id': updateRowData.id, 'account_id': value.newData.account_id[i] })
      }
    }
    //
    if (updateRowData.account_id != "") {
      for (let i in oldAccounts) {
        if (!value.newData.account_id.includes(oldAccounts[i])) {
          updateRowData['remove_accounts'].push({ 'deals_id': updateRowData.id, 'account_id': oldAccounts[i] })
        }
      }
    }

  }

  if (value.newData && value.newData.contact_id) {
    let oldContacts = updateRowData.contact_id != null ? updateRowData.contact_id.split(',') : [];
    for (let i in value.newData.contact_id) {
      if (!oldContacts.includes(value.newData.contact_id[i])) {
        updateRowData['insert_contacts'].push({ 'deals_id': updateRowData.id, 'contact_id': value.newData.contact_id[i] })
      }
    }
    //
    if (updateRowData.contact_id != "") {
      for (let i in oldContacts) {
        if (!value.newData.contact_id.includes(oldContacts[i])) {
          updateRowData['remove_contacts'].push({ 'deals_id': updateRowData.id, 'contact_id': oldContacts[i] })
        }
      }
    }

  }



  delete updateRowData.id;
  delete updateRowData.assignee_name;
  delete updateRowData.lead_source_name;
  delete updateRowData.lead_status_name;
  delete updateRowData.product_name;
}
// accounts data
const accountsData = new CustomStore({
  key: 'Value',
  loadMode: 'raw',
  load: (options) => {
    return Caller(get_all_crm_accounts + "?isLoadingAll=true&id", '', GET, false, false).then((res) => {
      return res.data.data
    })
  }
})
const contactsData = new CustomStore({
  key: 'Value',
  loadMode: 'raw',
  load: (options) => {
    return Caller(get_all_crm_contact + "?isLoadingAll=true&id", '', GET, false, false).then((res) => {
      return res.data.data
    })
  }
})

const contacts = (data) => {
  const onContactValueChanged = (e) => {
    data.data.setValue(e.value);
    contactIds.push(e.value);
  }
  const onSelectionChanged = (e) => {
    data.data.component.updateDimensions();
  }
  let myContactIds = []
  let valArray = data.data.value
  if (typeof valArray === 'object' && !Array.isArray(valArray) && valArray !== null) {
    myContactIds = valArray
  } else {
    valArray = "" + valArray
    myContactIds = valArray ? valArray.split(",") : []
  }
  return (
    <TagBox
      dataSource={data.data.column.lookup.dataSource}
      defaultValue={myContactIds}
      valueExpr="id"
      displayExpr="contact_name"
      showSelectionControls={true}
      maxDisplayedTags={3}
      showMultiTagOnly={false}
      applyValueMode="useButtons"
      searchEnabled={true}
      onValueChanged={onContactValueChanged}
      onSelectionChanged={onSelectionChanged}
    />
  )
}
const cellStyle = (container, options) => {
  let val = "" + options.value
  const myarray = val ? val.split(",") : []
  const noBreakSpace = '\u00A0';
  const text = (myarray || []).map((element) => options.column.lookup.calculateCellValue(element)).join(', ');
  container.textContent = text || noBreakSpace;
  return container.title = text;
}

const filterExpresssion = (filterValue, selectedFilterOperation, target) => {
  if (target === 'search' && typeof (filterValue) === 'string') {
    return [this.dataField, 'contains', filterValue];
  }
  return function (data) {
    return (data.AssignedEmployee || []).indexOf(filterValue) !== -1;
  };
}

// ============account data =========================
const accounts = (data) => {

  const onValueChanged = (e) => {
    data.data.setValue(e.value);
    accountsIds.push(e.value);
  }
  const onSelectionChanged = (e) => {
    data.data.component.updateDimensions();
  }

  let myAccountIds = []
  let valArray = data.data.value
  if (typeof valArray === 'object' && !Array.isArray(valArray) && valArray !== null) {
    myAccountIds = valArray
  } else {
    valArray = "" + valArray
    myAccountIds = valArray ? valArray.split(",") : []
  }

  return (
    <TagBox
      dataSource={data.data.column.lookup.dataSource}
      defaultValue={myAccountIds}
      valueExpr="id"
      displayExpr="account_name"
      showSelectionControls={true}
      maxDisplayedTags={3}
      showMultiTagOnly={false}
      applyValueMode="useButtons"
      searchEnabled={true}
      onValueChanged={onValueChanged}
      onSelectionChanged={onSelectionChanged}
    />
  )
}
const cellTemplate = (container, options) => {
  let val = "" + options.value
  const myarray = val ? val.split(",") : []
  const noBreakSpace = '\u00A0';
  const text = (myarray || []).map((element) => options.column.lookup.calculateCellValue(element)).join(', ');
  container.textContent = text || noBreakSpace;
  return container.title = text;
}

const calculateFilterExpression = (filterValue, selectedFilterOperation, target) => {
  if (target === 'search' && typeof (filterValue) === 'string') {
    return [dataField, 'contains', filterValue];
  }
  return function (data) {
    return (data.value || []).indexOf(filterValue) !== -1;
  };
}


const AllDealsLeads = () => {
  const classes = useStyles();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [popuptitle, setPopupTitle] = useState("Employee Data 1");
  const [updateData, setUpdate] = useState("");
  const userGrid = useRef();
  const [open, setOpen] = useState(false);
  const [openLead, setOpenLead] = useState(false);
  const [changes, setChanges] = React.useState([]);
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      if (reason !== 'backdropClick') {
        setUpdate("");
        setOpen(false);
        setOpenLead(false);
        setDeleteOpen(false);
      }
    }
  };
  //   const [editRowKey, setEditRowKey] = React.useState(null);
  //   const onAddButtonClick =(e) => {
  //     const key = new Guid().toString();
  //     setChanges([{
  //       key,
  //       type: 'insert',
  //       insertAfterKey: e.row.key,
  //     }]);
  //     setEditRowKey(key);
  //   };

  //   const isAddButtonVisible = React.useCallback(({ row }) => !row.isEditing, []);

  //   const onRowInserted = React.useCallback((e) => {
  //     e.component.navigateToRow(e.key);
  //   }, []);
  const renderGridCell = (data) => {
    if (data.text == 'High') {
      return <div style={{ color: 'white', backgroundColor: 'red' }}>{data.text}</div>;
    }
    else if (data.text == 'Medium') {
      return <div style={{ color: 'white', backgroundColor: 'Green' }}>{data.text}</div>;
    }
    else {
      return <div style={{ color: 'white', backgroundColor: 'lightblue' }}>{data.text}</div>;
    }
  }

  const renderGridCellDealLength = (data) => {
    if (data.row.data.created_at) {
      var date1 = new Date(data.row.data.created_at);
      var date2 = new Date();

      // To calculate the time difference of two dates
      var Difference_In_Time = date2.getTime() - date1.getTime();

      // To calculate the no. of days between two dates
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      //To display the final no. of days (result)
      return `${Math.round(Difference_In_Days)} Days`;
    }
    else {
      return '';
    }
  }

  const renderGridCellStage = (data) => {
    if (data.text == 'New') {
      return <div style={{ color: 'white', backgroundColor: 'orange' }}>{data.text}</div>;
    }
    else if (data.text == 'Discovery') {
      return <div style={{ color: 'white', backgroundColor: 'lightblue' }}>{data.text}</div>;
    }
    else if (data.text == 'Proposal') {
      return <div style={{ color: 'white', backgroundColor: 'deepgreen' }}>{data.text}</div>;
    }
    else if (data.text == 'Negotiation') {
      return <div style={{ color: 'white', backgroundColor: 'Violet' }}>{data.text}</div>;
    }
    else if (data.text == 'Won') {
      return <div style={{ color: 'white', backgroundColor: 'green' }}>{data.text}</div>;
    }
    else if (data.text == 'Lost') {
      return <div style={{ color: 'white', backgroundColor: 'red' }}>{data.text}</div>;
    }

  }

  const renderGridCellCloseProbability = (data) => {
    return `${data.value} %`
  }
  const renderGridCellDealValue = (data) => {
    if (data.value) {
      return data.value.toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'INR'
      });
    } else {
      return '';
    }

  }

  function cellRender(data) {
    return <img src={team2} style={{ borderRadius: "50%", width: "2.5rem", height: "2.5rem" }} />;
  }

  //  function statusEditorRender(cell) {

  //     return <SelectBox
  //       defaultValue={cell.value}
  //       {...cell.column.lookup}
  //       // onValueChanged={onValueChanged}
  //       itemRender={itemRender}
  //     />;
  //   }

  //   function itemRender(data) {
  //     const imageSource = `images/icons/status-${data.id}.svg`;
  //     if (data != null) {
  //       return <div >

  //         <span className="middle">{'ccccc'}</span>
  //       </div>;
  //     }
  //     return <span>(All)</span>;
  //   }

  const selectPopupVisible = async (e) => {

    const formData = new FormData();
    formData.append("id", e.row.data.id);
    await Caller(get_employee_user_details + "?take=10&requireTotalCount=true&s=&id=" + e.row.data.employee_user_id, "", GET, false, true).then(async (res) => {
      if (res.success === true) {
        setUpdate(res.data.data);
      }
    });
    setPopupTitle("Update Employee");
    setOpen(true);
  }

  const refreshGrid = () => {
    userGrid.current.instance.refresh();
  }
  const deleteVisible = (e) => {
    setUpdate(e.row.data.id);
    setDeleteOpen(true);
  }
  const updateEmployee = (e) => {
    return navigate("/employees/update/" + e.row.data.employee_guid);
  }

  const handleChanged = async (event, id) => {

    let status = "";
    if (event.target.checked === false) {
      status = 0
    }
    else {
      status = 1
    }
    const formData = new FormData();
    formData.append("id", id);
    formData.append("active", status);
    await Caller(change_user_status + "?user_type=employee", formData, POST, false, true)
      .then((data) => {
        if (data.success === true) {
          refreshGrid();
        }
      });
  }

  const returnLoginStatus = (data) => {
    switch (data.value) {
      case 1:
        return <MDBadge badgeContent="Online" variant="gradient" color="success" size="xs" container />
      case 0:
        return <MDBadge badgeContent="Offline" variant="gradient" color="dark" size="xs" container />
      default:
        break;
    }
    // return <MDBadge badgeContent="Online" variant="gradient" color="success" container />
  }




  const renderGridStatus = (data) => {
    let func = "";
    let label = "";
    if (data.data.is_active == 'Yes') {
      func = true
      label = "Active"
    }
    else {
      func = false
      label = "Inactive"
    }

    return <FormControlLabel
      control={<Switch
        checked={func}
        color="secondary"
        onChange={(event) => handleChanged(event, data.data.employee_user_id)}
        inputProps={{ 'aria-label': 'controlled', role: 'switch' }}
      />}
      label={label} />
  }

  const renderProductColumn = (data) => {
    setPopupTitle(data.value.toUpperCase());
    return <MDTypography
      variant="h5"
      color="primary"
      textGradient
      display="inline-flex"
      onClick={() => setOpenLead(true)}
    >
      {data.value}&nbsp;&nbsp;Open
    </MDTypography>
  }

  let deleteText = "Do you want to delete this Employee?";


  return (


    <MDBox pt={2} pb={1}>
      {deleteOpen && <DeletePopup deleteId={updateData} open={deleteOpen} deleteText={deleteText} close={handleClose} refresh={refreshGrid} path={delete_user + "?user_type=employee"} />}




      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='sm'
        sx={{
          '.MuiDialogContent-root': { pl: 5, pr: 5, pb: 5, pt: 2 }
        }}
      >
        <DialogTitle
        ><MDBox
          color="white"
          variant="gradient"
          borderRadius="lg"
          shadow="lg"
          opacity={1}
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        // textAlign="center"
        >
            <MDTypography variant="h4" color="white">{popuptitle}</MDTypography>
            <IconButton
              style={{ color: "white", cursor: "pointer" }}
              sx={{
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
              }}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </MDBox></DialogTitle>
        <DialogContent p={3}>
          {/* <AddUpdateDesignation updateData={updateData} close={handleClose} refresh={refreshGrid} /> */}
        </DialogContent>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='sm'
        sx={{
          '.MuiDialogContent-root': { pl: 5, pr: 5, pb: 5, pt: 2 }
        }}
      >
        <DialogTitle
        ><MDBox
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          shadow="lg"
          opacity={1}
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        // textAlign="center"
        >
            <MDTypography variant="h4" color="white">{popuptitle}</MDTypography>
            <IconButton
              style={{ color: "white", cursor: "pointer" }}
              sx={{
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
              }}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </MDBox></DialogTitle>
        <DialogContent p={3}>
          {/* <AddUpdateDesignation updateData={updateData} close={handleClose} refresh={refreshGrid} /> */}
        </DialogContent>
      </Dialog>
      {/* <EmployeesData1 data={updateData} close={handleClose} />
              <EmployeesData2 data={updateData} close={handleClose} /> */}
      <MDBox p={3}>
        <DataGrid
          id="gridContainer"
          keyExpr="id"
          dataSource={dealsLeadData}
          ref={userGrid}
          showBorders={true}
          remoteOperations={true}
          wordWrapEnabled={true}
          showColumnLines={false}
          rowAlternationEnabled={true}
          allowColumnReordering={true}
          onRowUpdating={onRowUpdate}
        >
          <ColumnChooser
            enabled={true}
            mode="select"
          />
          <Pager
            visible={true}
            displayMode="full"
            showInfo={true}
            showNavigationButtons={true} />
          <Paging defaultPageSize={10} />
          <SearchPanel
            visible={true}
          />
          <Export
            enabled={true}
            fileName="Deals"
          />
          <Editing
            mode="popup"
            allowAdding={true}
            allowUpdating={true}
            useIcons={true}
            allowDeleting={true}
          >
            <Popup
              title="Deals Informations"
              showTitle={true}
              width="52vw"
              height="70vh"
              dragEnabled={false}
            />
            <Form>
              <Item itemType="group" colCount={2} colSpan={2}>
                <Item dataField="deal_name" >
                  <Label render={labelTemplates('user')} />
                </Item>
                <Item dataField="deal_stage" />
                <Item dataField="contact_id" />
                <Item dataField="account_id" />
                <Item dataField="deal_value" />
                <Item dataField="deal_industry" />
                <Item dataField="deal_priority" />
                <Item dataField="expected_close_date" dataType="date" />
                <Item dataField="close_probability" dataType="number" />
                <Item dataField="comments" editorType="dxTextArea" />
              </Item>
            </Form>
          </Editing>
          <Column
            caption="Deal Name"
            alignment="center"
            dataField="deal_name"
            minWidth={150}

          >
            <RequiredRule />
            {/* <Lookup dataSource={productData} valueExpr="product_id"  displayExpr="product_name"/> */}
          </Column>
          <Column
            caption="Stage"
            dataField="deal_stage"
            alignment="center"
            minWidth={150}
            cellRender={renderGridCellStage}
          // editCellRender={statusEditorRender}
          >
            <RequiredRule />
            <Lookup dataSource={dealStage} valueExpr="deal_stage" displayExpr="stage" />
          </Column>
          <Column
            caption="Contacts"
            dataField="contact_id"
            alignment="center"
            minWidth={200}
            // cellRender={renderGridCellStage}
            allowSorting={false}
            editCellComponent={contacts}
            cellTemplate={cellStyle}
            calculateFilterExpression={filterExpresssion}
          >

            {/* <RequiredRule /> */}
            <Lookup dataSource={contactsData} valueExpr="id" displayExpr="contact_name" />
          </Column>
          <Column
            caption="Accounts"
            dataField="account_id"
            alignment="center"
            minWidth={200}
            // cellRender={accounts}
            allowSorting={false}
            editCellComponent={accounts}
            cellTemplate={cellTemplate}
            calculateFilterExpression={calculateFilterExpression}
          >
            {/* <RequiredRule /> */}
            <Lookup dataSource={accountsData} valueExpr="id" displayExpr="account_name" />
          </Column>
          <Column
            caption="Deal Value"
            dataField="deal_value"
            alignment="left"
            cellRender={renderGridCellDealValue}
            minWidth={150}
            dataType="number"
          />
          <Column
            caption="Deal Industry"
            alignment="center"
            dataField="deal_industry"
            // cellRender={renderGridStatus}
            minWidth={150}
          >
            {/* <Lookup dataSource={leadStatus} valueExpr="lead_status"  displayExpr="lead_status"/> */}
          </Column>
          <Column
            caption="Priority"
            dataField="deal_priority"
            alignment="center"
            minWidth={150}
            cellRender={renderGridCell}
          >
            {/* <NumericRule /> */}
            {/* <StringLengthRule message="Name must have at least 10 digits" min={10} max={12} /> */}
            <RequiredRule />
            <Lookup dataSource={dealPriority} valueExpr="deal_priority" displayExpr="priority_at" />
          </Column>
          <Column
            caption="Deal Length"
            dataField=""
            alignment="center"
            minWidth={150}
            visible={true}
            cellRender={renderGridCellDealLength}
          />

          {/* <Column
                    caption="Lead Source"
                    dataField="lead_source"
                    alignment="center"
                    minWidth={150}
                    visible={false}
                  /> */}


          <Column
            caption="Expected Close Date"
            alignment="center"
            dataField="expected_close_date"
            minWidth={150}
            dataType="date"
            visible={false}
            format='dd/MM/yyyy'
          />
          <Column
            caption="Close Probability"
            alignment="center"
            dataField="close_probability"
            dataType="number"
            // format="percent"
            cellRender={renderGridCellCloseProbability}
            minWidth={150}
            visible={false}
          />
          <Column
            caption="Comments"
            alignment="center"
            dataField="comments"
            minWidth={150}
            visible={false}
          />

          <Column type="buttons" dataField="Actions" minWidth={150}>
            {/* <Button icon="edit" hint="Edit" onClick={selectPopupVisible} /> */}
            {/* <Button hint="Delete" icon="trash" style={{ color: "red" }} onClick={deleteVisible} /> */}
          </Column>
          <Toolbar>

            <Item name="searchPanel" />
            <Item name="exportButton" />
            <Item name="columnChooserButton"
              locateInMenu="auto" />
            <Item location="after" name='addRowButton' showText='always' />

          </Toolbar>
          <Toolbar>

            <Item name="searchPanel" />
            <Item name="exportButton" />
            <Item name="columnChooserButton"
              locateInMenu="auto" />
            <Item location="after" name='addRowButton' showText='always' >
              {/* <MDButton variant="gradient" color="success" onClick={onAddButtonClick}>
                        Add&nbsp;
                        <Icon>add</Icon>&nbsp;
                      </MDButton> */}


            </Item>
          </Toolbar>

        </DataGrid>

      </MDBox>



    </MDBox>
  );
}

export default AllDealsLeads;
