import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Card, Grid, IconButton } from '@mui/material';
import MDButton from 'components/MDButton';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PropTypes from 'prop-types';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import TtyIcon from '@mui/icons-material/Tty';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import EditIcon from '@mui/icons-material/Edit';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { useEffect } from 'react';
import Caller from 'Apis/config/Caller';
import { GET } from 'Apis/config/RequestType';
import { lead_activity } from 'Apis/config/Url';
import PreviewIcon from '@mui/icons-material/Preview';
import { useCallback } from 'react';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import moment from 'moment';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage:
    'linear-gradient( 136deg, #aa076b 0%, #61045f 50%, #8e54e9 100%)',
  boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
}));

function ColorlibStepIcon(props) {
  const icons = {
    1: <MeetingRoomIcon />,
    2: <TtyIcon />,
    3: <DocumentScannerIcon />,
    4: <MarkEmailReadIcon />
  };
  let iconState = String(props.type === "Meeting" ? 1 : (props.type === "Call" ? 2 : (props.type === "Notes" ? 3 : 4)))

  return (

    <ColorlibStepIconRoot>
      {icons[iconState]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = [
  {
    label: 'Meeting',
    meeting_created_date: '1/19/2023, 12:29:29 PM',
    description: `For each ad campaign that you create, you can control how much
              you're willing to spend on clicks and conversions, which networks
              and geographical locations you want your ads to show on, and more.`,
  },
  {
    label: 'Note',
    description:
      'An ad group contains one or more ads which target a shared set of keywords.',
  },
  {
    label: 'Call Summary',
    description: `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`,
  },
  {
    label: 'Email',
    subject: 'Are you sure ?',
    description: `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`,
  },
];

const EmailActivities = forwardRef((props, ref) => {
  let updateData = props.updateData ? props.updateData : "";
  let date = new Date().toLocaleString();
  const [timeline, setTimeline] = useState([]);


  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      Caller(lead_activity + "?lead_id=" + updateData.id.substring(3), "", GET, false, true).then(async (res) => {
        if (res.success === true) {
          setTimeline(res.data.data);
        }
      });
    }
  }, [])

  useImperativeHandle(ref, () => ({
    rerender: () => {
      Caller(lead_activity + "?lead_id=" + updateData.id.substring(3), "", GET, false, true).then(async (res) => {
        if (res.success === true) {
          setTimeline(res.data.data);
        }
      });
    }
  }));

  const setLabel = (data, index) => {
    switch (data.type) {
      case "Meeting":
        props.openMeeting(data);
        break;
      case "Call":
        props.openCallSummary(data, null);
        break;
      case "Notes":
        props.openNote(data);
        break;
      case "Email":
        props.openEmail(data);
        break;
      default:
        break;
    }
  }

  function convertDateTime(inputDateTime) {
    // Parse the initial date and time with the specific format
    const parsedDateTime = moment(inputDateTime, "DD/MM/YYYY hh:mm:ss A");
    // Add 5 hours and 30 minutes
    const updatedDateTime = parsedDateTime.add(5, 'hours').add(30, 'minutes').format('DD/MM/YYYY hh:mm:ss A');
    return updatedDateTime;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Stepper
            orientation="vertical"
            style={{
              background: "none",
              boxShadow: "none",
            }}
          >
            {timeline?.map((value, index) => (
              // console.log("Scheduled date and time = ", value.scheduled_time_date)
              // console.log(moment(value.scheduled_time_date).add(5, 'hours').add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss'))
              <Step key={value.id} active={true}>
                <StepLabel StepIconComponent={() => ColorlibStepIcon(value)}>
                  {value.type}
                </StepLabel>
                <Card
                  style={{
                    marginLeft: "2.5%",
                  }}>
                  <StepContent
                    active={true}
                    style={{ borderLeft: "none", paddingLeft: 1, paddingTop: 10, paddingBottom: 10 }}
                  >
                    <Box
                      component="span"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {value.type != "Assignment Log" && <Typography variant="h6" maxWidth="90%">{value.created_date ? value.created_date : value.mail_sent_time}</Typography>}

                      <IconButton color="secondary" size="small" display="flex" justifyContent="right" onClick={() => setLabel(value, index)}>
                        {(value.type != "Email" && value.type != "Assignment Log") && <EditIcon fontSize="inherit" />}
                        {(value.type == "Email") && <PreviewIcon fontSize="inherit" />}
                      </IconButton>
                    </Box>
                    {(value.type == "Assignment Log") && <Typography variant="h6" maxWidth="90%">{value.created_date ? moment.utc(value.created_at).format('YYYY-MM-DD h:mm:ss a') : value.created}</Typography>}
                    {/* {(value.type == "Assignment Log") && <Typography variant="h6" maxWidth="35%" style={{ background: "linear-gradient( 136deg, #FFD200 50%, #fdbb2d 100%)" }} p={1} mb={1}>Assign To Status Change From:- {value.previous_employee_id}-{value.previous_assigne_name} To {value.changed_employee_id}-{value.changed_assigne_name} By {value.changed_by_name}-{value.changed_by_assigne_name} </Typography>} */}
                    {(value.type == "Assignment Log") && <Typography variant="h6" maxWidth="35%" style={{ background: "linear-gradient( 136deg, #FFD200 50%, #fdbb2d 100%)" }} p={1} mb={1}>Assign To Status Change From:- {value.previous_employee_id ? value.previous_employee_id : ' NULL '}-{value.previous_assigne_name} To {value.changed_employee_id ? value.changed_employee_id : ' NULL '}-{value.changed_assigne_name} By {value.changed_by_name}-{value.changed_by_assigne_name} </Typography>}
                    {(value.type == "Meeting") && <Typography variant="h6" maxWidth="35%" style={{ background: "linear-gradient( 136deg, #FFD200 50%, #fdbb2d 100%)" }} p={1} mb={1}>Meeting Scheduled On: {value.scheduled_time_date ? moment.utc(value.scheduled_time_date).format('YYYY-MM-DD h:mm:ss a') : ""}</Typography>}
                    {/* {(value.type == "Meeting") && <Typography variant="h6" maxWidth="35%" style={{ background: "linear-gradient( 136deg, #FFD200 50%, #fdbb2d 100%)" }} p={1} mb={1}>Meeting Scheduled On: {value.scheduled_time_date ? value.scheduled_time_date : ""}</Typography>} */}

                    {(value.type == "Email") && <Typography variant="h6" maxWidth="35%" p={1} mb={1} style={{ background: "linear-gradient( 145deg, #F8FFAE 50%, #34e89e 100%)" }}>Subject: {value.subject ? value.subject : ""}</Typography>}
                    {(value.type == "Email") && <Typography variant="h6" maxWidth="90%">{value.body.replace(/<\/?[^>]+(>|$)|&[a-zA-Z0-9]+;/g, "").length > 200 ? value.body.replace(/<\/?[^>]+(>|$)|&[a-zA-Z0-9]+;/g, "").substring(0, 200) + '...' : value.body.replace(/<\/?[^>]+(>|$)|&[a-zA-Z0-9]+;/g, "")}</Typography>}
                    <Typography variant="h6" maxWidth="90%">{value.description.replace(/<\/?[^>]+(>|$)|&[a-zA-Z0-9]+;/g, "").length > 200 ? value.description.replace(/<\/?[^>]+(>|$)|&[a-zA-Z0-9]+;/g, "").substring(0, 200) + '...' : value.description.replace(/<\/?[^>]+(>|$)|&[a-zA-Z0-9]+;/g, "")}</Typography>
                  </StepContent>
                </Card>

              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
    </>
  );
})

export default EmailActivities;