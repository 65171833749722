/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React context
import { useMaterialUIController, setLayout } from "context";
import AllLeads from "pages/organization/leads/all-leads/allLeads";
import Footer from "examples/Footer";
import { Card, Collapse, Divider, FormControlLabel, Grid, IconButton, InputBase, Slide, Switch } from "@mui/material";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AccountBalanceOutlined, ApiOutlined, AssessmentOutlined, AssignmentIndOutlined, AssignmentOutlined, GroupAddOutlined, MonetizationOnOutlined, Search } from "@mui/icons-material";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";
import AllAccounts from "pages/organization/accounts/all-accounts/allAccounts";
import AllContacts from "pages/organization/contact/all-contact/allContact";
import { useState } from "react";
import { useRef } from "react";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import theme from "assets/theme";
import AllDealsLeads from "pages/organization/leads/deals/deals-lead";
import AllActivities from "pages/organization/leads/activities/allActivities";
import BulkLeadUpload from "pages/organization/leads/bulk-lead/bulk-lead-upload";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `horizontal-tab-${index}`,
    'aria-controls': `horizontal-tabpanel-${index}`,
  };
}


function CrmLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();
  const [value, setValue] = useState(0);
  const [checked, setChecked] = useState(true);
  let slideRef = useRef();

  const handleToggleChange = () => {
    setChecked((prev) => !prev);
  };


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  return (

    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        pl: 0,
        pt: 0,
        pr: 0,
        pb: 0,
        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(80) : pxToRem(250),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      <Grid spacing={0}>
        <Grid item xs={12}>
          <Box
            pt={1}
          >
            <Box sx={{ p: 2 }}>
              <Tabs
                orientation="horizontal"
                value={value}
                onChange={handleChange}
                sx={{ display: 'flex', justifyContent: 'space-between', borderColor: 'divider', p: 1, pt: 1, pl: 3, pr: 3 }}
                TabIndicatorProps={{
                  style: { display: 'none' }
                }}
              >

                {/* <Tab icon={<MonetizationOnOutlined />} sx={{ justifyContent: 'center', fontSize: pxToRem(13), p: 1, "&:hover": { backgroundColor: "#267feaad", color: "white !important" }, "&.Mui-selected": { backgroundColor: "#267feb", color: "white !important" } }} label="Deals" {...a11yProps(0)} />
              <Tab icon={<GroupAddOutlined />} sx={{ justifyContent: 'center', fontSize: pxToRem(13), p: 1, "&:hover": { backgroundColor: "#267feaad", color: "white !important" }, "&.Mui-selected": { backgroundColor: "#267feb", color: "white !important" } }} label="Contacts" {...a11yProps(1)} />
              <Tab icon={<AccountBalanceOutlined />} sx={{ justifyContent: 'center', fontSize: pxToRem(13), p: 1, "&:hover": { backgroundColor: "#267feaad", color: "white !important" }, "&.Mui-selected": { backgroundColor: "#267feb", color: "white !important" } }} label="Accounts" {...a11yProps(2)} /> */}
                <Tab icon={<ApiOutlined />} sx={{ flex: 1, justifyContent: 'center', fontSize: pxToRem(13), p: 1, "&:hover": { backgroundColor: "#267feaad", color: "white !important" }, "&.Mui-selected": { backgroundColor: "#267feb", color: "white !important" } }} label="Leads" {...a11yProps(0)} />
                <Tab icon={<AssignmentOutlined />} sx={{ flex: 1, justifyContent: 'center', fontSize: pxToRem(13), p: 1, "&:hover": { backgroundColor: "#267feaad", color: "white !important" }, "&.Mui-selected": { backgroundColor: "#267feb", color: "white !important" } }} label="Activities" {...a11yProps(1)} />
                {/* <Tab icon={<AssignmentOutlined />} sx={{ justifyContent: 'center', fontSize: pxToRem(13), p: 1, "&:hover": { backgroundColor: "#267feaad", color: "white !important" }, "&.Mui-selected": { backgroundColor: "#267feb", color: "white !important" } }} label="Bulk Lead Upload" {...a11yProps(5)} /> */}
              </Tabs>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {/* <TabPanel value={value} index={0}>
            <MDBox pt={6} pb={3}>

              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h4" color="white">
                        Deals
                      </MDTypography>
                    </MDBox>
                    <AllDealsLeads></AllDealsLeads>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
            <Footer />
          </TabPanel> */}
          {/* <TabPanel value={value} index={1}>
            <MDBox pt={6} pb={3}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h4" color="white">
                        Contacts
                      </MDTypography>
                    </MDBox>
                    <AllContacts></AllContacts>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
            <Footer />
          </TabPanel> */}
          {/* <TabPanel value={value} index={2}>
            <MDBox pt={6} pb={3}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h4" color="white">
                        Accounts
                      </MDTypography>
                    </MDBox>
                    <AllAccounts></AllAccounts>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
            <Footer />
          </TabPanel> */}
          <TabPanel value={value} index={0}>
            <MDBox pb={3}>
              <Grid container>
                <Grid item xs={12}>
                  <Card>
                    {/* <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h4" color="white">
                        Leads
                      </MDTypography>
                    </MDBox> */}
                    <AllLeads></AllLeads>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
            <Footer />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <MDBox pt={6} pb={3}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h4" color="white">
                        Activities
                      </MDTypography>
                    </MDBox>
                    <AllActivities></AllActivities>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
            <Footer />
          </TabPanel>
          {/* <TabPanel value={value} index={5}>
            <MDBox pt={6} pb={3}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h4" color="white">
                        Bulk Lead Upload
                      </MDTypography>
                    </MDBox>
                    <BulkLeadUpload></BulkLeadUpload>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
            <Footer />
          </TabPanel> */}
        </Grid>
      </Grid>
    </MDBox>

  );
}

// Typechecking props for the DashboardLayout
CrmLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CrmLayout;
