/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { LOGIN_AUTH_COOKIE, LOGIN_REFRESH_COOKIE, LEAD_ASSIGN_MODE, UNIVERSAL_SEARCH_COOKIE } from 'Apis/config/ResponseHandler';
import { useState, useEffect, useRef } from "react";
import Cookies from "js-cookie";
// react-router components
import { useLocation, Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { NavLink, useNavigate } from "react-router-dom";
import { clearCache } from "../../../pages/organization/leads/all-leads/allLeads"

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  // setOpenConfigurator,
} from "context";
import { RIGHTS } from 'Apis/config/ResponseHandler';
import { NOT_VERIFIED_OR_SUBMITTED } from 'Apis/config/ResponseHandler';
import { EMP_USER_ID } from 'Apis/config/ResponseHandler';
import Caller from "Apis/config/Caller";
import { logout } from 'Apis/config/Url';

import { check_in } from 'Apis/config/Url';
import { check_out } from 'Apis/config/Url';
import { check_login_status } from 'Apis/config/Url';

import { GET, POST, PUT } from "Apis/config/RequestType";
import { Autocomplete, Box, Popover, TextField } from '@mui/material';
// import { render } from '@testing-library/react';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { universal_search, all_leads } from 'Apis/config/Url';
import { getWhatsappNotification } from 'Apis/config/Url';

import data from 'layouts/tables/data/authorsTableData';
import Badge from '@mui/material/Badge';
// import MailIcon from '@mui/icons-material/Mail';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import ToggleButton from '@mui/material/ToggleButton';
import moment from 'moment';
import { Logout as LogoutIcon, Login as LoginIcon, Timer, Padding, ArrowDropDown } from '@mui/icons-material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import NotificationItem from "examples/Items/NotificationItem";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { LOGIN_USERTYPE_COOKIE } from 'Apis/config/ResponseHandler';

// import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';

import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import { CHATNOTIFICATION } from 'Apis/config/ResponseHandler';
import { ScrollView } from 'devextreme-react';
import { BASE_URL, leads } from '../../../Apis/config/Url'
import { useSnackbar } from 'notistack';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextareaAutosize, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { attendance, get_attendance } from '../../../Apis/config/Url';
import { log } from 'util';


function DashboardNavbar(props, { absolute, light, isMini }) {
  const [searchText, setSearchText] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopper, setOpenPopper] = useState(false);
  const [openNotificationPopper, setOpenNotificationPopper] = useState(false);
  const popoverRef = useRef(null);
  const [isProfile, setIsProfile] = useState(false)
  const [notificationCount, setNotificationCount] = useState(0);
  const [initialRender, setInitialRender] = useState(true)

  // For timer
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const intervalRef = useRef(null);

  const [timeDifference, setTimeDifference] = useState(0)
  //break-time-state
  const [selectedBreak, setSelectedBreak] = useState(null);
  const [breakTimer, setBreakTimer] = useState(0);
  const [isBreakRunning, setIsBreakRunning] = useState(false);
  const breakIntervalRef = useRef(null);
  const [breakTime, setBreakTime] = useState(0);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isValidTime, setIsValidTime] = useState(false);
  const [reason, setReason] = useState('');
  const [enabledYes, setEnabledYes] = useState(false)
  const [inBreak, setInBreak] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [openLeadPopup, setOpenLeadPopup] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [leadPage, setLeadPage] = useState(1);
  const [totalLeadPages, setTotalLeadPages] = useState('');
  const navigate = useNavigate();


  if (initialRender) {
    if (props.isUpdatedClicked) {
      setIsProfile(true)
    }
    setInitialRender(false)
  }
  // if(props.isUpdatedClicked){
  //   setIsProfile(true)
  // }
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openNMenu, setOpenNMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  // const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => {
    Cookies.remove(LOGIN_AUTH_COOKIE);
    Cookies.remove(LOGIN_REFRESH_COOKIE);
    Cookies.remove(RIGHTS);
    Cookies.remove(NOT_VERIFIED_OR_SUBMITTED);
    Cookies.remove(EMP_USER_ID);
    Cookies.remove(LEAD_ASSIGN_MODE);
    Cookies.remove(LOGIN_USERTYPE_COOKIE);
    // setOpenMenu(event.currentTarget);
  }
  const handleCloseMenu = () => setOpenMenu(false);

  // const userLogout = async () => {
  //   clearCache()
  //   await Caller(logout, '', POST, false, false);
  //   handleOpenMenu();
  // }

  const userLogout = async () => {

    try {
        const res = await Caller(logout, '', POST, false, false);
        if (res.success === false) {
            console.error('Logout attempt blocked:', res.message);  
            enqueueSnackbar(res.message, { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
            return;
        }else if (res.success === true) {
            clearCache();
            handleOpenMenu();
            navigate("/authentication/sign-in")
        }

    } catch (error) {
        console.error('Error during logout:', error);
    }
};


  useEffect(() => {
    const handleBeforeUnload = async (event) => {
      if (event.currentTarget.performance.navigation.type == 2) {
        await Caller(logout, '', POST, false, false);
        handleOpenMenu();
      }

    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  // const handleNotificationOpenMenu = (event) => setOpenNMenu(event.currentTarget);
  const handleNotificationOpenMenu = (event) => {
    // setNotificationCount(0); // Clear notifications when the menu is opened
    setOpenNMenu(event.currentTarget);
    setOpenNotificationPopper(true);
  };
  const handleNotificationCloseMenu = () => setOpenNMenu(false);
  // Render the notifications menu
  const renderNotificationMenu = () => (
    <Menu
      anchorEl={openNMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "Top",
        horizontal: "right",
      }}
      open={Boolean(openNMenu)}
      onClose={handleNotificationCloseMenu}
      sx={{ mt: 4 }}
    >
      <NotificationItem icon={<Badge badgeContent={notifications.notificationCount} color="primary"><MailIcon color="action" /></Badge>} title="Anil" />

    </Menu>

  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });


  const handleSearchTextChange = async (e) => {
    let s = e.target.value;
    setSearchText(s);

    if (s === '') {
      setAnchorEl(null);
      setOpenPopper(false);
    } else {
      setAnchorEl(e.currentTarget);
      setOpenPopper(true);
    }

    await Caller(universal_search + `?s=${s}`, '', GET, false, false).then((data) => {
      Cookies.set(UNIVERSAL_SEARCH_COOKIE, e.target.value)
      // setSearchData(data.data)      
      setSearchData(data.data !=null ? data.data : [])
      // if (data.data.length == 0) {
      //   setOpenPopper(false);

      // }
    })
    console.log('seach', searchData)
  };

  const handleClosePopover = () => {
    setOpenPopper(false);
  };

  const handleCloseNotificationPopover = (data) => {
    setOpenNotificationPopper(false);
  };

  const handleTextFieldClick = (event) => {
    if (searchText !== '') {
      setAnchorEl(event.currentTarget);
    }
  };


  const liCss = {
    marginBottom: '5px',
    textDecoration: 'none',
    color: 'black',
    padding: 3,
    marginBottom: "3px ",
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#F9F7F7'
  }


  const timer = () => {
    intervalRef.current = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);
  }

  // const whatsAppNotification = async () => {
  //   await Caller(getWhatsappNotification, '', GET, false, false).then((res) => {
  //     if (res) {
  //       setNotificationCount(res.data.notificationCount)
  //       setNotifications(res.data.chatdata);
  //     }
  //   })
  // }


  // useEffect(() => {

    // const authToken = Cookies.get('user_auth');
    
    // let fullUrl = BASE_URL + getWhatsappNotification;

    // fullUrl += `?authToken=${encodeURIComponent(authToken)}`;

    // const evtSource = new EventSource(fullUrl);

    // evtSource.onopen = () => {
    //   console.log("EventSource connection opened");
    // };

    // evtSource.onmessage = (event) => {
    //   try {
    //     const data = JSON.parse(event.data);
    //     setNotifications(data.chatdata);
    //     setNotificationCount(data.notificationCount);
    //   } catch (error) {
    //     console.error("Error parsing JSON:", error);
    //   }
    // };

    // evtSource.onerror = (error) => {
    //   console.error("EventSource error:", error);
    // };

    // return () => {
    //   evtSource.close();
    // };
  // }, []);




  useEffect(() => {
    (async () => {
      await Caller(check_login_status, '', POST, false, false).then((res) => {
        console.log('response123====',res);
        
        if (res.data?.timeDifference) {
          setIsRunning(true);
          setTimeDifference(res.data?.timeDifference || 0);
          setTime(res.data.timeDifference || 0);
          timer();
        }
        else {
          console.log('enter into else part');
          
          setIsRunning(false);
          clearInterval(intervalRef.current);
          setTime(0);
          setTimeDifference(0);
        }
      })

      // whatsAppNotification();
      // const intervalId = setInterval(whatsAppNotification, 10000);

    })()
  }, []);


  const startStopwatch = async () => {
    setIsRunning(true);
    await Caller(check_in, '', POST, false, false);
    timer();
  };

  const stopStopwatch = async () => {
    setIsRunning(false);
    clearInterval(intervalRef.current);
    setTime(0);
    await Caller(check_out, '', POST, false, false);
    setTimeDifference(0);
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  /////////////BREAK IMPLEMENTATION////////////////////


  const get_attendanceType = async () => {
    await Caller(get_attendance, "", GET, true, false).then((res) => {
      const attendanceData = res.data;
      const dateFormat = 'DD/MM/YYYY HH:mm:ss';

      const validBreakLogs = attendanceData?.filter(breakLog => breakLog.end_time);

      // Extract all start_time and end_time from the valid break logs
      const allBreakTimes = validBreakLogs?.map(breakLog => ({
        start: moment(breakLog.start_time, dateFormat),
        end: moment(breakLog.end_time, dateFormat)
      }));


      const takenBreaks = res.data?.map(breakLog => breakLog.type);

      const updatedBreaks = availableBreaks?.map(breakOption => {
        // Do not disable emergency_break
        if (breakOption.value === 'emergency_break') {
          return breakOption;
        }

        return {
          ...breakOption,
          disabled: takenBreaks?.includes(breakOption.value)
        };
      });

      setAvailableBreaks(updatedBreaks);
      // Update the checkIsCurrentlyInBreak function to use the latest break times
      setAllBreakTimes(allBreakTimes);
      setInBreak(checkIsCurrentlyInBreak(allBreakTimes)); // Update inBreak state immediately after fetching data

    });
  };

  // Separate state to store break times
  const [allBreakTimes, setAllBreakTimes] = useState([]);

  const checkIsCurrentlyInBreak = () => {
    const now = moment();
    const isCurrentlyInBreak = allBreakTimes?.some(breakTime => now.isBetween(breakTime.start, breakTime.end));
    return isCurrentlyInBreak;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const inBreak = checkIsCurrentlyInBreak();
      setInBreak(inBreak);
    }, 1000); // Check every second

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [allBreakTimes]);

  useEffect(() => {
    get_attendanceType();
  }, []);

  // useEffect(() => {
  //   // Check inBreak state on mount
  //   setInBreak(checkIsCurrentlyInBreak(allBreakTimes));

  //   const intervalId = setInterval(() => {
  //     setInBreak(checkIsCurrentlyInBreak(allBreakTimes));
  //   }, 10000); // Check every 10 seconds

  //   // Clear interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, [allBreakTimes]); // Only reset interval when allBreakTimes changes

  const [availableBreaks, setAvailableBreaks] = useState([
    { value: 'short_break_a', label: '15 min break', disabled: false },
    { value: 'short_break_b', label: '15 min break', disabled: false },
    { value: 'lunch', label: 'Lunch break', disabled: false },
    { value: 'emergency_break', label: 'Emergency break', disabled: false },
  ]);


  const handleBreakOpenMenu = (event) => {
    get_attendanceType();
    setAnchorEl(event.currentTarget);
    setErrorMessage(''); // Reset error message when menu opens
  };

  const handleBreakCloseMenu = () => {
    setAnchorEl(null);
    setErrorMessage(''); // Reset error message when menu closes
  };

  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };
  const handleBreakClick = (breakType) => {
    const currentTime = getCurrentTime();
    setStartTime(currentTime);
    validateTimes(currentTime, endTime);
    setEnabledYes(true)

    setSelectedBreak(breakType);
    setConfirmationOpen(true);
    // setStartTime(''); // Reset start time
    setEndTime(''); // Reset end time
    setErrorMessage(''); // Reset error message when a new break is selected
    setIsValidTime(false); // Reset validity when a new break is selected
  };

  const handleConfirmBreak = async () => {

    const formattedStartTime = moment().set({ hour: moment(startTime, 'HH:mm').hours(), minute: moment(startTime, 'HH:mm').minutes(), }).format('YYYY-MM-DD HH:mm:ss');


    const formData = new FormData();

    formData.append('type', selectedBreak)
    // formData.append('start_time', startTime);
    formData.append('start_time', formattedStartTime);

    if (selectedBreak === 'emergency_break') {
      const formattedEndTime = moment().set({ hour: moment(endTime, 'HH:mm').hours(), minute: moment(endTime, 'HH:mm').minutes(), }).format('YYYY-MM-DD HH:mm:ss');

      // formData.append('end_time', endTime);
      formData.append('end_time', formattedEndTime);

      formData.append('reason', reason.trim());

    }

    const response = await Caller(attendance, formData, POST, false, true)

    if (response.success === true) {

      enqueueSnackbar('Break Applied Successfully.', { variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' } });

      setConfirmationOpen(false);
      setIsBreakRunning(true);
      setBreakTime(breakTypeToSeconds(selectedBreak));
      setStartTime('');
      setEndTime('');
      setReason('')
      setEnabledYes(false);
      setAnchorEl(null);

      // call the log get log function.
      get_attendanceType();
    }
  };

  const handleCancelBreak = () => {
    setConfirmationOpen(false);
    setSelectedBreak(null);
    setStartTime('');
    setEndTime('');
    setIsValidTime(false)
    setErrorMessage('');
    setReason('')
    setEnabledYes(false)
    setAnchorEl(null)

  };

  const breakTypeToSeconds = (breakType) => {
    switch (breakType) {
      case 'short_break_a':
        return 15 * 60;
      case 'short_break_b':
        return 15 * 60;
      case 'lunch':
        return 60 * 60;
      case 'emergency_break':
        return 10 * 60;
      default:
        return 0;
    }
  };

  const getBreakLabel = (breakType) => {
    const breakOption = availableBreaks.find(option => option.value === breakType);
    return breakOption ? breakOption.label : '';
  };


  // const handleStartTimeChange = (event) => {
  //   const newStartTime = event.target.value;
  //   setStartTime(newStartTime);
  //   validateTimes(newStartTime, endTime); // Validate times with the new start time
  // };

  const handleStartTimeChange = (event) => {
    const newStartTime = event.target.value;
    setStartTime(newStartTime);
    validateTimes(newStartTime, endTime, reason); // Validate times with the new start time
  };


  // const handleEndTimeChange = (event) => {
  //   const newEndTime = event.target.value;
  //   setEndTime(newEndTime);
  //   validateTimes(startTime, newEndTime); // Validate times with the new end time
  // };

  const handleEndTimeChange = (event) => {
    const newEndTime = event.target.value;
    setEndTime(newEndTime);
    validateTimes(startTime, newEndTime, reason); // Validate times with the new end time
  };

  const handleReasonChange = (event) => {
    const value = event.target.value;
    let filteredValue = value.replace(/[^a-zA-Z0-9 ]/g, '');
    if (filteredValue.startsWith(' ')) {
      filteredValue = filteredValue.trimStart();
    }
    if (filteredValue.length <= 200) {
      setReason(filteredValue);
    }

    validateTimes(startTime, endTime, filteredValue);
  };

  // const validateTimes = (start, end, reason = '') => {
  //   const now = new Date();
  //   const formattedCurrentTime = `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;
  //   const endOfDay = '18:00'; // 6 PM

  //   const startDate = new Date();
  //   const [startHours, startMinutes] = start.split(':').map(Number);
  //   startDate.setHours(startHours, startMinutes);


  //   if ((selectedBreak != 'emergency_break') && (start < formattedCurrentTime)) {
  //     setErrorMessage(`Selected time is incorrect. Start time (${formatAMPM(start)}) is before current time.`);
  //     setIsValidTime(false);
  //     setEnabledYes(false)
  //     return;
  //   }

  //   if (selectedBreak === 'short_break_a' || selectedBreak === 'short_break_b') {
  //     if (start > '17:45') { // After 5:45 PM
  //       setErrorMessage('Selected time is incorrect. 15 min break cannot start after 5:45 PM.');
  //       setIsValidTime(false);
  //       setEnabledYes(false)
  //       return;
  //     }
  //   }

  //   if (selectedBreak === 'lunch') {
  //     if (start > '17:30') { // After 5:30 PM
  //       setErrorMessage('Selected time is incorrect. Lunch break cannot start after 5:30 PM.');
  //       setIsValidTime(false);
  //       setEnabledYes(false)
  //       return;
  //     }
  //   }

  //   if (selectedBreak === 'emergency_break') {
  //     const endDate = new Date();
  //     const [endHours, endMinutes] = end.split(':').map(Number);
  //     endDate.setHours(endHours, endMinutes);

  //     if (start < formattedCurrentTime) {
  //       setErrorMessage(`Selected time is incorrect. Start time (${formatAMPM(start)}) is before current time.`);
  //       setIsValidTime(false);
  //       setEnabledYes(false)
  //       return;
  //     } else { setErrorMessage('') }

  //     if (end > endOfDay) { // After 6 PM
  //       setErrorMessage('Selected time is incorrect. End time must be before 6 PM.');
  //       setIsValidTime(false);
  //       setEnabledYes(false)
  //       return;
  //     }
  //     if (start > endOfDay) { // After 6 PM
  //       setErrorMessage('Selected time is incorrect. End time must be before 6 PM.');
  //       setIsValidTime(false);
  //       setEnabledYes(false)
  //       return;
  //     }

  //     if (endDate <= startDate) {
  //       // setErrorMessage(`Selected time is incorrect. End time (${formatAMPM(end)}) is before or equal to start time (${formatAMPM(start)}).`);
  //       setIsValidTime(false);
  //       setEnabledYes(false)
  //       setEndTime('')
  //       return;
  //     }

  //   }

  //   if (selectedBreak === 'emergency_break' && (!reason || reason.trim().length == 0)) {
  //     // setErrorMessage('Reason must be provided for emergency break.');
  //     setIsValidTime(false);
  //     setEnabledYes(false)
  //     return;
  //   }

  //   setErrorMessage('');
  //   setIsValidTime(true);
  // };

  const validateTimes = (start, end, reason = '') => {
    const now = new Date();
    const formattedCurrentTime = `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;
    const endOfDay = '18:00'; // 6 PM

    const startDate = new Date();
    const [startHours, startMinutes] = start.split(':').map(Number);
    startDate.setHours(startHours, startMinutes);

    if (selectedBreak !== 'emergency_break') {
      const trimmedStart = start.trim();
      console.log('trimmedstart', trimmedStart)
      console.log('formattedCurrentTime', formattedCurrentTime)
      if (!trimmedStart || (/^\d{1,2}:\d{2}\s[AP]M$/.test(trimmedStart))) {
        setErrorMessage('Time format is not correct.');
        setIsValidTime(false);
        setEnabledYes(false);
        return;
      }

      if (trimmedStart < formattedCurrentTime) {
        setErrorMessage(`Selected time is incorrect. Start time (${formatAMPM(trimmedStart)}) is before current time.`);
        setIsValidTime(false);
        setEnabledYes(false);
        return;
      }
    }

    if (selectedBreak === 'short_break_a' || selectedBreak === 'short_break_b') {
      if (start > '17:45') { // After 5:45 PM
        setErrorMessage('Selected time is incorrect. 15 min break cannot start after 5:45 PM.');
        setIsValidTime(false);
        setEnabledYes(false)
        return;
      }
    }

    if (selectedBreak === 'lunch') {
      if (start > '17:30') { // After 5:30 PM
        setErrorMessage('Selected time is incorrect. Lunch break cannot start after 5:30 PM.');
        setIsValidTime(false);
        setEnabledYes(false)
        return;
      }
    }

    if (selectedBreak === 'emergency_break') {
      const endDate = new Date();
      const [endHours, endMinutes] = end.split(':').map(Number);
      endDate.setHours(endHours, endMinutes);

      if (!start || (/^\d{1,2}:\d{2}\s[AP]M$/.test(start))) {
        setErrorMessage('Time format is not correct.');
        setIsValidTime(false);
        setEnabledYes(false);
        return;
      }

      if (!end || (/^\d{1,2}:\d{2}\s[AP]M$/.test(start))) {
        setErrorMessage('Please select a valid End-Time.');
        setIsValidTime(false);
        setEnabledYes(false);
        return;
      }

      if (start < formattedCurrentTime) {
        setErrorMessage(`Selected time is incorrect. Start time (${formatAMPM(start)}) is before current time.`);
        setIsValidTime(false);
        setEnabledYes(false)
        return;
      } else { setErrorMessage('') }

      if (end > endOfDay) { // After 6 PM
        setErrorMessage('Selected time is incorrect. End time must be before 6 PM.');
        setIsValidTime(false);
        setEnabledYes(false)
        return;
      }
      if (start > endOfDay) { // After 6 PM
        setErrorMessage('Selected time is incorrect. End time must be before 6 PM.');
        setIsValidTime(false);
        setEnabledYes(false)
        return;
      }

      if (endDate <= startDate) {
        // setErrorMessage(`Selected time is incorrect. End time (${formatAMPM(end)}) is before or equal to start time (${formatAMPM(start)}).`);
        setIsValidTime(false);
        setEnabledYes(false)
        setEndTime('')
        setErrorMessage('Please select a valid End-Time.');
        return;
      }

      // if (selectedBreak === 'emergency_break' && !reason) {
      if (!reason) {
        setErrorMessage('Reason must be provided for emergency break.');
        setIsValidTime(false);
        setEnabledYes(false)
        return;
      }

    }

    setErrorMessage('');
    setIsValidTime(true);
  };

  const formatAMPM = (time) => {
    const [hours, minutes] = time.split(':');
    const hoursInt = parseInt(hours, 10);
    const period = hoursInt >= 12 ? 'PM' : 'AM';
    const formattedHours = hoursInt % 12 || 12; // Convert 0 to 12 for 12 AM/PM
    return `${formattedHours}:${minutes} ${period}`;
  };

  const calculateEndTime = (startTime, durationMinutes) => {
    const [hours, minutes] = startTime.split(':').map(Number);
    const endTime = new Date();
    endTime.setHours(hours);
    endTime.setMinutes(minutes + durationMinutes);
    return endTime.toTimeString().slice(0, 5); // Format to "HH:MM"
  };

  // const handleLeadClick = (lead) => {
  //   console.log('leading', lead)
  //   setSelectedLead(lead); // Set the clicked lead data
  //   setOpenLeadPopup(true); // Open the popup
  // };
  const getLeadsData = async () => {
    await Caller(all_leads + `?s=${searchText}&onlylead=true&take=50&page=1&id=`, '', GET, false, false).then((data) => {
      Cookies.set(UNIVERSAL_SEARCH_COOKIE, searchText)
      // const leadsItem = data?.data?.find(data => data.name === 'Leads');
      const leadsItem = data?.data
      setSelectedLead(leadsItem?.data)
      setOpenLeadPopup(true)
      setTotalLeadPages(leadsItem?.totalpages)
      setLeadPage(leadsItem?.page)
    })

  }

  const handleContactScroll = async (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const scrollBottom = scrollHeight - clientHeight - scrollTop;
    
    if (Math.floor(scrollBottom) <= 10 && leadPage < totalLeadPages) {
     
      setLeadPage(Number(leadPage + 1))
      let data = await Caller(all_leads + `?s=${searchText}&onlylead=true&take=50&page=${(leadPage) + 1}&id=`, '', GET, false, false)
      if (data) {
        setSelectedLead(prevLeads => [...selectedLead, ...data?.data?.data])
      }     
    
    }

  };


  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => ({
        ...navbar(theme, { transparentNavbar, absolute, light, darkMode }),
        padding: '0px',
      })}

    // sx={{padding:0}}
    >
      <DashboardLayout>
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <MDBox
            color="inherit"
            mb={{ xs: 1, md: 0 }}
            sx={(theme) => ({
              ...navbarRow(theme, { isMini }),
              padding: '0px',
            })}
          >
            {/* <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} /> */}
            <Breadcrumbs icon="home" title={route[route.length - 1] === 'all_payment' ? 'All Payment' : route[route.length - 1].startsWith("update") ? "Update" : route[route.length - 1] == "emailtemplates" ? "Email Templates" : route[route.length - 1]} route={route} light={light} />


          </MDBox>
          {isMini ? null : (
            <MDBox sx={(theme) => ({
              ...navbarRow(theme, { isMini }),
              padding: '0px',
            })}>

              <div style={{ display: 'flex', alignItems: 'center' }}>

                {isRunning && (
                  <>
                    {inBreak && (
                      <Typography
                        variant="h6"
                        component="span"
                        sx={{ color: 'orange', width: '100px', padding: '0px' }}
                      >
                        IN-BREAK
                      </Typography>
                    )}

                    <Button
                      variant="contained"
                      endIcon={<ArrowDropDown style={{ color: 'white' }} />}
                      onClick={handleBreakOpenMenu}
                      style={{ color: 'white', marginRight: '5px', backgroundColor: "#4DA851", width: '150px', paddingLeft: '20px', paddingRight: '20px' }}
                    >
                      Take a break
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleBreakCloseMenu}
                    >
                      {availableBreaks.map((option) => (
                        <MenuItem
                          key={option.value}
                          onClick={() => handleBreakClick(option.value)}
                          disabled={option.disabled}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                )}

                {!isRunning && <Button variant="contained" endIcon={<LoginIcon style={{ color: 'white' }} />} onClick={startStopwatch} style={{ color: 'white', marginRight: '10px', backgroundColor: "#4DA851", width: '130px', paddingLeft: '10px', paddingRight: '10px' }}>Check-in </Button>}
                {isRunning && <Button variant="contained" endIcon={<LogoutIcon style={{ color: 'white' }} />} onClick={stopStopwatch} style={{ color: 'white', marginRight: '10px', backgroundColor: "#F44335", width: '130px', paddingLeft: '10px', paddingRight: '10px' }}>Check-out</Button>}
                {isRunning && <Typography variant="h4" component="h4" sx={{ marginRight: '10px' }}>
                  {formatTime(time)}
                </Typography>
                }
              </div>

              <Dialog
                open={confirmationOpen}
                onClose={handleCancelBreak}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                fullWidth
                PaperProps={{
                  style: { width: '560px', height: '350px' },
                }}
              >
                <DialogTitle id="alert-dialog-title">{"Confirm Break Time"}</DialogTitle>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <InputLabel style={{ marginBottom: '5px' }}>Start Time<sup style={{ color: "red" }}> * </sup></InputLabel>
                      <TextField
                        id="start-time"
                        type="time"
                        value={startTime}
                        onChange={handleStartTimeChange}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    {selectedBreak === 'emergency_break' && (
                      <>
                        <Grid item xs={6}>
                          <InputLabel style={{ marginBottom: '5px' }}>End Time <sup style={{ color: "red" }}> * </sup></InputLabel>
                          <TextField
                            id="end-time"
                            type="time"
                            value={endTime}
                            onChange={handleEndTimeChange}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <InputLabel style={{ marginBottom: '5px' }}>Reason <sup style={{ color: "red" }}> * </sup></InputLabel>

                          <TextareaAutosize
                            minRows={2}
                            value={reason}
                            onChange={handleReasonChange}
                            style={{
                              width: '100%',
                              padding: '5px',
                              resize: 'none'
                            }}
                          />

                        </Grid>
                      </>

                    )}
                  </Grid>
                  {errorMessage && (
                    // <Typography variant="body1" color="error" style={{ marginTop: '10px', fontSize: '1rem',WebkitUserSelect: 'none', // Safari
                    //   MozUserSelect: 'none',    // Firefox
                    //   msUserSelect: 'none',     // Internet Explorer/Edge
                    //   userSelect: 'none' }}>
                    //   {errorMessage}
                    // </Typography>


                    <Typography
                      variant="body1"
                      color="error"
                      style={{
                        marginTop: '10px',
                        fontSize: '1rem',
                        WebkitUserSelect: 'none', // Safari
                        MozUserSelect: 'none',    // Firefox
                        msUserSelect: 'none',     // Internet Explorer/Edge
                        userSelect: 'none'        // Non-prefixed version, supported by Chrome, Opera, and Edge
                      }}
                    >
                      {errorMessage}
                    </Typography>
                  )}
                  {/* {startTime && endTime && !errorMessage && (
                    <Typography variant="body1" style={{ marginTop: '10px' }}>
                      {`Your ${getBreakLabel(selectedBreak)} starts from ${formatAMPM(startTime)} to ${formatAMPM(endTime)}.`}
                    </Typography>
                  )} */}
                  {startTime && !errorMessage && (
                    <Typography variant="body1" style={{ marginTop: '10px', fontSize: '1rem', color: 'rgb(77, 168, 81)', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none', userSelect: 'none' }}>
                      {/* {`Your ${getBreakLabel(selectedBreak)} starts from ${formatAMPM(startTime)}`} */}
                      {/* {selectedBreak != 'emergency_break' && !reason && !endTime ? `Your ${getBreakLabel(selectedBreak)} starts from ${formatAMPM(startTime)}` : (selectedBreak === 'emergency_break' && reason && endTime) ? `Your ${getBreakLabel(selectedBreak)} starts from ${formatAMPM(startTime)}` : <Typography variant="body1" color="error" style={{ marginTop: '10px', fontSize: '1rem' }} >Please select a valid End-Time and Reason. </Typography>} */}
                      {!reason && !endTime && selectedBreak !== 'emergency_break' || selectedBreak === 'emergency_break' && reason && endTime ?
                        `Your ${getBreakLabel(selectedBreak)} starts from ${formatAMPM(startTime)}` :
                        <Typography variant="body1" color="error" style={{ marginTop: '10px', fontSize: '1rem', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none', userSelect: 'none' }}>
                          Please select a valid End-Time and Reason.
                        </Typography>
                      }

                      {selectedBreak === 'short_break_a' || selectedBreak === 'short_break_b'
                        ? ` to ${formatAMPM(calculateEndTime(startTime, 15))}.`
                        : selectedBreak === 'lunch'
                          ? ` to ${formatAMPM(calculateEndTime(startTime, 30))}.`
                          : selectedBreak === 'emergency_break' && endTime
                            ? ` to ${formatAMPM(endTime)}.`
                            : ''}
                    </Typography>
                  )}
                </DialogContent>
                <DialogActions sx={{ mt: 1 }}>
                  {/* <Button variant="contained" onClick={handleCancelBreak} color="secondary" autoFocus> */}


                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#FB8C00', color: '#fff',
                      '&:hover': {
                        backgroundColor: '#FB8C00',
                      },
                    }}
                    onClick={handleCancelBreak}
                  >
                    CANCEL
                  </Button>

                  {/* <Button variant="contained" color="success" onClick={handleConfirmBreak} disabled={selectedBreak === 'emergency_break' ? !(isValidTime) : !(isValidTime || enabledYes)}>
                  SAVE
                </Button> */}

                  <Button variant="contained" sx={{
                    backgroundColor: '#4DA851', color: '#fff', '&:hover': {
                      backgroundColor: '#4DA851',
                    },
                  }}
                    onClick={handleConfirmBreak}
                    // disabled={selectedBreak === 'emergency_break' ? !(isValidTime) : !(isValidTime || enabledYes)}>
                    disabled={selectedBreak === 'emergency_break' ? !(isValidTime && endTime) : !(isValidTime || enabledYes)}>
                    SAVE
                  </Button>
                </DialogActions>
              </Dialog>




              {/* {!isRunning && <Button variant="contained" endIcon={<LoginIcon style={{ color: 'white' }} />} onClick={startStopwatch} style={{ color: 'white', marginRight: '10px', backgroundColor: "#4DA851", width: '150px' }}>Check-in </Button>}
              {isRunning && <Button variant="contained" endIcon={<LogoutIcon style={{ color: 'white' }} />} onClick={stopStopwatch} style={{ color: 'white', marginRight: '10px', backgroundColor: "#F44335", width: '170px' }}> Check-out </Button>} */}
              {/* 
              {isRunning && <Typography variant="h4" component="h4" sx={{ marginRight: '10px' }}>
                {formatTime(time)}
              </Typography>} */}

              <TextField
                required
                id="outlined-required"
                style={{ width: "60%" }}
                placeholder="Search..."
                inputProps={{ maxLength: 50 }}
                autoComplete="off"
                onChange={handleSearchTextChange}
                onClick={handleTextFieldClick}
                inputRef={(input) => openPopper && input?.focus()}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon fontSize="medium" style={{ cursor: 'pointer' }} />
                    </InputAdornment>
                  ),
                }}
              />

              <Dialog
                open={openLeadPopup}
                // onClose={() => setOpenLeadPopup(false)}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    setOpenLeadPopup(false);
                  }
                }}
                maxWidth="lg"
                fullWidth={true}
              >
                {/* <DialogTitle>Lead Details</DialogTitle> */}
                <DialogTitle sx={{ margin: '1.8%', borderRadius: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: '#2196F3', color: '#FFFFFF' }}>
                  Lead Details
                  <IconButton
                    onClick={() => {setOpenLeadPopup(false); setLeadPage(1)}}
                    sx={{ color: '#FFFFFF' }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>

                <DialogContent
                  sx={{
                    width: 'auto',
                    height: '70vh',
                    overflowY: 'auto',
                    bgcolor: '#FFFFFF',
                    scrollbarWidth: 'thin'
                  }}
                  onScroll={handleContactScroll}
                >
                  {selectedLead && selectedLead.length > 0 ? (
                    <Grid container spacing={0} sx={{ border: '1px solid #E0E0E0', borderRadius: 1 }}>
                      {/* Top row with field labels */}
                      <Grid item xs={1} sx={{ borderRight: '1px solid #E0E0E0' }}>
                        <Typography
                          variant='subtitle1'
                          fontWeight="400"
                          sx={{ bgcolor: '#2196F3', color: '#FFFFFF', textAlign: 'center', p: 1 }}
                        >
                          ID
                        </Typography>
                      </Grid>
                      <Grid item xs={3} sx={{ borderRight: '1px solid #E0E0E0' }}>
                        <Typography
                          variant='subtitle1'
                          fontWeight="400"
                          sx={{ bgcolor: '#2196F3', color: '#FFFFFF', textAlign: 'center', p: 1 }}
                        >
                          Name
                        </Typography>
                      </Grid>
                      {/* <Grid item xs={1.5} sx={{ borderRight: '1px solid #E0E0E0' }}>
                        <Typography
                          variant='subtitle1'
                          fontWeight="400"
                          sx={{ bgcolor: '#2196F3', color: '#FFFFFF', textAlign: 'center', p: 1 }}
                        >
                          Last Name
                        </Typography>
                      </Grid> */}
                      <Grid item xs={2} sx={{ borderRight: '1px solid #E0E0E0' }}>
                        <Typography
                          variant='subtitle1'
                          fontWeight="400"
                          sx={{ bgcolor: '#2196F3', color: '#FFFFFF', textAlign: 'center', p: 1 }}
                        >
                          Mobile
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sx={{ borderRight: '1px solid #E0E0E0' }}>
                        <Typography
                          variant='subtitle1'
                          fontWeight="400"
                          sx={{ bgcolor: '#2196F3', color: '#FFFFFF', textAlign: 'center', p: 1 }}
                        >
                          Assigned To
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sx={{ borderRight: '1px solid #E0E0E0' }}>
                        <Typography
                          variant='subtitle1'
                          fontWeight="400"
                          sx={{ bgcolor: '#2196F3', color: '#FFFFFF', textAlign: 'center', p: 1 }}
                        >
                          Product
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          variant='subtitle1'
                          fontWeight="400"
                          sx={{ bgcolor: '#2196F3', color: '#FFFFFF', textAlign: 'center', p: 1 }}
                        >
                          Lead source
                        </Typography>
                      </Grid>



                      {/* Data row starts from here */}
                      {selectedLead?.map((lead, index) => (
                        <Grid container spacing={0} sx={{ borderTop: '1px solid #E0E0E0' }}>
                          <Grid item xs={1} sx={{ borderRight: '1px solid #E0E0E0' }}>
                            <Typography
                              sx={{ color: '#000000', fontSize: '14px', textAlign: 'center', p: 1 }}
                            >
                              {lead.id}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} sx={{ borderRight: '1px solid #E0E0E0' }}>
                            <Typography
                              sx={{ color: '#000000', fontSize: '14px', textAlign: 'center', p: 1 }}
                            >
                              {/* {(selectedLead.first_name != 'null') ? selectedLead.first_name : ''} */}
                              {(lead.first_name != 'null' ? lead.first_name : '') + ((lead.last_name && lead.last_name != 'null') ? ' ' + lead.last_name : '')}

                            </Typography>
                          </Grid>
                          {/* <Grid item xs={1.5} sx={{ borderRight: '1px solid #E0E0E0' }}>
                          <Typography
                            sx={{ fontSize: '14px', textAlign: 'center', p: 1 }}
                          >
                            {(selectedLead.last_name != 'null') ? selectedLead.last_name : ''}
                          </Typography>
                        </Grid> */}
                          <Grid item xs={2} sx={{ borderRight: '1px solid #E0E0E0' }}>
                            <Typography
                              sx={{ color: '#000000', fontSize: '14px', textAlign: 'center', p: 1 }}
                            >
                              {(lead.mobile && lead.mobile != 'null') ? lead.mobile : ''}
                            </Typography>
                          </Grid>
                          <Grid item xs={2} sx={{ borderRight: '1px solid #E0E0E0' }}>
                            <Typography
                              sx={{ color: '#000000', fontSize: '14px', textAlign: 'center', p: 1 }}
                            >
                              {/* {lead.assignee_name != 'null' ? lead.assignee_name : ''} */}
                              {lead.assignee_name !== 'null' && lead.assignee_name ? `${lead.employee_id}-${lead.assignee_name} ` : ''}
                            </Typography>
                          </Grid>
                          <Grid item xs={2} sx={{ borderRight: '1px solid #E0E0E0' }}>
                            <Typography
                              sx={{ color: '#000000', fontSize: '14px', textAlign: 'center', p: 1 }}
                            >
                              {lead.product_name != 'null' ? lead.product_name : ''}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              sx={{ color: '#000000', fontSize: '14px', textAlign: 'center', p: 1 }}
                            >
                              {lead.option_value != 'null' ? lead.option_value : ''}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Typography
                      sx={{ textAlign: 'center', p: 3, color: '#000000', fontSize: '16px' }}
                    >
                      No Lead Found
                    </Typography>

                  )}
                </DialogContent>
              </Dialog>

              <Popover
                open={openPopper}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                placement={'bottom-start'}
                PaperProps={{
                  style: {
                    width: '250px',
                  },
                }}
                ref={popoverRef}
              >
                <Box sx={{ p: 1, borderRadius: 1, bgcolor: '#F9F7F7' }}>
                  <ul style={{ listStyleType: 'none' }}>
                    {searchData.map((data) => (
                      data.name === "Leads" ? (
                        <Link
                          key={data.id}
                          to={(data.link === "/products" ? "/product-assignment" : data.link) + "?isUniversalSearch=true" + (data.link === "/products" ? "&productsTab=true" : (data.link === "/product-assignment" ? "&productsAssignmentTab=true" : ""))}
                          style={{ textDecoration: 'none', color: 'inherit' }} // Optional: ensures the link looks like normal text
                        >
                          <li
                            style={liCss}
                            onMouseEnter={(e) => e.target.style.backgroundColor = '#E5E3E3'}
                            onMouseLeave={(e) => e.target.style.backgroundColor = '#F9F7F7'}
                          >
                            <span>{data.name}</span>
                            <span style={{ float: 'right', marginRight: '10px', marginBottom: '3px' }}>
                              <Badge badgeContent={data.count} color="primary" />
                            </span>
                          </li>
                        </Link>
                      ) : (
                        <Link
                          key={data.id}
                          to={(data.link === "/products" ? "/product-assignment" : data.link) + "?isUniversalSearch=true" + (data.link === "/products" ? "&productsTab=true" : (data.link === "/product-assignment" ? "&productsAssignmentTab=true" : ""))}
                        >
                          <li
                            style={liCss}
                            onMouseEnter={(e) => e.target.style.backgroundColor = '#E5E3E3'}
                            onMouseLeave={(e) => e.target.style.backgroundColor = '#F9F7F7'}
                          >
                            <span>{data.name}</span>
                            <span style={{ float: 'right', marginRight: '10px', marginBottom: '3px' }}>
                              <Badge badgeContent={data.count} color="primary" />
                            </span>
                          </li>
                        </Link>
                      )
                    ))}

                    {/* {searchData.some(data => data.name === 'Leads') && ( */}
                    <li
                      style={{
                        marginTop: '10px',
                        textAlign: 'center',
                        color: '#FF8C00', // Orange color for the button text
                        cursor: 'pointer',
                        fontWeight: 'bold',
                      }}
                      onClick={() => getLeadsData()}

                    >
                      Search in entire CRM
                    </li>
                    {/* )} */}
                  </ul>
                </Box>
              </Popover>


              {!isProfile && <Link
                to="/profile"
              >
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link>}

              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>

              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleNotificationOpenMenu}
              >
                <Badge badgeContent={notificationCount} color="secondary">
                  <WhatsAppIcon sx={iconsStyle} />
                </Badge>
              </IconButton>

              <Popover
                open={openNotificationPopper}
                onClose={() => handleCloseNotificationPopover(null)}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 80, left: 1600 }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                placement={'bottom-start'}
                PaperProps={{
                  style: {
                    width: '500px',
                  },
                }}
              >
                <Box sx={{ p: 1, borderRadius: 1, bgcolor: '#F9F7F7' }}>
                  <ScrollView height="100%">
                    <ul style={{ listStyleType: 'none', maxHeight: "40vh" }}>
                      {
                        notifications.map(data => (
                          <Link to="/chat" onClick={() => handleCloseNotificationPopover(data)}> <li style={liCss} onMouseEnter={(e) => e.target.style.backgroundColor = '#E5E3E3'} onMouseLeave={(e) => e.target.style.backgroundColor = '#F9F7F7'}> <span>{data.first_name}</span> <span>({data.mobile.substring(0, 5) + '....'}):</span> <span>{data.message_type ? (data.message_type == "document" ? "Document" : (data.message_type == "image" ? "Image" : (data.message_type == "video" ? "Video" : data.whatsapp_message))) : data.whatsapp_message}</span>   </li></Link>

                        ))
                      }
                    </ul>
                  </ScrollView>
                </Box>

              </Popover>


              {/* <Link to="/authentication/sign-in"> */}
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  // onClick={handleOpenMenu}
                  onClick={userLogout}
                >
                  <Icon sx={iconsStyle} >logout</Icon>
                </IconButton>
              {/* </Link> */}

            </MDBox>

          )}
        </Toolbar>
      </DashboardLayout>
    </AppBar >
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
