/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import team2 from "assets/images/team-2.jpg";


// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import MDButton from "components/MDButton";
import { Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Icon, IconButton, InputLabel, ListItemText, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { Button, Column, ColumnChooser, DataGrid, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { Pager } from 'devextreme-react/tree-list';
import CloseIcon from '@mui/icons-material/Close';
import { UsersData } from "layouts/tables/data/mockdata";
import { GET } from "Apis/config/RequestType";
import { update_user } from "Apis/config/Url";
import { PUT } from "Apis/config/RequestType";
import DeletePopup from "layouts/master-data/delete-popup";
import { delete_user } from "Apis/config/Url";
import Caller from "Apis/config/Caller";
import { useNavigate } from "react-router-dom";
import { getEmployeeUserDetails } from "Apis/Auth/auth";
import CustomStore from "devextreme/data/custom_store";
import { change_user_status } from "Apis/config/Url";
import { POST } from "Apis/config/RequestType";
import { get_employee_user_details } from "Apis/config/Url";
import MDBadge from "components/MDBadge";
import styled from "@emotion/styled";
import { buttonUnstyledClasses } from "@mui/base";
import { useSnackbar } from "notistack";
import BackupIcon from '@mui/icons-material/Backup';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { import_employee_bulk } from "Apis/config/Url";
import { getImportErrors } from "Apis/Auth/auth";



const ImportEmployeeLogs = (props) => {

    const [deleteOpen, setDeleteOpen] = useState(false);
    const [popuptitle, setPopupTitle] = useState("Employee Data 1");
    const [updateData, setUpdate] = useState("");
    const userGrid = useRef();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [tabVal, setTabVal] = useState(0);

    let url_string = window.location.href;

    const { enqueueSnackbar } = useSnackbar();
    const [dbrowsData, dbsetRowsData] = useState([]);
    const [values, setAllValues] = useState({
        files: "",
        file_name: ""
    })
    const [errors, setAllErrors] = useState({
        files: ""
    })

    const [isInitialRender, setIsInitialRender] = useState(true);
    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);
        }
    }, [])

    const handleChange = (e) => {
        setAllValues((values) => ({
            ...values,
            files: e.target.files[0],
            file_name: e.target.files[0].name
        }))
    }


    function cellRender(data) {
        return <img src={team2} style={{ borderRadius: "50%", width: "2.5rem", height: "2.5rem" }} />;
    }

    const selectPopupVisible = async (e) => {
        const formData = new FormData();
        formData.append("id", e.row.data.id);
        await Caller(get_employee_user_details + "?take=10&requireTotalCount=true&s=&id=" + e.row.data.employee_user_id, "", GET, false, true).then(async (res) => {
            if (res.success === true) {
                setUpdate(res.data.data);
            }
        });
        setPopupTitle("Update Employee");
        setOpen(true);
    }

    // const refreshGrid = () => {
    //     userGrid.current.instance.refresh();
    // }
    const deleteVisible = (e) => {
        setUpdate(e.row.data.employee_user_id);
        setDeleteOpen(true);
    }
    const updateEmployee = (e) => {
        return navigate("/employees/update/" + e.row.data.employee_guid);
    }

    const handleChanged = async (event, id) => {

        let status = "";
        if (event.target.checked === false) {
            status = 0
        }
        else {
            status = 1
        }
        const formData = new FormData();
        formData.append("id", id);
        formData.append("active", status);
        await Caller(change_user_status + "?user_type=employee", formData, POST, false, true)
            .then((data) => {
                if (data.success === true) {
                    refreshGrid();
                }
            });
    }

    const returnLoginStatus = (data) => {
        switch (data.value) {
            case 1:
                return <MDBadge badgeContent="Online" variant="gradient" color="success" size="xs" container />
            case 0:
                return <MDBadge badgeContent="Offline" variant="gradient" color="dark" size="xs" container />
            default:
                break;
        }
        // return <MDBadge badgeContent="Online" variant="gradient" color="success" container />
    }

    const renderGridStatus = (data) => {
        let func = "";
        let label = "";
        if (data.data.is_active == 'Yes') {
            func = true
            label = "Active"
        }
        else {
            func = false
            label = "Inactive"
        }

        return <FormControlLabel
            control={<Switch
                checked={func}
                color="secondary"
                onChange={(event) => handleChanged(event, data.data.employee_user_id)}
                inputProps={{ 'aria-label': 'controlled', role: 'switch' }}
            />}
            label={label} />
    }

    let deleteText = "Do you want to delete this Employee?";


    return (
        <MDBox
            color="white"
            bgColor="white"
            variant="gradient"
            borderRadius="lg"
            shadow="lg"
            opacity={1}
            p={2}
            mt={5}
            border="0.5px solid #eeeeee"
        >
            <DataGrid
                id="gridContainer"
                keyExpr="id"
                dataSource={props.gridData}
                ref={userGrid}
                showBorders={true}
                remoteOperations={true}
                wordWrapEnabled={true}
                showColumnLines={false}
                rowAlternationEnabled={true}
            >
                <ColumnChooser
                    enabled={true}
                    mode="select"
                />
                <Pager
                    visible={true}
                    displayMode="full"
                    showInfo={true}
                    showNavigationButtons={true} />
                <Paging defaultPageSize={10} />
                <SearchPanel
                    visible={true}
                />
                <Export
                    enabled={true}
                    fileName="Import Logs"
                />
                <Column
                    caption="ID"
                    dataField="id"
                    alignment="center"
                    minWidth={150}
                />

                <Column
                    caption="File Name"
                    dataField="file_name"
                    alignment="center"
                    minWidth={350}
                />
                <Column
                    caption="Import Type"
                    dataField="import_type"
                    alignment="center"
                    minWidth={150}
                />
                <Column
                    caption="Total Rows Count"
                    dataField="total_rows_count"
                    alignment="center"
                    minWidth={150}
                />
                <Column
                    caption="Import Status"
                    dataField="import_status"
                    alignment="center"
                    minWidth={150}
                />

                <Toolbar>
                    <Item name="searchPanel" />
                    <Item name="exportButton" />                
                </Toolbar>
            </DataGrid>
        </MDBox>

    );
}

export default ImportEmployeeLogs;
