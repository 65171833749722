/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
/* eslint-disable prettier/prettier */
import { ExpandMore } from "@mui/icons-material";
import {
  Box,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import Caller from "Apis/config/Caller";
import { PUT } from "Apis/config/RequestType";
import { GET, POST } from "Apis/config/RequestType";
import { get_related_type_designation } from "Apis/config/Url";
import { update_lookup } from "Apis/config/Url";

// import { add_calls } from "Apis/config/Url";
import { edit_calls } from "Apis/config/Url";

// import { expiry_date } from "Apis/config/Url";
import { get_symbols } from "Apis/config/Url";

import { post_symbols } from "Apis/config/Url";
import { edit_symbols } from "Apis/config/Url";



import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

import "./styles.css";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "10vw",
    },
  },
};

/// whenever, there will be a need to reset the value. simply, It can be called. 
const initialSetAllValues = {
  exchange: "none",
  type: "none",
  symbol: "",
  strike_rate: "",
  differences: "",
  path: "",
  option_name: "none",
  is_option_chain: "false",
  created_at: "",
  updated_at: "",
  is_active: "none",
  // checkExpiryDisabled: false
};

const AddUpdateCalls = (props) => {
  const [visible, setVisible] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [disabledSave, setDisabledSave] = useState();
  const [values, setAllValues] = useState(initialSetAllValues);

  const [errors, setAllErrors] = useState({

    exchange: "",
    type: "",
    symbol: "",
    strike_rate: "",
    differences: "",
    // path: "",
    option_name: "none",
    is_option_chain: "none",
    created_at: "",
    updated_at: "",
    is_active: "",
  });


  const textUpdate = (e) => {
    const re = /^[a-zA-Z0-9 ]+$/;
    if (re.test(e.target.value) || e.target.value === "") {
      setAllValues((state) => ({
        ...state,
        [`${e.target.name}`]: e.target.value,
      }));
    }
    setAllErrors((values) => ({
      ...values,
      [`${e.target.name}`]: "",
    }));
  };

  const symbolTextUpdate = (e) => {
    const re = /^[a-zA-Z0-9 ]+$/;

    if (re.test(e.target.value) || e.target.value === "") {
      setAllValues((state) => ({
        ...state,
        [`${e.target.name}`]: e.target.value.toUpperCase(),
      }));
    }
    setAllErrors((values) => ({
      ...values,
      [`${e.target.name}`]: "",
    }));
  };

  const digitsUpdate = (f) => {
    // const re = /^\d+$/;   //original
    const re = /^\d{1,}(\.\d{0,2})?$/;
    var charLength = f.target.value.length;
    if (re.test(f.target.value) || f.target.value === "") {
      setAllValues((state) => ({
        ...state,
        [`${f.target.name}`]: f.target.value,
      }));
    }
    setAllErrors((values) => ({
      ...values,
      [`${f.target.name}`]: "",
    }));
  };

  const [isInitialRender, setIsInitialRender] = useState(true);
  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      if (props.updateData) {
        setAllValues((values) => ({
          ...values,
          exchange: props.updateData.exchange,
          type: props.updateData.type,
          symbol: props.updateData.symbol,
          strike_rate: props.updateData.strike_rate,
          differences: props.updateData.differences,
          is_active: props.updateData.is_active === 1 ? "true" : "false",

        }));
      }

    }
  }, []);

  // Option List for 'option'
  const optionOption = [
    { key: "CE", value: "CE" },
    { key: "PE", value: "PE" },
  ];

  /// Option List for 'exchange'
  const exchangeOption = [
    { key: "NSE", value: "NSE" },
    { key: "MCX", value: "MCX" },
  ];

  // Options List for Type
  const NSEtypeOption = [
    { key: "STOCK", value: "STOCK" },
    { key: "INDICES", value: "INDICES" },
    { key: "OPTIDX", value: "OPTIDX" },
    { key: "OPTSTK", value: "OPTSTK" },
    { key: "OPTCUR", value: "OPTCUR" },
    { key: "FUTIDX", value: "FUTIDX" },      // <-- Not required right now >
    { key: "FUTSTK", value: "FUTSTK" },

  ];

  const MCXtypeOption = [
    /// both for MCX Exchange type
    { key: "FUTCOM", value: "FUTCOM" },
    { key: "OPTCOM", value: "OPTCOM" },

  ];


  /// is_active check
  const isActiveOption = [
    { key: "YES", value: "true" },
    { key: "NO", value: "false" },
  ];

  const handleOptionTypeChange = async (e) => {

    switch (e.target.name) {
      case "exchange": {
        /// When it will change, it will reset the all values.
        if (e.target.value != values.exchange) {
          setAllValues(initialSetAllValues);
        }
        await setAllValues((values) => ({ ...values, exchange: e.target.value }));
        break;
      }
      /// there is nothing intresting to share about it.
      case "type": {
        if (!e.target.value.includes("OPT")) {
          setAllValues((values) => ({
            ...values,
            strike_rate: "", differences: "",
            option_name: "none", is_option_chain: "none"
          }))
        }
        await setAllValues((values) => ({ ...values, type: e.target.value }));
        break;
      }

      case "option_name": {
        await setAllValues((values) => ({ ...values, option_name: e.target.value }));
        break;
      }

      case "is_option_chain": {
        await setAllValues((values) => ({ ...values, is_option_chain: e.target.value }));
        break;
      }

      case "is_active": {
        await setAllValues((values) => ({ ...values, is_active: e.target.value }));
        break;
      }

    }

  };

  const addLookup = async (event) => {
    event.target.classList.add("was-validated");
    setDisabledSave("disabled");
    event.preventDefault();
    event.stopPropagation();
    const formData = new FormData();

    // formData.append("product", values.product);

    formData.append("exchange", values.exchange);
    formData.append("type", values.type);
    formData.append("symbol", values.symbol);

    if (values.type.includes("OPT")) {
      formData.append("strike_rate", values.strike_rate);
      formData.append("differences", values.differences);
      formData.append("option_name", values.option_name);
      formData.append("is_option_chain", values.is_option_chain == "none" ? "false" : values.is_option_chain);
    }

    formData.append("is_active", values.is_active);
    // formData.append("path", values.path);

    /// If required fields are empty and user want to sent by editing inspect
    /// from browser then It will return to false response.
    if (allTrue()) {
      return false;
    }

    if (props.updateData) {
      await Caller(edit_symbols + "?id=" + props.updateData.id, formData, POST, false, true)
        .then((res) => {
          if (res.success === true) {
            enqueueSnackbar("Data Updated Successfully!", {
              variant: "success",
              autoHideDuration: 2000,
              TransitionProps: { direction: "left" },
              anchorOrigin: { horizontal: "right", vertical: "top" },
            });
            props.refresh();
            props.close();
          } else if (res.success === false) {
            setDisabledSave("");
            for (const [key, value] of Object.entries(res.message["errors"])) {
              setAllErrors((values) => ({
                ...values,
                [`${key}`]: value,
              }));
            }
          }
        })
        .catch((error) => {
          alert(JSON.stringify(error.message));
        });
    } else {

      await Caller(post_symbols, formData, POST, false, true)
        .then((res) => {
          if (res.success === true) {
            enqueueSnackbar("Added successfully.", {
              variant: "success",
              autoHideDuration: 2000,
              TransitionProps: { direction: "left" },
              anchorOrigin: { horizontal: "right", vertical: "top" },
            });
            props.refresh();
            props.close();
          } else if (res.success === false) {
            setDisabledSave("");
            if(res.message.includes("must be unique")){
              setAllErrors((errors) => ({
                ...errors,
                symbol: `"${values.symbol}" symbol of type "${values.type}" already exists.`,
              }));
            }else{
              for (const [key, value] of Object.entries(res.message["errors"])) {
                setAllErrors((values) => ({
                  ...values,
                  [`${key}`]: value,
                }));
              }
            }
          }
        })
        .catch((error) => {
          alert(JSON.stringify(error.message));
        });
    }
  };

  const allTrue = () => {

    if (
      values.exchange !== "none" &&
      values.type !== "none" &&
      values.symbol &&
      (values.type.includes("OPT") == true ? values.differences : true) &&  // find OPT and validating the fields
      (values.type.includes("OPT") == true ? values.strike_rate : true) &&
      (values.type.includes("OPT") == true ? values.option_name !== "none" : true) &&
      (values.is_active == "false" || values.is_active == "true" || values.is_active != "none")
    ) {
      return false;
    } else {
      return true;
    }
  };


  return (
    <Box
      component="form"
      onSubmit={addLookup}
      sx={{
        width: 750,
        height: 350,

        "#demo-row-radio-buttons-group-label": { fontSize: "medium", textAlign: "left" },
        ".role-select-custom": { width: "100%" },
        ".add-label": { pb: 1 },
        ".radio-button-custom": { fontSize: "small" },
      }}
      noValidate
    >

      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {/* <Grid item xs={2} sm={4} md={4}> */}
        <Grid
          item
          xs={2}
          sm={4}
          md={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <InputLabel className="add-label">
            Exchange :<sup style={{ color: "red" }}>*</sup>
          </InputLabel>

          <Select
            name="exchange"
            IconComponent={() => <ExpandMore className="dropdown-arrow" />}
            style={{ width: "60%", padding: "10px", height: "3.3em", borderRadius: "5px" }}
            displayEmpty
            // disabled={props.updateData ? true : false}
            defaultValue={props.updateData.exchange ?? "none"}
            value={values.exchange}
            onChange={handleOptionTypeChange}
            required
            MenuProps={MenuProps}
          >
            <MenuItem value="none">--Select--</MenuItem>
            {exchangeOption?.map((e, key) => {
              return (
                <MenuItem key={key} value={e.value}>
                  {e.key}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>

        <Grid
          item
          xs={2}
          sm={4}
          md={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <InputLabel className="add-label">
            Type :<sup style={{ color: "red" }}>*</sup>
          </InputLabel>

          <Select
            name="type"
            IconComponent={() => <ExpandMore className="dropdown-arrow" />}
            style={{ width: "60%", padding: "10px", height: "3.3em", borderRadius: "5px" }}
            displayEmpty
            // disabled={props.updateData ? true : false}
            defaultValue={props.updateData.type ?? "none"}
            value={values.type}
            onChange={handleOptionTypeChange}
            error={errors.symbol}
            required
            MenuProps={MenuProps}
          >
            <MenuItem value="none">--Select--</MenuItem>
            {(values.exchange != "NSE" ? MCXtypeOption : NSEtypeOption)?.map((e, key) => {
              return (
                <MenuItem key={key} value={e.value}>
                  {e.key}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>

        <Grid
          item
          xs={2}
          sm={4}
          md={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <InputLabel className="add-label">
            Symbol :<sup style={{ color: "red" }}>*</sup>
          </InputLabel>
          <TextField
            required
            id="outlined-required"
            name="symbol"
            // type="number"
            value={values.symbol}
            error={errors.symbol}
            helperText={errors.symbol === "" ? "" : errors.symbol}
            style={{ width: "60%" }}
            inputProps={{ maxLength: 50 }}
            onChange={symbolTextUpdate}
          // onChange={textUpdate}
          />
        </Grid>

        <>
          <style>
            {`
            .element-visible { display: inline-flex }
            .element-hidden { display: none }
            `}
          </style>
          <Grid
            item
            xs={2}
            sm={4}
            md={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={values.type.includes("OPT") == true ? "element-visible" : "element-hidden"}
          >
            <InputLabel className="add-label">Strike Rate :<sup style={{ color: "red" }}>*</sup></InputLabel>
            <TextField
              id="outlined-required"
              name="strike_rate"
              // type="number"
              value={values.strike_rate}
              error={errors.strike_rate}
              helperText={errors.strike_rate === "" ? "" : errors.strike_rate}
              style={{ width: "60%" }}
              onFocus={(e) =>
                e.target.addEventListener(
                  "wheel",
                  function (e) {
                    e.preventDefault();
                  },
                  { passive: false }
                )
              }
              inputProps={{ maxLength: 9 }}
              onChange={digitsUpdate}
            />
          </Grid>

          <Grid
            item
            xs={2}
            sm={4}
            md={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={values.type.includes("OPT") == true ? "element-visible" : "element-hidden"}
          >
            <InputLabel className="add-label">
              Differences :<sup style={{ color: "red" }}>*</sup>
            </InputLabel>
            <TextField
              required
              id="outlined-required"
              name="differences"
              // type="number"
              value={values.differences}
              error={errors.differences}
              helperText={errors.differences === "" ? "" : errors.differences}
              style={{ width: "60%" }}
              inputProps={{ maxLength: 9 }}
              onChange={digitsUpdate}
            />
          </Grid>

          <Grid
            item
            xs={2}
            sm={4}
            md={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={values.type.includes("OPT") == true ? "element-visible" : "element-hidden"}
          >
            <InputLabel className="add-label">
              Option :<sup style={{ color: "red" }}>*</sup>
            </InputLabel>
            <Select
              name="option_name"
              IconComponent={() => <ExpandMore className="dropdown-arrow" />}
              style={{ width: "60%", padding: "10px", height: "3.3em", borderRadius: "5px" }}
              displayEmpty
              // disabled={props.updateData ? true : false}
              defaultValue={props.updateData.option_name ?? "none"}
              value={values.option_name}
              onChange={handleOptionTypeChange}
              // required
              MenuProps={MenuProps}
            >
              <MenuItem value="none">--Select--</MenuItem>
              {optionOption?.map((e, key) => {
                return (
                  <MenuItem key={key} value={e.value}>
                    {e.key}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>

          <Grid
            item
            xs={2}
            sm={4}
            md={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={values.type.includes("OPT") == true ? "element-visible" : "element-hidden"}
          >
            <InputLabel className="add-label">
              Is Option<br />Chain:
            </InputLabel>
            <Select
              name="is_option_chain"
              IconComponent={() => <ExpandMore className="dropdown-arrow" />}
              style={{ width: "60%", padding: "10px", height: "3.3em", borderRadius: "5px" }}
              displayEmpty
              // disabled={props.updateData ? true : false}
              defaultValue={props.updateData.is_option_chain ?? "none"}
              value={values.is_option_chain}
              onChange={handleOptionTypeChange}
              // required
              MenuProps={MenuProps}
            >
              <MenuItem value="none">--Select--</MenuItem>
              {isActiveOption?.map((e, key) => {
                return (
                  <MenuItem key={key} value={e.value}>
                    {e.key}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </>

        <Grid
          item
          xs={2}
          sm={4}
          md={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <InputLabel className="add-label">
            Is Active :<sup style={{ color: "red" }}>*</sup>
          </InputLabel>
          <Select
            name="is_active"
            IconComponent={() => <ExpandMore className="dropdown-arrow" />}
            style={{ width: "60%", padding: "10px", height: "3.3em", borderRadius: "5px" }}
            displayEmpty
            // disabled={props.updateData ? true : false}
            defaultValue={
              props.updateData.is_active == 1
                ? "true"
                : props.updateData.is_active == 0
                  ? "false"
                  : "none"
            }
            value={values.is_active}
            onChange={handleOptionTypeChange}
            required
            MenuProps={MenuProps}
          >
            <MenuItem value="none">--Select--</MenuItem>
            {isActiveOption?.map((e, key) => {
              return (
                <MenuItem key={key} value={e.value}>
                  {e.key}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>

        <DialogActions
          sx={{
            background: "linear-gradient(195deg, #dee1e1 10%, #f4f4f4 360%)",
            marginTop: "10%",
            marginBottom: "0%",
            width: "104%",
            borderRadius: "0.5rem",
            transform: "translateY(35%)",
            ".action-button": { width: 100 },
          }}
        >
          <MDButton
            color="success"
            className={`action-button ${disabledSave}`}
            type="submit"
            disabled={allTrue()}
          >
            {props.updateData ? "Update" : "Add"}
          </MDButton>
          <MDButton color="warning" className="action-button" onClick={props.close}>
            Cancel
          </MDButton>
        </DialogActions>
      </Grid>
    </Box>
  );
};

export default AddUpdateCalls;
