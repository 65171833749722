/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
/* eslint-disable prettier/prettier */
import { FileUpload } from '@mui/icons-material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { Box, Button, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import MDButton from 'components/MDButton';
import React, { useEffect, useState } from 'react';
import Caller from 'Apis/config/Caller';
import { POST, GET } from 'Apis/config/RequestType';
import { create_user, update_user, department_designation, update_profile } from 'Apis/config/Url';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import Footer from 'examples/Footer';
import { Link, useNavigate } from 'react-router-dom';
import { department, demat_roles, designation, employment_status, employment_type } from 'Apis/Auth/auth';
import { useSnackbar } from 'notistack';
import { get_employee_user_details } from 'Apis/config/Url';
import { get_product } from 'Apis/config/Url';
import { reporting_manager } from 'Apis/config/Url';
import BackupIcon from '@mui/icons-material/Backup';
import { BASE_URL } from 'Apis/config/Url';
import { get_related_admin_user } from 'Apis/config/Url';
import MDAvatar from "components/MDAvatar";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import EmployeesData1 from 'pages/organization/employees/create';
import { useLocation } from "react-router-dom";



const UpdateProfile = (props) => {
    let url_string = window.location.href;
    let myParam = url_string.split('update/')[1];

    const { enqueueSnackbar } = useSnackbar();
    const [dbrowsData, dbsetRowsData] = useState([]);
    const [values, setAllValues] = useState({
        employee_user_id: "",
        employee_id: "",
        first_name: '',
        last_name: "",
        email: "",
        mobile: "",
        extension: '',
        profile_pic: "",
        password: "",
        department_id: "",
        department: [],
        designation: [],
        designation_id: "",
        demat_roles: "",
        employment_type: "",
        employment_status: "",
        date_of_joining: "",
        reporting_manager_id: "",
        reporting_manager: [],
        date_of_birth: "",
        gender: "Female",
        marital_status: "Unmarried",
        uan: "",
        pan: "",
        aadhar: "",
        date_of_exit: "",
        products_value: []
    })
    const [selectedImage, setSelectedImage] = useState(null);

    const [products, setProducts] = useState([]);
    const navigate = useNavigate();
    const [disabledSave, setDisabledSave] = useState();
    const [eidDisable, setEidDisable] = useState(false);
    const [errors, setAllErrors] = useState({
        employee_id: "",
        employee_user_id: '',
        first_name: '',
        last_name: "",
        email: "",
        mobile: "",
        extension: '',
        profile_pic: "",
        password: "",
        department_id: "",
        department: [],
        designation: [],
        designation_id: "",
        demat_roles: "",
        employment_type: "",
        employment_status: "",
        date_of_joining: "",
        reporting_manager: "",
        date_of_birth: "",
        gender: "",
        marital_status: "",
        uan: "",
        pan: "",
        aadhar: "",
        date_of_exit: "",
        products: "",
        profile_pic: '',
    })
    let [picUrl, setPicUrl] = useState('')
    const [isInitialRender, setIsInitialRender] = useState(true);
    const [employee_id_update, setEmployee_id_update] = useState()
    const [checkForUpdateProfile, setCheckForUpdateProfile] = useState()
    const location = useLocation();

    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);
            if (location.state) {
                setCheckForUpdateProfile(location.state.message)
            }
            Caller(get_related_admin_user, "", GET, false, true).then(async (res) => {
                if (res.success === true) {
                    setEidDisable(true);
                    // setSelectedImage(res.data.data[0].profile_pic)
                    if (res.data[0].profile_pic !== null) {
                        let filePath = `${BASE_URL}public/profile_photos/${res.data[0].profile_pic}`
                        fetch(filePath)
                            .then(response => {
                                response.blob().then(blob => {
                                    let url = window.URL.createObjectURL(blob);
                                    setPicUrl(url)
                                });
                            });
                    };
                    setEmployee_id_update(res.data[0].employee_user_id)
                    setAllValues((values) => ({
                        ...values,
                        employee_id: res.data[0].employee_id ? res.data[0].employee_id : '',
                        employee_user_id: res.data[0].employee_user_id,
                        first_name: res.data[0].first_name,
                        last_name: res.data[0].last_name,
                        profile_pic: res.data[0].profile_pic,
                        email: res.data[0].username,
                        mobile: res.data[0].mobile,
                        extension: res.data[0].extension,
                        department_id: res.data[0].department,
                        designation_id: res.data[0].designation,
                        demat_roles: res.data[0].user_type,
                        employment_type: res.data[0].employment_type,
                        employment_status: res.data[0].employment_status,
                        date_of_joining: res.data[0].date_of_joining ? res.data[0].date_of_joining.split("/").reverse().join("-") : '',
                        reporting_manager_id: res.data[0].reporting_manager,
                        date_of_birth: res.data[0].date_of_birth ? res.data[0].date_of_birth.split("/").reverse().join("-") : '',
                        gender: res.data[0].gender,
                        marital_status: res.data[0].marital_status,
                        uan: res.data[0].uan,
                        pan: res.data[0].pan,
                        aadhar: res.data[0].aadhar,
                        date_of_exit: res.data[0].date_of_exit ? res.data[0].date_of_exit.split("/").reverse().join("-") : '',
                        products_value: res.data[0].product_id ? [res.data[0].product_id] : [],

                    }))

                    if (res.data[0].document_files) {
                        dbsetRowsData(res.data[0].document_files)
                    }
                }
            });


            Caller(get_product + "?id=&take=10&requireTotalCount=true&page=1&isLoadingAll=true", "", GET, false, true).then(async (res) => {
                if (res.success === true) {
                    await setProducts(res.data.data);
                }
            });
            Caller(department_designation + "?type=Department", "", GET, false, true).then(async (res) => {
                if (res.success === true) {

                    await setAllValues((values) => ({
                        ...values,
                        department: res.data
                    }));
                }
            });
            Caller(department_designation + "?type=Designation", "", GET, false, true).then(async (res) => {
                if (res.success === true) {

                    await setAllValues((values) => ({
                        ...values,
                        designation: res.data
                    }));
                }
            });




        }
    }, [])


    const downloadFile = async (filePath, fileName) => {
        fetch(filePath)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.setAttribute(
                        'download',
                        fileName,
                    );

                    // Append to html link element page
                    document.body.appendChild(a);

                    // Start download
                    a.click();

                    // Clean up and remove the link
                    a.parentNode.removeChild(a);
                });
            });

    };
    const handleChange = (index, evnt) => {
        let re = /^.{0,100}$/;
        const { name, value } = evnt.target;
        if (evnt.target.value.match(re) || evnt.target.value === '') {
            const rowsInput = [...dbrowsData];
            (name == "file_name") ? (rowsInput[index]["file_path"] = evnt.target.files[0], rowsInput[index]["file_name"] = evnt.target.files[0].name) : rowsInput[index][name] = value;
            dbsetRowsData(rowsInput);
        }

    }

    const autoGeneratePassword = (e) => {
        let length = 15,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+./-",
            retVal = "";
        for (let i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        setAllValues((values) => ({
            ...values,
            password: retVal
        }))
        setAllErrors((state) => ({
            ...state,
            [`${e.target.name}`]: ""
        }))
    }

    const textUpdate = (e) => {
        const re = /^[\-a-zA-Z0-9 ]+$/;
        if (re.test(e.target.value) || (e.target.value === "")) {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }))
        }
        setAllErrors((state) => ({
            ...state,
            [`${e.target.name}`]: ""
        }))
    }
    const nameUpdate = (e) => {
        const re = /^[a-zA-Z ]+$/;
        if (re.test(e.target.value) || (e.target.value === "")) {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }))
        }
        setAllErrors((state) => ({
            ...state,
            [`${e.target.name}`]: ""
        }))
    }

    const uanUpdate = (e) => {
        const re = /^[a-zA-Z0-9 ]+$/;

        if (re.test(e.target.value) || (e.target.value === "")) {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }))
        }
        setAllErrors((state) => ({
            ...state,
            [`${e.target.name}`]: ""
        }))
    }
    const panUpdate = (e) => {
        const re = /^[a-zA-Z0-9 ]+$/;
        if (re.test(e.target.value) || (e.target.value === "")) {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }))
        }
        setAllErrors((state) => ({
            ...state,
            [`${e.target.name}`]: ""
        }))
    }
    const aadharUpdate = (e) => {
        const re = /^[0-9]+$/;
        if (re.test(e.target.value) || (e.target.value === "")) {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }))
        }
        setAllErrors((state) => ({
            ...state,
            [`${e.target.name}`]: ""
        }))
    }

    const passUpdate = (e) => {
        setAllValues((state) => ({
            ...state,
            [`${e.target.name}`]: e.target.value
        }))
        setAllErrors((state) => ({
            ...state,
            [`${e.target.name}`]: ""
        }))
    }
    const emailUpdate = (e) => {
        setAllValues((state) => ({
            ...state,
            [`${e.target.name}`]: e.target.value
        }))
        setAllErrors((state) => ({
            ...state,
            [`${e.target.name}`]: ""
        }))
    }

    const onMobileUpdate = (e) => {
        const re = /^[1-9][0-9]*$/;
        if (re.test(e.target.value) || (e.target.value === "")) {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }))
        }
        setAllErrors((state) => ({
            ...state,
            [`${e.target.name}`]: ""
        }))
    }
    const dematRoleUpdate = (e) => {
        if (e.target.value != 1) {
            Caller(reporting_manager + "?type=" + e.target.value, "", GET, false, true).then(async (res) => {
                if (res.success === true) {
                    setAllValues((values) => ({
                        ...values,
                        reporting_manager: res.data
                    }));
                }
            });
        }
        setAllValues((values) => ({
            ...values,
            demat_roles: e.target.value
        }));
        setAllErrors((state) => ({
            ...state,
            [`${e.target.name}`]: ""
        }))
    }

    const addTableRows = (event) => {
        event.preventDefault();
        const rowsInput = {
            document_name: '',
            document_number: '',
            file_name: '',
            file_path: ""
        }
        if (dbrowsData !== undefined && dbrowsData.length < 10) {
            dbsetRowsData([...dbrowsData, rowsInput])
        } else if (dbrowsData.length < 10) {
            dbsetRowsData([rowsInput])
        }

    }

    const productSelected = (products) => {
        return products.join(", ");

    }

    const deleteTableRows = (index) => {
        const rows = [...dbrowsData];
        rows.splice(index, 1);
        if (index === "clear") {
            rows.splice(0, rows.length);
        }
        dbsetRowsData(rows);
    }

    const updateAdmin = async (event) => {
        event.target.classList.add("was-validated");
        setDisabledSave('disabled');
        event.preventDefault();
        event.stopPropagation();

        const formData = new FormData();
        formData.append('id', values.employee_user_id);
        formData.append('employee_user_id', values.employee_id);
        formData.append('first_name', values.first_name);
        formData.append('last_name', values.last_name);
        formData.append('profile_pic', values.profile_pic);
        formData.append('mobile', values.mobile);
        formData.append('phone', values.mobile);
        formData.append('date_of_birth', values.date_of_birth);
        formData.append('gender', values.gender);
        formData.append('is_logged_in', 1);
        formData.append('marital_status', values.marital_status);
        formData.append('product_id', values.products_value);
        formData.append('document', dbrowsData ? JSON.stringify(dbrowsData) : '');
        for (let i = 0; i < dbrowsData.length; i++) {
            formData.append("files", dbrowsData[i].file_path);
        }
        await Caller(update_profile + "?user_type=employee", formData, POST, false, true).then((res) => {
            if (res.success === true) {
                enqueueSnackbar(`Employee ${"updated"} successfully!`, { variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
                navigate("/profile");
            }
            else if (res.success === false) {
                setDisabledSave('');
                // for (const [key, value] of Object.entries(res.message['errors'])) {
                //     setAllErrors((values) => ({
                //         ...values,
                //         [`${key}`]: value,
                //     }))
                // }
            }
        })
    }

    const selectedFileHandler = (event) => {

        setAllValues((state) => ({
            ...state,
            profile_pic: event.target.files[0]
        }))
        setSelectedImage(URL.createObjectURL(event.target.files[0]));
    };


    const allTrue = () => {
        // if (myParam) {
        if (values.first_name && values.last_name && values.email && values.department_id && values.designation_id && values.demat_roles && values.employment_type && values.employment_status && values.date_of_birth && values.gender && values.marital_status && values.mobile) {
            return false;
        } else {
            return true;
        }

        // } else {
        //     if (values.first_name && values.last_name && values.email && values.password && values.department_id && values.designation_id && values.demat_roles && values.employment_type && values.employment_status && values.date_of_birth && values.gender && values.marital_status && values.mobile) {
        //         return false;
        //     } else {
        //         return true;
        //     }
        // }
    }
    let isProfileClicked = false;

    if (checkForUpdateProfile == "navigatedToProfile") {
        isProfileClicked = true;
    }

    return (
        <>

            {/* <EmployeesData1 isProfile={true} data={{ 'employee_user_id': employee_id_update }} ></EmployeesData1> */}
            {isProfileClicked && <EmployeesData1 isProfile={true} data={{ 'employee_user_id': employee_id_update }} />}

        </>

    )
}

export default UpdateProfile